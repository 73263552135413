import React from 'react';

export const SecondaryRedCircle = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 3107 2393'
      style={{ enableBackground: 'new 0 0 3107 2393' }}
      xmlSpace='preserve'
    >
      <circle fill='#F54634' cx='1553.5' cy='1196.5' r='982.48' />
      <g id='_x31_z81j6_1_'>
        <g>
          <path
            fill='#D50000'
            fill='#D50000'
            d='M960.41,608.22c4.71,0.03,7.25,2.68,7.19,7.51c-0.05,4.72-3.41,8.49-7.4,8.3c-3.85-0.18-6.96-3.91-6.93-8.29
			C953.32,610.75,955.75,608.19,960.41,608.22z'
          />
          <path
            fill='#D50000'
            d='M953.29,663.44c0.12-4.9,2.59-7.33,7.39-7.3c4.49,0.03,7.12,2.97,6.93,7.73c-0.18,4.48-3.35,8.08-7.13,8.1
			C956.51,672,953.17,668.03,953.29,663.44z'
          />
          <path
            fill='#D50000'
            d='M2408.51,858.58c-0.01-4.58,2.85-8.13,6.72-8.34c3.62-0.2,7.58,4.04,7.62,8.16c0.04,4.13-3.8,8.44-7.45,8.37
			C2411.56,866.69,2408.53,863.08,2408.51,858.58z'
          />
          <path
            fill='#D50000'
            d='M2408.51,906.48c-0.03-4.51,2.9-8.14,6.73-8.33c3.64-0.18,7.57,4.05,7.61,8.17c0.04,4.1-3.87,8.49-7.47,8.38
			C2411.55,914.6,2408.54,911,2408.51,906.48z'
          />
          <path
            fill='#D50000'
            d='M2408.53,783.64c0.05-4.51,3.04-8.06,6.87-8.16c3.68-0.1,7.51,4.2,7.45,8.36c-0.06,4.14-3.45,7.98-7.14,8.1
			C2411.64,792.07,2408.48,788.42,2408.53,783.64z'
          />
          <path
            fill='#D50000'
            d='M2408.52,1003.37c-0.03-4.49,2.97-8.16,6.77-8.29c3.68-0.12,7.55,4.1,7.56,8.23
			c0.01,4.09-3.95,8.48-7.52,8.33C2411.5,1011.49,2408.55,1007.91,2408.52,1003.37z'
          />
          <path
            fill='#D50000'
            d='M2364.67,858.16c0.06-4.53,3.32-8.16,7.1-7.91c3.51,0.23,7.24,4.55,7.19,8.32c-0.05,3.73-3.98,8.12-7.34,8.2
			C2367.68,866.87,2364.6,863.05,2364.67,858.16z'
          />
          <path
            fill='#D50000'
            d='M966.64,1294.82c0.09,4.73-2.61,7.86-6.89,7.98c-4.63,0.13-7.32-2.49-7.46-7.29
			c-0.14-4.68,3.03-8.56,7.06-8.64C963.16,1286.79,966.55,1290.49,966.64,1294.82z'
          />
          <path
            fill='#D50000'
            d='M996.17,907.45c0.06-4.64,2.93-7.91,7.01-7.97c3.81-0.06,7.43,4.09,7.29,8.35c-0.15,4.44-3.24,7.9-7.17,8.03
			C999.35,915.99,996.11,912.17,996.17,907.45z'
          />
          <path
            fill='#D50000'
            d='M2408.51,808.61c-0.07-4.71,3.16-8.61,7.05-8.49c3.62,0.11,7.14,3.98,7.27,8c0.14,4.07-3.66,8.53-7.28,8.55
			C2411.82,816.69,2408.58,812.97,2408.51,808.61z'
          />
          <path
            fill='#D50000'
            d='M966.64,1342.71c0.11,4.7-2.63,7.9-6.86,8.03c-4.58,0.15-7.34-2.53-7.48-7.27c-0.14-4.69,2.99-8.56,7.01-8.67
			C963.12,1334.7,966.54,1338.39,966.64,1342.71z'
          />
          <path
            fill='#D50000'
            d='M2408.51,955.4c0.07-4.56,3.03-8.02,6.93-8.09c3.79-0.07,7.44,4.05,7.39,8.34c-0.05,4.45-3.68,8.32-7.59,8.08
			C2411.35,963.51,2408.44,959.9,2408.51,955.4z'
          />
          <path
            fill='#D50000'
            d='M1422.44,518.18c-0.15,4.45-3.77,8.07-7.86,7.85c-3.94-0.2-6.88-3.87-6.71-8.36
			c0.19-4.79,2.95-7.58,7.42-7.49C1419.21,510.27,1422.58,514.03,1422.44,518.18z'
          />
          <path
            fill='#D50000'
            d='M974.03,1440.51c0.09-4.48,3.34-7.89,7.33-7.69c4.04,0.2,7.26,3.79,7.2,8.03c-0.07,4.37-3.66,7.93-7.85,7.76
			C976.41,1448.45,973.93,1445.44,974.03,1440.51z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1588.31c-0.09,4.81-2.68,7.41-7.31,7.35c-4.19-0.06-7.12-3.33-7.03-7.83
			c0.08-4.25,3.43-7.95,7.23-7.98C1898.05,1579.81,1901.3,1583.68,1901.21,1588.31z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1587.87c0.04-4.3,3.33-8.04,7.06-8.03c4.02,0.01,7.38,3.91,7.26,8.43
			c-0.12,4.58-3.08,7.51-7.46,7.39C1911.12,1595.54,1908.78,1592.88,1908.83,1587.87z'
          />
          <path
            fill='#D50000'
            d='M2400.93,858.34c0.05,4.79-3.15,8.59-7.1,8.43c-3.49-0.15-7.14-4.23-7.25-8.12c-0.12-4.16,3.58-8.35,7.42-8.4
			C2397.78,850.2,2400.88,853.82,2400.93,858.34z'
          />
          <path
            fill='#D50000'
            d='M2364.67,905.91c0.11-4.23,3.16-7.71,6.79-7.75c3.63-0.04,7.67,4.55,7.49,8.51
			c-0.17,3.76-4.23,8.08-7.52,8.01C2367.47,914.6,2364.54,910.79,2364.67,905.91z'
          />
          <path
            fill='#D50000'
            d='M1443.59,443.12c0.07,4.89-2.52,8.01-6.74,8.14c-4.25,0.13-7.77-3.53-7.71-8.05
			c0.05-4.24,3.38-7.75,7.38-7.78C1440.94,435.4,1443.52,438.2,1443.59,443.12z'
          />
          <path
            fill='#D50000'
            d='M945.7,907.53c0.01,4.69-3.3,8.52-7.2,8.32c-3.41-0.17-7.18-4.6-7.11-8.35c0.07-3.85,3.8-7.79,7.48-7.9
			C942.9,899.49,945.69,902.72,945.7,907.53z'
          />
          <path
            fill='#D50000'
            d='M974.07,735.16c0.04-4.97,2.51-7.76,6.92-7.79c3.96-0.03,7.29,3.43,7.4,7.7c0.11,4.54-3.28,8.21-7.53,8.14
			C976.67,743.13,974.03,740.01,974.07,735.16z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1294.69c0.06-4.28,3.47-8,7.21-7.86c3.96,0.15,7.09,3.72,7.11,8.08c0.02,4.54-2.93,7.76-7.11,7.76
			C1933.18,1302.68,1930.68,1299.92,1930.75,1294.69z'
          />
          <path
            fill='#D50000'
            d='M980.93,1545.56c-4.54-0.09-7.1-3.19-6.89-8.35c0.17-4.17,3.32-7.49,7.1-7.46c3.95,0.03,7.34,3.63,7.4,7.85
			C988.6,1541.93,985.05,1545.65,980.93,1545.56z'
          />
          <path
            fill='#D50000'
            d='M967.61,955.37c0.08,4.67-3.21,8.49-7.25,8.41c-3.91-0.08-7.02-3.63-7.08-8.08
			c-0.06-4.62,3.26-8.44,7.24-8.34C964.41,947.47,967.53,951,967.61,955.37z'
          />
          <path
            fill='#D50000'
            d='M1452.97,566.7c-0.06-4.52,2.97-8.29,6.74-8.4c3.89-0.11,7.69,4.01,7.6,8.26c-0.08,3.85-3.84,8.09-7.3,8.23
			C1456.24,574.93,1453.03,571.24,1452.97,566.7z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1490.88c0.04-4.27,3.37-8.02,7.09-7.99c4,0.03,7.35,3.97,7.22,8.48c-0.13,4.6-3.08,7.48-7.5,7.35
			C1911.06,1498.57,1908.78,1495.95,1908.83,1490.88z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1491.19c-0.03,4.84-2.56,7.51-7.14,7.53c-4.26,0.02-7.17-3.1-7.16-7.68c0-4.51,3.55-8.35,7.53-8.16
			C1898.18,1483.07,1901.24,1486.82,1901.21,1491.19z'
          />
          <path
            fill='#D50000'
            d='M2400.93,783.67c0,4.66-2.88,8.12-6.84,8.22c-3.74,0.09-7.61-4.22-7.51-8.38c0.09-3.82,3.99-8.04,7.43-8.04
			C2397.77,775.46,2400.93,779.21,2400.93,783.67z'
          />
          <path
            fill='#D50000'
            d='M2422.84,978.75c0,4.16-3.45,8.11-7.15,8.19c-3.95,0.08-7.12-3.44-7.18-7.94c-0.06-4.71,3.27-8.67,7.15-8.51
			C2419.3,970.63,2422.84,974.71,2422.84,978.75z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1733.45c-0.01-4.92,2.41-7.59,6.95-7.67c4.23-0.07,7.29,3.03,7.37,7.46
			c0.09,4.55-3.33,8.45-7.33,8.35C1911.98,1741.5,1908.84,1737.84,1908.83,1733.45z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1003.21c-0.06-4.36,3.09-8.1,6.93-8.23c3.87-0.13,7.25,3.46,7.37,7.83
			c0.14,4.96-2.71,8.17-7.23,8.14C1955.32,1010.92,1952.75,1008.1,1952.69,1003.21z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1393.19c-0.07-4.37,3-8.14,6.76-8.33c4.08-0.2,7.56,3.53,7.56,8.12c0,4.56-2.93,7.73-7.16,7.72
			C1933.28,1400.71,1930.83,1398.13,1930.75,1393.19z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1393.07c0-4.63,3.35-8.36,7.39-8.21c3.82,0.14,6.99,3.91,6.91,8.21c-0.09,4.67-2.92,7.66-7.21,7.63
			C1955.3,1400.67,1952.69,1397.87,1952.69,1393.07z'
          />
          <path
            fill='#D50000'
            d='M2378.95,1003.19c0.08,3.75-3.64,8.18-7.1,8.44c-3.74,0.28-7.1-3.39-7.19-7.85c-0.1-4.88,2.94-8.73,6.86-8.69
			C2374.93,995.13,2378.87,999.42,2378.95,1003.19z'
          />
          <path
            fill='#D50000'
            d='M944.68,1441.19c-0.13,5.1-2.46,7.53-7.14,7.42c-3.84-0.08-7.24-3.84-7.17-7.93
			c0.07-4.47,3.67-8.07,7.86-7.86C942.27,1433.02,944.81,1436.31,944.68,1441.19z'
          />
          <path
            fill='#D50000'
            d='M2430.45,1052.2c0.04-4.6,3.07-8.03,7.02-7.93c3.52,0.08,7.3,4.33,7.3,8.21c0,4.07-4.11,8.55-7.77,8.17
			C2433.51,1060.29,2429.59,1056.8,2430.45,1052.2z'
          />
          <path
            fill='#D50000'
            d='M1445.39,566.4c0.04,4.41-3.55,8.5-7.34,8.35c-3.74-0.15-6.98-3.94-7-8.18c-0.02-4.61,3.35-8.45,7.31-8.3
			C1442.25,558.41,1445.35,561.99,1445.39,566.4z'
          />
          <path
            fill='#D50000'
            d='M1923.15,808.49c0.02,4.32-3.16,8.18-6.87,8.34c-3.9,0.17-7.51-3.87-7.45-8.33c0.06-4.55,3.09-7.79,7.25-7.75
			C1920.44,800.8,1923.12,803.73,1923.15,808.49z'
          />
          <path
            fill='#D50000'
            d='M1959.83,1140.87c3.92,0.04,7.13,3.6,7.16,7.93c0.03,4.82-3.01,8.04-7.48,7.92
			c-4.13-0.11-6.84-3.28-6.82-7.98C1952.7,1144.36,1955.91,1140.83,1959.83,1140.87z'
          />
          <path
            fill='#D50000'
            d='M945.68,808.92c-0.05,4.66-2.97,8-6.94,7.93c-3.88-0.07-7.57-4.28-7.37-8.41c0.21-4.21,3.66-7.56,7.64-7.42
			C943.59,801.19,945.74,803.74,945.68,808.92z'
          />
          <path
            fill='#D50000'
            d='M945.86,955.99c-0.1,4.22-3.75,8.05-7.42,7.78c-3.43-0.25-7.17-4.74-7.05-8.45c0.12-3.77,4.1-7.96,7.54-7.95
			C942.95,947.38,945.97,951.14,945.86,955.99z'
          />
          <path
            fill='#D50000'
            d='M1938.11,573.95c-3.92-0.06-7.27-3.78-7.18-7.98c0.09-4.44,3.29-7.86,7.36-7.86c4.16,0,7.64,3.9,7.43,8.32
			C1945.5,570.82,1942.26,574.01,1938.11,573.95z'
          />
          <path
            fill='#D50000'
            d='M1894.77,573.95c-4.08,0.06-7.38-3.24-7.57-7.57c-0.2-4.39,3.39-8.35,7.49-8.27
			c4.03,0.08,7.25,3.56,7.34,7.91C1902.1,570.21,1898.73,573.9,1894.77,573.95z'
          />
          <path
            fill='#D50000'
            d='M988.4,857.6c-0.01,3.86-3.84,8.14-7.33,8.19c-3.69,0.05-7.5-4.37-7.39-8.57c0.11-4.11,3.86-8.06,7.5-7.89
			C984.82,849.49,988.41,853.6,988.4,857.6z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1197.89c0.03-4.29,3.36-8.05,7.08-7.99c3.96,0.06,7.16,3.58,7.24,7.95
			c0.08,4.79-2.98,8.02-7.49,7.89C1933.1,1205.62,1930.72,1202.87,1930.75,1197.89z'
          />
          <path
            fill='#D50000'
            d='M2400.93,1003.41c-0.02,4.51-3.03,8.14-6.82,8.23c-3.6,0.09-7.5-4.25-7.52-8.36
			c-0.03-4.16,3.86-8.34,7.62-8.18C2398.02,995.25,2400.95,998.88,2400.93,1003.41z'
          />
          <path
            fill='#D50000'
            d='M1937.71,873.12c3.97-0.04,7.26,3.38,7.36,7.67c0.11,4.52-3.36,8.47-7.33,8.33
			c-3.83-0.13-6.99-3.81-6.99-8.14C1930.76,876.24,1933.47,873.17,1937.71,873.12z'
          />
          <path
            fill='#D50000'
            d='M960.75,558.1c4.29,0.05,6.82,2.74,6.85,7.3c0.03,4.56-3.36,8.58-7.2,8.54c-3.83-0.04-7.25-4.18-7.11-8.62
			C953.44,560.47,955.95,558.05,960.75,558.1z'
          />
          <path
            fill='#D50000'
            d='M953.3,857.27c0.03-4.33,3.31-8,7.14-7.97c4.08,0.03,7.29,3.79,7.17,8.42c-0.11,4.41-3.34,8.06-7.14,8.06
			C956.46,865.78,953.26,861.99,953.3,857.27z'
          />
          <path
            fill='#D50000'
            d='M975.21,955.78c-0.04-5.01,2.76-8.37,7.01-8.36c3.52,0,7.22,4.03,7.32,7.97c0.11,4.4-3.36,8.36-7.34,8.39
			C978.45,963.8,975.25,960.15,975.21,955.78z'
          />
          <path
            fill='#D50000'
            d='M2408.51,881.76c0.03-4.49,3.17-8.19,6.95-8.19c3.81,0,7.55,4.31,7.39,8.54c-0.15,4.1-3.83,7.97-7.45,7.84
			C2411.38,889.79,2408.48,886.35,2408.51,881.76z'
          />
          <path
            fill='#D50000'
            d='M1494.73,443.53c-0.06-5.08,2.29-7.95,6.61-8.1c4.1-0.14,7.82,3.63,7.8,7.89c-0.03,4.27-3.3,7.82-7.31,7.94
			C1497.59,451.39,1494.78,448.33,1494.73,443.53z'
          />
          <path
            fill='#D50000'
            d='M1901.82,1148.88c0.01,4.62-3.18,7.93-7.58,7.84c-3.9-0.08-7.13-3.64-7.15-7.87
			c-0.01-4.33,3.12-7.82,7.15-7.96C1898.37,1140.73,1901.81,1144.37,1901.82,1148.88z'
          />
          <path
            fill='#D50000'
            d='M2371.4,1043.93c3.94-0.05,7.4,3.48,7.56,7.7c0.16,4.25-3.41,8.18-7.41,8.15c-4.58-0.03-6.88-2.6-6.88-7.69
			C2364.66,1047.19,2367.31,1043.98,2371.4,1043.93z'
          />
          <path
            fill='#D50000'
            d='M1952.69,856.53c-0.02-4.79,2.63-7.79,6.97-7.88c4.29-0.09,7.23,2.96,7.33,7.61
			c0.09,4.45-3.45,8.58-7.31,8.52C1956.04,864.72,1952.71,860.79,1952.69,856.53z'
          />
          <path
            fill='#D50000'
            d='M1443.6,493.41c0.02,4.83-2.68,7.94-6.91,7.97c-4.18,0.03-7.75-3.82-7.58-8.18c0.15-4.08,3.69-7.67,7.55-7.65
			C1440.88,485.57,1443.59,488.63,1443.6,493.41z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1393.11c0,4.81-2.58,7.57-7.09,7.6c-4.25,0.03-7.19-3.08-7.23-7.64c-0.04-4.59,3.38-8.34,7.46-8.2
			C1920.08,1385.01,1923.15,1388.7,1923.15,1393.11z'
          />
          <path
            fill='#D50000'
            d='M1880.15,1149.1c-0.14,4.27-3.49,7.67-7.51,7.62c-4.36-0.05-7.43-3.65-7.14-8.38
			c0.27-4.46,3.59-7.72,7.62-7.46C1877.14,1141.14,1880.29,1144.82,1880.15,1149.1z'
          />
          <path
            fill='#D50000'
            d='M1930.75,856.58c-0.06-4.79,2.57-7.81,6.88-7.92c4.14-0.1,7.3,3.12,7.44,7.61c0.14,4.42-3.38,8.51-7.3,8.5
			C1934.16,864.75,1930.8,860.84,1930.75,856.58z'
          />
          <path
            fill='#D50000'
            d='M1872.9,1302.68c-4.57,0.02-7.47-3.14-7.37-8.04c0.08-4.04,3.27-7.65,6.91-7.81
			c4.13-0.18,7.67,3.52,7.71,8.08C1880.2,1299.06,1876.82,1302.67,1872.9,1302.68z'
          />
          <path
            fill='#D50000'
            d='M2378.96,955.59c0,4.02-4.09,8.31-7.77,8.15c-3.7-0.15-6.58-3.92-6.53-8.52c0.05-4.59,3.16-8.07,7.06-7.91
			C2375.21,947.45,2378.97,951.75,2378.96,955.59z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1003.27c-0.02-4.42,3.05-8.12,6.87-8.28c4-0.17,7.49,3.71,7.45,8.28c-0.04,4.63-2.92,7.69-7.23,7.7
			C1933.39,1010.97,1930.78,1008.14,1930.75,1003.27z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1003.21c-0.01,4.79-2.67,7.71-7.07,7.75c-4.28,0.03-7.15-3.01-7.25-7.67
			c-0.09-4.54,3.39-8.44,7.4-8.31C1920.07,995.11,1923.15,998.79,1923.15,1003.21z'
          />
          <path
            fill='#D50000'
            d='M1960.01,1237.82c3.9,0.11,7.01,3.71,6.98,8.08c-0.03,4.68-3.25,7.95-7.65,7.77
			c-4.04-0.17-6.73-3.47-6.65-8.15C1952.77,1241.16,1956.05,1237.71,1960.01,1237.82z'
          />
          <path
            fill='#D50000'
            d='M945.7,760.84c-0.02,4.68-2.88,8.09-6.8,8.09c-4.01,0-7.64-3.99-7.52-8.26c0.12-4.23,3.49-7.64,7.49-7.57
			C943.46,753.18,945.72,755.73,945.7,760.84z'
          />
          <path
            fill='#D50000'
            d='M2400.93,955.84c-0.06,4.44-3.36,8.06-7.2,7.91c-3.62-0.14-7.19-4.27-7.15-8.26
			c0.04-3.89,3.79-8.09,7.31-8.19C2397.99,947.2,2401,950.85,2400.93,955.84z'
          />
          <path
            fill='#D50000'
            d='M1930.75,977.79c0.06-4.72,2.85-7.76,7.1-7.74c3.97,0.02,7.17,3.49,7.22,7.83c0.05,4.32-3.13,8.06-6.97,8.2
			C1934.14,986.22,1930.7,982.34,1930.75,977.79z'
          />
          <path
            fill='#D50000'
            d='M1415.27,451.27c-4.08,0.12-7.29-3.21-7.41-7.67c-0.13-4.75,2.56-8.01,6.72-8.16
			c4.18-0.15,7.77,3.41,7.86,7.81C1422.53,447.44,1419.21,451.15,1415.27,451.27z'
          />
          <path
            fill='#D50000'
            d='M1901.82,1245.7c0.1,4.36-3.01,7.84-7.11,7.96c-4.06,0.12-7.37-3.16-7.61-7.53c-0.25-4.55,3.1-8.32,7.4-8.31
			C1898.4,1237.83,1901.72,1241.4,1901.82,1245.7z'
          />
          <path
            fill='#D50000'
            d='M1443.59,518.15c-0.05,4.87-2.76,7.9-7.04,7.88c-4.06-0.02-7.62-4.01-7.43-8.35c0.18-4.32,3.51-7.57,7.66-7.5
			C1441.16,510.26,1443.65,513.17,1443.59,518.15z'
          />
          <path
            fill='#D50000'
            d='M1009.91,1390.81c-0.13,4.62-3.1,7.76-7.29,7.72c-3.95-0.03-7.29-3.6-7.33-7.81
			c-0.04-4.53,3.46-8.19,7.68-8.04C1006.98,1382.83,1010.03,1386.41,1009.91,1390.81z'
          />
          <path
            fill='#D50000'
            d='M975.21,907.76c-0.01-5.02,2.87-8.38,7.09-8.3c3.52,0.07,7.21,4.2,7.25,8.1c0.04,4.39-3.51,8.35-7.44,8.3
			C978.35,915.81,975.22,912.13,975.21,907.76z'
          />
          <path
            fill='#D50000'
            d='M967.6,907.59c0,4.72-3.34,8.45-7.39,8.26c-3.93-0.19-6.92-3.74-6.93-8.23c-0.01-4.48,2.98-7.98,6.94-8.12
			C964.25,899.36,967.6,903.03,967.6,907.59z'
          />
          <path
            fill='#D50000'
            d='M1009.92,1488.84c-0.1,4.64-3.02,7.75-7.27,7.72c-4.04-0.02-7.32-3.48-7.36-7.76
			c-0.04-4.57,3.41-8.2,7.66-8.07C1006.98,1480.86,1010.01,1484.4,1009.92,1488.84z'
          />
          <path
            fill='#D50000'
            d='M1930.75,782.64c0.13-4.74,2.88-7.59,7.28-7.52c3.99,0.07,7.08,3.55,7.05,7.94
			c-0.04,4.34-3.32,8.04-7.14,8.06C1933.95,791.13,1930.63,787.21,1930.75,782.64z'
          />
          <path
            fill='#D50000'
            d='M2430.45,1101.36c0.01-4.55,2.97-8.14,6.78-8.23c3.69-0.08,7.52,4.13,7.53,8.29c0.01,4.1-3.93,8.38-7.59,8.23
			C2433.34,1109.5,2430.44,1105.92,2430.45,1101.36z'
          />
          <path
            fill='#D50000'
            d='M1923.15,783.18c-0.08,4.31-3.42,7.97-7.24,7.92c-3.83-0.05-7.07-3.75-7.08-8.1
			c-0.01-4.66,3.23-8.01,7.58-7.84C1920.71,775.33,1923.24,778.34,1923.15,783.18z'
          />
          <path
            fill='#D50000'
            d='M1959.16,1725.78c4.42,0.12,6.92,2.98,6.83,7.82c-0.08,4.4-3.31,8.02-7.12,7.99
			c-4.02-0.03-7.33-3.95-7.18-8.49C1951.83,1728.69,1954.92,1725.66,1959.16,1725.78z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1539.01c-0.04,4.63-2.85,7.65-7.13,7.64c-4.3-0.01-7.35-3.5-7.19-8.22c0.14-4.18,3.63-7.73,7.49-7.6
			C1898.2,1530.95,1901.25,1534.6,1901.21,1539.01z'
          />
          <path
            fill='#D50000'
            d='M2400.92,906.62c-0.05,4.49-3.18,8.12-6.96,8.07c-3.65-0.04-7.45-4.43-7.38-8.51
			c0.07-3.91,3.67-7.88,7.29-8.03C2397.75,897.99,2400.97,901.87,2400.92,906.62z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1101.19c-0.07-4.44,2.92-8.08,6.78-8.23c4.12-0.17,7.55,3.49,7.54,8.05
			c-0.01,4.44-3.05,7.75-7.15,7.78C1933.68,1108.82,1930.82,1105.8,1930.75,1101.19z'
          />
          <path
            fill='#D50000'
            d='M1937.37,1725.78c4.36,0.02,7.17,3.02,7.12,7.62c-0.04,4.15-3.44,8.1-7.05,8.2c-3.69,0.1-7.64-4.26-7.52-8.32
			C1930.04,1729.09,1933.35,1725.76,1937.37,1725.78z'
          />
          <path
            fill='#D50000'
            d='M1923.15,833.19c-0.02,4.25-3.35,8.01-7.1,8.05c-3.79,0.03-7.16-3.68-7.22-7.96
			c-0.06-4.52,3.32-8.18,7.47-8.07C1920.48,825.32,1923.18,828.46,1923.15,833.19z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1539c-0.04,4.66-2.85,7.67-7.12,7.65c-4.26-0.02-7.33-3.54-7.19-8.22c0.13-4.22,3.57-7.73,7.45-7.61
			C1962.94,1530.94,1966.04,1534.64,1965.99,1539z'
          />
          <path
            fill='#D50000'
            d='M1443.39,541.81c-0.11,4.8-2.95,7.75-7.2,7.49c-3.95-0.23-7.18-3.97-7.05-8.16c0.13-4.12,3.62-7.71,7.47-7.68
			C1441.12,533.5,1443.52,536.45,1443.39,541.81z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1100.77c0.09,4.67-2.63,7.9-6.76,8.01c-4.39,0.12-7.52-3.14-7.56-7.88
			c-0.04-4.27,3.21-7.9,7.12-7.96C1919.89,1092.88,1923.07,1096.33,1923.15,1100.77z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1050.67c0.02-4.33,3.25-7.86,7.16-7.83c3.9,0.03,7.12,3.59,7.14,7.91c0.02,4.63-3.2,8.04-7.5,7.93
			C1955.48,1058.57,1952.67,1055.26,1952.69,1050.67z'
          />
          <path
            fill='#D50000'
            d='M1880.25,782.77c0.09,4.33-2.97,8.07-6.81,8.32c-3.95,0.26-7.48-3.59-7.47-8.16c0-4.78,2.66-7.76,6.98-7.82
			C1876.98,775.05,1880.16,778.38,1880.25,782.77z'
          />
          <path
            fill='#D50000'
            d='M1959.53,1092.95c3.96-0.13,7.31,3.29,7.46,7.61c0.16,4.51-2.7,8.03-6.68,8.22
			c-4.56,0.22-7.54-2.77-7.63-7.67C1952.61,1096.71,1955.66,1093.07,1959.53,1092.95z'
          />
          <path
            fill='#D50000'
            d='M1894.49,1092.95c4.05,0,7.46,3.85,7.32,8.27c-0.14,4.45-3.16,7.56-7.36,7.57c-4.03,0.01-7.25-3.31-7.39-7.64
			C1886.92,1096.61,1890.25,1092.95,1894.49,1092.95z'
          />
          <path
            fill='#D50000'
            d='M988.54,1488.79c-0.07,4.08-3.56,7.76-7.36,7.77c-4.6,0.02-7.26-3.01-7.15-8.15c0.09-4.43,3.4-7.89,7.35-7.68
			C985.42,1480.95,988.61,1484.53,988.54,1488.79z'
          />
          <path
            fill='#D50000'
            d='M1945.6,443.3c0.08,4.44-2.97,7.88-7.07,7.96c-4.18,0.08-7.44-3.15-7.6-7.55c-0.16-4.45,3.25-8.26,7.42-8.28
			C1942.37,435.42,1945.52,438.84,1945.6,443.3z'
          />
          <path
            fill='#D50000'
            d='M1872.86,1092.95c4.04,0.1,7.25,3.61,7.3,7.95c0.05,4.48-3.43,8.02-7.74,7.88c-4.16-0.13-7.03-3.5-6.91-8.09
			C1865.62,1096.24,1868.84,1092.84,1872.86,1092.95z'
          />
          <path
            fill='#D50000'
            d='M1930.75,833.26c-0.05-4.74,2.6-7.88,6.78-8.04c4.15-0.16,7.57,3.46,7.54,7.98
			c-0.03,4.27-3.37,8.02-7.15,8.03C1934.16,841.24,1930.8,837.51,1930.75,833.26z'
          />
          <path
            fill='#D50000'
            d='M1945.77,614.62c0.03,4-4.08,8.46-7.69,8.35c-3.58-0.11-7.24-4.33-7.15-8.24c0.1-4.38,3.22-7.6,7.37-7.6
			C1942.61,607.14,1945.74,610.27,1945.77,614.62z'
          />
          <path
            fill='#D50000'
            d='M1923.15,443.66c-0.07,4.84-2.73,7.69-7.11,7.61c-3.96-0.07-7.23-3.65-7.2-7.89
			c0.03-4.41,3.61-8.11,7.69-7.95C1920.5,435.59,1923.22,438.97,1923.15,443.66z'
          />
          <path
            fill='#D50000'
            d='M923.73,907.67c0.02,4.4-3.06,8.11-6.79,8.18c-3.65,0.08-7.58-4.33-7.47-8.39c0.11-4.02,3.7-7.7,7.51-7.69
			C921.35,899.79,923.72,902.56,923.73,907.67z'
          />
          <path
            fill='#D50000'
            d='M1952.69,783.06c-0.04-4.9,2.47-7.8,6.84-7.91c4.45-0.11,7.54,3.19,7.46,7.97c-0.07,4.36-3.38,8.03-7.2,7.99
			C1955.95,791.06,1952.72,787.41,1952.69,783.06z'
          />
          <path
            fill='#D50000'
            d='M2364.66,783.85c-0.02-4.47,2.95-8.19,6.68-8.37c3.52-0.17,7.69,4.4,7.61,8.32c-0.08,3.77-4.06,8.14-7.4,8.12
			C2367.66,791.91,2364.68,788.42,2364.66,783.85z'
          />
          <path
            fill='#D50000'
            d='M1465.32,443.2c0.05,4.79-2.61,7.94-6.8,8.07c-4.1,0.12-7.58-3.63-7.52-8.11c0.06-4.15,3.46-7.72,7.35-7.72
			C1462.62,435.44,1465.27,438.39,1465.32,443.2z'
          />
          <path
            fill='#D50000'
            d='M952.28,1488.75c0.02-4.38,3.19-8,7.02-8.01c3.88-0.02,7.26,3.57,7.34,7.77c0.09,4.62-3.11,8.11-7.39,8.06
			C955.14,1496.52,952.26,1493.29,952.28,1488.75z'
          />
          <path
            fill='#D50000'
            d='M945.69,735.13c0.05,4.8-2.64,8.04-6.71,8.07c-4.16,0.03-7.72-3.77-7.61-8.13c0.1-4,3.7-7.7,7.48-7.7
			C943.28,727.38,945.63,730.05,945.69,735.13z'
          />
          <path
            fill='#D50000'
            d='M1952.69,832.99c0.04-4.68,2.83-7.78,7.05-7.82c3.95-0.04,7.19,3.43,7.26,7.78c0.07,4.52-3.46,8.47-7.4,8.28
			C1955.79,841.04,1952.65,837.3,1952.69,832.99z'
          />
          <path
            fill='#D50000'
            d='M1959.36,1774.81c4.22,0.18,6.79,3.31,6.63,8.06c-0.15,4.34-3.53,7.9-7.35,7.75
			c-3.97-0.15-7.2-4.19-6.95-8.68C1951.93,1777.64,1955.16,1774.63,1959.36,1774.81z'
          />
          <path
            fill='#D50000'
            d='M1915.64,1433.88c3.88-0.12,7.28,3.34,7.51,7.62c0.23,4.35-2.78,8.01-6.72,8.2
			c-4.39,0.21-7.39-2.61-7.59-7.16C1908.62,1437.76,1911.57,1434.01,1915.64,1433.88z'
          />
          <path
            fill='#D50000'
            d='M1959.92,970.04c4.01,0.07,7.13,3.56,7.08,7.95c-0.05,4.36-3.29,8.06-7.09,8.09
			c-3.96,0.04-7.31-3.87-7.21-8.43C1952.79,972.95,1955.61,969.97,1959.92,970.04z'
          />
          <path
            fill='#D50000'
            d='M974.22,1294.86c0.02-4.24,3.33-8.01,7.03-8c3.64,0.02,7.17,3.93,7.3,8.09c0.14,4.34-3.4,7.92-7.71,7.81
			C976.28,1302.65,974.19,1300.16,974.22,1294.86z'
          />
          <path
            fill='#D50000'
            d='M1937.12,1482.89c3.85-0.11,7.6,4.01,7.6,8.34c-0.01,4.23-2.93,7.36-6.99,7.5c-4.42,0.15-7.83-3.23-7.81-7.73
			C1929.92,1486.83,1933.33,1483,1937.12,1482.89z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1710.8c-0.03,4.71-2.7,7.53-7.13,7.55c-4.1,0.01-7.15-3.25-7.17-7.64
			c-0.02-4.51,3.51-8.39,7.45-8.18C1962.94,1702.72,1966.02,1706.45,1965.99,1710.8z'
          />
          <path
            fill='#D50000'
            d='M1872.67,1546.64c-4.18,0.08-7.34-3.12-7.5-7.58c-0.15-4.23,3.09-8.05,7-8.25c4.14-0.21,7.63,3.43,7.68,8.02
			C1879.89,1543.31,1876.89,1546.57,1872.67,1546.64z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1710.92c-0.11,4.3-3.34,7.52-7.42,7.42c-4.4-0.11-6.95-2.98-6.9-7.76c0.05-4.33,3.29-8.04,7.05-8.06
			C1919.87,1702.5,1923.26,1706.42,1923.15,1710.92z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1637.04c-0.03,4.65-2.82,7.64-7.12,7.63c-4.14-0.01-7.12-3.22-7.19-7.72
			c-0.06-4.42,3.45-8.24,7.46-8.12C1962.84,1628.95,1966.02,1632.77,1965.99,1637.04z'
          />
          <path
            fill='#D50000'
            d='M1472.93,443.27c0.04-4.99,2.49-7.79,6.87-7.84c3.94-0.04,7.4,3.52,7.44,7.65c0.04,4.55-3.34,8.25-7.48,8.19
			C1475.55,451.21,1472.88,448.09,1472.93,443.27z'
          />
          <path
            fill='#D50000'
            d='M2444.78,955.48c0.02,4.04-4.09,8.55-7.74,8.21c-3.44-0.32-7.15-3.8-6.69-8.41c0.46-4.55,3.12-8.02,7.06-7.95
			C2440.94,947.4,2444.77,951.64,2444.78,955.48z'
          />
          <path
            fill='#D50000'
            d='M967.61,735.18c0.02,4.32-3.15,7.95-7.02,8.03c-4.07,0.08-7.37-3.65-7.31-8.25c0.06-4.43,3.11-7.63,7.24-7.59
			C964.51,727.41,967.58,730.8,967.61,735.18z'
          />
          <path
            fill='#D50000'
            d='M1966.99,590.31c-0.03,4.27-3.32,7.99-7.09,8c-3.8,0.01-7.16-3.68-7.21-7.93c-0.05-4.34,3.04-7.85,6.96-7.89
			C1964.37,582.45,1967.03,585.28,1966.99,590.31z'
          />
          <path
            fill='#D50000'
            d='M967.61,761.05c-0.08,4.24-3.44,7.91-7.21,7.88c-3.96-0.04-7.25-3.97-7.11-8.5c0.14-4.58,3.07-7.46,7.46-7.33
			C964.97,753.22,967.7,756.38,967.61,761.05z'
          />
          <path
            fill='#D50000'
            d='M995.98,735.54c-0.08-4.91,2.43-8.01,6.61-8.17c4.05-0.15,7.86,3.64,7.86,7.81c0,4.18-3.32,7.86-7.26,8.02
			C999.04,743.37,996.05,740.2,995.98,735.54z'
          />
          <path
            fill='#D50000'
            d='M1865.96,1392.96c0.01-4.38,3.22-8.09,6.98-8.08c4.13,0.02,7.43,3.77,7.31,8.32
			c-0.12,4.66-2.98,7.59-7.33,7.51C1868.39,1400.62,1865.95,1397.91,1865.96,1392.96z'
          />
          <path
            fill='#D50000'
            d='M1923.15,590.37c0.03,4.4-3.11,7.98-6.97,7.95c-3.9-0.03-7.24-3.61-7.34-7.87c-0.11-4.13,3.21-7.87,7.05-7.94
			C1920.58,582.41,1923.12,585.16,1923.15,590.37z'
          />
          <path
            fill='#D50000'
            d='M1894.61,1400.73c-4.16,0.04-7.38-3.19-7.51-7.55c-0.13-4.45,3.41-8.39,7.47-8.3c3.7,0.07,7.18,3.95,7.27,8.1
			C1901.95,1397.35,1898.85,1400.69,1894.61,1400.73z'
          />
          <path
            fill='#D50000'
            d='M945.7,857.8c-0.07,4.33-3.45,8.13-7.08,7.97c-3.36-0.15-7.24-4.6-7.23-8.3c0.01-4.05,4.06-8.18,7.89-8.05
			C943.11,849.56,945.78,853.03,945.7,857.8z'
          />
          <path
            fill='#D50000'
            d='M1916.26,1774.81c3.98,0.09,7.04,3.63,6.88,7.96c-0.15,4.33-3.51,7.94-7.3,7.86
			c-4.01-0.09-7.18-3.98-7.02-8.57C1909,1777.34,1911.71,1774.71,1916.26,1774.81z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1343.97c-0.04-4.41,3.04-8.03,6.89-8.1c3.95-0.07,7.28,3.36,7.43,7.65
			c0.16,4.68-2.93,8.15-7.26,8.14C1933.53,1351.65,1930.79,1348.67,1930.75,1343.97z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1294.9c0.04,4.81-2.52,7.67-6.94,7.77c-4.28,0.1-7.26-2.91-7.38-7.46
			c-0.12-4.57,3.19-8.37,7.29-8.38C1919.86,1286.84,1923.12,1290.57,1923.15,1294.9z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1442.28c-0.13,4.65-2.99,7.54-7.34,7.43c-4.22-0.11-7.2-3.71-6.96-8.45
			c0.21-4.17,3.79-7.61,7.68-7.37C1963.13,1434.12,1966.11,1437.9,1965.99,1442.28z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1343.73c0.05,4.67-2.68,7.84-6.83,7.94c-4.4,0.1-7.49-3.16-7.49-7.92
			c-0.01-4.31,3.22-7.85,7.18-7.88C1919.96,1335.84,1923.1,1339.29,1923.15,1343.73z'
          />
          <path
            fill='#D50000'
            d='M1923.15,470.03c-0.03,4.24-3.38,8.09-7.03,8.05c-3.9-0.04-7.41-4.14-7.3-8.52c0.12-4.28,3.32-7.36,7.6-7.3
			C1921.01,462.32,1923.18,464.83,1923.15,470.03z'
          />
          <path
            fill='#D50000'
            d='M1894.35,1351.66c-4.08-0.03-7.22-3.35-7.28-7.7c-0.06-4.55,3.3-8.16,7.53-8.09c3.9,0.07,7.12,3.58,7.22,7.87
			C1901.93,1348.38,1898.81,1351.69,1894.35,1351.66z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1294.66c0.11-4.33,3.43-7.9,7.28-7.82c3.85,0.08,7.04,3.75,7.03,8.09
			c-0.01,4.83-3.05,7.93-7.58,7.74C1955.1,1302.5,1952.57,1299.48,1952.69,1294.66z'
          />
          <path
            fill='#D50000'
            d='M1936.92,1595.66c-4.01-0.12-7.25-3.81-7.01-8c0.24-4.29,3.7-7.9,7.5-7.82c3.85,0.08,7.43,4.35,7.24,8.62
			C1944.45,1593,1941.45,1595.8,1936.92,1595.66z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1442.12c-0.05,4.64-2.88,7.62-7.19,7.58c-4.18-0.03-7.12-3.23-7.15-7.77
			c-0.03-4.48,3.48-8.22,7.55-8.05C1898.26,1434.04,1901.26,1437.69,1901.21,1442.12z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1197.81c0.06-4.35,3.3-7.92,7.18-7.91c3.83,0.01,7.09,3.67,7.13,8c0.04,4.85-2.97,7.99-7.5,7.85
			C1955.14,1205.6,1952.62,1202.66,1952.69,1197.81z'
          />
          <path
            fill='#D50000'
            d='M916.9,608.23c4.72,0.01,6.84,2.35,6.85,7.58c0.01,4.63-3.3,8.36-7.27,8.2c-3.65-0.15-7.15-4.29-7.01-8.28
			C909.62,611.44,912.81,608.22,916.9,608.23z'
          />
          <path
            fill='#D50000'
            d='M1952.69,808.61c-0.01-4.87,2.56-7.74,6.99-7.82c4.34-0.08,7.23,2.92,7.31,7.59
			c0.08,4.52-3.41,8.55-7.32,8.45C1955.96,816.75,1952.69,812.89,1952.69,808.61z'
          />
          <path
            fill='#D50000'
            d='M1872.37,1449.7c-4.11-0.05-7.26-3.49-7.21-7.86c0.05-4.39,3.26-7.9,7.26-7.96c4.17-0.06,7.46,3.66,7.32,8.28
			C1879.61,1446.78,1876.69,1449.76,1872.37,1449.7z'
          />
          <path
            fill='#D50000'
            d='M1930.75,808.39c0.04-4.76,2.8-7.7,7.16-7.63c4.18,0.06,7.18,3.37,7.16,7.88c-0.02,4.22-3.34,8.07-7.08,8.19
			C1934.19,816.96,1930.71,812.91,1930.75,808.39z'
          />
          <path
            fill='#D50000'
            d='M952.28,1269.36c0.01-4.21,3.38-8.15,7.04-8.23c3.86-0.09,7.41,4.06,7.3,8.52c-0.11,4.64-3.03,7.64-7.36,7.56
			C954.82,1277.12,952.26,1274.25,952.28,1269.36z'
          />
          <path
            fill='#D50000'
            d='M953.29,590.16c0.01-4.64,2.76-7.62,7.05-7.66c4.28-0.04,7.2,2.99,7.26,7.5c0.06,4.55-3.37,8.43-7.33,8.3
			C956.48,598.18,953.28,594.44,953.29,590.16z'
          />
          <path
            fill='#D50000'
            d='M974.21,1342.73c0.01-4.18,3.4-7.97,7.08-7.93c3.63,0.04,7.15,3.98,7.26,8.13c0.11,4.11-3.3,7.79-7.25,7.84
			C976.53,1350.82,974.19,1348.17,974.21,1342.73z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1197.94c0.04,4.83-2.5,7.7-6.9,7.81c-4.29,0.11-7.28-2.89-7.42-7.43c-0.14-4.58,3.14-8.38,7.26-8.41
			C1919.83,1189.87,1923.11,1193.6,1923.15,1197.94z'
          />
          <path
            fill='#D50000'
            d='M1894.44,1042.85c3.93-0.02,7.29,3.53,7.4,7.82c0.12,4.52-3.27,8.09-7.6,8.01c-3.87-0.07-7.2-3.81-7.15-8
			C1887.14,1046.3,1890.36,1042.87,1894.44,1042.85z'
          />
          <path
            fill='#D50000'
            d='M1937.25,1677.87c3.77-0.04,7.54,4.24,7.46,8.46c-0.08,4.25-2.97,7.26-7.08,7.36
			c-4.39,0.11-7.85-3.39-7.72-7.81C1930.04,1681.69,1933.5,1677.91,1937.25,1677.87z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1685.82c0.07-4.19,3.49-7.98,7.16-7.95c3.89,0.03,7.29,4.09,7.16,8.53
			c-0.14,4.51-3.18,7.45-7.55,7.29C1911.09,1693.53,1908.74,1690.8,1908.83,1685.82z'
          />
          <path
            fill='#D50000'
            d='M1872.65,1205.75c-4.45-0.05-7.31-3.3-7.14-8.12c0.15-4.24,3.58-7.83,7.37-7.73
			c4.06,0.11,7.49,4.07,7.28,8.42C1879.95,1202.48,1876.61,1205.79,1872.65,1205.75z'
          />
          <path
            fill='#D50000'
            d='M923.74,955.84c-0.05,4.35-3.34,8.05-7.04,7.94c-3.48-0.11-7.19-4.3-7.23-8.16
			c-0.04-4.27,3.67-8.05,7.77-7.92C921.52,947.83,923.8,950.69,923.74,955.84z'
          />
          <path
            fill='#D50000'
            d='M1400.49,443.42c-0.02,4.23-3.37,7.78-7.41,7.84c-4.1,0.07-7.14-3.25-7.18-7.83c-0.03-4.99,2.85-8.1,7.41-8
			C1397.17,435.52,1400.51,439.24,1400.49,443.42z'
          />
          <path
            fill='#D50000'
            d='M1865.97,858.35c0.01-4.48,3.07-8.14,6.89-8.25c4.01-0.12,7.44,3.75,7.38,8.34
			c-0.05,4.67-2.85,7.61-7.23,7.61C1868.6,866.07,1865.96,863.17,1865.97,858.35z'
          />
          <path
            fill='#D50000'
            d='M944.68,1343.09c-0.05,5.06-2.41,7.66-6.96,7.67c-3.88,0.01-7.38-3.69-7.36-7.75
			c0.02-4.09,3.48-8.07,7.13-8.21C941.33,1334.65,944.73,1338.57,944.68,1343.09z'
          />
          <path
            fill='#D50000'
            d='M959.39,1432.82c3.91,0.05,7.19,3.6,7.24,7.85c0.06,4.62-3.15,8.03-7.47,7.95c-4.14-0.08-6.94-3.3-6.88-7.89
			C952.34,1436.31,955.53,1432.77,959.39,1432.82z'
          />
          <path
            fill='#D50000'
            d='M2401.91,1052.51c0,4.48-3.12,8.23-6.8,8.18c-3.67-0.06-7.63-4.56-7.5-8.51c0.12-3.68,4.29-7.99,7.61-7.86
			C2399.23,1044.48,2401.9,1047.75,2401.91,1052.51z'
          />
          <path
            fill='#D50000'
            d='M2400.93,808.16c0.04,4.58-2.86,8.26-6.67,8.46c-3.64,0.2-7.63-4.13-7.67-8.32
			c-0.04-4.03,3.47-7.99,7.24-8.17C2397.59,799.95,2400.89,803.68,2400.93,808.16z'
          />
          <path
            fill='#D50000'
            d='M2378.95,808.57c-0.13,3.71-4.24,8.39-7.48,7.97c-3.59-0.46-7.48-2.99-6.85-9.14
			c0.39-3.83,3.33-7.22,6.76-7.26C2375.06,800.09,2379.09,804.58,2378.95,808.57z'
          />
          <path
            fill='#D50000'
            d='M2423.86,1052.41c0.01,4.51-3.03,8.25-6.7,8.28c-3.38,0.03-7.48-4.28-7.62-7.99c-0.14-3.88,4-8.51,7.55-8.45
			C2420.98,1044.31,2423.86,1047.78,2423.86,1052.41z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1686.38c-0.08,4.75-2.73,7.39-7.33,7.31c-4.18-0.07-7.18-3.43-7.01-7.86
			c0.16-4.2,3.58-7.96,7.25-7.97C1897.96,1677.85,1901.29,1681.85,1901.21,1686.38z'
          />
          <path
            fill='#D50000'
            d='M1937.21,1035.36c-4.54-0.38-6.88-3.55-6.46-8.76c0.31-3.84,4.16-7.51,7.52-7.18c3.9,0.39,7.15,4.68,6.79,9
			C1944.69,1033.02,1941.62,1035.73,1937.21,1035.36z'
          />
          <path
            fill='#D50000'
            d='M1002.62,1545.57c-3.98,0-7.34-3.51-7.34-7.67c0-4.49,3.56-8.29,7.64-8.15c3.66,0.13,6.85,3.67,6.95,7.73
			C1009.99,1542.52,1007.26,1545.57,1002.62,1545.57z'
          />
          <path
            fill='#D50000'
            d='M1929.92,1661.18c0.2-4.33,3.54-7.76,7.54-7.71c4.05,0.05,7.36,4.03,7.08,8.5c-0.28,4.54-3.37,7.5-7.62,7.31
			C1933.08,1669.12,1929.74,1665.25,1929.92,1661.18z'
          />
          <path
            fill='#D50000'
            d='M996,808.77c0.02-5.04,2.42-7.73,6.91-7.75c4.07-0.02,7.4,3.33,7.53,7.56c0.13,4.23-3.59,8.31-7.53,8.27
			C999.04,816.81,995.98,813.22,996,808.77z'
          />
          <path
            fill='#D50000'
            d='M952.28,1318.76c-0.08-4.5,3.36-8.61,7.2-8.6c3.68,0.01,7.11,3.94,7.14,8.18c0.04,4.69-2.76,7.86-7.01,7.95
			C955.2,1326.37,952.36,1323.46,952.28,1318.76z'
          />
          <path
            fill='#D50000'
            d='M1865.96,808.49c0-4.79,2.71-7.75,7.07-7.73c4.15,0.02,7.24,3.36,7.22,7.8c-0.03,4.56-3.52,8.45-7.43,8.27
			C1869.16,816.66,1865.96,812.76,1865.96,808.49z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1027.57c0.03,4.78-2.62,7.8-6.94,7.93c-4.27,0.12-7.26-2.94-7.38-7.55
			c-0.12-4.41,3.4-8.56,7.27-8.57C1919.72,1019.38,1923.13,1023.33,1923.15,1027.57z'
          />
          <path
            fill='#D50000'
            d='M923.73,590.44c-0.05,4.49-3.12,7.94-6.98,7.87c-3.79-0.07-7.29-3.91-7.27-7.99
			c0.02-4.13,3.76-7.93,7.73-7.83C921.65,582.61,923.79,585.22,923.73,590.44z'
          />
          <path
            fill='#D50000'
            d='M945.69,590.68c-0.08,4.16-3.06,7.55-6.74,7.63c-4,0.1-7.69-3.9-7.59-8.23c0.09-3.89,3.75-7.6,7.5-7.59
			C943.54,582.5,945.8,585.21,945.69,590.68z'
          />
          <path
            fill='#D50000'
            d='M1966.99,566.42c-0.07,4.47-3.08,7.6-7.23,7.54c-3.74-0.06-7.14-3.87-7.07-7.92
			c0.08-4.29,3.33-7.89,7.16-7.93C1964.07,558.07,1967.07,561.56,1966.99,566.42z'
          />
          <path
            fill='#D50000'
            d='M1873.18,1335.87c4.07,0.12,7.28,3.97,7.07,8.49c-0.2,4.4-3.36,7.45-7.56,7.3c-4.13-0.15-6.8-3.35-6.72-8.06
			C1866.03,1339.12,1869.18,1335.75,1873.18,1335.87z'
          />
          <path
            fill='#D50000'
            d='M1865.96,566.07c0.01-4.31,3.28-8.02,7.02-7.95c4.11,0.07,7.39,3.82,7.27,8.32c-0.11,4.24-3.42,7.6-7.42,7.52
			C1868.72,573.87,1865.95,570.69,1865.96,566.07z'
          />
          <path
            fill='#D50000'
            d='M1496.88,566.68c-0.09-4.65,3.14-8.44,7.17-8.42c3.79,0.02,6.99,3.63,7.1,8.02c0.11,4.38-3.41,8.53-7.2,8.48
			C1500.22,574.71,1496.96,570.98,1496.88,566.68z'
          />
          <path
            fill='#D50000'
            d='M1489.23,566.71c-0.03,4.5-3.16,8.14-6.95,8.06c-3.37-0.07-7.29-4.48-7.34-8.25
			c-0.05-3.94,4.11-8.4,7.69-8.22C1486.41,558.48,1489.26,562.12,1489.23,566.71z'
          />
          <path
            fill='#D50000'
            d='M1423.46,566.6c-0.02,4.56-3.04,8.18-6.83,8.17c-3.38,0-7.41-4.38-7.49-8.14c-0.09-4.1,3.88-8.46,7.61-8.37
			C1420.45,558.35,1423.47,562.11,1423.46,566.6z'
          />
          <path
            fill='#D50000'
            d='M1966.99,664.25c-0.07,4.55-2.98,7.71-7.13,7.73c-4.39,0.02-7.32-3.28-7.17-8.1c0.14-4.31,3.5-7.87,7.33-7.74
			C1963.9,656.29,1967.06,659.95,1966.99,664.25z'
          />
          <path
            fill='#D50000'
            d='M1865.97,664.12c0.01-4.36,3.24-8.04,7-7.97c4.1,0.07,7.38,3.83,7.28,8.34c-0.1,4.24-3.39,7.57-7.41,7.49
			C1868.56,671.9,1865.96,668.93,1865.97,664.12z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1148.91c-0.01-4.41,3.11-8.01,6.97-8.04c3.92-0.03,7.24,3.47,7.35,7.76
			c0.12,4.68-3.01,8.13-7.34,8.09C1933.57,1156.68,1930.76,1153.53,1930.75,1148.91z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1149.02c-0.04,4.61-2.88,7.7-7.09,7.69c-4.3-0.01-7.33-3.44-7.23-8.22
			c0.08-4.23,3.51-7.74,7.45-7.62C1920.15,1140.99,1923.18,1144.58,1923.15,1149.02z'
          />
          <path
            fill='#D50000'
            d='M1865.97,590.31c-0.04-4.71,2.59-7.69,6.89-7.81c4.1-0.12,7.25,3.06,7.39,7.46c0.15,4.48-3.17,8.31-7.27,8.37
			C1869.27,598.38,1866,594.65,1865.97,590.31z'
          />
          <path
            fill='#D50000'
            d='M2452.41,1052.58c-0.01-4.81,2.64-8.07,6.68-8.25c3.74-0.16,7.73,4.21,7.6,8.31
			c-0.13,3.93-3.94,8.1-7.37,8.06C2455.46,1060.67,2452.41,1057.08,2452.41,1052.58z'
          />
          <path
            fill='#D50000'
            d='M923.72,565.69c-0.01,4.52-3.43,8.42-7.23,8.24c-3.58-0.17-7.15-4.4-7.02-8.32c0.14-4.33,3.28-7.51,7.43-7.51
			C921.62,558.1,923.73,560.45,923.72,565.69z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1343.55c0.08-4.36,3.35-7.8,7.3-7.69c3.93,0.12,7.02,3.68,7,8.05c-0.03,4.78-3.13,7.92-7.64,7.74
			C1955.22,1351.5,1952.6,1348.32,1952.69,1343.55z'
          />
          <path
            fill='#D50000'
            d='M1987.93,1686.09c-0.05,4.59-2.97,7.65-7.25,7.61c-4.77-0.04-7.16-2.79-7.03-8.06
			c0.1-4.15,3.62-7.9,7.29-7.77C1984.6,1678.01,1987.98,1681.97,1987.93,1686.09z'
          />
          <path
            fill='#D50000'
            d='M894.92,825.42c4.03-0.06,7.37,3.24,7.6,7.52c0.23,4.31-3.34,8.29-7.45,8.31c-4.03,0.02-7.32-3.49-7.37-7.86
			C887.66,829.23,891.06,825.47,894.92,825.42z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1491.19c-0.05-4.34,3.01-8.11,6.74-8.3c3.91-0.2,7.56,3.76,7.54,8.17c-0.02,4.65-2.86,7.67-7.2,7.66
			C1976.09,1498.7,1973.71,1496.16,1973.65,1491.19z'
          />
          <path
            fill='#D50000'
            d='M923.74,833.32c0.03,4.75-2.73,7.97-6.81,7.93c-3.96-0.04-7.33-3.55-7.46-7.76
			c-0.12-4.12,3.63-8.13,7.58-8.08C921.31,825.45,923.7,828.28,923.74,833.32z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1710.68c0,4.81-2.55,7.61-6.97,7.65c-4.12,0.04-7.3-3.21-7.36-7.52c-0.06-4.53,3.4-8.42,7.37-8.3
			C1898.06,1702.65,1901.21,1706.34,1901.21,1710.68z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1491.51c-0.16,4.76-2.85,7.35-7.47,7.21c-4.2-0.13-7.01-3.43-6.83-7.99
			c0.17-4.32,3.48-7.86,7.33-7.84C1962.89,1482.92,1966.15,1486.94,1965.99,1491.51z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1637.13c-0.06-4.28,3.1-8.17,6.73-8.29c4.02-0.13,7.59,3.66,7.59,8.06c0,4.43-3.03,7.73-7.13,7.77
			C1911.71,1644.71,1908.89,1641.75,1908.83,1637.13z'
          />
          <path
            fill='#D50000'
            d='M944.85,1366.59c-0.2,4.77-2.96,7.48-7.44,7.3c-3.88-0.15-7.23-4-7.04-8.08c0.21-4.35,4.04-8.06,8-7.76
			C942.15,1358.34,945.03,1362.15,944.85,1366.59z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1636.72c0.08,4.69-2.59,7.82-6.79,7.95c-4.39,0.13-7.53-3.15-7.55-7.88
			c-0.02-4.1,3.41-7.89,7.19-7.94C1897.89,1628.8,1901.14,1632.37,1901.21,1636.72z'
          />
          <path
            fill='#D50000'
            d='M2364.67,978.79c0-4.36,3.1-8.18,6.74-8.32c3.51-0.13,7.7,4.53,7.55,8.4c-0.15,3.83-4.11,8.08-7.46,8.01
			C2367.57,986.82,2364.66,983.37,2364.67,978.79z'
          />
          <path
            fill='#D50000'
            d='M1944.62,1441.83c0.06,4.45-2.87,7.75-7,7.88c-4.36,0.14-7.66-3.19-7.7-7.77c-0.04-4.24,3.15-7.88,7.05-8.05
			C1941.14,1433.7,1944.55,1437.25,1944.62,1441.83z'
          />
          <path
            fill='#D50000'
            d='M2400.93,978.59c0.05,4.61-2.77,8.05-6.75,8.25c-3.72,0.19-7.64-4.08-7.58-8.25
			c0.05-3.89,3.81-8.03,7.35-8.11C2397.6,970.39,2400.88,974.2,2400.93,978.59z'
          />
          <path
            fill='#D50000'
            d='M945.69,710.63c0,4.64-2.84,8.11-6.69,8.18c-3.9,0.07-7.67-3.97-7.63-8.19c0.03-4.09,3.52-7.69,7.41-7.65
			C943.4,703.01,945.69,705.54,945.69,710.63z'
          />
          <path
            fill='#D50000'
            d='M1002.8,1229.02c-3.98,0.1-7.35-3.34-7.51-7.66c-0.16-4.34,3.36-8.17,7.51-8.17c3.98,0,7.1,3.46,7.15,7.93
			C1009.98,1225.57,1006.96,1228.92,1002.8,1229.02z'
          />
          <path
            fill='#D50000'
            d='M981.16,825.4c3.91,0.09,7.25,3.75,7.23,7.92c-0.03,4.55-3.59,8.17-7.8,7.93c-3.99-0.23-6.94-3.84-6.72-8.22
			C974.1,828.25,976.91,825.31,981.16,825.4z'
          />
          <path
            fill='#D50000'
            d='M2430.45,906.08c0.04-4.42,3.36-8.13,7.08-7.93c3.44,0.19,7.28,4.59,7.24,8.32
			c-0.03,3.66-4.11,8.19-7.39,8.22C2433.43,914.74,2430.4,910.96,2430.45,906.08z'
          />
          <path
            fill='#D50000'
            d='M944.88,1390.44c0.06,5.06-2.69,8.14-7.22,8.09c-3.67-0.03-7.26-3.92-7.28-7.88
			c-0.02-4.2,3.27-7.81,7.27-7.97C941.59,1382.53,944.83,1386.01,944.88,1390.44z'
          />
          <path
            fill='#D50000'
            d='M952.29,1390.46c0.06-4.29,3.41-7.9,7.23-7.79c3.82,0.11,7.13,3.84,7.11,8c-0.03,4.58-3.34,8.01-7.6,7.86
			C955.07,1398.4,952.22,1394.98,952.29,1390.46z'
          />
          <path
            fill='#D50000'
            d='M1916.05,1530.81c3.83,0.1,7.1,3.78,7.1,8c0,4.63-3.24,7.97-7.59,7.83c-4.15-0.14-6.84-3.34-6.73-8.02
			C1908.93,1534.3,1912.24,1530.72,1916.05,1530.81z'
          />
          <path
            fill='#D50000'
            d='M1952.69,905.51c0.02-4.81,2.6-7.55,7.11-7.57c4.33-0.02,7.17,2.99,7.19,7.62c0.02,4.39-3.64,8.43-7.47,8.23
			C1955.82,913.6,1952.67,909.78,1952.69,905.51z'
          />
          <path
            fill='#D50000'
            d='M1944.61,1538.98c-0.07,4.69-3.22,7.83-7.7,7.67c-4-0.15-7.14-3.72-7.01-7.97c0.14-4.36,3.31-7.78,7.27-7.84
			C1941.36,1530.77,1944.68,1534.4,1944.61,1538.98z'
          />
          <path
            fill='#D50000'
            d='M995.29,1245.32c-0.01-4.02,3.53-7.75,7.39-7.8c4.12-0.05,7.35,3.34,7.34,7.71c-0.01,4.09-4.04,8.57-7.56,8.4
			C999.09,1253.46,995.3,1249.07,995.29,1245.32z'
          />
          <path
            fill='#D50000'
            d='M1422.44,541.28c0.1,4.14-3.27,7.92-7.17,8.02c-4.11,0.11-7.33-3.19-7.43-7.63c-0.1-4.66,2.69-8.06,6.73-8.21
			C1418.68,533.32,1422.34,536.96,1422.44,541.28z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1538.74c0.06-4.41,3.21-7.96,7.06-7.93c3.82,0.03,7.17,3.68,7.22,7.89
			c0.06,4.69-3.08,8.04-7.46,7.94C1976.28,1546.55,1973.59,1543.43,1973.65,1538.74z'
          />
          <path
            fill='#D50000'
            d='M953.28,881.94c0.03-4.62,2.92-7.77,7.14-7.78c4.2-0.02,7.16,3.18,7.18,7.75c0.02,4.6-3.45,8.51-7.39,8.3
			C956.38,890.01,953.25,886.28,953.28,881.94z'
          />
          <path
            fill='#D50000'
            d='M974,1390.36c0.08-4.38,3.44-7.89,7.35-7.69c4,0.21,7.27,3.88,7.19,8.06c-0.08,4.28-3.8,7.97-7.86,7.79
			C976.48,1398.35,973.91,1395.21,974,1390.36z'
          />
          <path
            fill='#D50000'
            d='M1423.45,615.12c-0.05,4.42-3.25,7.96-7.07,7.85c-3.63-0.11-7.22-4.1-7.25-8.06
			c-0.04-4.12,3.37-7.77,7.24-7.77C1421.19,607.14,1423.52,609.76,1423.45,615.12z'
          />
          <path
            fill='#D50000'
            d='M988.3,1100.03c0.15,3.92-3.79,8.48-7.41,8.58c-3.27,0.09-7.36-4.27-7.5-8c-0.15-3.97,3.76-8.49,7.4-8.58
			C984.1,1091.95,988.16,1096.27,988.3,1100.03z'
          />
          <path
            fill='#D50000'
            d='M1873.15,607.14c4.3,0.02,7.16,3.13,7.1,7.73c-0.05,4.25-3.29,7.98-7.04,8.11c-3.78,0.13-7.3-3.94-7.24-8.36
			C1866.03,609.7,1868.51,607.12,1873.15,607.14z'
          />
          <path
            fill='#D50000'
            d='M1901.69,615.33c0.11,4.3-3.53,8.5-7.31,8.45c-3.39-0.05-7.26-4.42-7.28-8.2c-0.02-3.82,3.8-8.18,7.26-8.28
			C1897.83,607.19,1901.58,611.3,1901.69,615.33z'
          />
          <path
            fill='#D50000'
            d='M967.26,1100.29c-0.02,3.81-3.83,8.18-7.26,8.32c-3.62,0.15-7.71-4.47-7.55-8.51
			c0.15-3.88,4.12-8.13,7.53-8.05C963.46,1092.13,967.28,1096.46,967.26,1100.29z'
          />
          <path
            fill='#D50000'
            d='M1966.99,614.76c0,4.18-3.29,8.09-6.93,8.21c-3.9,0.13-7.44-3.87-7.37-8.31c0.07-4.55,3.02-7.58,7.33-7.52
			C1964.63,607.2,1966.99,609.78,1966.99,614.76z'
          />
          <path
            fill='#D50000'
            d='M1472.93,518.12c-0.03-4.84,2.54-7.84,6.78-7.93c4.16-0.09,7.65,3.64,7.52,8.03
			c-0.12,4.11-3.6,7.81-7.34,7.81C1475.93,526.03,1472.96,522.65,1472.93,518.12z'
          />
          <path
            fill='#D50000'
            d='M1880.15,1246.01c-0.12,4.23-3.5,7.69-7.47,7.66c-4.3-0.04-7.44-3.7-7.18-8.36c0.25-4.46,3.56-7.72,7.59-7.48
			C1877.14,1238.07,1880.27,1241.7,1880.15,1246.01z'
          />
          <path
            fill='#D50000'
            d='M923.74,735.49c-0.02,4.47-2.66,7.58-6.54,7.71c-4.15,0.14-7.76-3.59-7.72-7.98
			c0.03-4.02,3.54-7.79,7.29-7.85C921.35,727.3,923.76,730.1,923.74,735.49z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1245.89c-0.03,4.59-2.89,7.76-7.02,7.78c-4.27,0.02-7.38-3.45-7.3-8.15
			c0.08-4.27,3.44-7.77,7.38-7.69C1920.09,1237.9,1923.18,1241.49,1923.15,1245.89z'
          />
          <path
            fill='#D50000'
            d='M1937.75,1237.82c3.92-0.02,7.24,3.5,7.32,7.77c0.09,4.66-3.07,8.12-7.37,8.08
			c-4.11-0.05-6.94-3.24-6.95-7.84C1930.74,1241.43,1933.88,1237.85,1937.75,1237.82z'
          />
          <path
            fill='#D50000'
            d='M996,760.91c0.01-5.08,2.35-7.76,6.84-7.81c4.25-0.05,7.78,3.67,7.59,8c-0.19,4.23-3.62,7.83-7.47,7.83
			C999.04,768.93,995.99,765.42,996,760.91z'
          />
          <path
            fill='#D50000'
            d='M973.72,760.8c0-4.5,2.94-7.62,7.26-7.7c4.13-0.07,7.35,3.26,7.4,7.66c0.05,4.52-3.43,8.28-7.54,8.17
			C976.99,768.82,973.73,765.09,973.72,760.8z'
          />
          <path
            fill='#D50000'
            d='M923.74,761.42c-0.12,4.22-3.11,7.5-6.86,7.51c-3.96,0.02-7.61-4.1-7.39-8.34c0.22-4.31,3.53-7.6,7.53-7.49
			C921.69,753.23,923.89,755.95,923.74,761.42z'
          />
          <path
            fill='#D50000'
            d='M1937.3,1774.81c4.37,0.04,7.18,2.99,7.28,7.64c0.09,4.06-3.41,8.1-7.09,8.19c-4.13,0.1-7.8-3.71-7.5-8.22
			C1930.28,1778.02,1933.5,1773.85,1937.3,1774.81z'
          />
          <path
            fill='#D50000'
            d='M2357.01,1003.49c-0.07,4.21-3.97,8.39-7.6,8.14c-3.8-0.26-6.67-3.9-6.65-8.43c0.02-4.43,3.19-8.16,6.91-8.13
			C2353.52,995.1,2357.09,999.19,2357.01,1003.49z'
          />
          <path
            fill='#D50000'
            d='M1422.44,493.37c0.05,4.19-3.3,7.92-7.18,8.01c-4.09,0.1-7.32-3.24-7.41-7.65c-0.1-4.91,2.87-8.26,7.25-8.19
			C1418.93,485.61,1422.4,489.3,1422.44,493.37z'
          />
          <path
            fill='#D50000'
            d='M923.74,857.39c0.91,4.27-3.31,8.05-6.62,8.34c-3.54,0.31-7.67-4.25-7.66-8.24c0.01-3.99,3.53-7.74,7.35-7.83
			C921.14,849.56,923.71,852.43,923.74,857.39z'
          />
          <path
            fill='#D50000'
            d='M1400.48,493.42c0.07,4.14-3.27,7.8-7.26,7.95c-4.13,0.16-7.19-3.06-7.29-7.67c-0.1-4.61,2.72-8.02,6.76-8.15
			C1396.77,485.41,1400.41,489.09,1400.48,493.42z'
          />
          <path
            fill='#D50000'
            d='M1938.2,462.25c4.39-0.07,7.29,2.78,7.48,7.33c0.16,3.95-3.94,8.61-7.46,8.5c-3.41-0.1-7.12-4.27-7.26-8.14
			C1930.81,465.78,1934.07,462.31,1938.2,462.25z'
          />
          <path
            fill='#D50000'
            d='M2400.93,881.96c-0.04,4.53-3.15,8.06-7.05,7.99c-3.46-0.06-7.24-4.27-7.28-8.11
			c-0.05-4.07,3.99-8.42,7.66-8.27C2397.97,873.72,2400.96,877.48,2400.93,881.96z'
          />
          <path
            fill='#D50000'
            d='M2364.66,881.78c0-4.31,3.19-8.16,6.81-8.22c3.37-0.06,7.38,4.2,7.49,7.96c0.12,3.96-3.99,8.55-7.53,8.4
			C2367.46,889.76,2364.67,886.4,2364.66,881.78z'
          />
          <path
            fill='#D50000'
            d='M1959.93,750.51c4.1,0.05,7.09,3.41,7.07,7.96c-0.02,4.16-3.44,8.13-7.1,8.24c-3.77,0.11-7.28-4.04-7.21-8.53
			C1952.76,753.7,1955.82,750.46,1959.93,750.51z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1587.79c0.06-4.29,3.36-8,7.08-7.95c3.76,0.04,7.16,3.83,7.2,8.01c0.05,4.87-2.96,7.98-7.54,7.81
			C1975.86,1595.51,1973.58,1592.84,1973.65,1587.79z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1588.34c-0.14,4.83-2.73,7.41-7.35,7.32c-4.2-0.08-7.07-3.34-6.94-7.88
			c0.12-4.33,3.36-7.9,7.22-7.94C1962.82,1579.8,1966.12,1583.76,1965.99,1588.34z'
          />
          <path
            fill='#D50000'
            d='M1002.48,1456.1c3.9-0.1,7.27,3.44,7.43,7.79c0.17,4.49-2.72,7.89-6.84,8.06c-4.29,0.17-7.86-3.38-7.8-7.78
			C995.33,1459.95,998.69,1456.19,1002.48,1456.1z'
          />
          <path
            fill='#D50000'
            d='M1009.92,1440.76c0,4.62-2.77,7.75-6.95,7.85c-4.3,0.1-7.7-3.35-7.68-7.82c0.02-4.55,3.4-8.09,7.62-7.97
			C1006.96,1432.93,1009.92,1436.29,1009.92,1440.76z'
          />
          <path
            fill='#D50000'
            d='M2466.67,858.41c0.04,3.96-4.04,8.47-7.56,8.35c-3.8-0.13-6.39-3.69-6.79-8.2c-0.42-4.79,2.64-7.55,6.67-8.25
			C2462.43,849.71,2466.63,854.43,2466.67,858.41z'
          />
          <path
            fill='#D50000'
            d='M1930.75,735.17c0.04-4.58,2.93-7.79,7.03-7.8c4.05-0.01,7.44,3.82,7.29,8.25c-0.14,4.32-3.45,7.69-7.46,7.58
			C1933.57,743.1,1930.71,739.75,1930.75,735.17z'
          />
          <path
            fill='#D50000'
            d='M2444.77,1003.45c-0.07,3.77-4.06,8.16-7.43,8.17c-3.96,0.02-6.96-3.74-6.89-8.64
			c0.06-4.41,3.43-8.14,7.13-7.89C2441.08,995.35,2444.84,999.71,2444.77,1003.45z'
          />
          <path
            fill='#D50000'
            d='M2452.41,1002.95c0.05-4.22,3-7.69,6.66-7.84c3.58-0.15,7.68,4.38,7.61,8.39c-0.07,3.77-4.11,8.49-7.4,8.06
			C2454.92,1010.99,2451.64,1007.91,2452.41,1002.95z'
          />
          <path
            fill='#D50000'
            d='M996.02,857.37c0.07-4.58,3.11-8.09,6.95-8.04c3.68,0.05,7.69,4.62,7.47,8.52c-0.22,3.87-4.28,8.08-7.64,7.91
			C999.13,865.58,995.95,861.64,996.02,857.37z'
          />
          <path
            fill='#D50000'
            d='M1960.03,1067.21c3.78,0.15,7.21,4.43,6.96,8.69c-0.28,4.59-3.3,7.43-7.65,7.19
			c-4.21-0.24-6.82-3.42-6.64-8.11C1952.84,1070.77,1956.35,1067.06,1960.03,1067.21z'
          />
          <path
            fill='#D50000'
            d='M2430.45,858.34c0.02-4.51,3.13-8.15,6.91-8.09c3.43,0.05,7.33,4.36,7.4,8.15c0.06,3.68-3.83,8.21-7.2,8.38
			C2433.61,866.96,2430.43,863.19,2430.45,858.34z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1075.47c-0.02-4.17,3.22-8.1,6.82-8.27c3.86-0.18,7.53,3.85,7.5,8.21c-0.04,4.61-2.96,7.72-7.22,7.7
			C1933.4,1083.08,1930.78,1080.26,1930.75,1075.47z'
          />
          <path
            fill='#D50000'
            d='M2378.97,712.06c-0.05,4.58-3,7.88-7.02,7.85c-3.73-0.03-7.21-3.8-7.28-7.88c-0.07-4.43,3.43-8.09,7.6-7.96
			C2376.55,704.21,2379.02,707.16,2378.97,712.06z'
          />
          <path
            fill='#D50000'
            d='M1872.88,1058.68c-4.17,0.05-7.23-3.12-7.39-7.67c-0.15-4.35,2.93-8,6.91-8.16c4.27-0.17,7.76,3.41,7.76,7.97
			C1880.16,1054.98,1876.77,1058.64,1872.88,1058.68z'
          />
          <path
            fill='#D50000'
            d='M1980.69,1433.88c3.85,0.02,7.19,3.65,7.24,7.87c0.06,4.7-3.08,8.06-7.45,7.96c-4.19-0.09-6.89-3.2-6.83-7.89
			C1973.7,1437.39,1976.83,1433.86,1980.69,1433.88z'
          />
          <path
            fill='#D50000'
            d='M1422.44,467.86c-0.04,4.35-3.71,8.17-7.7,8.02c-3.95-0.15-6.98-3.74-6.89-8.17
			c0.09-4.69,2.91-7.66,7.27-7.65C1419.01,460.07,1422.48,463.76,1422.44,467.86z'
          />
          <path
            fill='#D50000'
            d='M2342.76,858.51c-0.01-4.55,2.92-8.14,6.75-8.27c3.74-0.13,7.5,3.99,7.51,8.22c0,4.18-3.8,8.42-7.45,8.32
			C2345.79,866.67,2342.77,863,2342.76,858.51z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1027.82c-0.07-4.29,3.1-8.22,6.8-8.41c3.89-0.2,7.51,3.8,7.51,8.3c0,4.55-2.97,7.82-7.13,7.84
			C1955.65,1035.58,1952.76,1032.46,1952.69,1027.82z'
          />
          <path
            fill='#D50000'
            d='M1865.97,735.22c0.02-4.56,2.9-7.8,6.99-7.85c4.03-0.05,7.38,3.73,7.28,8.2c-0.1,4.33-3.38,7.73-7.37,7.64
			C1868.83,743.12,1865.95,739.78,1865.97,735.22z'
          />
          <path
            fill='#D50000'
            d='M953.28,542.32c-0.03-4.59,2.75-7.67,6.99-7.73c4.26-0.07,7.25,2.96,7.34,7.44c0.09,4.48-3.37,8.48-7.26,8.39
			C956.59,550.32,953.31,546.57,953.28,542.32z'
          />
          <path
            fill='#D50000'
            d='M946.69,1269.3c0.05,5.1-2.25,7.77-6.78,7.86c-4.21,0.08-7.44-3.13-7.54-7.51c-0.11-4.27,3.58-8.52,7.38-8.52
			C943.28,1261.13,946.65,1265.09,946.69,1269.3z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1050.79c0.01,4.52-2.89,7.83-6.91,7.89c-4.25,0.07-7.43-3.38-7.42-8.04
			c0.01-4.21,3.37-7.81,7.26-7.79C1919.97,1042.87,1923.14,1046.43,1923.15,1050.79z'
          />
          <path
            fill='#D50000'
            d='M1894.75,727.37c4,0.1,7.13,3.69,6.99,8.06c-0.13,4.5-3.21,7.76-7.32,7.78c-4,0.02-7.31-3.5-7.35-7.82
			C1887.04,730.91,1890.52,727.27,1894.75,727.37z'
          />
          <path
            fill='#D50000'
            d='M1002.81,608.23c4-0.07,7.47,3.25,7.66,7.32c0.19,4.04-3.69,8.44-7.45,8.46c-3.81,0.03-6.79-3.5-6.84-8.07
			C996.12,610.55,998.05,608.31,1002.81,608.23z'
          />
          <path
            fill='#D50000'
            d='M988.54,1269.6c-0.05,4.31-3.33,7.62-7.51,7.55c-4.73-0.07-6.94-2.75-6.81-8.28
			c0.09-3.85,3.47-7.68,6.83-7.74C984.77,1261.07,988.59,1265.38,988.54,1269.6z'
          />
          <path
            fill='#D50000'
            d='M1002.65,1277.2c-3.84,0.03-7.36-3.69-7.36-7.75c0-3.68,3.86-8.13,7.2-8.31c3.46-0.18,7.5,4.36,7.48,8.43
			C1009.96,1274.02,1006.93,1277.17,1002.65,1277.2z'
          />
          <path
            fill='#D50000'
            d='M1923.15,735.22c0.03,4.35-3.04,7.88-6.96,7.98c-3.92,0.1-7.25-3.37-7.36-7.67
			c-0.11-4.45,3.28-8.21,7.35-8.17C1920.24,727.41,1923.12,730.65,1923.15,735.22z'
          />
          <path
            fill='#D50000'
            d='M1937.56,1042.85c3.89-0.14,7.33,3.34,7.51,7.57c0.19,4.58-2.98,8.21-7.2,8.26
			c-4.07,0.05-7.04-3.15-7.12-7.65C1930.68,1046.67,1933.75,1042.98,1937.56,1042.85z'
          />
          <path
            fill='#D50000'
            d='M945.7,616.11c-0.04,4.51-3.17,8.04-7.01,7.91c-3.64-0.12-7.26-4.12-7.3-8.06c-0.05-4.28,3.51-7.82,7.74-7.72
			C943.69,608.34,945.75,610.81,945.7,616.11z'
          />
          <path
            fill='#D50000'
            d='M1981.32,1042.85c4.08-0.02,7.33,3.38,7.43,7.77c0.09,4.18-3.22,7.94-7.07,8.06c-4.4,0.13-7.6-3.25-7.55-7.95
			C1974.17,1046.37,1977.37,1042.87,1981.32,1042.85z'
          />
          <path
            fill='#D50000'
            d='M1002.78,1373.89c-4.12,0.03-7.41-3.29-7.5-7.59c-0.09-4.4,3.46-8.32,7.49-8.25
			c3.95,0.07,7.17,3.69,7.13,8.04C1009.86,1370.77,1007.04,1373.86,1002.78,1373.89z'
          />
          <path
            fill='#D50000'
            d='M2408.51,1027.85c0-4.45,3.1-8.11,6.95-8.2c3.73-0.09,7.47,4.2,7.4,8.49c-0.06,4.1-3.62,7.88-7.42,7.9
			C2411.67,1036.05,2408.51,1032.32,2408.51,1027.85z'
          />
          <path
            fill='#D50000'
            d='M2364.66,688.73c-0.05-5.1,2.64-8.2,7.05-8.14c3.78,0.05,7.24,3.82,7.24,7.87c0,4.38-3.57,8.03-7.76,7.92
			C2367.21,696.28,2364.71,693.35,2364.66,688.73z'
          />
          <path
            fill='#D50000'
            d='M988.55,1366.22c-0.07,4.19-3.48,7.7-7.44,7.67c-4.61-0.04-7.27-3.13-7.07-8.2c0.18-4.38,3.51-7.81,7.42-7.63
			C985.14,1358.22,988.62,1362.23,988.55,1366.22z'
          />
          <path
            fill='#D50000'
            d='M959.43,1358.05c3.78-0.02,7.16,3.7,7.19,7.91c0.03,4.42-3,7.85-7.01,7.93c-4.33,0.1-7.24-2.86-7.32-7.44
			C952.21,1361.81,955.39,1358.07,959.43,1358.05z'
          />
          <path
            fill='#D50000'
            d='M895,743.2c-4.02-0.04-7.21-3.46-7.3-7.83c-0.1-4.33,3.48-8.11,7.55-8.01c3.98,0.11,7.22,3.53,7.29,7.71
			C902.61,739.39,899.03,743.24,895,743.2z'
          />
          <path
            fill='#D50000'
            d='M2452.41,955.64c-0.01-4.82,2.61-8.07,6.66-8.26c3.73-0.18,7.74,4.2,7.61,8.29
			c-0.13,3.93-3.93,8.11-7.36,8.08C2455.48,963.72,2452.41,960.13,2452.41,955.64z'
          />
          <path
            fill='#D50000'
            d='M923.74,710.49c0.06,4.8-3.02,8.43-7.07,8.33c-3.69-0.1-7.22-4.03-7.21-8.05c0-4.16,3.37-7.79,7.25-7.81
			C921.28,702.94,923.67,705.5,923.74,710.49z'
          />
          <path
            fill='#D50000'
            d='M1981.38,1108.78c-4.25-0.01-7.15-3.11-7.23-7.73c-0.08-4.67,3.24-8.26,7.47-8.1
			c4.07,0.16,7.2,3.71,7.12,8.08C1988.66,1105.37,1985.41,1108.79,1981.38,1108.78z'
          />
          <path
            fill='#D50000'
            d='M953.29,688.07c0.07-4.59,2.89-7.52,7.19-7.49c4.2,0.03,7.12,3.16,7.12,7.64c0.01,4.23-3.25,8.01-6.99,8.12
			C956.69,696.47,953.22,692.55,953.29,688.07z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1782.63c-0.02-4.93,2.4-7.71,6.8-7.82c4.21-0.1,7.35,2.99,7.48,7.37c0.13,4.51-3.29,8.5-7.23,8.44
			C1976.88,1790.56,1973.67,1786.92,1973.65,1782.63z'
          />
          <path
            fill='#D50000'
            d='M1988.71,735.53c-0.14,4.42-3.4,7.78-7.45,7.67c-4.07-0.1-7.08-3.49-7.03-7.92c0.05-4.85,3.12-8.08,7.54-7.92
			C1985.6,727.51,1988.84,731.32,1988.71,735.53z'
          />
          <path
            fill='#D50000'
            d='M1872.54,1083.09c-4.23-0.09-7.11-3.32-7.07-7.91c0.04-4.18,3.54-8.01,7.3-7.98
			c4.06,0.03,7.56,4.01,7.38,8.39C1879.97,1079.92,1876.66,1083.18,1872.54,1083.09z'
          />
          <path
            fill='#D50000'
            d='M945.88,688.52c-0.02,4.23-3.4,7.93-7.17,7.86c-3.78-0.08-7.25-3.84-7.33-7.97c-0.08-4.03,3.75-7.9,7.76-7.82
			C943.47,680.67,945.91,683.54,945.88,688.52z'
          />
          <path
            fill='#D50000'
            d='M923.73,688.58c-0.06,4.51-3.15,7.91-7.05,7.78c-3.79-0.13-7.3-4.05-7.21-8.04c0.1-4.18,3.82-7.87,7.81-7.74
			C921.66,680.73,923.8,683.38,923.73,688.58z'
          />
          <path
            fill='#D50000'
            d='M1465.32,541.6c-0.05,4.55-3.12,7.87-7.14,7.7c-3.93-0.16-7.18-3.75-7.18-7.96c0-4.23,3.7-8.02,7.71-7.88
			C1462.66,533.61,1465.37,536.93,1465.32,541.6z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1612.78c-0.05,4.68-2.77,7.52-7.17,7.48c-4.14-0.04-7.15-3.28-7.13-7.68
			c0.02-4.53,3.47-8.25,7.54-8.12C1962.92,1604.58,1966.04,1608.42,1965.99,1612.78z'
          />
          <path
            fill='#D50000'
            d='M1002.84,1553.05c3.81,0.14,7.22,4.01,7.17,8.14c-0.06,4.66-3.38,7.94-7.8,7.71
			c-3.99-0.21-7.18-3.98-6.91-8.15C995.59,1556.41,999.03,1552.9,1002.84,1553.05z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1733.54c-0.05-5.01,2.27-7.65,6.81-7.76c4.23-0.1,7.36,2.98,7.47,7.37
			c0.12,4.56-3.23,8.47-7.24,8.44C1976.89,1741.57,1973.69,1737.91,1973.65,1733.54z'
          />
          <path
            fill='#D50000'
            d='M945.86,1004.65c0.06,4.3-3.1,8.06-6.92,8.23c-3.5,0.16-7.35-3.81-7.57-7.81c-0.21-3.92,3.27-8.01,6.96-8.14
			C943.12,996.76,945.78,999.48,945.86,1004.65z'
          />
          <path
            fill='#D50000'
            d='M1981.92,607.13c3.95,0.09,7.2,3.79,6.98,7.93c-0.23,4.22-3.7,7.94-7.38,7.9c-3.95-0.04-7.02-3.88-6.87-8.56
			C1974.81,609.47,1977.25,607.03,1981.92,607.13z'
          />
          <path
            fill='#D50000'
            d='M945.69,881.82c0.04,4.51-3.34,8.51-7.07,8.39c-3.62-0.12-7.3-4.23-7.25-8.12c0.05-4.1,3.37-7.56,7.35-7.63
			C943.47,874.37,945.65,876.67,945.69,881.82z'
          />
          <path
            fill='#D50000'
            d='M1387.44,566.67c-0.09-3.98,3.97-8.52,7.52-8.41c3.41,0.11,7.27,4.34,7.39,8.11c0.12,3.7-4.18,8.52-7.57,8.48
			C1391.61,574.79,1387.52,570.25,1387.44,566.67z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1075.14c0.07,4.82-2.5,7.8-6.82,7.95c-4.37,0.15-7.36-2.79-7.5-7.37c-0.14-4.42,3.27-8.48,7.16-8.52
			C1919.62,1067.17,1923.09,1071.03,1923.15,1075.14z'
          />
          <path
            fill='#D50000'
            d='M1002.61,1350.76c-3.79,0.02-7.31-3.71-7.31-7.74c0-3.84,3.77-8.13,7.22-8.21c3.75-0.09,7.55,4.18,7.48,8.41
			C1009.93,1347.61,1006.87,1350.73,1002.61,1350.76z'
          />
          <path
            fill='#D50000'
            d='M995.98,882.53c-0.09-4.98,2.32-7.93,6.6-8.08c3.95-0.14,7.86,3.71,7.86,7.75c0,4.13-3.34,7.86-7.23,8.06
			C999.37,890.45,996.05,886.92,995.98,882.53z'
          />
          <path
            fill='#D50000'
            d='M1465.32,517.82c0.06,4.74-3.03,8.28-7.18,8.22c-3.75-0.06-7.06-3.76-7.14-7.97
			c-0.07-4.16,3.24-7.79,7.19-7.87C1462.32,510.1,1465.26,513.24,1465.32,517.82z'
          />
          <path
            fill='#D50000'
            d='M1392.99,510.19c3.99-0.08,7.38,3.39,7.49,7.67c0.11,4.15-3.21,7.98-7.07,8.17c-4.19,0.2-7.28-2.92-7.47-7.56
			C1385.73,513.36,1388.35,510.28,1392.99,510.19z'
          />
          <path
            fill='#D50000'
            d='M1981.12,1140.88c4.11-0.14,7.38,3.12,7.62,7.59c0.23,4.17-3.02,8.06-6.88,8.24
			c-4.49,0.22-7.65-2.95-7.74-7.74C1974.05,1144.6,1977.14,1141.01,1981.12,1140.88z'
          />
          <path
            fill='#D50000'
            d='M2466.67,906.58c-0.09,3.79-4.08,8.15-7.44,8.12c-4.09-0.04-7.01-3.98-6.82-9.22
			c0.14-3.83,3.22-7.2,6.68-7.31C2462.7,898.06,2466.77,902.57,2466.67,906.58z'
          />
          <path
            fill='#D50000'
            d='M1952.69,687.22c0.01-4.5,2.96-7.78,6.99-7.78c4.26,0,7.42,3.51,7.31,8.13c-0.1,4.26-3.46,7.79-7.35,7.71
			C1955.78,695.2,1952.68,691.6,1952.69,687.22z'
          />
          <path
            fill='#D50000'
            d='M1937.03,1644.67c-4.08-0.05-7.24-3.57-7.11-7.94c0.12-4.34,3.42-7.88,7.34-7.89
			c4.08-0.02,7.55,3.94,7.36,8.39C1944.43,1641.73,1941.38,1644.72,1937.03,1644.67z'
          />
          <path
            fill='#D50000'
            d='M967.6,833.49c-0.08,4.28-3.4,7.84-7.26,7.77c-4.02-0.07-7.24-3.94-7.06-8.5c0.17-4.34,3.4-7.5,7.49-7.33
			C964.74,825.59,967.69,829.07,967.6,833.49z'
          />
          <path
            fill='#D50000'
            d='M1937.85,679.44c4,0.03,7.19,3.45,7.23,7.74c0.04,4.46-3.44,8.22-7.49,8.1c-3.78-0.11-6.89-3.82-6.84-8.16
			C1930.81,682.62,1933.78,679.4,1937.85,679.44z'
          />
          <path
            fill='#D50000'
            d='M1865.96,687.01c0.07-4.42,3.13-7.63,7.21-7.59c3.91,0.05,7.1,3.6,7.07,7.86c-0.04,4.47-3.56,8.18-7.58,7.98
			C1868.92,695.09,1865.89,691.36,1865.96,687.01z'
          />
          <path
            fill='#D50000'
            d='M1393.28,475.88c-4.12,0.11-7.22-3.13-7.34-7.67c-0.13-5.01,2.65-8.14,7.24-8.16
			c3.92-0.01,7.27,3.57,7.31,7.82C1400.52,471.93,1397.06,475.78,1393.28,475.88z'
          />
          <path
            fill='#D50000'
            d='M2452.41,1150.56c0-4.74,2.67-8.04,6.65-8.23c3.24-0.15,7.46,4.22,7.59,7.87c0.14,4.01-3.76,8.46-7.47,8.5
			C2455.49,1158.75,2452.41,1155.03,2452.41,1150.56z'
          />
          <path
            fill='#D50000'
            d='M974.02,516.78c0.07-4.69,2.98-7.81,7.16-7.68c4.01,0.12,7.19,3.65,7.2,7.97c0,4.15-3.37,7.82-7.23,7.86
			C976.66,524.96,973.94,521.86,974.02,516.78z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1758.01c-0.03-4.84,2.46-7.55,7.01-7.61c4.27-0.06,7.31,3.08,7.31,7.54c0,4.37-3.61,8.43-7.38,8.3
			C1912.22,1766.11,1908.85,1762.13,1908.83,1758.01z'
          />
          <path
            fill='#D50000'
            d='M945.68,664.08c-0.06,4.42-3.32,8.07-7.05,7.89c-3.63-0.18-7.21-4.2-7.24-8.14c-0.03-4.26,3.56-7.8,7.79-7.68
			C943.75,656.27,945.75,658.72,945.68,664.08z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1515.58c0.01,4.74-2.63,7.69-6.92,7.74c-4.19,0.05-7.26-3.05-7.38-7.44
			c-0.12-4.47,3.25-8.34,7.29-8.37C1962.66,1507.48,1965.98,1511.3,1965.99,1515.58z'
          />
          <path
            fill='#D50000'
            d='M2430.45,807.99c0.05-4.18,3.02-7.69,6.63-7.85c3.59-0.16,7.75,4.36,7.67,8.34
			c-0.07,3.67-4.15,8.14-7.44,8.15C2433.44,816.65,2430.39,812.8,2430.45,807.99z'
          />
          <path
            fill='#D50000'
            d='M2393.6,704.07c3.95,0.03,7.32,3.59,7.32,7.76c0,4.41-3.54,8.21-7.53,8.08c-3.97-0.14-6.83-3.53-6.81-8.08
			C2386.6,707.22,2389.48,704.05,2393.6,704.07z'
          />
          <path
            fill='#D50000'
            d='M2422.85,931.22c-0.11,4.13-3.68,7.88-7.47,7.86c-3.93-0.02-7.02-3.95-6.86-8.73
			c0.14-4.1,3.34-7.62,6.98-7.67C2419.21,922.64,2422.97,927,2422.85,931.22z'
          />
          <path
            fill='#D50000'
            d='M923.74,808.61c0.05,4.66-2.72,8.09-6.65,8.24c-3.9,0.15-7.69-3.88-7.63-8.13c0.06-4.23,3.35-7.67,7.35-7.71
			C921.36,800.98,923.69,803.54,923.74,808.61z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1173.44c0.02-4.28,3.26-8.1,6.94-8.16c3.84-0.07,7.43,3.97,7.36,8.28
			c-0.07,4.67-2.93,7.69-7.22,7.65C1955.57,1181.18,1952.67,1177.99,1952.69,1173.44z'
          />
          <path
            fill='#D50000'
            d='M960.55,801.02c4.27,0.05,7.07,3.13,7.06,7.76c-0.02,4.23-3.29,8-7.03,8.07c-3.93,0.07-7.34-3.82-7.28-8.32
			C953.36,803.91,956.2,800.97,960.55,801.02z'
          />
          <path
            fill='#D50000'
            d='M980.88,816.85c-3.83-0.09-7.14-3.83-7.15-8.1c-0.02-4.71,3.19-7.93,7.68-7.73c4.1,0.18,7.19,3.76,6.97,8.09
			C988.16,813.44,984.78,816.94,980.88,816.85z'
          />
          <path
            fill='#D50000'
            d='M1894.61,462.26c4.15,0.01,7.44,3.33,7.44,7.5c0,3.55-4.03,8.16-7.26,8.3c-3.4,0.15-7.66-4.51-7.67-8.4
			C1887.11,465.5,1890.4,462.25,1894.61,462.26z'
          />
          <path
            fill='#D50000'
            d='M2393.61,751.99c4.28,0.02,7.3,3.16,7.31,7.59c0.01,4.36-3.69,8.43-7.51,8.25c-3.58-0.17-6.85-4.16-6.83-8.33
			C2386.61,754.53,2389.02,751.96,2393.61,751.99z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1661.69c-0.07,4.53-2.97,7.62-7.17,7.61c-4.04,0-7.12-3.35-7.13-7.74c-0.01-4.45,3.55-8.3,7.48-8.11
			C1962.89,1653.64,1966.06,1657.46,1965.99,1661.69z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1661.73c-0.05-4.52,3.33-8.36,7.28-8.26c3.75,0.09,7.06,3.88,7.04,8.07
			c-0.02,4.42-3.06,7.73-7.16,7.77C1911.81,1669.34,1908.88,1666.25,1908.83,1661.73z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1661.34c0.04,4.57-2.76,7.85-6.79,7.96c-4.35,0.12-7.59-3.26-7.54-7.89
			c0.05-4.2,3.41-7.93,7.17-7.96C1897.78,1653.43,1901.17,1657.17,1901.21,1661.34z'
          />
          <path
            fill='#D50000'
            d='M1901.86,1198.1c0,4.4-3.13,7.65-7.37,7.65c-4.09,0-7.37-3.39-7.42-7.68c-0.06-4.47,3.53-8.31,7.63-8.17
			C1898.42,1190.03,1901.86,1193.97,1901.86,1198.1z'
          />
          <path
            fill='#D50000'
            d='M2378.97,759.91c-0.05,4.12-3.58,8.01-7.16,7.9c-3.63-0.11-7.12-4.1-7.14-8.17
			c-0.03-4.65,3.12-7.76,7.73-7.65C2376.86,752.09,2379.03,754.71,2378.97,759.91z'
          />
          <path
            fill='#D50000'
            d='M988.54,1318.55c0,4.16-3.44,7.71-7.44,7.68c-4.55-0.03-6.87-2.64-6.89-7.75c-0.02-4.18,3.23-8.19,6.74-8.32
			C984.56,1310.04,988.55,1314.44,988.54,1318.55z'
          />
          <path
            fill='#D50000'
            d='M1024.75,816.85c-3.8,0.09-7.36-3.47-7.62-7.64c-0.27-4.29,3.25-8.18,7.41-8.19c4.47-0.01,7.07,2.75,7.19,7.6
			C1031.81,812.81,1028.47,816.76,1024.75,816.85z'
          />
          <path
            fill='#D50000'
            d='M1472.93,493.68c-0.04-5.11,2.65-8.21,7.05-8.13c3.71,0.06,7.28,3.94,7.24,7.88
			c-0.04,4.46-3.54,8.07-7.7,7.95C1475.77,501.28,1472.96,498,1472.93,493.68z'
          />
          <path
            fill='#D50000'
            d='M1465.32,493.57c-0.02,4.57-2.96,7.82-7.06,7.82c-3.83,0-7.2-3.65-7.26-7.85c-0.06-4.29,3.56-8.09,7.61-7.98
			C1462.69,485.67,1465.34,488.84,1465.32,493.57z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1757.86c0.02,4.38-3.59,8.52-7.3,8.38c-3.66-0.14-6.95-3.98-7-8.17c-0.06-4.52,2.86-7.66,7.14-7.67
			C1963.42,1750.39,1965.97,1753.05,1965.99,1757.86z'
          />
          <path
            fill='#D50000'
            d='M1930.75,758.15c0.07-4.62,3.04-7.76,7.23-7.65c3.99,0.1,7.15,3.69,7.09,8.04c-0.06,4.13-3.53,8.09-7.16,8.17
			C1934.18,766.78,1930.68,762.6,1930.75,758.15z'
          />
          <path
            fill='#D50000'
            d='M1870.88,462.26c4.36-0.03,7.36,3,7.37,7.45c0.01,4.61-3.36,8.45-7.33,8.36c-3.71-0.08-6.91-3.87-6.92-8.19
			C1863.99,464.8,1866.26,462.29,1870.88,462.26z'
          />
          <path
            fill='#D50000'
            d='M2378.97,736.41c0.02,4.65-2.76,7.84-6.89,7.9c-3.84,0.06-7.33-3.57-7.41-7.69c-0.08-4.23,3.57-8.18,7.5-8.15
			C2376.26,728.5,2378.95,731.65,2378.97,736.41z'
          />
          <path
            fill='#D50000'
            d='M1472.92,467.99c-0.03-4.86,2.48-7.81,6.75-7.93c3.91-0.11,7.44,3.39,7.56,7.48
			c0.12,4.24-3.49,8.33-7.35,8.34C1475.87,475.88,1472.95,472.57,1472.92,467.99z'
          />
          <path
            fill='#D50000'
            d='M1443.64,467.83c0.01,4.72-2.78,7.98-6.9,8.05c-4.01,0.07-7.72-3.89-7.65-8.16c0.07-4.16,3.51-7.67,7.51-7.66
			C1440.97,460.06,1443.63,462.99,1443.64,467.83z'
          />
          <path
            fill='#D50000'
            d='M1887.18,590.2c0.1-4.42,3.6-7.85,7.88-7.7c3.82,0.13,7.2,4.06,6.96,8.11c-0.23,4.03-3.99,7.82-7.63,7.71
			C1890.81,598.21,1887.09,594.03,1887.18,590.2z'
          />
          <path
            fill='#D50000'
            d='M923.73,664.21c-0.09,4.21-3.52,7.9-7.21,7.76c-3.61-0.14-7.17-4.33-7.05-8.27c0.14-4.52,3.47-7.7,7.89-7.54
			C921.86,656.31,923.84,658.82,923.73,664.21z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1173.19c0.07,4.77-2.51,7.8-6.8,7.96c-4.32,0.16-7.38-2.83-7.52-7.34c-0.13-4.36,3.34-8.5,7.15-8.53
			C1919.63,1165.25,1923.09,1169.07,1923.15,1173.19z'
          />
          <path
            fill='#D50000'
            d='M953.3,516.74c0.09-4.22,3.48-7.74,7.35-7.64c3.85,0.1,7,3.75,6.95,8.07c-0.04,4.38-3.16,7.78-7.13,7.79
			C956.26,524.95,953.19,521.43,953.3,516.74z'
          />
          <path
            fill='#D50000'
            d='M1944.67,1515.64c0.05,4.3-3.13,7.64-7.31,7.69c-3.93,0.05-7.33-3.42-7.44-7.58
			c-0.12-4.32,3.59-8.36,7.58-8.24C1941.21,1507.62,1944.63,1511.49,1944.67,1515.64z'
          />
          <path
            fill='#D50000'
            d='M1894.32,1302.69c-3.98-0.05-7.3-3.65-7.25-7.84c0.06-4.34,3.35-7.94,7.3-8c3.92-0.06,7.6,4.06,7.5,8.37
			C1901.77,1299.61,1898.61,1302.74,1894.32,1302.69z'
          />
          <path
            fill='#D50000'
            d='M1938.19,598.32c-3.78-0.08-7.29-3.96-7.26-8.04c0.03-4.38,3.57-7.94,7.74-7.77
			c4.06,0.16,7.19,3.71,7.02,7.97C1945.53,594.53,1941.84,598.39,1938.19,598.32z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1636.73c0.07-4.36,3.29-7.94,7.09-7.9c3.7,0.04,7.15,3.87,7.19,7.96c0.04,4.67-3.15,8-7.54,7.87
			C1976.21,1644.54,1973.57,1641.44,1973.65,1636.73z'
          />
          <path
            fill='#D50000'
            d='M945.69,833.32c0.01,4.74-2.78,7.99-6.83,7.94c-3.98-0.05-7.34-3.53-7.49-7.77c-0.15-4.1,3.64-8.13,7.6-8.08
			C943.31,825.46,945.67,828.24,945.69,833.32z'
          />
          <path
            fill='#D50000'
            d='M2487.6,1199.32c0.04,4.68-2.88,8.29-6.74,8.36c-3.77,0.07-7.53-4.13-7.5-8.39c0.02-3.96,3.67-8.03,7.28-8.14
			C2484.4,1191.05,2487.56,1194.75,2487.6,1199.32z'
          />
          <path
            fill='#D50000'
            d='M1974.43,687.1c0.08-4.83,2.72-7.7,7.07-7.68c3.84,0.02,7.21,3.68,7.24,7.87c0.03,4.5-3.5,8.17-7.67,7.98
			C1976.96,695.09,1974.36,691.88,1974.43,687.1z'
          />
          <path
            fill='#D50000'
            d='M995.98,833.36c-0.01-4.82,2.64-7.9,6.82-7.95c3.96-0.05,7.77,3.98,7.63,8.06c-0.15,4.21-3.55,7.74-7.49,7.78
			C998.86,841.29,995.99,838.03,995.98,833.36z'
          />
          <path
            fill='#D50000'
            d='M1865.97,710.82c-0.03-4.66,2.72-7.77,6.93-7.86c3.97-0.08,7.25,3.33,7.35,7.63c0.1,4.48-3.31,8.31-7.33,8.22
			C1869.03,718.74,1865.99,715.25,1865.97,710.82z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1515.78c-0.03-4.3,3.16-8.17,6.82-8.26c4.04-0.1,7.53,3.7,7.5,8.17c-0.03,4.33-3.14,7.63-7.24,7.66
			C1911.57,1523.37,1908.86,1520.48,1908.83,1515.78z'
          />
          <path
            fill='#D50000'
            d='M2342.76,906.36c0-4.51,3.01-8.14,6.81-8.21c3.82-0.07,7.45,3.98,7.43,8.3c-0.01,4.15-3.87,8.39-7.5,8.24
			C2345.77,914.55,2342.76,910.84,2342.76,906.36z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1466.64c-0.04-4.2,3.15-8.01,6.85-8.17c3.89-0.17,7.5,3.76,7.47,8.13c-0.02,4.41-3.12,7.7-7.22,7.68
			C1911.67,1474.26,1908.87,1471.24,1908.83,1466.64z'
          />
          <path
            fill='#D50000'
            d='M974.21,1245.44c-0.02-5.2,2.17-7.72,6.76-7.78c4.2-0.06,7.47,3.17,7.57,7.47c0.1,4.2-3.68,8.52-7.44,8.5
			C977.53,1253.61,974.23,1249.69,974.21,1245.44z'
          />
          <path
            fill='#D50000'
            d='M975.22,881.72c0.09-4.76,2.86-7.66,7.22-7.54c3.84,0.11,7.24,3.94,7.11,8.05c-0.12,4.1-3.67,7.98-7.32,7.99
			C978.43,890.24,975.14,886.24,975.22,881.72z'
          />
          <path
            fill='#D50000'
            d='M1923.15,566.14c-0.06,4.97-2.52,7.78-6.86,7.81c-3.97,0.03-7.61-3.96-7.45-8.17
			c0.16-4.28,3.52-7.7,7.52-7.67C1920.44,558.15,1923.21,561.42,1923.15,566.14z'
          />
          <path
            fill='#D50000'
            d='M1394.65,607.14c4.19-0.08,7.41,3.02,7.67,7.39c0.24,4.01-3.64,8.42-7.43,8.44
			c-3.68,0.02-7.18-3.77-7.39-7.99C1387.28,610.74,1390.5,607.22,1394.65,607.14z'
          />
          <path
            fill='#D50000'
            d='M1494.89,517.98c0-5.19,2.23-7.74,6.78-7.79c4.06-0.04,7.29,3.25,7.46,7.62c0.17,4.2-3.57,8.25-7.58,8.22
			C1497.33,526,1494.89,523.05,1494.89,517.98z'
          />
          <path
            fill='#D50000'
            d='M1002.83,1302.78c-3.9,0.06-7.44-3.44-7.56-7.49c-0.11-3.75,3.59-8.18,7.02-8.42
			c3.74-0.26,7.64,3.89,7.69,8.19C1010.04,1299.55,1007.11,1302.71,1002.83,1302.78z'
          />
          <path
            fill='#D50000'
            d='M1944.67,1612.68c-0.02,4.35-3.17,7.58-7.39,7.57c-3.96-0.01-7.33-3.52-7.35-7.66
			c-0.02-4.38,3.68-8.31,7.65-8.13C1941.25,1604.63,1944.68,1608.61,1944.67,1612.68z'
          />
          <path
            fill='#D50000'
            d='M1472.93,541.64c-0.04-5.11,2.63-8.22,7.02-8.18c3.73,0.03,7.3,3.88,7.28,7.83
			c-0.03,4.53-3.46,8.12-7.65,8.01C1475.77,549.2,1472.97,545.97,1472.93,541.64z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1612.22c0.13-4.22,3.7-7.99,7.31-7.75c4.06,0.28,7.26,4.22,7.01,8.64c-0.25,4.3-3.49,7.31-7.72,7.15
			C1911.25,1620.11,1908.68,1616.97,1908.83,1612.22z'
          />
          <path
            fill='#D50000'
            d='M1901.85,1075.41c-0.02,4.44-3.1,7.66-7.34,7.68c-4.14,0.02-7.36-3.26-7.48-7.63
			c-0.11-4.23,3.7-8.34,7.66-8.24C1898.34,1067.3,1901.87,1071.34,1901.85,1075.41z'
          />
          <path
            fill='#D50000'
            d='M1908.83,1563.59c-0.04-4.2,3.16-8.03,6.84-8.21c3.86-0.18,7.5,3.79,7.48,8.15
			c-0.02,4.43-3.09,7.69-7.22,7.68C1911.67,1571.2,1908.87,1568.19,1908.83,1563.59z'
          />
          <path
            fill='#D50000'
            d='M1981.41,1335.87c4.04,0.02,7.25,3.45,7.34,7.85c0.09,4.49-3.35,8.07-7.63,7.95
			c-4.21-0.12-7.01-3.34-6.92-7.97C1974.28,1339.18,1977.35,1335.85,1981.41,1335.87z'
          />
          <path
            fill='#D50000'
            d='M1952.69,735.47c-0.06-4.47,2.85-7.97,6.74-8.1c4.23-0.15,7.55,3.33,7.56,7.91c0.01,4.32-3.18,7.89-7.09,7.93
			C1955.94,743.25,1952.75,739.83,1952.69,735.47z'
          />
          <path
            fill='#D50000'
            d='M2010.4,1343.66c0.12,4.51-2.84,7.88-7.03,8.01c-4.08,0.13-7.43-3.1-7.69-7.41c-0.27-4.4,3.17-8.32,7.34-8.38
			C2007.03,1335.81,2010.29,1339.25,2010.4,1343.66z'
          />
          <path
            fill='#D50000'
            d='M2364.66,1027.36c0-4.5,2.99-8.01,6.84-8.04c3.93-0.03,7.59,4.04,7.45,8.28c-0.14,4.16-3.6,7.65-7.48,7.54
			C2366.87,1035.01,2364.66,1032.49,2364.66,1027.36z'
          />
          <path
            fill='#D50000'
            d='M980.95,777.5c3.96,0,7.36,3.52,7.44,7.69c0.08,4.48-3.44,8.24-7.61,8.12c-3.94-0.11-7.12-3.74-7.02-8.01
			C973.88,780.74,976.86,777.51,980.95,777.5z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1270.24c0.03,4.74-2.6,7.74-6.9,7.86c-4.3,0.12-7.31-2.9-7.42-7.45c-0.1-4.35,3.43-8.46,7.25-8.42
			C1919.7,1262.27,1923.12,1266.14,1923.15,1270.24z'
          />
          <path
            fill='#D50000'
            d='M1937.1,1718.34c-3.8-0.02-7.23-3.77-7.17-7.82c0.06-4.16,3.51-7.95,7.3-8.01c3.7-0.06,7.3,3.79,7.37,7.89
			C1944.67,1715.09,1941.58,1718.37,1937.1,1718.34z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1710.87c-0.07-4.56,3.29-8.46,7.22-8.36c3.78,0.09,7.01,3.81,7.04,8.08
			c0.03,4.4-3.01,7.71-7.11,7.75C1976.33,1718.39,1973.73,1715.66,1973.65,1710.87z'
          />
          <path
            fill='#D50000'
            d='M1966.99,542.35c0.01,4.18-3.3,7.98-7.03,8.06c-3.73,0.08-7.17-3.64-7.28-7.88
			c-0.11-4.35,2.98-7.91,6.92-7.97C1964.3,534.5,1966.98,537.32,1966.99,542.35z'
          />
          <path
            fill='#D50000'
            d='M2342.76,978.57c0.05-4.33,3.45-8.19,7.12-8.1c3.68,0.09,7.11,4.04,7.13,8.21c0.02,4.31-3.2,8.09-7.03,8.24
			C2345.89,987.07,2342.7,983.39,2342.76,978.57z'
          />
          <path
            fill='#D50000'
            d='M945.7,785.22c0.05,4.77-2.61,8.02-6.65,8.11c-4.09,0.1-7.74-3.73-7.67-8.06c0.06-3.93,3.7-7.75,7.41-7.77
			C943.15,777.47,945.65,780.26,945.7,785.22z'
          />
          <path
            fill='#D50000'
            d='M923.73,785.41c0.01,4.78-2.85,8.04-6.94,7.92c-3.98-0.12-7.37-3.82-7.32-8c0.05-4.06,3.62-7.8,7.46-7.83
			C921.27,777.48,923.71,780.31,923.73,785.41z'
          />
          <path
            fill='#D50000'
            d='M2003.01,1108.79c-4.01-0.02-7.31-3.54-7.32-7.81c-0.01-4.57,3.45-8.19,7.69-8.03
			c3.94,0.15,7.16,3.89,7.03,8.16C2010.28,1105.71,2007.29,1108.81,2003.01,1108.79z'
          />
          <path
            fill='#D50000'
            d='M1981.13,1237.83c4.1-0.13,7.35,3.12,7.61,7.6c0.24,4.14-3.03,8.06-6.86,8.24c-4.5,0.21-7.63-2.93-7.73-7.74
			C1974.05,1241.54,1977.14,1237.95,1981.13,1237.83z'
          />
          <path
            fill='#D50000'
            d='M2400.93,1028.16c-0.05,4.1-3.12,7.74-6.66,7.87c-3.83,0.15-7.71-3.98-7.68-8.17
			c0.03-3.83,3.79-8.1,7.24-8.22C2397.7,1019.52,2400.98,1023.46,2400.93,1028.16z'
          />
          <path
            fill='#D50000'
            d='M1865.44,1270.13c0.07-4.16,3.59-7.93,7.37-7.9c4,0.03,7.54,4.08,7.34,8.41c-0.2,4.29-3.54,7.55-7.65,7.44
			C1868.34,1277.98,1865.37,1274.63,1865.44,1270.13z'
          />
          <path
            fill='#D50000'
            d='M945.68,542.18c0.05,4.56-2.77,8.06-6.62,8.23c-3.91,0.17-7.67-3.81-7.67-8.12c0-3.82,3.69-7.67,7.38-7.7
			C943.32,534.55,945.63,537.09,945.68,542.18z'
          />
          <path
            fill='#D50000'
            d='M2430.45,881.86c0.01-4.47,2.99-8.16,6.71-8.31c3.52-0.15,7.72,4.51,7.59,8.42c-0.13,3.83-4.1,8.04-7.49,7.95
			C2433.27,889.8,2430.44,886.45,2430.45,881.86z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1686.03c0.04,4.86-2.45,7.6-6.98,7.66c-4.33,0.06-7.32-3.01-7.32-7.51c0-4.51,3.45-8.42,7.33-8.32
			C1962.71,1677.97,1965.96,1681.77,1965.99,1686.03z'
          />
          <path
            fill='#D50000'
            d='M2342.76,783.71c0.03-4.48,3.03-8.1,6.83-8.22c3.77-0.13,7.44,3.98,7.42,8.29c-0.02,4.4-3.67,8.35-7.5,8.13
			C2345.48,791.67,2342.73,788.33,2342.76,783.71z'
          />
          <path
            fill='#D50000'
            d='M996.17,542.09c0.06-5.06,2.38-7.57,6.97-7.51c3.66,0.05,7.35,3.98,7.28,7.76c-0.08,4.14-3.5,7.95-7.24,8.06
			C999.23,550.54,996.12,546.84,996.17,542.09z'
          />
          <path
            fill='#D50000'
            d='M2010.52,782.62c0.1,4.14-3.72,8.5-7.43,8.5c-3.53,0-7.26-4.02-7.4-7.97c-0.14-4.06,3.26-7.81,7.12-7.86
			C2007.05,775.22,2010.42,778.44,2010.52,782.62z'
          />
          <path
            fill='#D50000'
            d='M1902.03,542.31c-0.03,3.92-3.69,7.99-7.28,8.11c-3.7,0.12-7.64-4.22-7.57-8.33
			c0.07-4.16,3.44-7.51,7.55-7.49C1898.67,534.6,1902.06,538.19,1902.03,542.31z'
          />
          <path
            fill='#D50000'
            d='M1031.39,1488.85c-0.02,5.01-2.45,7.73-6.9,7.72c-3.89-0.01-7.3-3.58-7.36-7.71
			c-0.07-4.48,3.41-8.24,7.52-8.12C1028.74,1480.84,1031.4,1484.04,1031.39,1488.85z'
          />
          <path
            fill='#D50000'
            d='M2430.45,978.59c0.04-4.44,3.21-8.16,6.9-8.11c3.41,0.05,7.37,4.39,7.42,8.12c0.05,3.74-3.81,8.18-7.22,8.31
			C2433.51,987.07,2430.41,983.42,2430.45,978.59z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1125.32c0.04,4.73-2.6,7.78-6.84,7.92c-4.26,0.14-7.36-2.92-7.48-7.38
			c-0.12-4.38,3.35-8.45,7.19-8.44C1919.67,1117.42,1923.12,1121.24,1923.15,1125.32z'
          />
          <path
            fill='#D50000'
            d='M1923.15,542.25c0.05,4.14-3.29,8.13-6.83,8.16c-4.01,0.04-7.53-3.85-7.48-8.26
			c0.04-3.84,3.32-7.45,6.89-7.57C1920.47,534.44,1923.08,537.13,1923.15,542.25z'
          />
          <path
            fill='#D50000'
            d='M995.99,785.46c-0.01-4.83,2.62-7.92,6.79-7.96c4.02-0.05,7.79,3.9,7.66,8.02c-0.13,4.2-3.54,7.77-7.45,7.81
			C998.88,793.37,995.99,790.13,995.99,785.46z'
          />
          <path
            fill='#D50000'
            d='M996.17,688.15c0.05-5.14,2.4-7.66,7.03-7.56c3.76,0.08,7.42,4.1,7.22,7.94c-0.22,4.24-3.79,7.93-7.58,7.85
			C998.92,696.28,996.12,692.83,996.17,688.15z'
          />
          <path
            fill='#D50000'
            d='M1894.45,775.28c4.19,0.01,7.52,3.35,7.48,7.52c-0.05,4.23-3.9,8.4-7.66,8.29c-3.64-0.1-7.2-4.14-7.16-8.15
			C1887.15,778.75,1890.47,775.28,1894.45,775.28z'
          />
          <path
            fill='#D50000'
            d='M1501.65,533.47c3.93-0.03,7.6,3.99,7.47,8.18c-0.13,4.24-3.56,7.72-7.55,7.65
			c-4.23-0.07-6.69-3.02-6.68-8.02C1494.91,536.2,1497.24,533.5,1501.65,533.47z'
          />
          <path
            fill='#D50000'
            d='M1002.77,702.97c4.06-0.07,7.44,3.22,7.65,7.45c0.21,4.13-3.53,8.36-7.41,8.4c-3.82,0.04-7-3.58-7.02-7.97
			C995.98,705.81,998.36,703.04,1002.77,702.97z'
          />
          <path
            fill='#D50000'
            d='M2342.76,955.45c0.08-4.61,2.97-8.07,6.83-8.17c3.82-0.1,7.42,3.93,7.42,8.3c0,4.51-3.55,8.39-7.48,8.18
			C2345.67,963.55,2342.68,959.89,2342.76,955.45z'
          />
          <path
            fill='#D50000'
            d='M996.17,565.59c-0.01-5.26,2.07-7.11,6.84-7.6c4.05-0.42,7.22,4.06,7.38,7.68c0.18,4.18-3.89,8.42-7.64,8.27
			C999.04,573.78,996.18,570.16,996.17,565.59z'
          />
          <path
            fill='#D50000'
            d='M1393.08,533.47c4.06,0.03,7.57,3.96,7.39,8.28c-0.18,4.25-3.62,7.64-7.66,7.56
			c-4.1-0.09-6.99-3.47-6.89-8.08C1386.02,536.48,1388.83,533.43,1393.08,533.47z'
          />
          <path
            fill='#D50000'
            d='M2335.07,616.23c-0.04,4.68-2.95,7.92-7.01,7.8c-3.89-0.12-7.23-3.8-7.2-7.94c0.03-4.6,3.34-8,7.63-7.86
			C2332.88,608.38,2335.11,611.09,2335.07,616.23z'
          />
          <path
            fill='#D50000'
            d='M1009.93,1513.43c-0.12,4.58-3.1,7.62-7.37,7.54c-4.06-0.07-7.3-3.55-7.25-7.79c0.05-4.42,3.75-8.27,7.7-8.03
			C1006.8,1505.37,1010.04,1509.26,1009.93,1513.43z'
          />
          <path
            fill='#D50000'
            d='M1974.65,566.03c-0.02-4.76,2.76-7.96,6.86-7.91c4.22,0.05,7.5,3.74,7.38,8.29c-0.1,3.96-3.76,7.62-7.55,7.55
			C1977.05,573.87,1974.67,571.06,1974.65,566.03z'
          />
          <path
            fill='#D50000'
            d='M1024.53,753.1c4.45-0.01,7.05,2.77,7.14,7.64c0.08,4.26-3.19,8.12-6.95,8.19c-4.03,0.08-7.65-3.81-7.58-8.15
			C1017.2,756.58,1020.54,753.1,1024.53,753.1z'
          />
          <path
            fill='#D50000'
            d='M1944.62,1466.53c-0.03,4.44-3.09,7.71-7.24,7.72c-4.01,0.01-7.35-3.41-7.45-7.62
			c-0.1-4.38,3.51-8.26,7.59-8.16C1941.32,1458.57,1944.66,1462.36,1944.62,1466.53z'
          />
          <path
            fill='#D50000'
            d='M895.26,793.33c-3.98,0.11-7.42-3.4-7.56-7.69c-0.13-4.17,3.18-7.99,7.06-8.13c4.01-0.15,7.51,3.13,7.81,7.31
			C902.86,788.95,899.2,793.22,895.26,793.33z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1515.64c0,4.79-2.59,7.66-6.94,7.7c-4.19,0.04-7.27-3.1-7.38-7.51c-0.11-4.44,3.34-8.32,7.37-8.31
			C1897.93,1507.52,1901.21,1511.35,1901.21,1515.64z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1466.46c0.01,4.58-2.81,7.76-6.91,7.8c-4.23,0.04-7.31-3.06-7.41-7.48c-0.1-4.41,3.38-8.38,7.3-8.31
			C1897.9,1458.52,1901.2,1462.29,1901.21,1466.46z'
          />
          <path
            fill='#D50000'
            d='M1872.6,1010.99c-3.87-0.05-7.35-3.85-7.24-7.91c0.11-4.02,3.69-7.99,7.33-8.12
			c3.63-0.14,7.64,4.41,7.47,8.46C1879.97,1007.61,1876.55,1011.04,1872.6,1010.99z'
          />
          <path
            fill='#D50000'
            d='M895.29,947.46c3.54,0.05,7.3,4.17,7.34,8.06c0.04,3.88-4.31,8.49-7.78,8.25c-3.32-0.23-7.22-4.86-7.12-8.45
			C887.84,951.32,891.62,947.4,895.29,947.46z'
          />
          <path
            fill='#D50000'
            d='M2342.76,808.62c-0.07-4.71,3.14-8.6,7-8.51c3.67,0.09,7.13,3.91,7.25,7.99c0.12,4.23-3.45,8.49-7.19,8.57
			C2346.24,816.75,2342.82,812.84,2342.76,808.62z'
          />
          <path
            fill='#D50000'
            d='M1923.15,710.7c0.05,4.32-3.11,7.99-6.97,8.11c-3.79,0.12-7.3-3.63-7.35-7.85c-0.05-4.39,3.12-7.94,7.13-7.98
			C1920.25,702.93,1923.1,706,1923.15,710.7z'
          />
          <path
            fill='#D50000'
            d='M1937.79,702.97c4-0.02,7.2,3.38,7.28,7.74c0.08,4.13-3.24,7.96-6.99,8.09c-4.15,0.14-7.34-3.38-7.33-8.09
			C1930.76,706.07,1933.56,702.99,1937.79,702.97z'
          />
          <path
            fill='#D50000'
            d='M1952.69,710.43c0.11-4.49,3.26-7.64,7.47-7.46c4.02,0.17,6.98,3.74,6.83,8.22
			c-0.13,3.93-3.53,7.59-7.08,7.62C1955.86,718.86,1952.57,715.04,1952.69,710.43z'
          />
          <path
            fill='#D50000'
            d='M1872.64,1165.28c3.85-0.08,7.26,3.45,7.49,7.75c0.23,4.16-3.02,7.94-6.97,8.12c-4.49,0.21-7.65-3-7.69-7.81
			C1865.44,1169.18,1868.84,1165.35,1872.64,1165.28z'
          />
          <path
            fill='#D50000'
            d='M1937.78,1165.28c3.64-0.05,7.17,3.79,7.29,7.91c0.13,4.58-2.71,7.85-6.93,7.96
			c-4.44,0.12-7.27-2.68-7.39-7.34C1930.64,1169.43,1934.01,1165.33,1937.78,1165.28z'
          />
          <path
            fill='#D50000'
            d='M1865.16,1466.25c0.13-4.28,3.51-7.79,7.48-7.78c3.91,0.01,7.45,4.17,7.18,8.44c-0.29,4.6-3.28,7.5-7.57,7.35
			C1868.34,1474.12,1865.03,1470.38,1865.16,1466.25z'
          />
          <path
            fill='#D50000'
            d='M1980.61,1653.46c3.74-0.08,7.18,3.58,7.32,7.81c0.15,4.35-2.89,7.89-6.9,8.04
			c-4.25,0.15-7.24-2.81-7.38-7.32C1973.51,1657.46,1976.7,1653.55,1980.61,1653.46z'
          />
          <path
            fill='#D50000'
            d='M2342.76,664.15c-0.08-4.73,2.65-7.92,6.85-8c3.99-0.07,7.41,3.47,7.4,7.66c-0.02,4.37-3.19,8.06-7.02,8.17
			C2346.03,672.09,2342.83,668.63,2342.76,664.15z'
          />
          <path
            fill='#D50000'
            d='M1988.74,1002.9c0.1,4.11-3.24,7.96-7.04,8.11c-4.08,0.17-7.39-3.04-7.61-7.39
			c-0.21-4.09,3.44-8.47,7.22-8.65C1984.81,994.8,1988.65,998.89,1988.74,1002.9z'
          />
          <path
            fill='#D50000'
            d='M974.07,590.3c-0.03-4.99,2.34-7.72,6.8-7.8c3.97-0.07,7.34,3.28,7.51,7.48c0.18,4.45-3.26,8.33-7.37,8.34
			C977.07,598.32,974.09,594.88,974.07,590.3z'
          />
          <path
            fill='#D50000'
            d='M996.17,590.21c0.02-5.18,2.22-7.69,6.76-7.72c3.72-0.02,7.44,3.73,7.49,7.55c0.06,4.34-3.59,8.35-7.53,8.27
			C999.02,598.23,996.16,594.78,996.17,590.21z'
          />
          <path
            fill='#D50000'
            d='M1995.7,1294.46c0.36-4.46,3.71-7.78,7.67-7.61c3.96,0.17,7.44,4.45,7.09,8.72c-0.38,4.64-3.82,7.54-8.42,7.1
			C1998.51,1302.33,1995.39,1298.29,1995.7,1294.46z'
          />
          <path
            fill='#D50000'
            d='M1980.71,1750.4c4.34-0.01,7.32,3.18,7.22,7.73c-0.09,4.16-3.62,8.08-7.27,8.09
			c-3.61,0.01-6.99-3.94-7.02-8.2C1973.62,1753.09,1976.1,1750.41,1980.71,1750.4z'
          />
          <path
            fill='#D50000'
            d='M2003.12,1400.7c-4.06,0-7.43-3.46-7.44-7.63c-0.01-4.49,3.53-8.31,7.6-8.19c3.7,0.1,7.18,4.04,7.23,8.19
			C2010.57,1397.37,2007.34,1400.71,2003.12,1400.7z'
          />
          <path
            fill='#D50000'
            d='M1981.27,1302.69c-4.37-0.05-7-2.95-7.04-7.77c-0.04-4.31,3.22-8.03,7.06-8.08c4.06-0.05,7.6,3.9,7.44,8.29
			C1988.59,1299.42,1985.31,1302.73,1981.27,1302.69z'
          />
          <path
            fill='#D50000'
            d='M1981.39,1205.75c-4.4,0-7.06-2.84-7.17-7.65c-0.1-4.29,3.11-8.08,6.95-8.2c4.14-0.13,7.65,3.65,7.57,8.16
			C1988.66,1202.32,1985.38,1205.75,1981.39,1205.75z'
          />
          <path
            fill='#D50000'
            d='M1974.43,664.23c0-4.69,2.8-8.01,6.82-8.08c4.22-0.08,7.62,3.63,7.49,8.17c-0.12,4.26-3.43,7.7-7.4,7.67
			C1976.9,671.95,1974.43,669.18,1974.43,664.23z'
          />
          <path
            fill='#D50000'
            d='M974.01,1464.4c-0.09-4.31,3-8.09,6.77-8.31c3.83-0.22,7.71,3.78,7.76,8.01c0.05,4.09-3.39,7.79-7.28,7.84
			C976.86,1472,974.11,1469.14,974.01,1464.4z'
          />
          <path
            fill='#D50000'
            d='M952.28,1463.98c0.08-4.29,3.33-7.85,7.17-7.87c3.92-0.02,7.35,4.02,7.17,8.41c-0.18,4.39-3.4,7.56-7.53,7.42
			C954.94,1471.8,952.19,1468.6,952.28,1463.98z'
          />
          <path
            fill='#D50000'
            d='M974,1416.28c-0.04-4.31,3.11-7.93,7-8.05c3.83-0.12,7.64,3.99,7.56,8.17c-0.08,4.09-3.6,7.68-7.47,7.63
			C976.71,1423.95,974.04,1421.03,974,1416.28z'
          />
          <path
            fill='#D50000'
            d='M2003.04,1205.77c-3.92-0.02-7.28-3.51-7.35-7.63c-0.08-4.45,3.36-8.25,7.44-8.23
			c3.9,0.02,7.51,4.1,7.41,8.38C2010.44,1202.55,2007.19,1205.78,2003.04,1205.77z'
          />
          <path
            fill='#D50000'
            d='M1002.79,1424.02c-4.1,0.03-7.39-3.29-7.48-7.57c-0.1-4.42,3.46-8.34,7.47-8.23c3.83,0.1,7.22,3.86,7.22,8.02
			C1010.01,1420.67,1006.94,1423.99,1002.79,1424.02z'
          />
          <path
            fill='#D50000'
            d='M2430.45,930.62c0.05-4.42,3.28-8.03,7.04-7.88c3.32,0.13,7.28,4.63,7.28,8.27c0,3.75-3.83,7.96-7.35,8.08
			C2433.57,939.22,2430.39,935.36,2430.45,930.62z'
          />
          <path
            fill='#D50000'
            d='M2400.91,931.03c0,4.38-3.28,8.18-6.95,8.07c-3.7-0.11-7.3-4.01-7.37-7.97c-0.07-4.09,3.88-8.47,7.54-8.36
			C2397.94,922.88,2400.92,926.51,2400.91,931.03z'
          />
          <path
            fill='#D50000'
            d='M1465.32,468.15c-0.06,4.49-3.08,7.76-7.13,7.73c-3.73-0.03-7.16-3.81-7.19-7.94
			c-0.03-4.43,3.5-8.07,7.67-7.89C1462.8,460.24,1465.38,463.38,1465.32,468.15z'
          />
          <path
            fill='#D50000'
            d='M973.93,663.26c0.27-4.99,3.07-7.47,8-7.11c3.64,0.27,6.66,3.99,6.46,7.97c-0.21,4.08-3.89,7.94-7.5,7.85
			C977.09,671.86,973.69,667.62,973.93,663.26z'
          />
          <path
            fill='#D50000'
            d='M901.79,1198.88c0.03,5.28-2.41,7.98-7.17,7.92c-3.7-0.05-6.96-3.45-7.07-7.38
			c-0.13-4.54,3.16-8.41,7.18-8.43C898.61,1190.95,901.76,1194.48,901.79,1198.88z'
          />
          <path
            fill='#D50000'
            d='M1031.7,833.25c0.06,4.34-3.15,7.99-7.04,8c-3.98,0.01-7.36-3.44-7.53-7.68c-0.17-4.21,3.45-8.16,7.49-8.16
			C1028.75,825.4,1031.64,828.61,1031.7,833.25z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1295.26c-0.03,5-2.46,7.6-7.05,7.56c-4.12-0.03-7.26-3.42-7.21-7.78c0.05-4.32,3.27-8.03,7.09-8.17
			C1028.14,1286.72,1031.41,1290.55,1031.38,1295.26z'
          />
          <path
            fill='#D50000'
            d='M2408.52,834.08c-0.03-4.5,2.95-8.19,6.75-8.35c3.82-0.16,7.64,4,7.58,8.25c-0.05,4.15-3.56,8.07-7.23,8.09
			C2411.69,842.09,2408.55,838.56,2408.52,834.08z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1270.55c-0.03-4.44,3.45-8.43,7.27-8.32c3.62,0.1,7.04,4.06,7.03,8.11
			c-0.02,4.6-2.86,7.71-7.09,7.75C1955.6,1278.12,1952.72,1275.11,1952.69,1270.55z'
          />
          <path
            fill='#D50000'
            d='M1879.9,1563.35c0.04,4.38-3.04,7.78-7.15,7.86c-4.18,0.09-7.68-3.6-7.59-7.98c0.09-4.2,3.52-7.81,7.44-7.83
			C1876.26,1555.38,1879.86,1559.3,1879.9,1563.35z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1563.6c-0.05,4.55-2.95,7.65-7.11,7.61c-4.17-0.04-7.2-3.27-7.22-7.69c-0.02-4.42,3.6-8.34,7.5-8.14
			C1898.05,1555.56,1901.26,1559.42,1901.21,1563.6z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1270.34c0.02-4.14,3.34-8.01,6.96-8.11c3.81-0.11,7.45,4,7.36,8.32c-0.09,4.56-3.07,7.61-7.35,7.53
			C1933.33,1278,1930.73,1275.11,1930.75,1270.34z'
          />
          <path
            fill='#D50000'
            d='M1872.88,1035.63c-3.79,0.14-7.45-3.65-7.51-7.77c-0.06-3.92,4.05-8.56,7.53-8.5
			c3.28,0.06,7.26,4.58,7.27,8.25C1880.17,1031.66,1876.7,1035.48,1872.88,1035.63z'
          />
          <path
            fill='#D50000'
            d='M1944.62,1563.5c-0.04,4.43-3.14,7.71-7.26,7.71c-4,0-7.31-3.42-7.42-7.66c-0.11-4.35,3.55-8.27,7.6-8.14
			C1941.34,1555.52,1944.66,1559.32,1944.62,1563.5z'
          />
          <path
            fill='#D50000'
            d='M901.8,1246.82c-0.04,4.37-3.29,8-7.06,7.9c-4.04-0.1-7.31-3.96-7.19-8.47c0.11-4.08,3.2-7.29,7.07-7.34
			C899.4,1238.85,901.85,1241.55,901.8,1246.82z'
          />
          <path
            fill='#D50000'
            d='M1988.74,856.48c0.04,3.85-3.69,8.16-7.17,8.29c-3.67,0.13-7.47-4.17-7.45-8.43
			c0.02-4.43,3.06-7.61,7.32-7.65C1985.32,848.66,1988.7,852.27,1988.74,856.48z'
          />
          <path
            fill='#D50000'
            d='M2452.41,881.69c0.02-4.44,3.14-8.16,6.84-8.16c3.64,0,7.56,4.52,7.42,8.57c-0.13,3.87-4.12,7.98-7.57,7.8
			C2455.1,889.69,2452.39,886.37,2452.41,881.69z'
          />
          <path
            fill='#D50000'
            d='M2430.45,1027.59c0.06-4.47,3.2-8.02,7.01-7.92c3.31,0.09,7.29,4.58,7.32,8.24c0.03,3.76-3.77,7.98-7.32,8.12
			C2433.62,1036.19,2430.39,1032.28,2430.45,1027.59z'
          />
          <path
            fill='#D50000'
            d='M2342.76,881.69c0.03-4.35,3.36-8.22,7.04-8.17c3.63,0.05,7.16,4.03,7.21,8.14c0.06,4.43-3.6,8.5-7.45,8.29
			C2345.51,889.72,2342.72,886.34,2342.76,881.69z'
          />
          <path
            fill='#D50000'
            d='M974.06,615.76c0-5.02,2.29-7.51,6.96-7.52c4.14-0.02,7.28,3.2,7.37,7.53c0.08,4.23-3.7,8.38-7.54,8.25
			C977.09,623.89,974.06,620.21,974.06,615.76z'
          />
          <path
            fill='#D50000'
            d='M1031.66,857.52c0.08,4.01-3.53,8.21-7.08,8.25c-3.33,0.04-7.34-4.29-7.45-8.06
			c-0.12-4.06,3.82-8.44,7.57-8.43C1028.42,849.3,1031.57,853.02,1031.66,857.52z'
          />
          <path
            fill='#D50000'
            d='M1930.75,639.73c-0.02-4.27,3.29-8.16,6.96-8.18c3.76-0.02,7.3,3.77,7.37,7.9c0.07,4.45-3,7.87-7.11,7.92
			C1933.69,647.42,1930.77,644.33,1930.75,639.73z'
          />
          <path
            fill='#D50000'
            d='M1894.33,631.55c3.91-0.07,7.54,3.94,7.47,8.26c-0.06,4.44-3.12,7.53-7.47,7.55
			c-4.03,0.02-7.28-3.46-7.26-7.8C1887.08,635.43,1890.53,631.62,1894.33,631.55z'
          />
          <path
            fill='#D50000'
            d='M1901.21,1612.7c-0.03,4.76-2.65,7.56-7.06,7.55c-4.18-0.01-7.22-3.19-7.28-7.59
			c-0.05-4.44,3.46-8.28,7.5-8.19C1898.03,1604.55,1901.24,1608.41,1901.21,1612.7z'
          />
          <path
            fill='#D50000'
            d='M1068.56,1358.05c3.81,0.04,7.19,3.81,7.2,8.03c0.01,4.57-2.9,7.76-7.14,7.81c-4.26,0.05-7.71-3.66-7.51-8.07
			C1061.31,1361.56,1064.71,1358.01,1068.56,1358.05z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1343.08c-0.01,4.94-2.49,7.68-6.96,7.71c-4.04,0.02-7.28-3.4-7.29-7.72
			c-0.02-4.47,3.57-8.43,7.49-8.26C1028.37,1334.96,1031.4,1338.67,1031.38,1343.08z'
          />
          <path
            fill='#D50000'
            d='M974.02,1512.99c0.08-4.27,3.49-7.96,7.25-7.85c3.65,0.11,7.26,4.1,7.28,8.05c0.02,4.24-3.66,7.92-7.79,7.79
			C976.49,1520.85,973.93,1517.81,974.02,1512.99z'
          />
          <path
            fill='#D50000'
            d='M1974.65,590.33c0.05-5.24,2.54-7.94,7.25-7.83c3.65,0.09,6.92,3.58,6.99,7.47c0.08,4.5-3.33,8.36-7.36,8.34
			C1977.66,598.3,1974.61,594.76,1974.65,590.33z'
          />
          <path
            fill='#D50000'
            d='M2452.41,1101.38c0-4.53,2.95-8.16,6.7-8.26c3.35-0.08,7.39,4.22,7.53,8.03c0.14,3.88-4.08,8.64-7.56,8.51
			C2455.29,1109.53,2452.41,1105.96,2452.41,1101.38z'
          />
          <path
            fill='#D50000'
            d='M1864,907.66c-0.08-4.8,3.08-8.62,7.07-8.55c3.9,0.07,7.09,3.66,7.17,8.08c0.09,4.74-2.58,7.76-6.95,7.86
			C1866.7,915.16,1864.08,912.51,1864,907.66z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1101.4c-0.02,4.61-3.08,8.31-6.85,8.28c-3.72-0.04-7.43-4.36-7.34-8.54
			c0.09-4.04,3.74-8.01,7.36-8.02C2506.49,1093.11,2509.53,1096.79,2509.51,1101.4z'
          />
          <path
            fill='#D50000'
            d='M1850.93,1010.95c-4.39-0.09-7.08-3.4-6.86-8.47c0.17-4.04,3.52-7.56,7.13-7.49
			c4.09,0.08,7.25,3.87,7.11,8.54C1858.17,1008.24,1855.39,1011.04,1850.93,1010.95z'
          />
          <path
            fill='#D50000'
            d='M1002.72,1326.24c-3.77,0.07-7.38-3.68-7.42-7.71c-0.04-3.69,3.76-8.16,7.11-8.36c3.4-0.2,7.6,4.42,7.6,8.36
			C1010.01,1322.91,1006.93,1326.17,1002.72,1326.24z'
          />
          <path
            fill='#D50000'
            d='M1894.77,1011c-4.01,0.08-7.75-3.82-7.69-8.01c0.05-3.74,3.52-7.77,6.9-8.01c3.59-0.26,7.74,3.98,7.93,8.08
			C1902.08,1007,1898.56,1010.92,1894.77,1011z'
          />
          <path
            fill='#D50000'
            d='M2342.76,931.09c-0.06-4.42,2.94-8.13,6.75-8.36c3.79-0.22,7.5,3.86,7.49,8.26c0,4.22-3.28,7.91-7.17,8.09
			C2346.11,939.26,2342.82,935.55,2342.76,931.09z'
          />
          <path
            fill='#D50000'
            d='M2364.66,930.76c0-4.34,3.2-8.04,6.93-8.03c3.3,0.01,7.26,4.39,7.36,8.14c0.11,3.97-3.96,8.34-7.66,8.21
			C2367.61,938.95,2364.66,935.26,2364.66,930.76z'
          />
          <path
            fill='#D50000'
            d='M2488.08,906.53c-0.11,3.99-3.89,8.27-7.22,8.18c-3.54-0.1-7.61-4.9-7.37-8.7c0.24-3.87,4.27-7.99,7.67-7.84
			C2484.77,898.34,2488.19,902.47,2488.08,906.53z'
          />
          <path
            fill='#D50000'
            d='M2452.41,1027.58c0.08-4.47,3.33-8.12,7.05-7.93c3.43,0.18,7.27,4.6,7.21,8.3c-0.07,4.17-4.1,8.36-7.79,8.09
			C2455.18,1035.78,2452.33,1032.05,2452.41,1027.58z'
          />
          <path
            fill='#D50000'
            d='M1858.31,590.24c0.07,4.69-2.7,8.01-6.72,8.08c-3.9,0.07-7.28-3.38-7.5-7.66c-0.21-4.05,3.55-8.2,7.4-8.16
			C1855.9,582.55,1858.24,585.2,1858.31,590.24z'
          />
          <path
            fill='#D50000'
            d='M1031.67,785.21c0.08,4.38-3.05,8.06-6.92,8.12c-4.12,0.07-7.7-3.72-7.62-8.07c0.07-4.06,3.52-7.69,7.37-7.74
			C1028.67,777.46,1031.59,780.59,1031.67,785.21z'
          />
          <path
            fill='#D50000'
            d='M895.19,816.85c-3.73,0.04-7.31-3.67-7.49-7.77c-0.2-4.54,3.01-8.06,7.36-8.07c4.24-0.01,7.31,3.05,7.48,7.46
			C902.69,812.67,899.06,816.81,895.19,816.85z'
          />
          <path
            fill='#D50000'
            d='M1394.61,599.45c-4.06-0.09-7.38-3.75-7.15-7.87c0.24-4.22,3.74-7.93,7.45-7.91c3.83,0.03,7.67,4.38,7.4,8.41
			C1402.02,596.41,1398.75,599.54,1394.61,599.45z'
          />
          <path
            fill='#D50000'
            d='M967.6,785.26c0.03,4.27-3.14,7.96-6.95,8.07c-4.03,0.12-7.4-3.62-7.37-8.18c0.03-4.36,3.16-7.7,7.17-7.66
			C964.45,777.54,967.57,780.92,967.6,785.26z'
          />
          <path
            fill='#D50000'
            d='M2010.38,735.15c0.08,4.43-2.96,7.91-7.04,8.06c-4.07,0.15-7.38-3.12-7.62-7.53
			c-0.24-4.38,3.17-8.23,7.34-8.3C2007.09,727.3,2010.3,730.71,2010.38,735.15z'
          />
          <path
            fill='#D50000'
            d='M2002.81,1523.35c-3.75-0.04-7.2-3.89-7.12-7.91c0.09-4.09,3.66-7.91,7.42-7.93
			c3.89-0.02,7.51,4.13,7.37,8.44C2010.35,1520.14,2006.96,1523.4,2002.81,1523.35z'
          />
          <path
            fill='#D50000'
            d='M1031.39,1537.74c0.02,5.13-2.23,7.75-6.73,7.83c-3.91,0.07-7.42-3.41-7.53-7.46
			c-0.13-4.51,3.19-8.29,7.34-8.35C1028.45,1529.69,1031.37,1533.06,1031.39,1537.74z'
          />
          <path
            fill='#D50000'
            d='M1030.4,1125.07c-0.01,4.18-3.3,8.01-6.96,8.11c-3.87,0.1-7.43-3.98-7.31-8.37c0.11-4.16,3.62-7.8,7.41-7.68
			C1027.71,1117.24,1030.41,1120.37,1030.4,1125.07z'
          />
          <path
            fill='#D50000'
            d='M1894.44,801c4.17-0.03,7.55,3.3,7.51,7.43c-0.04,4.03-4.1,8.5-7.63,8.4c-3.49-0.09-7.25-4.39-7.23-8.24
			C1887.11,804.29,1890.25,801.04,1894.44,801z'
          />
          <path
            fill='#D50000'
            d='M1858.3,664.02c-0.01,5.39-2.36,8.02-7.12,7.97c-3.65-0.04-7.17-3.97-7.11-7.96c0.06-4.36,3.24-7.8,7.27-7.87
			C1855.43,656.09,1858.31,659.34,1858.3,664.02z'
          />
          <path
            fill='#D50000'
            d='M1494.89,493.33c0.03-5.03,2.45-7.78,6.81-7.78c3.9,0,7.62,4.14,7.42,8.25c-0.2,4.23-3.67,7.68-7.61,7.58
			C1497.33,501.28,1494.86,498.28,1494.89,493.33z'
          />
          <path
            fill='#D50000'
            d='M2003.33,1433.88c3.85,0.11,7.16,3.93,7.08,8.15c-0.09,4.75-3.32,7.9-7.85,7.67
			c-3.76-0.19-6.81-3.63-6.86-7.74C1995.66,1437.48,1999.17,1433.76,2003.33,1433.88z'
          />
          <path
            fill='#D50000'
            d='M2488.07,979.05c-0.1,3.99-3.84,8.05-7.33,7.94c-3.39-0.11-7.31-4.55-7.26-8.24
			c0.05-3.95,4.34-8.51,7.77-8.25C2484.59,970.76,2488.17,975.24,2488.07,979.05z'
          />
          <path
            fill='#D50000'
            d='M2452.41,978.54c0.04-4.38,3.29-8.14,6.96-8.05c3.47,0.08,7.22,4.27,7.31,8.17c0.09,3.8-3.7,8.47-7.17,8.15
			C2455.47,986.43,2451.68,983.95,2452.41,978.54z'
          />
          <path
            fill='#D50000'
            d='M2488.03,1052.2c0.07,4.45-3.5,8.63-7.27,8.5c-3.3-0.11-7.27-4.61-7.28-8.23c0-3.93,3.58-7.92,7.25-8.09
			C2484.51,1044.21,2487.97,1047.91,2488.03,1052.2z'
          />
          <path
            fill='#D50000'
            d='M1938.36,534.59c4.2,0.05,7.22,3.24,7.26,7.66c0.04,3.99-3.51,8.07-7.09,8.17c-3.82,0.11-7.71-4.14-7.57-8.24
			C1931.11,537.95,1934.43,534.55,1938.36,534.59z'
          />
          <path
            fill='#D50000'
            d='M2488.08,1003.49c-0.1,4.01-3.85,8.25-7.23,8.17c-3.54-0.09-7.6-4.88-7.36-8.7c0.25-3.86,4.28-7.98,7.67-7.83
			C2484.75,995.29,2488.17,999.43,2488.08,1003.49z'
          />
          <path
            fill='#D50000'
            d='M1024.6,582.5c4.45,0.02,7.25,3.27,7.11,8.21c-0.12,4.02-3.37,7.54-7.01,7.61c-4.02,0.07-7.66-3.9-7.56-8.25
			C1017.22,586.12,1020.81,582.48,1024.6,582.5z'
          />
          <path
            fill='#D50000'
            d='M953.3,710.36c0.1-4.53,3.05-7.5,7.37-7.39c4.2,0.1,6.98,3.26,6.94,7.89c-0.04,4.13-3.47,7.95-7.15,7.96
			C956.64,718.82,953.2,714.76,953.3,710.36z'
          />
          <path
            fill='#D50000'
            d='M974.04,709.95c0.29-4.78,3.12-7.33,7.72-6.97c3.76,0.29,6.73,3.83,6.62,7.91c-0.12,4.46-3.81,8.2-7.8,7.92
			C976.56,718.52,973.75,714.71,974.04,709.95z'
          />
          <path
            fill='#D50000'
            d='M2305.9,1060.84c-3.91-0.21-7.14-4.11-6.84-8.26c0.3-4.27,3.86-7.74,7.73-7.56c3.79,0.18,7.36,4.64,7.01,8.75
			C2313.41,1058.26,2310.28,1061.07,2305.9,1060.84z'
          />
          <path
            fill='#D50000'
            d='M2386.58,736.57c-0.04-4.53,2.82-7.98,6.71-8.1c3.99-0.12,7.68,3.76,7.63,8.02
			c-0.05,4.18-3.44,7.82-7.27,7.82C2389.5,744.32,2386.63,741.17,2386.58,736.57z'
          />
          <path
            fill='#D50000'
            d='M1046.51,1642.51c-3.87,0.03-7.36-3.54-7.43-7.58c-0.07-4.43,3.41-8.24,7.52-8.23c3.83,0.01,7.07,3.78,7,8.14
			C1053.53,1639.75,1051,1642.48,1046.51,1642.51z'
          />
          <path
            fill='#D50000'
            d='M2488.04,955.25c0.07,4.42-3.53,8.62-7.28,8.49c-3.32-0.11-7.26-4.58-7.27-8.24
			c-0.01-3.92,3.59-7.94,7.26-8.09C2484.52,947.26,2487.97,950.97,2488.04,955.25z'
          />
          <path
            fill='#D50000'
            d='M1075.78,1560.99c0.06,4.51-2.89,7.8-7.07,7.9c-4.11,0.09-7.43-3.18-7.59-7.47c-0.16-4.38,3.37-8.4,7.34-8.37
			C1072.27,1553.07,1075.72,1556.82,1075.78,1560.99z'
          />
          <path
            fill='#D50000'
            d='M2400.93,833.73c0.03,4.65-2.72,8.01-6.73,8.21c-3.72,0.19-7.65-4.03-7.61-8.16c0.04-3.9,3.71-7.94,7.31-8.07
			C2397.56,825.59,2400.9,829.4,2400.93,833.73z'
          />
          <path
            fill='#D50000'
            d='M1031.71,710.86c-0.04,4.17-3.54,8.01-7.25,7.95c-3.74-0.06-7.25-3.86-7.34-7.94
			c-0.09-4.33,3.49-8.02,7.69-7.92C1029.08,703.06,1031.75,706.13,1031.71,710.86z'
          />
          <path
            fill='#D50000'
            d='M1075.83,1464.19c-0.04,4.48-3.09,7.74-7.26,7.76c-4.06,0.02-7.37-3.37-7.45-7.63
			c-0.08-4.36,3.56-8.34,7.5-8.21C1072.42,1456.22,1075.86,1460.09,1075.83,1464.19z'
          />
          <path
            fill='#D50000'
            d='M2003.21,1067.21c3.71,0.02,7.2,3.95,7.19,8.1c-0.01,4.6-2.91,7.75-7.18,7.79c-4.29,0.04-7.73-3.65-7.52-8.08
			C1995.91,1070.81,1999.39,1067.18,2003.21,1067.21z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1563.68c-0.08,4.56-3.1,7.64-7.36,7.52c-4.06-0.11-7.08-3.61-6.94-8.04
			c0.13-4.22,3.67-7.92,7.43-7.77C1962.86,1555.55,1966.07,1559.42,1965.99,1563.68z'
          />
          <path
            fill='#D50000'
            d='M944.84,1416.64c-0.19,4.79-2.91,7.51-7.36,7.37c-3.85-0.12-7.26-3.96-7.11-7.98
			c0.15-4.05,3.76-7.84,7.42-7.8C941.92,1408.27,945.02,1411.98,944.84,1416.64z'
          />
          <path
            fill='#D50000'
            d='M1865.97,758.41c-0.05-4.83,2.49-7.55,7.01-7.53c4.4,0.02,7.28,3,7.27,7.53c-0.01,4.27-3.15,8.08-6.86,8.31
			C1869.75,766.93,1866.01,762.75,1865.97,758.41z'
          />
          <path
            fill='#D50000'
            d='M953.29,931.92c0.07-4.59,3.04-7.67,7.31-7.58c4.17,0.08,7.05,3.33,7.01,7.9c-0.05,4.24-3.4,8.14-7.06,8.22
			C956.74,940.54,953.22,936.4,953.29,931.92z'
          />
          <path
            fill='#D50000'
            d='M1965.99,1466.52c-0.01,4.63-2.88,7.73-7.15,7.74c-4.13,0-7.2-3.35-7.15-7.8c0.05-4.26,3.43-8.01,7.22-8
			C1962.65,1458.47,1966,1462.28,1965.99,1466.52z'
          />
          <path
            fill='#D50000'
            d='M2002.9,1620.27c-3.83-0.03-7.24-3.74-7.19-7.81c0.05-4.12,3.54-7.93,7.32-8c3.87-0.07,7.55,4.04,7.47,8.35
			C2010.43,1616.96,2007.02,1620.3,2002.9,1620.27z'
          />
          <path
            fill='#D50000'
            d='M1901.86,710.59c0.06,4.19-3.22,8.04-6.99,8.21c-4.09,0.19-7.73-3.55-7.75-7.96
			c-0.02-4.39,3.07-7.76,7.24-7.88C1898.41,702.85,1901.8,706.3,1901.86,710.59z'
          />
          <path
            fill='#D50000'
            d='M923.74,882.45c-0.05,4.12-3.58,7.94-7.19,7.77c-3.65-0.17-7.09-4.18-7.08-8.25
			c0.01-4.31,3.47-7.61,7.91-7.53C921.79,874.53,923.81,877.07,923.74,882.45z'
          />
          <path
            fill='#D50000'
            d='M1974.33,710.71c0.02-4.69,2.75-7.68,7.1-7.74c4.03-0.06,7.22,3.32,7.29,7.72c0.07,4.39-3.49,8.2-7.58,8.13
			C1977.18,718.74,1974.32,715.33,1974.33,710.71z'
          />
          <path
            fill='#D50000'
            d='M1901.94,1027.84c-0.05,3.98-3.82,7.88-7.55,7.79c-3.68-0.08-7.32-4.08-7.34-8.05
			c-0.01-3.77,3.91-8.19,7.3-8.23C1897.78,1019.32,1901.99,1024.03,1901.94,1027.84z'
          />
          <path
            fill='#D50000'
            d='M2002.89,718.81c-3.86-0.11-7.19-3.83-7.18-8.02c0.02-4.52,3.54-8.06,7.78-7.82
			c4.13,0.23,7.08,3.73,6.86,8.14C2010.13,715.52,2006.84,718.92,2002.89,718.81z'
          />
          <path
            fill='#D50000'
            d='M895.13,768.93c-3.77,0.02-7.29-3.67-7.44-7.8c-0.17-4.6,3.01-8.04,7.4-8.03c4.21,0.02,7.35,3.17,7.46,7.5
			C902.67,764.85,899.05,768.91,895.13,768.93z'
          />
          <path
            fill='#D50000'
            d='M945.88,565.81c0,4.13-3.48,8.14-7.02,8.12c-3.74-0.03-7.62-4.41-7.46-8.44c0.16-4.1,3.59-7.44,7.59-7.39
			C943.51,558.16,945.88,560.81,945.88,565.81z'
          />
          <path
            fill='#D50000'
            d='M1053.28,857.65c-0.06,4.34-3.3,8.13-6.96,8.14c-4,0.01-7.29-3.81-7.24-8.41c0.04-4.41,3.6-8.17,7.53-7.97
			C1050.67,849.61,1053.35,852.92,1053.28,857.65z'
          />
          <path
            fill='#D50000'
            d='M1380.55,566.67c-0.07,4.28-3.88,8.35-7.63,8.13c-3.76-0.22-6.66-3.92-6.61-8.45
			c0.05-4.46,3.17-8.13,6.93-8.13C1377.14,558.21,1380.62,562.23,1380.55,566.67z'
          />
          <path
            fill='#D50000'
            d='M917.12,1276.96c-4.24,0-7.46-3.23-7.44-7.44c0.02-3.53,4.06-8.23,7.21-8.38c3.46-0.16,7.72,4.44,7.72,8.35
			C924.61,1273.55,921.19,1276.97,917.12,1276.96z'
          />
          <path
            fill='#D50000'
            d='M2003.19,1140.87c3.98,0.03,7.24,3.61,7.23,7.95c0,4.42-3.13,7.84-7.21,7.89c-4.22,0.06-7.71-3.7-7.51-8.11
			C1995.9,1144.16,1999.12,1140.84,2003.19,1140.87z'
          />
          <path
            fill='#D50000'
            d='M895.33,608.23c4.12,0.04,7.28,3.41,7.18,7.66c-0.09,3.83-3.9,8.04-7.35,8.13c-3.72,0.1-7.49-4.2-7.42-8.46
			C887.8,611.33,891.05,608.19,895.33,608.23z'
          />
          <path
            fill='#D50000'
            d='M1887.09,881.04c0.01-4.08,3.46-7.71,7.32-7.72c4.12-0.01,7.49,3.36,7.5,7.51c0.01,4.22-3.83,8.42-7.59,8.32
			C1890.69,889.04,1887.07,884.99,1887.09,881.04z'
          />
          <path
            fill='#D50000'
            d='M2474.35,1150.31c0.02-4.59,2.97-8.01,6.88-7.99c3.31,0.02,7.3,4.43,7.34,8.11c0.05,4-4.04,8.43-7.65,8.28
			C2477.25,1158.56,2474.33,1154.83,2474.35,1150.31z'
          />
          <path
            fill='#D50000'
            d='M1366.3,1464.37c-0.04-5.15,2.66-8.36,6.98-8.29c3.69,0.06,7.14,3.87,7.24,7.98c0.1,4.07-3.33,7.81-7.22,7.87
			C1368.74,1472.02,1366.34,1469.42,1366.3,1464.37z'
          />
          <path
            fill='#D50000'
            d='M2335.07,1052.97c0.01,5.36-2.34,7.94-7.15,7.87c-3.62-0.05-6.97-3.58-7.07-7.43
			c-0.11-4.48,3.24-8.35,7.29-8.39C2332.02,1044.98,2335.06,1048.47,2335.07,1052.97z'
          />
          <path
            fill='#D50000'
            d='M2408.53,759.24c0.1-5.07,2.38-7.38,7.18-7.26c3.88,0.1,7.26,3.81,7.11,7.84c-0.15,4.02-3.79,7.97-7.37,7.98
			C2411.66,767.82,2408.44,763.84,2408.53,759.24z'
          />
          <path
            fill='#D50000'
            d='M1037.96,1683.67c0.01-4.64,2.93-8,6.93-7.96c3.94,0.04,7.28,3.58,7.39,7.82c0.11,4.29-3.49,8.09-7.58,8.01
			C1040.28,1691.44,1037.95,1688.73,1037.96,1683.67z'
          />
          <path
            fill='#D50000'
            d='M2003.7,1653.47c3.84,0.3,6.88,4.17,6.62,8.43c-0.29,4.75-3.62,7.8-8.06,7.4c-3.56-0.33-6.69-4.19-6.54-8.06
			C1995.9,1656.79,1999.64,1653.15,2003.7,1653.47z'
          />
          <path
            fill='#D50000'
            d='M2003.01,1237.82c3.97-0.07,7.29,3.4,7.41,7.77c0.12,4.32-3.05,7.95-7.04,8.08
			c-4.22,0.13-7.76-3.53-7.68-7.94C1995.78,1241.34,1998.97,1237.9,2003.01,1237.82z'
          />
          <path
            fill='#D50000'
            d='M1872.73,1604.46c3.86,0.14,7.41,4.45,7.11,8.62c-0.33,4.48-3.42,7.35-7.73,7.19
			c-3.78-0.14-7.14-4.06-6.95-8.09C1865.36,1608.03,1869,1604.32,1872.73,1604.46z'
          />
          <path
            fill='#D50000'
            d='M1844.06,1027.67c-0.04-4.11,3.39-8.19,6.97-8.29c3.89-0.11,7.32,3.89,7.27,8.47
			c-0.05,4.58-2.98,7.7-7.2,7.68C1846.88,1035.5,1844.11,1032.41,1844.06,1027.67z'
          />
          <path
            fill='#D50000'
            d='M1952.69,639.53c0.08-4.26,3.54-8.09,7.2-7.99c3.65,0.1,7.15,4.14,7.1,8.17c-0.06,4.65-2.97,7.7-7.29,7.65
			C1955.45,647.32,1952.6,644.13,1952.69,639.53z'
          />
          <path
            fill='#D50000'
            d='M2003.06,1595.67c-4.05-0.02-7.39-3.51-7.35-7.69c0.04-4.46,3.66-8.31,7.65-8.14
			c3.62,0.16,7.17,4.25,7.17,8.27C2010.52,1592.5,2007.37,1595.69,2003.06,1595.67z'
          />
          <path
            fill='#D50000'
            d='M2010.47,639.95c-0.02,4.25-3.35,7.47-7.66,7.42c-3.97-0.05-7.26-3.74-7.1-7.96c0.17-4.25,3.6-7.93,7.35-7.89
			C2006.91,631.57,2010.5,635.65,2010.47,639.95z'
          />
          <path
            fill='#D50000'
            d='M2075.59,712.97c-0.08,4.25-3.57,8.02-7.3,7.9c-3.74-0.12-6.84-3.92-6.81-8.35c0.04-4.59,2.99-8.01,6.97-8.08
			C2072.41,704.37,2075.68,708.28,2075.59,712.97z'
          />
          <path
            fill='#D50000'
            d='M1610.82,1205.74c-3.81-0.1-7.23-3.97-7.06-7.99c0.18-4.29,3.56-7.87,7.41-7.86
			c3.79,0.01,7.88,4.41,7.28,8.51C1617.82,1202.71,1615.47,1206.43,1610.82,1205.74z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1612.62c-0.02-4.28,3.22-8.12,6.89-8.15c4.02-0.04,7.43,3.78,7.38,8.24
			c-0.06,4.46-3.08,7.58-7.31,7.57C1976.2,1620.25,1973.67,1617.47,1973.65,1612.62z'
          />
          <path
            fill='#D50000'
            d='M1981.1,1400.72c-4.34-0.1-6.9-3.06-6.84-7.92c0.05-4.3,3.41-7.99,7.21-7.93c4.01,0.07,7.53,4.17,7.27,8.48
			C1988.47,1397.75,1985.3,1400.81,1981.1,1400.72z'
          />
          <path
            fill='#D50000'
            d='M2009.84,614.69c0.01,4.64-2.82,8.16-6.65,8.29c-3.95,0.12-7.64-3.85-7.59-8.15c0.05-4.14,3.43-7.68,7.33-7.7
			C2007.67,607.09,2009.82,609.45,2009.84,614.69z'
          />
          <path
            fill='#D50000'
            d='M1858.3,1197.83c0.08,4.62-2.69,7.82-6.86,7.91c-4.51,0.1-7.21-2.6-7.38-7.35c-0.16-4.62,3.06-8.48,7.09-8.49
			C1854.98,1189.89,1858.22,1193.49,1858.3,1197.83z'
          />
          <path
            fill='#D50000'
            d='M2003.25,1677.87c3.53,0.08,7.17,4.28,7.13,8.21c-0.04,4.83-3.07,7.81-7.74,7.62
			c-3.77-0.15-6.93-3.61-6.93-7.58C1995.71,1681.71,1999.3,1677.77,2003.25,1677.87z'
          />
          <path
            fill='#D50000'
            d='M1264.08,702.95c4.2,0.01,7.46,3.3,7.5,7.58c0.04,4.21-3.82,8.42-7.59,8.28c-3.77-0.14-7.29-4.07-7.26-8.1
			C1256.76,706.46,1260.09,702.95,1264.08,702.95z'
          />
          <path
            fill='#D50000'
            d='M1031.69,882.2c0.07,4.28-3.19,8.03-7.01,8.05c-3.93,0.02-7.32-3.46-7.53-7.72c-0.19-3.91,3.3-7.96,6.95-8.08
			C1028.74,874.3,1031.61,877.23,1031.69,882.2z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1563.24c0.05-4.18,3.44-7.89,7.16-7.84c3.74,0.04,7.13,3.83,7.12,7.97c0,4.68-3.19,7.98-7.58,7.84
			C1976.19,1571.08,1973.59,1567.99,1973.65,1563.24z'
          />
          <path
            fill='#D50000'
            d='M1988.75,1027.51c0.03,4.2-3.26,7.93-7.15,8.11c-3.86,0.18-7.41-3.43-7.55-7.66c-0.12-3.9,3.87-8.58,7.34-8.6
			C1984.8,1019.33,1988.72,1023.68,1988.75,1027.51z'
          />
          <path
            fill='#D50000'
            d='M1494.89,467.78c0.01-5.11,2.29-7.69,6.83-7.73c3.93-0.03,7.3,3.48,7.4,7.71c0.1,4.19-3.67,8.19-7.64,8.11
			C1497.32,475.79,1494.89,472.81,1494.89,467.78z'
          />
          <path
            fill='#D50000'
            d='M1865.97,639.91c-0.07-4.37,3.43-8.44,7.19-8.37c3.69,0.07,6.95,3.72,7.09,7.94
			c0.14,4.32-2.97,7.81-7.03,7.88C1868.8,647.45,1866.04,644.61,1865.97,639.91z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1466.52c-0.01-4.27,3.18-7.97,6.93-8.05c3.77-0.08,7.25,3.58,7.35,7.73
			c0.11,4.71-2.97,8.09-7.35,8.06C1976.37,1474.23,1973.66,1471.21,1973.65,1466.52z'
          />
          <path
            fill='#D50000'
            d='M1981.22,1067.2c3.84-0.09,7.33,3.51,7.53,7.77c0.2,4.25-2.98,7.96-6.95,8.12c-4.48,0.18-7.76-3.1-7.73-7.76
			C1974.07,1071.18,1977.5,1067.29,1981.22,1067.2z'
          />
          <path
            fill='#D50000'
            d='M1974.17,758.21c0.08-4.44,3.14-7.62,7.36-7.62c3.75,0,7.22,3.81,7.21,7.91c-0.01,3.84-3.79,8.11-7.25,8.18
			C1977.82,766.75,1974.1,762.45,1974.17,758.21z'
          />
          <path
            fill='#D50000'
            d='M887.74,590.03c0.45-3.75,3.44-8.15,7.49-7.63c4.04,0.52,7.28,3.54,7.26,7.78c-0.01,3.9-3.72,8.07-7.24,8.15
			C891.4,598.41,887.69,594.31,887.74,590.03z'
          />
          <path
            fill='#D50000'
            d='M1293.39,1561.22c-0.07,4.38-3.21,7.66-7.34,7.68c-4.03,0.02-7.3-3.4-7.36-7.7
			c-0.07-4.32,3.67-8.32,7.62-8.15C1289.98,1553.2,1293.46,1557.22,1293.39,1561.22z'
          />
          <path
            fill='#D50000'
            d='M1923.15,758.49c-0.01,4.13-3.45,8.17-7.01,8.22c-3.62,0.05-7.22-3.9-7.31-8.03
			c-0.1-4.71,3.13-8.27,7.39-8.13C1920.5,750.69,1923.16,753.73,1923.15,758.49z'
          />
          <path
            fill='#D50000'
            d='M2010.55,1027.68c0.08,3.97-3.62,7.95-7.38,7.94c-3.64-0.01-7.4-3.96-7.48-7.87
			c-0.08-3.74,3.7-8.22,7.11-8.42C2006.12,1019.15,2010.48,1023.83,2010.55,1027.68z'
          />
          <path
            fill='#D50000'
            d='M952.29,1416.21c0.05-4.35,3.19-7.91,7.03-7.99c3.97-0.08,7.44,3.85,7.31,8.27c-0.13,4.38-3.31,7.62-7.4,7.54
			C955.03,1423.94,952.24,1420.79,952.29,1416.21z'
          />
          <path
            fill='#D50000'
            d='M2430.45,833.92c0.01-4.43,3.05-8.16,6.73-8.24c3.39-0.08,7.44,4.14,7.59,7.89c0.15,3.92-3.98,8.52-7.53,8.41
			C2433.27,841.85,2430.44,838.48,2430.45,833.92z'
          />
          <path
            fill='#D50000'
            d='M1068.18,1642.51c-3.8-0.07-7.18-3.8-7.12-7.87c0.07-4.45,3.72-8.16,7.8-7.94c3.78,0.2,6.84,4.08,6.65,8.44
			C1075.3,1640.03,1072.75,1642.59,1068.18,1642.51z'
          />
          <path
            fill='#D50000'
            d='M2343.76,711.82c0.02-5.13,2.29-7.7,6.84-7.74c3.95-0.03,7.29,3.44,7.4,7.7c0.12,4.27-3.49,8.13-7.57,8.11
			C2346.38,719.88,2343.74,716.7,2343.76,711.82z'
          />
          <path
            fill='#D50000'
            d='M2364.66,833.83c0.03-4.3,3.27-8.14,6.88-8.15c3.38-0.02,7.34,4.25,7.42,7.99c0.08,3.98-4.05,8.74-7.57,8.24
			C2366.97,841.28,2363.86,838.28,2364.66,833.83z'
          />
          <path
            fill='#D50000'
            d='M1431.05,591.63c0.03-4.18,3.47-7.98,7.21-7.96c3.73,0.02,7.14,3.88,7.13,8.07
			c-0.02,4.53-3.07,7.78-7.25,7.73C1433.93,599.43,1431.03,596.21,1431.05,591.63z'
          />
          <path
            fill='#D50000'
            d='M2342.76,833.77c0.04-4.32,3.4-8.18,7.07-8.1c3.69,0.08,7.15,4,7.18,8.11c0.03,4.4-3.63,8.44-7.47,8.25
			C2345.55,841.83,2342.72,838.37,2342.76,833.77z'
          />
          <path
            fill='#D50000'
            d='M2010.54,833.12c-0.15,4.28-4.07,8.37-7.79,8.12c-3.57-0.24-7.16-4.48-7.05-8.31
			c0.12-4.1,3.59-7.59,7.48-7.52C2007.2,825.47,2010.68,829.12,2010.54,833.12z'
          />
          <path
            fill='#D50000'
            d='M2002.88,913.8c-3.96-0.13-7.43-4.34-7.15-8.65c0.28-4.26,3.64-7.36,7.81-7.21c4,0.14,6.96,3.35,6.94,7.53
			C2010.46,909.74,2006.65,913.92,2002.88,913.8z'
          />
          <path
            fill='#D50000'
            d='M2003.23,1546.64c-4.13,0.06-7.38-3.2-7.53-7.56c-0.15-4.43,3.32-8.29,7.45-8.27
			c3.89,0.02,7.2,3.69,7.22,7.99C2010.4,1543.38,2007.48,1546.58,2003.23,1546.64z'
          />
          <path
            fill='#D50000'
            d='M1988.75,1222.28c0.13,4.16-3.12,7.79-7.09,7.91c-4.45,0.14-7.2-2.57-7.42-7.32c-0.2-4.29,3.34-8.51,7.13-8.5
			C1985.14,1214.38,1988.62,1218.11,1988.75,1222.28z'
          />
          <path
            fill='#D50000'
            d='M1053.29,590.16c0.05,4.33-3.14,8.12-6.86,8.15c-4.09,0.04-7.41-3.68-7.35-8.25
			c0.05-4.42,3.08-7.59,7.23-7.58C1050.71,582.51,1053.23,585.28,1053.29,590.16z'
          />
          <path
            fill='#D50000'
            d='M2509.51,978.72c0.01,4.6-2.94,8.17-6.8,8.22c-3.73,0.04-7.5-4.28-7.39-8.47c0.11-3.91,3.87-8.04,7.33-8.02
			C2506.24,970.46,2509.49,974.38,2509.51,978.72z'
          />
          <path
            fill='#D50000'
            d='M1880.16,979.87c-0.07,4.01-3.68,7.79-7.47,7.84c-3.81,0.04-7.31-3.77-7.29-7.93
			c0.02-3.86,4.3-8.52,7.66-8.33C1876.38,971.63,1880.23,976.19,1880.16,979.87z'
          />
          <path
            fill='#D50000'
            d='M1858.3,1295.05c-0.07,4.62-2.88,7.63-7.15,7.63c-4.54,0.01-7.26-3.13-7.09-8.14
			c0.15-4.34,3.53-7.85,7.38-7.69C1855.24,1287.01,1858.36,1290.74,1858.3,1295.05z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1366.22c0,5.08-2.33,7.67-6.91,7.67c-3.95,0-7.34-3.57-7.35-7.72c-0.01-4.4,3.53-8.21,7.55-8.12
			C1028.57,1358.14,1031.38,1361.56,1031.38,1366.22z'
          />
          <path
            fill='#D50000'
            d='M1067.56,1837.5c-3.97,0.03-7.4-3.43-7.49-7.55c-0.09-4.32,3.54-8.33,7.51-8.28c3.67,0.04,7.13,4,7.14,8.16
			C1074.72,1834.51,1071.97,1837.46,1067.56,1837.5z'
          />
          <path
            fill='#D50000'
            d='M1031.39,1440.66c0.05,5.17-2.18,7.85-6.62,7.95c-4.16,0.1-7.72-3.59-7.63-7.9c0.09-4.36,3.3-7.85,7.25-7.89
			C1028.56,1432.77,1031.34,1435.87,1031.39,1440.66z'
          />
          <path
            fill='#D50000'
            d='M2003.05,671.98c-4,0.03-7.32-3.47-7.34-7.75c-0.03-4.35,3.12-7.93,7.11-8.09c4.13-0.17,7.57,3.52,7.57,8.12
			C2010.39,668.67,2007.28,671.95,2003.05,671.98z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1125.58c0-4.28,3.31-8.12,7.02-8.16c3.71-0.05,7.26,3.83,7.29,7.94c0.03,4.65-2.86,7.86-7.08,7.87
			C1955.55,1133.24,1952.68,1130.21,1952.69,1125.58z'
          />
          <path
            fill='#D50000'
            d='M1938.12,1117.42c3.82,0.15,7.23,4.41,6.95,8.7c-0.28,4.25-3.26,7.18-7.27,7.13
			c-4.53-0.05-7.19-3.13-7.05-8.17C1930.87,1120.94,1934.4,1117.28,1938.12,1117.42z'
          />
          <path
            fill='#D50000'
            d='M1988.73,808.75c-0.05,3.89-3.85,8.08-7.33,8.08c-3.72-0.01-7.35-4.32-7.21-8.58
			c0.15-4.58,3.07-7.51,7.45-7.44C1985.57,800.87,1988.79,804.47,1988.73,808.75z'
          />
          <path
            fill='#D50000'
            d='M2335.07,663.77c0.06,4.7-2.7,8.13-6.61,8.21c-4.04,0.09-7.7-3.8-7.59-8.07c0.11-4.41,3.23-7.75,7.24-7.76
			C2332.61,656.13,2335.01,658.75,2335.07,663.77z'
          />
          <path
            fill='#D50000'
            d='M1039.95,1440.86c-0.03-4.65,2.85-8.04,6.83-8.04c3.98,0,7.31,3.42,7.5,7.7c0.19,4.24-3.41,8.15-7.46,8.1
			C1042.36,1448.56,1039.99,1445.89,1039.95,1440.86z'
          />
          <path
            fill='#D50000'
            d='M1068.65,1545.57c-3.99,0.06-7.38-3.29-7.54-7.45c-0.17-4.38,3.33-8.35,7.39-8.36c3.8-0.01,7.17,3.73,7.2,8
			C1075.73,1542.36,1072.89,1545.51,1068.65,1545.57z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1222.28c0.07-4.14,3.55-7.98,7.16-7.92c3.83,0.07,7.31,4.24,7.14,8.58
			c-0.17,4.53-3.11,7.33-7.59,7.26C1955.16,1230.14,1952.6,1227.11,1952.69,1222.28z'
          />
          <path
            fill='#D50000'
            d='M2147.34,1124.14c0.11-4.16,3.7-8.06,7.24-7.89c3.57,0.18,6.86,4.21,6.8,8.33c-0.07,5.06-2.92,8.02-7.48,7.77
			C2149.69,1132.12,2147.2,1129.01,2147.34,1124.14z'
          />
          <path
            fill='#D50000'
            d='M2466.65,931.07c-0.1,3.99-3.71,7.94-7.34,8.03c-3.86,0.1-6.99-3.77-6.91-8.54c0.08-4.45,3.41-8.1,7.12-7.83
			C2463.06,922.98,2466.75,927.31,2466.65,931.07z'
          />
          <path
            fill='#D50000'
            d='M1937.46,1750.4c4.28,0.06,7.2,3.2,7.17,7.69c-0.03,3.9-3.73,8.08-7.23,8.16c-3.73,0.08-7.56-4.24-7.44-8.41
			C1930.07,1753.54,1933.3,1750.34,1937.46,1750.4z'
          />
          <path
            fill='#D50000'
            d='M2003.08,1628.84c3.89-0.01,7.23,3.63,7.33,7.96c0.1,4.49-2.88,7.79-7.12,7.88c-4.18,0.08-7.41-3.1-7.59-7.48
			C1995.52,1632.76,1998.99,1628.84,2003.08,1628.84z'
          />
          <path
            fill='#D50000'
            d='M1423.45,591.55c0.03,5.12-2.29,7.84-6.77,7.93c-3.88,0.08-7.49-3.58-7.55-7.66c-0.06-4.01,3.49-8,7.24-8.16
			C1420.22,583.49,1423.43,587.07,1423.45,591.55z'
          />
          <path
            fill='#D50000'
            d='M2003,1498.73c-4.06-0.05-7.38-3.56-7.32-7.74c0.06-4.43,3.75-8.29,7.72-8.09c3.62,0.18,7.16,4.33,7.13,8.33
			C2010.5,1495.63,2007.34,1498.78,2003,1498.73z'
          />
          <path
            fill='#D50000'
            d='M2307.33,598.32c-4.05-0.1-7.23-3.66-7.14-8c0.09-4.29,3.42-7.82,7.38-7.82c4.08,0,7.58,3.93,7.35,8.27
			C2314.69,595.15,2311.4,598.42,2307.33,598.32z'
          />
          <path
            fill='#D50000'
            d='M1024.55,743.2c-4.14-0.06-7.64-3.98-7.43-8.3c0.2-4.11,3.69-7.58,7.6-7.54c4.11,0.04,6.99,3.31,6.98,7.94
			C1031.69,739.68,1028.45,743.26,1024.55,743.2z'
          />
          <path
            fill='#D50000'
            d='M858.87,907.68c-0.07,4.5-3.16,8.1-7.02,8.17c-3.96,0.06-7.27-3.89-7.15-8.55c0.1-4.03,3.34-7.79,6.8-7.88
			C855.59,899.32,858.94,903.06,858.87,907.68z'
          />
          <path
            fill='#D50000'
            d='M2349.62,1059.79c-4.47-0.1-6.89-2.84-6.86-7.78c0.03-4.38,3.17-8,6.99-8.07c3.99-0.07,7.4,3.87,7.26,8.4
			C2356.88,1056.78,2353.8,1059.88,2349.62,1059.79z'
          />
          <path
            fill='#D50000'
            d='M1089.77,793.33c-3.87,0.05-7.29-3.53-7.38-7.71c-0.09-4.65,3.1-8.16,7.38-8.11
			c3.84,0.05,7.24,3.75,7.24,7.88C1097.02,789.66,1093.72,793.28,1089.77,793.33z'
          />
          <path
            fill='#D50000'
            d='M2286.35,1109.86c-4.05-0.07-7.31-3.63-7.24-7.88c0.08-4.17,3.54-7.91,7.37-7.95
			c3.88-0.04,7.65,4.16,7.51,8.38C2293.87,1106.65,2290.5,1109.94,2286.35,1109.86z'
          />
          <path
            fill='#D50000'
            d='M887.71,907.59c0.01-3.92,3.74-7.98,7.39-8.02c3.62-0.05,7.31,3.84,7.47,7.86c0.16,3.89-3.96,8.46-7.59,8.43
			C891.65,915.83,887.71,911.34,887.71,907.59z'
          />
          <path
            fill='#D50000'
            d='M2003.13,1058.68c-3.95,0.02-7.33-3.49-7.43-7.71c-0.1-4.5,3.36-8.21,7.59-8.12c3.95,0.08,7.21,3.73,7.16,8
			C2010.39,1055.2,2007.15,1058.66,2003.13,1058.68z'
          />
          <path
            fill='#D50000'
            d='M1981.4,938.17c-3.79,0.03-7.17-3.7-7.19-7.93c-0.02-4.69,2.72-7.81,6.93-7.89c4.14-0.08,7.69,3.63,7.58,7.94
			C1988.61,934.67,1985.39,938.14,1981.4,938.17z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1319.32c0.06,4.88-2.45,7.76-6.8,7.82c-4.41,0.06-7.39-2.84-7.52-7.34
			c-0.13-4.39,3.28-8.4,7.23-8.51C1919.55,1311.19,1923.1,1315.21,1923.15,1319.32z'
          />
          <path
            fill='#D50000'
            d='M981.07,680.59c3.91,0.02,7.26,3.54,7.29,7.69c0.04,4.44-3.55,8.26-7.6,8.1c-3.86-0.15-6.72-3.68-6.69-8.24
			C974.12,683.19,976.55,680.57,981.07,680.59z'
          />
          <path
            fill='#D50000'
            d='M887.68,688.26c0.1-4.38,3.65-7.85,7.85-7.67c4,0.17,7.2,3.86,6.94,7.98c-0.26,4.01-4.07,7.94-7.57,7.8
			C891.27,696.23,887.6,692.11,887.68,688.26z'
          />
          <path
            fill='#D50000'
            d='M2010.16,1733.25c0.01,4.33-3.13,8.17-6.83,8.35c-3.64,0.18-7.61-4.08-7.61-8.16c0-4.09,3.34-7.63,7.25-7.66
			C2007.39,1725.74,2010.14,1728.6,2010.16,1733.25z'
          />
          <path
            fill='#D50000'
            d='M873.28,1206.8c-4.13,0.03-7.51-3.41-7.49-7.64c0.02-4.18,3.4-8.03,7.19-8.18c3.96-0.16,7.73,3.79,7.74,8.11
			C880.73,1203.31,877.39,1206.77,873.28,1206.8z'
          />
          <path
            fill='#D50000'
            d='M1858.31,687.42c0.03,4.68-2.86,7.94-6.94,7.85c-3.99-0.09-7.16-3.5-7.3-7.84c-0.12-3.82,3.52-7.92,7.12-8.01
			C1855.81,679.31,1858.28,682.08,1858.31,687.42z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1319.48c0.01-4.18,3.3-8.09,6.91-8.2c3.78-0.11,7.47,4.05,7.39,8.34c-0.08,4.56-2.94,7.49-7.34,7.52
			C1955.3,1327.16,1952.68,1324.28,1952.69,1319.48z'
          />
          <path
            fill='#D50000'
            d='M1988.75,1319.42c0.01,4.19-3.29,7.68-7.28,7.72c-4.39,0.04-7.19-2.84-7.29-7.51
			c-0.1-4.27,3.64-8.48,7.38-8.34C1985.32,1311.43,1988.74,1315.29,1988.75,1319.42z'
          />
          <path
            fill='#D50000'
            d='M2010.4,687.32c0,4.33-3.21,7.89-7.17,7.96c-4.18,0.07-7.64-3.69-7.52-8.17c0.11-4.2,3.56-7.77,7.44-7.68
			C2007.21,679.51,2010.4,682.99,2010.4,687.32z'
          />
          <path
            fill='#D50000'
            d='M1974.38,640.14c-0.21-4.63,2.85-8.49,6.83-8.61c3.85-0.12,7.25,3.37,7.52,7.7c0.26,4.17-2.94,7.97-6.84,8.14
			C1977.35,647.56,1974.6,644.91,1974.38,640.14z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1294.64c0.11,4.91-2.43,7.91-6.79,8.04c-4.34,0.13-7.32-2.88-7.4-7.47
			c-0.08-4.61,3.26-8.42,7.34-8.37C2028.43,1286.88,2031.65,1290.54,2031.75,1294.64z'
          />
          <path
            fill='#D50000'
            d='M2175.56,1205.73c-4.04-0.12-6.99-3.56-6.86-8.01c0.12-4.23,3.6-7.94,7.36-7.84
			c3.84,0.11,7.16,3.82,7.19,8.04C2183.28,1202.24,2179.72,1205.85,2175.56,1205.73z'
          />
          <path
            fill='#D50000'
            d='M1850.96,1092.95c3.95-0.07,7.22,3.35,7.35,7.68c0.14,4.71-2.91,8.17-7.17,8.15
			c-4.25-0.02-7.01-3.04-7.07-7.75C1844.01,1096.67,1847.12,1093.02,1850.96,1092.95z'
          />
          <path
            fill='#D50000'
            d='M1879.91,1515.51c0.11,4.26-3.08,7.74-7.18,7.84c-3.92,0.09-7.34-3.28-7.58-7.47
			c-0.24-4.23,3.47-8.42,7.41-8.37C1876.22,1507.56,1879.81,1511.48,1879.91,1515.51z'
          />
          <path
            fill='#D50000'
            d='M1221.32,527.1c-3.96,0.07-7.33-3.39-7.5-7.69c-0.16-4.02,3.32-7.96,7.16-8.12c4.06-0.16,7.86,3.77,7.76,8.02
			C1228.64,523.58,1225.35,527.03,1221.32,527.1z'
          />
          <path
            fill='#D50000'
            d='M1844.07,1343.73c0.06-4.66,2.97-7.91,7.04-7.87c4.06,0.04,7.37,3.89,7.21,8.37
			c-0.15,4.29-3.43,7.54-7.48,7.43C1846.5,1351.55,1844,1348.62,1844.07,1343.73z'
          />
          <path
            fill='#D50000'
            d='M2199.01,1205.72c-4.27,0.03-7.15-2.93-7.33-7.51c-0.17-4.4,3.4-8.43,7.36-8.32c3.86,0.1,7.13,3.79,7.16,8.05
			C2206.23,1202.02,2202.83,1205.7,2199.01,1205.72z'
          />
          <path
            fill='#D50000'
            d='M876.83,1100.65c0.04,5.33-2.39,8.17-6.98,8.13c-3.8-0.03-7.18-3.72-7.21-7.85c-0.02-4.24,3.24-7.92,7.1-7.99
			C873.95,1092.86,876.79,1095.95,876.83,1100.65z'
          />
          <path
            fill='#D50000'
            d='M1356.6,420.19c0,5.07-2.33,7.75-6.79,7.81c-4.1,0.05-7.35-3.28-7.4-7.58c-0.05-4.64,3.14-8.22,7.39-8.29
			C1353.67,412.07,1356.6,415.55,1356.6,420.19z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1319.61c-0.05-4.14,3.27-8.2,6.8-8.32c3.85-0.13,7.51,3.86,7.53,8.21c0.02,4.47-2.92,7.57-7.24,7.64
			C1933.4,1327.21,1930.81,1324.45,1930.75,1319.61z'
          />
          <path
            fill='#D50000'
            d='M1370.94,412.16c3.88-0.14,7.35,3.3,7.6,7.54c0.24,4.1-2.98,8.1-6.69,8.3c-4.52,0.24-7.32-2.39-7.55-7.1
			C1364.06,415.91,1366.79,412.31,1370.94,412.16z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1003.17c0.02-4.5,3.08-8.13,6.9-8.19c3.99-0.06,7.37,3.83,7.29,8.4c-0.08,4.67-2.89,7.61-7.24,7.58
			C1824.79,1010.93,1822.13,1007.95,1822.15,1003.17z'
          />
          <path
            fill='#D50000'
            d='M1560.75,541.03c0.08-5.02,2.6-7.72,7.07-7.57c3.68,0.13,7.21,4.23,7.06,8.2c-0.16,4.26-3.68,7.77-7.65,7.64
			C1563.06,549.17,1560.67,546.12,1560.75,541.03z'
          />
          <path
            fill='#D50000'
            d='M2278,808.16c0.04-4.48,3.27-8.22,6.94-8.03c3.61,0.19,7.19,4.25,7.21,8.19c0.02,4.01-4.08,8.52-7.56,8.32
			C2280.94,816.43,2277.96,812.59,2278,808.16z'
          />
          <path
            fill='#D50000'
            d='M2009.83,590.3c0,4.72-2.78,7.99-6.83,8.03c-3.85,0.04-7.31-3.62-7.4-7.8c-0.09-4.22,3.52-8.09,7.47-8.03
			C2007.57,582.57,2009.83,585.19,2009.83,590.3z'
          />
          <path
            fill='#D50000'
            d='M2112.19,1205.73c-4.28,0.04-7.16-2.91-7.33-7.5c-0.16-4.39,3.39-8.43,7.34-8.34
			c3.82,0.08,7.19,3.84,7.2,8.02C2119.41,1201.99,2115.99,1205.7,2112.19,1205.73z'
          />
          <path
            fill='#D50000'
            d='M866.63,907.69c-0.02-4.98,2.51-8,6.73-8.02c3.86-0.02,7.33,3.58,7.45,7.74c0.13,4.47-3.29,8.42-7.31,8.44
			C869.74,915.86,866.65,912.19,866.63,907.69z'
          />
          <path
            fill='#D50000'
            d='M1177.32,1642.53c-3.87,0.02-7.3-3.5-7.4-7.61c-0.11-4.43,3.42-8.31,7.49-8.24c3.75,0.06,7.13,3.96,7.07,8.13
			C1184.41,1639.35,1181.48,1642.51,1177.32,1642.53z'
          />
          <path
            fill='#D50000'
            d='M1104.97,1634.31c0.32-4.57,3.98-8.03,8.05-7.6c3.8,0.4,6.57,4.26,6.25,8.68c-0.35,4.85-2.98,7.33-7.56,7.13
			C1108.09,1642.36,1104.7,1638.22,1104.97,1634.31z'
          />
          <path
            fill='#D50000'
            d='M1177.32,1740.55c-3.93,0.02-7.3-3.44-7.4-7.59c-0.1-4.33,3.53-8.33,7.48-8.24c3.72,0.08,7.14,4.01,7.08,8.15
			C1184.43,1737.42,1181.53,1740.53,1177.32,1740.55z'
          />
          <path
            fill='#D50000'
            d='M1039.94,1221.19c-0.04-4.9,2.48-7.89,6.73-8c3.94-0.1,7.7,3.91,7.59,8.09c-0.12,4.3-3.4,7.78-7.34,7.77
			C1042.76,1229.03,1039.97,1225.89,1039.94,1221.19z'
          />
          <path
            fill='#D50000'
            d='M1880.14,1319.87c-0.27,4.37-3.96,7.62-8.26,7.25c-4.06-0.34-6.64-3.84-6.24-8.46c0.35-4,3.8-7.48,7.29-7.37
			C1876.85,1311.42,1880.4,1315.64,1880.14,1319.87z'
          />
          <path
            fill='#D50000'
            d='M1814.38,735.15c0.01,4.94-2.63,8.09-6.75,8.05c-4-0.04-7.4-3.67-7.37-7.88c0.03-4.08,3.53-7.93,7.26-7.96
			C1811.89,727.33,1814.37,730.14,1814.38,735.15z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1318.45c0.02-4.32,3.17-8.17,6.81-8.3c3.6-0.13,7.19,3.8,7.27,7.96c0.1,5.02-2.74,8.24-7.19,8.15
			C1129.39,1326.18,1126.91,1323.37,1126.93,1318.45z'
          />
          <path
            fill='#D50000'
            d='M1880.14,1222.5c0.04,4.1-3.36,7.66-7.33,7.69c-4.36,0.03-7.14-2.86-7.28-7.56
			c-0.11-4.01,3.31-8.08,6.94-8.25C1876.42,1214.19,1880.1,1218.08,1880.14,1222.5z'
          />
          <path
            fill='#D50000'
            d='M1075.85,1318.76c-0.13,4.26-3.47,7.59-7.56,7.54c-3.64-0.05-7.27-4.1-7.18-8.01
			c0.09-3.69,4.1-8.12,7.35-8.13C1071.83,1310.16,1075.97,1314.97,1075.85,1318.76z'
          />
          <path
            fill='#D50000'
            d='M1858.31,735.44c-0.06,4.79-2.79,7.82-7.01,7.76c-3.96-0.05-7.23-3.61-7.22-7.87
			c0.01-4.28,3.69-8.1,7.68-7.96C1856.01,727.51,1858.37,730.42,1858.31,735.44z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1561.27c-0.01,4.98-2.47,7.67-6.97,7.63c-3.95-0.04-7.3-3.62-7.29-7.79
			c0.02-4.15,3.36-7.94,7.1-8.05C1028.37,1552.93,1031.39,1556.4,1031.38,1561.27z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1318.48c0.02,4.9-2.52,7.79-6.88,7.82c-3.95,0.03-7.37-3.56-7.38-7.75
			c-0.01-4.42,3.58-8.47,7.43-8.38C1028.03,1310.26,1031.37,1314.32,1031.38,1318.48z'
          />
          <path
            fill='#D50000'
            d='M1096.47,1877.26c0.03,4.28-3.3,8.15-7.01,8.15c-3.84,0-7.59-4.19-7.46-8.33c0.13-4.05,3.6-7.53,7.48-7.5
			C1093.93,1869.62,1096.44,1872.37,1096.47,1877.26z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1222.54c0.02,4.83-2.56,7.67-6.96,7.65c-4.4-0.01-7.31-2.97-7.37-7.48
			c-0.06-4.42,3.41-8.33,7.38-8.33C1919.75,1214.38,1923.13,1218.36,1923.15,1222.54z'
          />
          <path
            fill='#D50000'
            d='M1096.48,1830.77c0.05,4.85-2.51,7.75-6.88,7.81c-4.02,0.06-7.37-3.22-7.57-7.41
			c-0.21-4.28,3.34-8.36,7.33-8.43C1093.08,1822.66,1096.44,1826.45,1096.48,1830.77z'
          />
          <path
            fill='#D50000'
            d='M1567.52,485.55c3.73-0.01,7.29,3.82,7.35,7.9c0.06,4.16-3.38,7.88-7.33,7.93c-4.21,0.05-6.8-2.99-6.8-7.96
			C1560.75,488.34,1563.14,485.56,1567.52,485.55z'
          />
          <path
            fill='#D50000'
            d='M1973.65,1515.57c0.02-4.27,3.33-8.08,6.99-8.05c3.86,0.03,7.21,3.65,7.26,7.85
			c0.06,4.73-3.01,8.03-7.41,7.98C1976.16,1523.29,1973.63,1520.42,1973.65,1515.57z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1222.43c0.03-4.16,3.48-8.1,7.04-8.05c3.96,0.06,7.38,4.01,7.28,8.43c-0.1,4.45-3.11,7.43-7.48,7.39
			C1933.21,1230.15,1930.71,1227.31,1930.75,1222.43z'
          />
          <path
            fill='#D50000'
            d='M1880,1491.22c-0.04,4.34-3.23,7.53-7.52,7.5c-4.16-0.02-7.33-3.36-7.33-7.71c0-4.08,3.51-8.03,7.22-8.12
			C1876.15,1482.79,1880.04,1487.04,1880,1491.22z'
          />
          <path
            fill='#D50000'
            d='M2342.76,1027.56c-0.01-4.11,3.31-8.12,6.84-8.24c3.87-0.14,7.41,3.79,7.4,8.21
			c-0.01,4.34-3.13,7.6-7.29,7.61C2345.28,1035.16,2342.77,1032.42,2342.76,1027.56z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1052.27c0.05,4.58-2.81,8.19-6.66,8.41c-3.8,0.22-7.57-3.91-7.54-8.26c0.03-3.9,3.71-8.06,7.25-8.19
			C2506.43,1044.09,2509.46,1047.6,2509.51,1052.27z'
          />
          <path
            fill='#D50000'
            d='M2003.15,1011.01c-3.72,0-7.37-3.77-7.47-7.71c-0.09-3.81,3.62-8.19,7.08-8.35c3.64-0.17,7.69,4.11,7.77,8.23
			C2010.62,1007.12,2006.96,1011,2003.15,1011.01z'
          />
          <path
            fill='#D50000'
            d='M1039.95,1537.78c-0.02-4.61,2.87-7.98,6.89-8.02c3.84-0.04,7.31,3.6,7.43,7.77c0.11,4.03-3.3,7.94-7.01,8.04
			C1042.48,1545.69,1039.97,1543.01,1039.95,1537.78z'
          />
          <path
            fill='#D50000'
            d='M1068.5,1424.03c-4.06,0-7.37-3.45-7.38-7.69c-0.01-4.26,3.23-8,7.04-8.13c4.04-0.14,7.73,3.76,7.67,8.1
			C1075.76,1420.8,1072.69,1424.03,1068.5,1424.03z'
          />
          <path
            fill='#D50000'
            d='M2119.48,905.45c-0.06,4.14-3.6,8.15-7.22,8.16c-3.83,0.02-6.93-3.96-6.84-8.8c0.08-4.39,3.51-8.09,7.26-7.83
			C2116.49,897.24,2119.55,901.05,2119.48,905.45z'
          />
          <path
            fill='#D50000'
            d='M2350.37,728.46c4.01-0.11,7.66,3.72,7.62,8.01c-0.03,4.15-3.39,7.72-7.36,7.83c-4,0.11-6.75-2.81-6.87-7.29
			C2343.62,731.69,2346.02,728.59,2350.37,728.46z'
          />
          <path
            fill='#D50000'
            d='M2198.83,1302.67c-4.15-0.02-7.08-3.18-7.15-7.7c-0.07-4.18,3.29-8.05,7.05-8.14
			c4.03-0.09,7.61,3.84,7.48,8.23C2206.08,1299.21,2202.71,1302.7,2198.83,1302.67z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1464.28c0,5.05-2.37,7.67-6.92,7.67c-3.95-0.01-7.34-3.59-7.34-7.74c0-4.38,3.61-8.26,7.55-8.11
			C1028.6,1456.23,1031.38,1459.63,1031.38,1464.28z'
          />
          <path
            fill='#D50000'
            d='M996.17,932.06c0.07-4.8,2.75-7.71,7.09-7.73c3.91-0.01,7.23,3.61,7.21,7.85c-0.02,4.36-3.78,8.47-7.56,8.26
			C999.33,940.26,996.11,936.26,996.17,932.06z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1612.49c0.02,4.82-2.6,7.71-7.03,7.78c-4.13,0.06-7.2-3.23-7.16-7.69c0.04-4.39,3.16-7.98,7.08-8.12
			C2028.32,1604.32,2031.74,1608.18,2031.75,1612.49z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1003.3c-0.03,4.68-2.82,7.68-7.14,7.65c-4.28-0.03-7.03-3.05-7.05-7.76
			c-0.02-4.6,3.41-8.4,7.42-8.21C2028.85,995.17,2031.78,998.77,2031.75,1003.3z'
          />
          <path
            fill='#D50000'
            d='M2170.28,1124.37c0.02-4.19,3.52-8.21,7.09-8.13c3.63,0.08,7,4.09,6.97,8.31c-0.03,4.62-3.03,7.87-7.2,7.83
			C2172.88,1132.34,2170.26,1129.28,2170.28,1124.37z'
          />
          <path
            fill='#D50000'
            d='M1366.29,614.48c0.06-4.95,2.55-7.5,7.21-7.37c4.23,0.12,7.21,3.45,7.05,7.87c-0.16,4.32-3.39,7.91-7.2,8
			C1369.36,623.07,1366.23,619.3,1366.29,614.48z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1003.16c0-4.44,3.01-8.03,6.86-8.17c3.93-0.14,7.35,3.8,7.27,8.38c-0.08,4.69-2.89,7.6-7.27,7.55
			C2041.93,1010.87,2039.52,1008.14,2039.52,1003.16z'
          />
          <path
            fill='#D50000'
            d='M1364.31,443.29c0.03-4.9,2.54-7.78,6.87-7.86c3.92-0.07,7.27,3.4,7.37,7.62c0.1,4.46-3.29,8.24-7.39,8.22
			C1367.06,451.26,1364.28,448.03,1364.31,443.29z'
          />
          <path
            fill='#D50000'
            d='M866.64,881.83c0.04-4.98,2.48-7.48,7.2-7.39c3.88,0.08,6.91,3.3,6.99,7.42c0.08,4.39-3.61,8.51-7.48,8.36
			C869.6,890.07,866.6,886.32,866.64,881.83z'
          />
          <path
            fill='#D50000'
            d='M1719.73,1205.73c-3.82-0.07-7.27-3.85-7.18-7.89c0.09-4.22,3.49-7.91,7.33-7.95
			c3.81-0.04,7.61,4.25,7.46,8.42C1727.19,1202.53,1723.83,1205.8,1719.73,1205.73z'
          />
          <path
            fill='#D50000'
            d='M2278,1003.02c0.06-4.29,2.98-7.79,6.6-7.92c3.72-0.13,7.59,4.13,7.55,8.32c-0.03,3.81-3.77,8.09-7.19,8.23
			C2281.18,1011.81,2277.93,1007.77,2278,1003.02z'
          />
          <path
            fill='#D50000'
            d='M1851.1,1237.82c3.92,0,7.15,3.49,7.21,7.81c0.07,4.71-3.04,8.11-7.31,8.03c-4.16-0.08-6.91-3.22-6.93-7.91
			C1844.05,1241.37,1847.2,1237.82,1851.1,1237.82z'
          />
          <path
            fill='#D50000'
            d='M1844.07,1392.93c-0.02-4.3,3.26-8.1,6.97-8.07c3.88,0.03,7.15,3.57,7.27,7.88c0.13,4.72-2.92,8.02-7.38,7.97
			C1846.46,1400.66,1844.09,1397.97,1844.07,1392.93z'
          />
          <path
            fill='#D50000'
            d='M2003.44,801c3.92,0.07,7.05,3.29,7.09,7.31c0.04,4.02-3.95,8.57-7.48,8.52c-3.61-0.05-7.56-4.68-7.33-8.59
			C1995.97,804,1999.25,800.93,2003.44,801z'
          />
          <path
            fill='#D50000'
            d='M1880.16,1125.42c0.03,4.2-3.29,7.77-7.28,7.81c-4.31,0.05-7.22-2.94-7.37-7.58
			c-0.15-4.43,3.39-8.36,7.41-8.23C1876.81,1117.54,1880.13,1121.21,1880.16,1125.42z'
          />
          <path
            fill='#D50000'
            d='M1286.24,1617.91c-4.03,0.07-7.35-3.22-7.56-7.49c-0.2-4.21,3.47-8.33,7.42-8.35c3.65-0.01,7.15,3.87,7.2,8
			C1293.37,1614.54,1290.39,1617.84,1286.24,1617.91z'
          />
          <path
            fill='#D50000'
            d='M2053.65,1295.09c-0.07,4.62-2.94,7.64-7.2,7.59c-4.67-0.05-7.06-2.86-6.94-8.14
			c0.1-4.23,3.58-7.91,7.28-7.69C2050.68,1287.07,2053.72,1290.73,2053.65,1295.09z'
          />
          <path
            fill='#D50000'
            d='M2089.81,1165.28c3.77-0.16,7.35,3.42,7.62,7.63c0.27,4.14-2.93,8.01-6.81,8.22
			c-4.48,0.25-7.77-2.96-7.83-7.64C2082.75,1169.45,2086.18,1165.43,2089.81,1165.28z'
          />
          <path
            fill='#D50000'
            d='M2336.07,759.77c-0.06,4.23-3.4,8.04-7.07,8.05c-3.77,0.02-7.21-4.12-7.13-8.57c0.08-4.7,2.83-7.39,7.41-7.26
			C2333.78,752.12,2336.14,754.82,2336.07,759.77z'
          />
          <path
            fill='#D50000'
            d='M1858.31,1173.59c-0.08,4.6-3.07,7.69-7.35,7.57c-4.28-0.12-7-3.28-6.88-8.01c0.11-4.17,3.72-8.04,7.35-7.87
			C1855.14,1165.45,1858.38,1169.36,1858.31,1173.59z'
          />
          <path
            fill='#D50000'
            d='M2286.35,1158.87c-4.07-0.07-7.25-3.5-7.23-7.78c0.03-3.89,3.78-7.98,7.36-8.03c3.65-0.05,7.68,4.5,7.5,8.48
			C2293.8,1155.74,2290.47,1158.94,2286.35,1158.87z'
          />
          <path
            fill='#D50000'
            d='M2262.55,1143.06c3.97-0.05,7.36,3.38,7.55,7.64c0.19,4.44-3.23,8.2-7.43,8.16
			c-4.21-0.04-7.27-3.32-7.26-7.77C2255.42,1146.67,2258.6,1143.11,2262.55,1143.06z'
          />
          <path
            fill='#D50000'
            d='M2531.42,1150.74c-0.11,4.31-3.51,8.05-7.27,7.99c-3.85-0.06-6.93-3.74-6.87-8.2c0.05-4.7,2.9-8.15,6.81-8.24
			C2527.88,1142.18,2531.53,1146.39,2531.42,1150.74z'
          />
          <path
            fill='#D50000'
            d='M1822.15,954.09c0.02-4.47,3.13-8.11,6.95-8.14c4.02-0.03,7.34,3.84,7.24,8.42c-0.1,4.64-2.96,7.63-7.26,7.57
			C1824.81,961.9,1822.13,958.85,1822.15,954.09z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1150.71c-0.06,4.49-3.25,8.13-7.05,8.02c-3.67-0.1-7.11-4.03-7.15-8.16
			c-0.03-4.08,3.94-8.4,7.61-8.26C2506.85,1142.46,2509.57,1145.92,2509.51,1150.71z'
          />
          <path
            fill='#D50000'
            d='M2031.75,881.44c-0.13,4.28-3.61,7.9-7.39,7.7c-3.78-0.21-6.86-3.96-6.8-8.28c0.06-4.38,3.21-7.77,7.19-7.75
			C2029.07,873.13,2031.9,876.5,2031.75,881.44z'
          />
          <path
            fill='#D50000'
            d='M2003.45,873.31c3.74,0.14,7.09,3.69,7.08,7.49c-0.01,4.22-3.85,8.44-7.57,8.34
			c-3.77-0.11-7.54-4.58-7.24-8.59C1996.03,876.38,1999.48,873.16,2003.45,873.31z'
          />
          <path
            fill='#D50000'
            d='M866.63,663.24c0.14-4.98,2.5-7.28,7.31-7.1c4.05,0.16,7.1,3.67,6.88,7.95c-0.22,4.28-3.69,8-7.34,7.87
			C869.66,671.83,866.51,667.82,866.63,663.24z'
          />
          <path
            fill='#D50000'
            d='M866.63,615.63c0.02-5.11,2.25-7.47,6.99-7.4c4.2,0.06,7.21,3.25,7.2,7.62c-0.01,4.21-3.31,8.04-7.03,8.16
			C869.91,624.13,866.61,620.27,866.63,615.63z'
          />
          <path
            fill='#D50000'
            d='M1220.14,624.04c-3.6-0.08-7.2-4.14-7.26-8.18c-0.06-4.24,3.69-7.96,7.92-7.86c3.64,0.08,7.19,4.32,6.93,8.28
			C1227.48,620.43,1223.86,624.12,1220.14,624.04z'
          />
          <path
            fill='#D50000'
            d='M1814.39,616.1c0.04,4.95-2.43,7.84-6.77,7.94c-4.19,0.09-7.37-3.19-7.38-7.59c0-4.65,3.36-8.32,7.52-8.2
			C1811.34,608.34,1814.35,611.92,1814.39,616.1z'
          />
          <path
            fill='#D50000'
            d='M1836.34,616.29c-0.06,5.2-2.29,7.7-6.91,7.74c-3.8,0.03-7.27-3.73-7.26-7.87c0.01-4.24,3.43-7.92,7.35-7.92
			C1833.64,608.23,1836.4,611.49,1836.34,616.29z'
          />
          <path
            fill='#D50000'
            d='M1387.5,638.7c0.13-3.61,4.37-8.16,7.51-8.06c3.36,0.11,7.29,4.56,7.31,8.28c0.02,3.98-4.25,8.46-7.82,8.19
			C1390.9,646.84,1387.36,642.59,1387.5,638.7z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1149.1c-0.11,4.71-2.88,7.64-7.17,7.62c-4.15-0.03-7.17-3.6-7.02-8.31c0.14-4.29,3.5-7.69,7.46-7.54
			C2028.84,1141.02,2031.85,1144.7,2031.75,1149.1z'
          />
          <path
            fill='#D50000'
            d='M866.63,955.62c-0.02-5.11,2.37-7.93,6.72-7.95c3.94-0.02,7.36,3.5,7.47,7.69c0.12,4.51-3.27,8.42-7.31,8.43
			C869.79,963.8,866.65,960.07,866.63,955.62z'
          />
          <path
            fill='#D50000'
            d='M1872.42,1595.65c-4.17-0.04-7.29-3.37-7.26-7.73c0.04-4.18,3.46-8,7.23-8.08c3.8-0.07,7.65,4.23,7.52,8.38
			C1879.76,1592.74,1876.78,1595.69,1872.42,1595.65z'
          />
          <path
            fill='#D50000'
            d='M1119.39,1537.85c-0.04,4.72-2.76,7.69-7.07,7.73c-3.81,0.04-7.28-3.56-7.35-7.61
			c-0.09-4.46,3.34-8.25,7.44-8.22C1116.22,1529.77,1119.43,1533.5,1119.39,1537.85z'
          />
          <path
            fill='#D50000'
            d='M1177.17,1545.59c-3.82-0.02-7.25-3.7-7.25-7.77c0-4.44,3.59-8.24,7.63-8.07c3.76,0.16,7.06,4.1,6.91,8.26
			C1184.3,1542.61,1181.41,1545.62,1177.17,1545.59z'
          />
          <path
            fill='#D50000'
            d='M2205.29,784.04c-0.06,4.6-3.11,8.02-7.06,7.91c-3.74-0.11-7.07-4.02-7-8.24c0.07-4.46,3.77-8.49,7.58-8.26
			C2202.42,775.66,2205.35,779.55,2205.29,784.04z'
          />
          <path
            fill='#D50000'
            d='M2495.31,1248.88c0.08-6.1,2.93-8.03,7.01-8.51c3.52-0.41,7.13,4.03,7.18,8.1c0.05,4.34-3.13,8.12-6.97,8.3
			C2498.71,1256.95,2495.36,1253.29,2495.31,1248.88z'
          />
          <path
            fill='#D50000'
            d='M1053.28,1732.85c-0.04,5.05-2.38,7.68-6.9,7.7c-4.53,0.03-7.46-3.19-7.3-8.02c0.14-4.31,3.43-7.82,7.31-7.82
			C1050.07,1724.72,1053.31,1728.55,1053.28,1732.85z'
          />
          <path
            fill='#D50000'
            d='M1198.01,670.92c-3.68-0.03-7.17-3.89-7.13-7.89c0.04-4.21,3.4-7.9,7.25-7.98c3.66-0.07,7.26,3.81,7.36,7.92
			C1205.59,667.56,1202.4,670.95,1198.01,670.92z'
          />
          <path
            fill='#D50000'
            d='M2031.75,783.55c-0.2,4.3-3.76,7.86-7.53,7.55c-3.78-0.32-6.78-4.12-6.66-8.44c0.13-4.66,3.43-7.8,7.85-7.48
			C2029.6,775.49,2031.98,778.63,2031.75,783.55z'
          />
          <path
            fill='#D50000'
            d='M2002.64,1718.34c-3.73-0.09-7.18-4.09-6.95-8.05c0.24-4.17,3.81-7.86,7.53-7.8
			c3.66,0.06,7.21,4.09,7.16,8.12C2010.34,1715.27,2007.14,1718.46,2002.64,1718.34z'
          />
          <path
            fill='#D50000'
            d='M1314.68,1585.52c0.06,5.13-2.18,7.88-6.53,8c-3.81,0.11-7.46-3.45-7.62-7.43c-0.18-4.5,3.15-8.37,7.22-8.41
			C1311.98,1577.65,1314.62,1580.63,1314.68,1585.52z'
          />
          <path
            fill='#D50000'
            d='M1089.63,825.41c3.83-0.02,7.29,3.61,7.39,7.74c0.1,4.25-3.12,7.93-7.09,8.1c-4.07,0.17-7.55-3.6-7.42-8.04
			C1082.63,828.54,1085.48,825.43,1089.63,825.41z'
          />
          <path
            fill='#D50000'
            d='M1785.81,1067.22c3.59-0.04,7.15,3.86,7.29,7.97c0.15,4.36-2.9,7.76-7.06,7.88c-4.3,0.12-7.71-3.43-7.62-7.92
			C1778.51,1071.08,1782.07,1067.26,1785.81,1067.22z'
          />
          <path
            fill='#D50000'
            d='M1901.91,832.72c0.15,4.04-3.7,8.47-7.4,8.5c-3.69,0.04-7.31-3.82-7.41-7.9c-0.1-4.1,3.28-7.87,7.09-7.93
			C1898.38,825.34,1901.76,828.54,1901.91,832.72z'
          />
          <path
            fill='#D50000'
            d='M2010.54,856.29c-0.01,4.03-4.04,8.57-7.54,8.49c-3.43-0.07-7.25-4.34-7.31-8.16
			c-0.06-4.13,3.27-7.65,7.3-7.71C2007.2,848.84,2010.55,852.12,2010.54,856.29z'
          />
          <path
            fill='#D50000'
            d='M1329.5,460.05c3.92,0.03,7.15,3.64,7.1,7.95c-0.04,4.14-3.45,7.83-7.31,7.89c-4.17,0.07-7.34-3.59-7.2-8.32
			C1322.24,462.89,1325.08,460.01,1329.5,460.05z'
          />
          <path
            fill='#D50000'
            d='M1356.6,467.99c-0.02,4.66-2.82,7.86-6.91,7.9c-3.7,0.04-7.2-3.73-7.28-7.83c-0.09-4.43,3.37-8.13,7.52-8.01
			C1354.25,460.17,1356.61,462.97,1356.6,467.99z'
          />
          <path
            fill='#D50000'
            d='M1031.38,565.57c0.03,4.55-2.85,8.21-6.59,8.36c-3.8,0.16-7.63-3.93-7.66-8.18
			c-0.03-4.02,3.41-7.62,7.32-7.65C1029.29,558.07,1031.35,560.28,1031.38,565.57z'
          />
          <path
            fill='#D50000'
            d='M974.01,565.05c0.33-4.84,2.88-7.16,7.6-6.93c3.93,0.19,7.12,4.02,6.78,8.14c-0.34,4.05-4.21,7.92-7.69,7.68
			C976.85,573.66,973.7,569.49,974.01,565.05z'
          />
          <path
            fill='#D50000'
            d='M1981.38,534.58c4.03-0.07,7.38,3.26,7.5,7.45c0.13,4.43-3.29,8.34-7.33,8.39c-3.38,0.04-6.83-3.71-6.91-7.51
			C1974.55,537.55,1976.88,534.66,1981.38,534.58z'
          />
          <path
            fill='#D50000'
            d='M866.63,1004.8c-0.06-5.21,2.07-7.67,6.68-7.72c4.33-0.05,7.32,2.87,7.5,7.32c0.17,4.38-3.29,8.46-7.22,8.49
			C869.89,1012.92,866.68,1009.18,866.63,1004.8z'
          />
          <path
            fill='#D50000'
            d='M2009.82,542.7c-0.05,4.47-3.18,7.85-7.14,7.72c-3.74-0.12-7.19-4.09-7.09-8.15c0.11-4.01,3.6-7.69,7.31-7.69
			C2007.6,534.59,2009.89,537.27,2009.82,542.7z'
          />
          <path
            fill='#D50000'
            d='M887.76,856.73c0.48-4.19,4.19-7.54,7.98-7.19c3.7,0.34,7.08,4.57,6.73,8.44c-0.35,3.97-4.39,8.07-7.68,7.79
			C891.32,865.47,887.34,860.36,887.76,856.73z'
          />
          <path
            fill='#D50000'
            d='M1040.07,1269.5c-0.02-4.27,3.08-8.19,6.63-8.38c3.56-0.2,7.51,4.18,7.56,8.37c0.05,4.3-3.19,7.71-7.31,7.69
			C1042.37,1277.16,1040.09,1274.62,1040.07,1269.5z'
          />
          <path
            fill='#D50000'
            d='M895.12,558.11c4.16-0.01,7.5,3.36,7.45,7.51c-0.04,3.97-4.24,8.48-7.75,8.32c-3.52-0.16-7.06-4.3-7.11-8.34
			C887.66,561.35,890.86,558.11,895.12,558.11z'
          />
          <path
            fill='#D50000'
            d='M895.15,702.96c4.2,0.04,7.45,3.4,7.42,7.68c-0.02,4.25-3.89,8.33-7.74,8.17c-3.74-0.16-7.23-4.19-7.14-8.24
			C887.8,706.12,890.94,702.92,895.15,702.96z'
          />
          <path
            fill='#D50000'
            d='M1075.84,1269.53c0.01,4.25-3.24,7.66-7.34,7.7c-3.78,0.03-7.33-3.72-7.36-7.76
			c-0.02-3.75,3.73-8.13,7.13-8.32C1071.68,1260.96,1075.83,1265.55,1075.84,1269.53z'
          />
          <path
            fill='#D50000'
            d='M1858.3,1148.86c0,4.49-2.94,7.81-6.96,7.86c-4.43,0.05-7.33-3.17-7.25-8.07c0.07-4.37,3.3-7.88,7.19-7.78
			C1855.17,1140.97,1858.3,1144.53,1858.3,1148.86z'
          />
          <path
            fill='#D50000'
            d='M1829.4,775.28c4.16,0.09,7,3.29,6.95,7.82c-0.05,4.35-3.24,7.94-7.1,7.98c-4,0.05-7.24-3.82-7.1-8.46
			C1822.3,777.74,1824.82,775.19,1829.4,775.28z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1003.08c0.07,4.53-2.88,8.36-6.58,8.56c-3.65,0.2-7.53-4.02-7.61-8.26
			c-0.07-4.05,3.31-7.98,7.1-8.23C2506.2,994.89,2509.44,998.51,2509.51,1003.08z'
          />
          <path
            fill='#D50000'
            d='M1053.29,565.67c-0.03,4.18-3.37,8.17-6.92,8.27c-3.84,0.1-7.29-3.87-7.29-8.41c0-4.27,3.28-7.72,7.31-7.69
			C1050.77,557.87,1053.33,560.78,1053.29,565.67z'
          />
          <path
            fill='#D50000'
            d='M2097.44,1270.19c0.06,4.23-3.19,7.82-7.15,7.89c-4.31,0.08-7.27-2.91-7.43-7.5
			c-0.15-4.27,3.49-8.44,7.29-8.36C2093.98,1262.31,2097.38,1266.01,2097.44,1270.19z'
          />
          <path
            fill='#D50000'
            d='M1858.3,1270.7c-0.14,4.6-3.15,7.55-7.51,7.37c-4.23-0.17-6.91-3.42-6.72-8.14c0.15-3.89,3.6-7.66,7.03-7.7
			C1855.02,1262.19,1858.44,1266.21,1858.3,1270.7z'
          />
          <path
            fill='#D50000'
            d='M858.86,1004.67c-0.09,4.69-3.49,8.45-7.41,8.21c-3.82-0.24-6.87-4-6.77-8.35c0.12-4.94,2.57-7.48,7.2-7.45
			C856.42,997.11,858.95,999.86,858.86,1004.67z'
          />
          <path
            fill='#D50000'
            d='M1894.3,1278.1c-4.02-0.07-7.27-3.59-7.25-7.87c0.02-4.05,3.57-7.91,7.37-8c3.81-0.09,7.54,4.13,7.39,8.37
			C1901.65,1275.12,1898.59,1278.17,1894.3,1278.1z'
          />
          <path
            fill='#D50000'
            d='M953.29,640.18c0.04-4.67,2.86-7.56,7.31-7.49c4.24,0.06,7.11,3.29,7.01,7.85c-0.1,4.24-3.65,8.07-7.34,7.91
			C956.48,648.3,953.25,644.47,953.29,640.18z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1390.59c0.05,5-2.37,7.87-6.68,7.95c-4.07,0.07-7.66-3.73-7.57-8.02c0.08-4.27,3.42-7.84,7.31-7.83
			C1028.63,1382.7,1031.34,1385.78,1031.38,1390.59z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1075.43c-0.06,4.84-2.76,7.72-7.21,7.66c-4.29-0.06-7.07-3.2-6.99-7.91
			c0.07-4.25,3.48-8.02,7.21-7.99C2028.36,1067.24,2031.8,1071.29,2031.75,1075.43z'
          />
          <path
            fill='#D50000'
            d='M1184.49,1560.92c0.12,4.49-2.78,7.84-6.92,7.98c-4.11,0.14-7.37-3.01-7.63-7.4
			c-0.26-4.29,3.28-8.43,7.25-8.46C1180.95,1553.01,1184.38,1556.7,1184.49,1560.92z'
          />
          <path
            fill='#D50000'
            d='M1806.09,768.94c-4.54-0.02-6.91-2.67-6.85-7.65c0.05-4.49,3.17-8.14,6.94-8.13c4.04,0.01,7.3,3.83,7.21,8.46
			C1813.29,766.4,1810.75,768.95,1806.09,768.94z'
          />
          <path
            fill='#D50000'
            d='M923.73,640.51c-0.03,4.45-3.2,8.07-6.99,7.97c-3.77-0.1-7.37-4.12-7.27-8.11c0.1-4.03,3.66-7.67,7.51-7.66
			C921.52,632.71,923.76,635.3,923.73,640.51z'
          />
          <path
            fill='#D50000'
            d='M1046.49,1398.54c-4.38-0.17-6.79-3.37-6.51-8.63c0.23-4.31,3.3-7.41,7.13-7.22c4.04,0.2,7.22,3.75,7.16,8
			C1054.23,1394.91,1050.47,1398.69,1046.49,1398.54z'
          />
          <path
            fill='#D50000'
            d='M1981.78,1262.23c3.99,0.3,7.37,4.59,6.93,8.82c-0.44,4.34-3.83,7.31-8,7c-4.1-0.3-6.86-3.81-6.49-8.25
			C1974.59,1265.28,1977.93,1261.94,1981.78,1262.23z'
          />
          <path
            fill='#D50000'
            d='M2046.69,848.64c4.11,0.11,7,3.42,6.97,8.01c-0.02,4.14-3.49,8.09-7.13,8.13c-3.74,0.05-7.08-4.05-7.02-8.59
			C2039.59,851.45,2042.37,848.52,2046.69,848.64z'
          />
          <path
            fill='#D50000'
            d='M2061.47,856.55c0.01-4.92,2.5-7.76,6.88-7.86c4.48-0.1,7.34,3.1,7.24,8.1c-0.08,4.15-3.62,8.06-7.25,8
			C2064.75,864.72,2061.46,860.78,2061.47,856.55z'
          />
          <path
            fill='#D50000'
            d='M1039.95,1585.53c0.01-4.65,2.96-7.95,7.01-7.84c4.01,0.11,7.26,3.62,7.29,7.88
			c0.03,4.01-3.45,7.88-7.14,7.95C1042.48,1593.6,1039.93,1590.76,1039.95,1585.53z'
          />
          <path
            fill='#D50000'
            d='M1061.03,565.68c-0.01-5.09,2.29-7.72,6.79-7.78c4.17-0.06,7.36,3.23,7.39,7.59c0.03,4.34-3.65,8.54-7.4,8.43
			C1064.25,573.83,1061.04,569.93,1061.03,565.68z'
          />
          <path
            fill='#D50000'
            d='M1858.31,710.56c0.06,4.71-2.65,8.12-6.57,8.26c-3.89,0.14-7.71-3.88-7.66-8.07
			c0.05-4.29,3.28-7.77,7.24-7.79C1855.76,702.93,1858.25,705.64,1858.31,710.56z'
          />
          <path
            fill='#D50000'
            d='M1793.12,1270.57c-0.16,4.47-3.24,7.57-7.47,7.5c-3.99-0.06-7.25-3.58-7.23-7.83
			c0.02-4.27,3.89-8.26,7.8-8.02C1789.74,1262.44,1793.25,1266.68,1793.12,1270.57z'
          />
          <path
            fill='#D50000'
            d='M1119.1,1270.89c-0.04,4.21-3.02,7.88-6.48,7.99c-3.61,0.11-7.65-4.23-7.66-8.23
			c-0.01-3.97,3.55-8.12,7.08-8.24C1115.75,1262.27,1119.14,1266.35,1119.1,1270.89z'
          />
          <path
            fill='#D50000'
            d='M1851.66,775.28c4.7,0.08,6.79,2.7,6.65,8.33c-0.1,3.97-3.39,7.54-6.91,7.5c-3.96-0.04-7.45-4.09-7.29-8.46
			C1844.25,778.4,1847.52,775.21,1851.66,775.28z'
          />
          <path
            fill='#D50000'
            d='M1039.95,1488.75c-0.03-4.67,2.84-8.03,6.83-8.02c4,0.01,7.33,3.45,7.48,7.71c0.14,4.18-3.54,8.17-7.48,8.12
			C1042.39,1496.51,1039.98,1493.75,1039.95,1488.75z'
          />
          <path
            fill='#D50000'
            d='M2313.79,833.93c0.04,3.73-3.8,8.11-7.21,8.21c-3.32,0.1-7.37-4.21-7.5-7.99c-0.13-3.72,4.16-8.56,7.56-8.51
			C2309.84,825.68,2313.75,830.21,2313.79,833.93z'
          />
          <path
            fill='#D50000'
            d='M866.63,857.4c0.02-5,2.53-7.82,6.9-7.75c3.95,0.06,7.3,3.66,7.29,7.85c0,4.46-3.58,8.4-7.49,8.27
			C869.74,865.65,866.61,861.74,866.63,857.4z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1148.47c0.12-4.12,3.31-7.61,6.96-7.6c4.1,0.01,7.41,3.86,7.23,8.42c-0.17,4.43-3.38,7.59-7.53,7.42
			C1824.72,1156.54,1822.02,1153.21,1822.15,1148.47z'
          />
          <path
            fill='#D50000'
            d='M2335.07,955.46c0.03,4.51-2.9,8.13-6.73,8.31c-3.72,0.18-7.55-4.13-7.47-8.42c0.07-4.02,3.78-8.06,7.36-8.03
			C2332.22,947.36,2335.04,950.72,2335.07,955.46z'
          />
          <path
            fill='#D50000'
            d='M2234.13,833.8c0.03-4.39,3.22-8.19,6.92-8.23c3.58-0.03,7.08,3.96,7.15,8.15c0.07,4.49-3.42,8.5-7.35,8.43
			C2237.1,842.1,2234.11,838.37,2234.13,833.8z'
          />
          <path
            fill='#D50000'
            d='M1836.34,1100.88c0.02,4.54-2.96,7.88-7.05,7.9c-4.32,0.02-7.08-2.98-7.14-7.76
			c-0.05-4.43,3.13-8.11,6.99-8.08C1833.06,1092.97,1836.32,1096.57,1836.34,1100.88z'
          />
          <path
            fill='#D50000'
            d='M1981.81,775.29c3.8,0.12,7.1,3.96,6.92,8.06c-0.18,4.27-3.65,7.88-7.47,7.77c-3.67-0.11-7.03-4.03-6.97-8.15
			C1974.35,777.87,1977.03,775.13,1981.81,775.29z'
          />
          <path
            fill='#D50000'
            d='M1981.33,825.4c3.88-0.04,7.28,3.45,7.4,7.6c0.13,4.28-3.58,8.35-7.5,8.24c-3.64-0.1-7-4.05-6.95-8.15
			C1974.34,828.26,1976.92,825.45,1981.33,825.4z'
          />
          <path
            fill='#D50000'
            d='M2226.26,955.66c-0.03,4.45-3.16,8.12-6.91,8.12c-3.62,0-7.11-3.98-7.17-8.17c-0.06-4.27,3.74-8.52,7.52-8.41
			C2223.45,947.32,2226.29,950.98,2226.26,955.66z'
          />
          <path
            fill='#D50000'
            d='M2270.17,978.82c-0.09,3.99-3.71,8.07-7.26,8.16c-3.87,0.11-6.98-3.77-6.87-8.57
			c0.1-4.28,3.61-8.23,7.11-7.96C2266.6,970.71,2270.25,975.06,2270.17,978.82z'
          />
          <path
            fill='#D50000'
            d='M1061.03,857.25c0.07-4.8,2.87-7.91,7.04-7.82c3.7,0.08,7.21,4.09,7.15,8.14c-0.07,4.13-3.54,8.09-7.19,8.2
			C1064.21,865.89,1060.96,861.94,1061.03,857.25z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1269.65c-0.05,4.93-2.6,7.66-7.07,7.57c-4.02-0.08-7.28-3.67-7.19-7.91
			c0.09-4.22,3.42-8.03,7.14-8.18C1027.92,1260.99,1031.43,1265.18,1031.38,1269.65z'
          />
          <path
            fill='#D50000'
            d='M1068.49,1398.54c-3.92,0.01-7.29-3.53-7.36-7.75c-0.08-4.53,3.37-8.23,7.57-8.11
			c3.97,0.11,7.14,3.73,7.07,8.04C1075.68,1395.19,1072.58,1398.52,1068.49,1398.54z'
          />
          <path
            fill='#D50000'
            d='M2024.9,1546.64c-4.23,0.07-7.22-3.03-7.34-7.61c-0.11-4.3,3.05-8.06,6.91-8.22c3.78-0.16,7.2,3.52,7.28,7.83
			C2031.83,1543.47,2029.17,1546.58,2024.9,1546.64z'
          />
          <path
            fill='#D50000'
            d='M2278,978.91c-0.03-4.39,3.04-8.29,6.64-8.44c3.65-0.15,7.53,4.14,7.52,8.33c-0.01,3.93-3.63,8.02-7.23,8.16
			C2281.23,987.11,2278.02,983.38,2278,978.91z'
          />
          <path
            fill='#D50000'
            d='M786.42,768.93c-3.74,0.01-7.32-3.77-7.44-7.87c-0.13-4.47,3.29-8.06,7.61-7.97c4.14,0.08,7.25,3.5,7.15,7.84
			C793.65,765.05,790.1,768.92,786.42,768.93z'
          />
          <path
            fill='#D50000'
            d='M1155.38,1789.55c-3.99,0.04-7.31-3.38-7.42-7.63c-0.11-4.39,3.44-8.27,7.49-8.18
			c3.82,0.08,7.08,3.77,7.1,8.04C1162.57,1786.28,1159.59,1789.5,1155.38,1789.55z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1390.93c-0.04,4.98-2.59,7.72-7.08,7.61c-3.47-0.08-6.93-3.82-7.02-7.6
			c-0.11-4.59,3.33-8.41,7.45-8.27C1203.44,1382.82,1205.95,1385.92,1205.9,1390.93z'
          />
          <path
            fill='#D50000'
            d='M1067.99,825.4c3.66,0.04,7.3,4.01,7.23,7.88c-0.07,4.31-3.27,7.88-7.14,7.97c-4.35,0.09-7.1-3.11-7.05-8.2
			C1061.09,828.13,1063.61,825.35,1067.99,825.4z'
          />
          <path
            fill='#D50000'
            d='M2009.84,565.88c0.05,4.96-2.38,7.95-6.57,8.08c-4.05,0.12-7.69-3.63-7.67-7.92c0.02-4.2,3.3-7.8,7.22-7.92
			C2006.94,557.99,2009.79,561.15,2009.84,565.88z'
          />
          <path
            fill='#D50000'
            d='M2024.48,558.11c3.97-0.08,7.23,3.44,7.27,7.84c0.03,4.49-3,7.93-7.05,8c-4.12,0.08-7.09-3.12-7.14-7.71
			C2017.5,561.75,2020.54,558.19,2024.48,558.11z'
          />
          <path
            fill='#D50000'
            d='M1264.24,1691.54c-4.31-0.07-6.99-3.19-6.87-8c0.1-4.29,3.11-7.76,6.79-7.83c4.16-0.08,7.7,3.75,7.57,8.17
			C1271.59,1688.05,1268.13,1691.6,1264.24,1691.54z'
          />
          <path
            fill='#D50000'
            d='M1177.32,1358.05c3.74,0.02,7.15,3.81,7.19,8c0.05,4.76-3.06,7.98-7.58,7.85c-3.95-0.11-7.17-3.8-7.02-8.02
			C1170.06,1361.67,1173.52,1358.02,1177.32,1358.05z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1365.89c0.07-4.51,3.2-7.93,7.18-7.84c3.72,0.09,6.99,3.99,6.89,8.22
			c-0.11,4.64-3.07,7.73-7.31,7.62C1129.36,1373.78,1126.86,1370.81,1126.93,1365.89z'
          />
          <path
            fill='#D50000'
            d='M1785.71,1302.68c-3.85-0.02-7.27-3.66-7.31-7.79c-0.04-4.16,3.36-7.96,7.21-8.05
			c3.84-0.09,7.63,4.06,7.56,8.29C1793.11,1299.39,1789.83,1302.71,1785.71,1302.68z'
          />
          <path
            fill='#D50000'
            d='M836.9,955.43c0.01,4.45-2.9,8.16-6.56,8.36c-3.56,0.19-7.64-4.33-7.56-8.37c0.08-3.92,3.84-7.97,7.36-7.94
			C834.27,947.52,836.89,950.6,836.9,955.43z'
          />
          <path
            fill='#D50000'
            d='M720.35,768.93c-3.71-0.08-7.22-3.98-7.23-8.04c-0.01-4.47,3.47-7.96,7.76-7.79
			c4.14,0.17,7.22,3.71,6.95,8.01C727.56,765.37,724.08,769.01,720.35,768.93z'
          />
          <path
            fill='#D50000'
            d='M836.9,833.49c-0.06,4.66-2.6,7.68-6.53,7.77c-4.12,0.09-7.76-3.84-7.58-8.2c0.16-4.08,3.8-7.75,7.57-7.64
			C834.71,825.55,836.97,828.34,836.9,833.49z'
          />
          <path
            fill='#D50000'
            d='M2335.07,858.62c-0.03,4.54-3.15,8.21-6.94,8.14c-3.59-0.06-7.17-4.09-7.27-8.18
			c-0.1-4.23,3.75-8.51,7.53-8.36C2332.22,850.37,2335.1,853.99,2335.07,858.62z'
          />
          <path
            fill='#D50000'
            d='M1988.75,1125.66c-0.12,4.26-3.43,7.61-7.49,7.56c-4.12-0.05-7.28-3.44-7.25-7.77
			c0.02-4.05,3.59-8.01,7.23-8.04C1985.32,1117.39,1988.87,1121.29,1988.75,1125.66z'
          />
          <path
            fill='#D50000'
            d='M2307.54,624.03c-3.99,0.01-7.26-3.5-7.34-7.85c-0.07-4.43,2.99-7.85,7.12-7.94c4.29-0.1,7.74,3.52,7.62,7.99
			C2314.82,620.41,2311.4,624.02,2307.54,624.03z'
          />
          <path
            fill='#D50000'
            d='M1089.21,1789.55c-3.92-0.08-7.2-3.65-7.22-7.85c-0.02-4.3,3.68-8.1,7.72-7.95c3.79,0.14,6.88,3.94,6.75,8.31
			C1096.32,1786.86,1093.64,1789.64,1089.21,1789.55z'
          />
          <path
            fill='#D50000'
            d='M1814.39,1295.19c-0.08,4.86-2.69,7.56-7.23,7.48c-4.2-0.07-7.01-3.32-6.91-7.99
			c0.09-4.36,3.48-7.97,7.37-7.84C1811.34,1286.96,1814.46,1290.8,1814.39,1295.19z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1294.79c0.02-4.31,3.33-8.04,7.07-7.95c3.86,0.09,7.04,3.64,7.12,7.96c0.09,4.84-2.87,8-7.39,7.87
			C1824.53,1302.55,1822.13,1299.77,1822.15,1294.79z'
          />
          <path
            fill='#D50000'
            d='M2226.25,1054.14c-0.04,4.88-2.7,7.82-7,7.77c-3.83-0.05-7.05-3.73-7.07-8.06c-0.02-4.07,3.53-7.81,7.37-7.74
			C2223.79,1046.18,2226.3,1049.19,2226.25,1054.14z'
          />
          <path
            fill='#D50000'
            d='M1748.48,881.77c-0.02,4.94-2.59,7.72-7.09,7.66c-4.37-0.06-7.01-3.07-6.97-7.93
			c0.03-4.2,3.57-8.16,7.24-8.09C1745.31,873.47,1748.5,877.37,1748.48,881.77z'
          />
          <path
            fill='#D50000'
            d='M1821.15,881.81c-0.01-4.21,3.26-8.27,6.8-8.44c3.84-0.19,7.39,3.83,7.39,8.38c0,4.67-2.79,7.71-7.12,7.73
			C1823.84,889.51,1821.16,886.6,1821.15,881.81z'
          />
          <path
            fill='#D50000'
            d='M1836.34,1393.2c-0.07,4.67-2.93,7.6-7.32,7.5c-4.33-0.1-6.98-3.19-6.87-8.02c0.1-4.29,3.61-8.02,7.38-7.82
			C1833.34,1385.07,1836.41,1388.82,1836.34,1393.2z'
          />
          <path
            fill='#D50000'
            d='M1995.72,1757.87c0.08-4.17,3.44-7.52,7.49-7.47c4.3,0.05,7.15,3.11,7.16,7.69c0.01,3.87-3.69,8.08-7.18,8.16
			C1999.47,1766.33,1995.64,1762.05,1995.72,1757.87z'
          />
          <path
            fill='#D50000'
            d='M895.14,656.15c4.11-0.01,7.49,3.38,7.45,7.49c-0.03,3.95-4.2,8.45-7.72,8.33c-3.52-0.13-7.22-4.24-7.08-8.29
			C887.93,659.43,891.27,655.19,895.14,656.15z'
          />
          <path
            fill='#D50000'
            d='M1140.33,663.99c-0.01,4.14-3.5,8.02-7.18,7.99c-3.7-0.04-7.16-3.9-7.21-8.05c-0.05-4.29,3.49-7.93,7.63-7.84
			C1137.92,656.18,1140.34,659.01,1140.33,663.99z'
          />
          <path
            fill='#D50000'
            d='M1090.4,1691.54c-3.88-0.01-7.31-3.57-7.41-7.69c-0.1-4.33,3.46-8.18,7.54-8.15c3.86,0.04,6.95,3.65,6.92,8.1
			C1097.41,1688.57,1094.69,1691.55,1090.4,1691.54z'
          />
          <path
            fill='#D50000'
            d='M2039.52,710.63c0.04-4.79,2.79-7.74,7.14-7.66c4,0.07,7.06,3.61,6.98,8.06c-0.08,4.19-3.57,7.9-7.34,7.8
			C2042.33,718.72,2039.48,715.27,2039.52,710.63z'
          />
          <path
            fill='#D50000'
            d='M611.09,947.77c4.26-0.03,7.21,3.05,7.27,7.59c0.05,4.57-3.38,8.56-7.24,8.41c-3.87-0.14-6.93-3.76-6.89-8.16
			C604.27,950.61,606.72,947.8,611.09,947.77z'
          />
          <path
            fill='#D50000'
            d='M2335.07,783.54c0.05,4.9-3.04,8.54-7.12,8.38c-3.62-0.14-7.13-4.24-7.09-8.26c0.04-3.96,3.69-8.09,7.25-8.2
			C2331.82,775.34,2335.02,779.06,2335.07,783.54z'
          />
          <path
            fill='#D50000'
            d='M1097.16,1488.93c0,4.95-2.46,7.66-6.94,7.64c-3.77-0.02-7.21-3.72-7.22-7.78c-0.02-4.27,3.18-7.93,7.04-8.06
			C1094.38,1480.58,1097.15,1483.78,1097.16,1488.93z'
          />
          <path
            fill='#D50000'
            d='M1770.44,832.47c0.05,5.04-2.35,7.79-6.87,7.88c-4.44,0.09-7.16-2.79-7.22-7.66
			c-0.06-4.22,3.29-8.18,7.02-8.31C1767.05,824.25,1770.4,828.09,1770.44,832.47z'
          />
          <path
            fill='#D50000'
            d='M1741.4,840.17c-4.65-0.04-7.02-2.66-6.98-7.72c0.04-4.2,3.55-8.12,7.23-8.06c3.69,0.06,6.86,3.91,6.83,8.31
			C1748.46,837.81,1746.18,840.2,1741.4,840.17z'
          />
          <path
            fill='#D50000'
            d='M720.58,718.82c-3.7,0.06-7.27-3.68-7.46-7.83c-0.19-4.15,3.09-7.9,7.03-8.04c4.41-0.15,7.79,3.25,7.72,7.78
			C727.81,714.71,724.16,718.76,720.58,718.82z'
          />
          <path
            fill='#D50000'
            d='M1793.19,1173.46c-0.03,4.31-3.23,7.65-7.36,7.67c-4.04,0.02-7.3-3.34-7.41-7.64
			c-0.11-4.25,3.66-8.31,7.61-8.2C1789.55,1165.39,1793.22,1169.57,1793.19,1173.46z'
          />
          <path
            fill='#D50000'
            d='M2256.06,881.6c0.06-4.28,3.46-8.25,6.95-8.11c3.37,0.13,7.18,4.51,7.17,8.23c-0.02,3.97-4.15,8.48-7.63,8.31
			C2258.81,889.85,2256,886.2,2256.06,881.6z'
          />
          <path
            fill='#D50000'
            d='M2278,881.53c0.06-4.21,3.6-8.25,7.06-8.04c3.55,0.22,7.05,4.31,7.08,8.28c0.04,3.98-3.57,8.12-7.15,8.19
			C2280.95,890.06,2277.93,886.41,2278,881.53z'
          />
          <path
            fill='#D50000'
            d='M1112.22,1480.73c3.86-0.12,7.1,3.42,7.17,7.86c0.08,4.6-2.73,7.86-6.87,7.97c-3.83,0.1-7.29-3.31-7.55-7.46
			C1104.7,1484.79,1108.1,1480.86,1112.22,1480.73z'
          />
          <path
            fill='#D50000'
            d='M2024.67,1253.67c-4.06-0.01-7.08-3.35-7.11-7.86c-0.03-4.39,3.16-7.96,7.13-7.98
			c3.91-0.02,7.09,3.59,7.06,8.02C2031.71,1250.57,2028.9,1253.68,2024.67,1253.67z'
          />
          <path
            fill='#D50000'
            d='M1901.87,1173.39c0.02,4.36-3.13,7.71-7.29,7.76c-4.11,0.05-7.35-3.23-7.48-7.56
			c-0.13-4.28,3.58-8.35,7.54-8.3C1898.24,1165.33,1901.85,1169.37,1901.87,1173.39z'
          />
          <path
            fill='#D50000'
            d='M1062.78,1683.76c-0.06-4.63,2.78-7.97,6.82-8.05c4.09-0.08,7.66,3.79,7.53,8.17
			c-0.13,4.23-3.49,7.7-7.44,7.67C1065.29,1691.5,1062.84,1688.74,1062.78,1683.76z'
          />
          <path
            fill='#D50000'
            d='M1040.07,1342.87c0.01-4.29,3.17-8.02,6.87-8.09c3.75-0.07,7.47,4.23,7.33,8.49
			c-0.13,4.3-3.41,7.53-7.55,7.45C1042.22,1350.63,1040.06,1348.09,1040.07,1342.87z'
          />
          <path
            fill='#D50000'
            d='M2307.84,751.99c4.61-0.08,7.3,2.69,7.26,7.49c-0.03,4.4-3.16,8.18-6.91,8.36c-3.53,0.16-7.22-3.89-7.28-8.01
			C2300.83,754.88,2303.32,752.06,2307.84,751.99z'
          />
          <path
            fill='#D50000'
            d='M2271.19,541.63c0.04,4.91-2.93,8.34-7.18,8.29c-3.87-0.05-7.01-3.7-6.95-8.08c0.06-4.29,3.38-8.17,7.03-8.21
			C2267.86,533.57,2271.16,537.29,2271.19,541.63z'
          />
          <path
            fill='#D50000'
            d='M1901.85,1125.51c-0.01,4.39-3.13,7.68-7.3,7.71c-4.08,0.03-7.35-3.29-7.47-7.56
			c-0.12-4.29,3.57-8.3,7.56-8.23C1898.28,1117.49,1901.87,1121.52,1901.85,1125.51z'
          />
          <path
            fill='#D50000'
            d='M2335.07,1003.16c0.05,4.61-2.78,8.25-6.6,8.48c-3.65,0.22-7.59-4.1-7.61-8.36c-0.02-4.08,3.45-8.02,7.2-8.17
			C2331.87,994.97,2335.02,998.59,2335.07,1003.16z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1027.84c-0.03-4.5,3.49-8.62,7.24-8.46c3.7,0.16,6.93,4.01,6.95,8.29c0.03,4.61-2.82,7.76-7.07,7.81
			C1824.93,1035.53,1822.19,1032.59,1822.15,1027.84z'
          />
          <path
            fill='#D50000'
            d='M1177,1471.95c-3.96-0.09-7.19-3.73-7.07-7.97c0.11-4.18,3.58-7.91,7.34-7.89c3.91,0.01,7.45,4.16,7.2,8.42
			C1184.19,1469.2,1181.33,1472.05,1177,1471.95z'
          />
          <path
            fill='#D50000'
            d='M2069.52,607.12c3.93,0.13,7.17,3.88,6.96,8.04c-0.2,3.95-4.05,7.91-7.59,7.82
			c-3.46-0.09-7.21-4.36-7.23-8.22C2061.63,610.32,2065.07,606.97,2069.52,607.12z'
          />
          <path
            fill='#D50000'
            d='M1567.55,435.44c3.78,0,7.31,3.8,7.34,7.91c0.03,4.23-3.38,7.89-7.36,7.92c-4.25,0.03-6.77-2.96-6.77-8
			C1560.76,438.09,1563.06,435.44,1567.55,435.44z'
          />
          <path
            fill='#D50000'
            d='M1698.09,1205.73c-4.18-0.04-7.05-3.17-7.1-7.74c-0.05-4.18,3.31-8.05,7.05-8.11
			c3.99-0.06,7.59,3.93,7.46,8.26C1705.37,1202.29,1701.97,1205.77,1698.09,1205.73z'
          />
          <path
            fill='#D50000'
            d='M1531.04,443.16c0.05,4.76-2.79,8.12-6.84,8.11c-3.98-0.02-7.28-3.53-7.36-7.85
			c-0.08-4.17,3.27-7.93,7.13-7.98C1528.3,435.38,1530.99,438.32,1531.04,443.16z'
          />
          <path
            fill='#D50000'
            d='M2307.59,671.98c-3.94,0.03-7.29-3.52-7.4-7.83c-0.11-4.4,2.98-7.89,7.08-8c4.32-0.11,7.73,3.43,7.65,7.96
			C2314.85,668.24,2311.39,671.95,2307.59,671.98z'
          />
          <path
            fill='#D50000'
            d='M1829.29,1237.82c3.92,0.12,7.1,3.77,7.05,8.1c-0.05,4.5-3.11,7.79-7.21,7.75c-4.37-0.04-7.2-3.46-6.98-8.42
			C1822.34,1241.12,1825.62,1237.71,1829.29,1237.82z'
          />
          <path
            fill='#D50000'
            d='M2256.04,1003.09c0.05-4.56,3.23-8.16,7.01-7.96c3.6,0.19,7.15,4.3,7.13,8.25c-0.02,3.84-3.74,8.14-7.16,8.28
			C2259.25,1011.82,2255.98,1007.81,2256.04,1003.09z'
          />
          <path
            fill='#D50000'
            d='M1793.16,1197.97c0.08,4.22-3.17,7.71-7.23,7.76c-3.92,0.05-7.34-3.39-7.51-7.57
			c-0.17-4.09,3.18-8.05,6.98-8.27C1789.27,1189.68,1793.07,1193.63,1793.16,1197.97z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1197.7c0.07-4.3,3.46-7.97,7.22-7.79c3.87,0.18,6.95,3.76,6.98,8.11c0.03,4.57-2.83,7.71-7.03,7.72
			C1824.65,1205.74,1822.08,1202.85,1822.15,1197.7z'
          />
          <path
            fill='#D50000'
            d='M2234.13,1003.14c0.05-4.51,3.28-8.23,7.01-8.05c3.77,0.19,7.04,3.99,7.06,8.22
			c0.02,4.34-3.73,8.55-7.46,8.35C2237.07,1011.48,2234.09,1007.64,2234.13,1003.14z'
          />
          <path
            fill='#D50000'
            d='M895.01,874.46c4-0.05,7.46,3.26,7.61,7.3c0.14,3.71-4.06,8.39-7.6,8.46c-3.41,0.07-7.18-4.12-7.3-8.12
			C887.58,878.19,891.09,874.52,895.01,874.46z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1392.79c0.04-4.27,3.36-7.99,7.06-7.92c3.8,0.07,7.06,3.77,7.08,8.04c0.02,4.68-2.74,7.76-6.98,7.79
			C2041.92,1400.73,2039.47,1398.02,2039.52,1392.79z'
          />
          <path
            fill='#D50000'
            d='M1858.31,808.74c-0.05,4.23-3.37,8.11-6.94,8.09c-3.74-0.01-7.44-4.37-7.28-8.57
			c0.17-4.46,3.31-7.54,7.55-7.4C1856.18,801.01,1858.37,803.59,1858.31,808.74z'
          />
          <path
            fill='#D50000'
            d='M1799.24,809.51c-0.05-4.49,2.87-8.2,6.65-8.42c3.97-0.23,7.45,3.55,7.5,8.17c0.05,4.67-2.67,7.7-6.98,7.78
			C1802.03,817.11,1799.3,814.24,1799.24,809.51z'
          />
          <path
            fill='#D50000'
            d='M2111.92,1384.86c3.79-0.08,7.25,3.52,7.46,7.76c0.2,4.06-3.13,7.97-6.91,8.1c-4.54,0.16-7.57-2.92-7.59-7.71
			C2104.85,1388.8,2108.18,1384.94,2111.92,1384.86z'
          />
          <path
            fill='#D50000'
            d='M2132.69,1302.68c-4.07-0.1-6.98-3.46-6.87-7.96c0.1-4.26,3.52-7.96,7.28-7.89c3.86,0.08,7.19,3.76,7.25,7.99
			C2140.4,1299.1,2136.82,1302.78,2132.69,1302.68z'
          />
          <path
            fill='#D50000'
            d='M2132.66,1400.7c-4.11-0.11-6.95-3.42-6.82-7.96c0.12-4.25,3.56-7.96,7.31-7.87
			c3.78,0.09,7.17,3.87,7.21,8.03C2140.39,1397.22,2136.84,1400.81,2132.66,1400.7z'
          />
          <path
            fill='#D50000'
            d='M2154.55,1302.68c-4.02-0.15-6.96-3.64-6.8-8.07c0.15-4.2,3.68-7.9,7.41-7.77c3.8,0.13,7.15,3.94,7.13,8.09
			C2162.26,1299.23,2158.67,1302.83,2154.55,1302.68z'
          />
          <path
            fill='#D50000'
            d='M975.23,932.12c0.03-4.84,2.65-7.74,6.97-7.76c3.92-0.01,7.35,3.63,7.35,7.81c0,4.21-3.9,8.44-7.6,8.27
			C978.4,940.28,975.2,936.31,975.23,932.12z'
          />
          <path
            fill='#D50000'
            d='M1894.91,750.87c3.83,0.1,7.01,3.47,6.99,7.41c-0.02,4.03-4.03,8.49-7.55,8.4c-3.51-0.09-7.23-4.29-7.24-8.18
			C1887.1,753.99,1890.41,750.76,1894.91,750.87z'
          />
          <path
            fill='#D50000'
            d='M1102.96,1245.27c0.06-4.96,2.54-7.67,7.02-7.68c4.42-0.01,7.05,2.83,7.1,7.68c0.05,4.44-3.55,8.53-7.37,8.35
			C1106.05,1253.46,1102.9,1249.57,1102.96,1245.27z'
          />
          <path
            fill='#D50000'
            d='M2175.9,1302.67c-4.2-0.03-7.07-3.11-7.16-7.69c-0.08-4.2,3.23-8.05,7.02-8.15c4.02-0.1,7.6,3.82,7.5,8.21
			C2183.16,1299.15,2179.74,1302.7,2175.9,1302.67z'
          />
          <path
            fill='#D50000'
            d='M1046.97,1237.61c4.17,0.01,7.34,3.36,7.29,7.69c-0.05,4.2-3.98,8.51-7.59,8.32
			c-3.6-0.19-6.64-4.06-6.61-8.39C1040.1,1240.1,1042.38,1237.59,1046.97,1237.61z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1245.27c0.01,4.28-3.18,8.22-6.75,8.35c-3.82,0.14-7.51-3.94-7.51-8.3
			c-0.01-4.34,3.19-7.72,7.3-7.72C1029.03,1237.59,1031.37,1240.18,1031.38,1245.27z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1027.91c-0.05,4.72-2.87,7.68-7.21,7.57c-4.28-0.11-7-3.18-6.98-7.87c0.02-4.32,3.25-8.12,7-8.23
			C2028.29,1019.28,2031.8,1023.44,2031.75,1027.91z'
          />
          <path
            fill='#D50000'
            d='M2220.89,1302.67c-4.2,0-7.09-3.04-7.25-7.62c-0.14-4.15,3.19-8.08,6.95-8.21c4.04-0.14,7.62,3.7,7.59,8.14
			C2228.15,1299.05,2224.71,1302.67,2220.89,1302.67z'
          />
          <path
            fill='#D50000'
            d='M1089.74,816.85c-3.8,0.04-7.1-3.54-7.28-7.88c-0.19-4.66,2.92-8.01,7.39-7.95c4.03,0.05,7.21,3.54,7.17,7.87
			C1096.97,813.06,1093.54,816.82,1089.74,816.85z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1417c-0.16,4.52-2.77,7.1-7.08,7.01c-3.9-0.08-7.26-3.77-7.17-7.87c0.09-4.39,3.76-8.16,7.7-7.92
			C1028.93,1408.47,1031.56,1412,1031.38,1417z'
          />
          <path
            fill='#D50000'
            d='M2162.38,905.36c-0.02,4.43-3.28,8.32-6.92,8.26c-3.71-0.07-7.06-3.91-7.13-8.19
			c-0.08-4.66,3.33-8.6,7.29-8.44C2159.46,897.15,2162.39,900.77,2162.38,905.36z'
          />
          <path
            fill='#D50000'
            d='M2076.48,665.55c-0.25,4.56-3.79,7.84-8.11,7.51c-3.77-0.29-6.85-3.92-6.69-7.87
			c0.18-4.48,3.86-8.2,7.84-7.95C2073.28,657.47,2076.7,661.56,2076.48,665.55z'
          />
          <path
            fill='#D50000'
            d='M2133.75,673.06c-3.92,0.04-7.27-3.52-7.29-7.77c-0.02-4.23,3.25-7.94,7.1-8.06
			c4.02-0.12,7.59,3.75,7.54,8.17C2141.06,669.8,2137.97,673.01,2133.75,673.06z'
          />
          <path
            fill='#D50000'
            d='M1356.6,443.39c0.01,4.77-2.69,7.85-6.91,7.89c-3.88,0.04-7.2-3.52-7.28-7.8c-0.08-4.39,3.41-8.13,7.5-8.04
			C1354.17,435.53,1356.59,438.41,1356.6,443.39z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1515.79c-0.07,4.75-2.83,7.63-7.24,7.55c-4.14-0.07-7.07-3.41-6.96-7.91
			c0.11-4.35,3.41-7.91,7.32-7.91C2028.59,1507.52,2031.82,1511.4,2031.75,1515.79z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1637.08c-0.05-4.4,3.07-8.15,6.84-8.24c3.81-0.09,7.22,3.6,7.28,7.87c0.07,4.71-2.7,7.88-6.93,7.95
			C2064.25,1644.74,2061.52,1641.86,2061.47,1637.08z'
          />
          <path
            fill='#D50000'
            d='M2017.56,1197.51c0.23-4.3,3.62-7.75,7.47-7.61c3.9,0.14,7.04,4.25,6.72,8.79c-0.33,4.72-3.12,7.28-7.66,7.04
			C2020.08,1205.53,2017.31,1202.04,2017.56,1197.51z'
          />
          <path
            fill='#D50000'
            d='M1075.75,1440.75c0.03,4.47-3.01,7.82-7.14,7.86c-4.03,0.04-7.37-3.35-7.48-7.59
			c-0.12-4.5,3.28-8.24,7.47-8.21C1072.58,1432.85,1075.72,1436.34,1075.75,1440.75z'
          />
          <path
            fill='#D50000'
            d='M2249.86,665.26c0.09,4.29-3.01,7.66-7.15,7.8c-3.95,0.13-7.3-3.28-7.47-7.6c-0.16-4.12,3.16-8.04,6.97-8.23
			C2246.23,657.03,2249.77,660.73,2249.86,665.26z'
          />
          <path
            fill='#D50000'
            d='M866.63,808.48c0.04-5,2.43-7.54,7.04-7.46c3.9,0.06,7.16,3.65,7.14,7.85c-0.02,4.18-3.34,7.88-7.18,7.99
			C869.53,816.97,866.59,813.47,866.63,808.48z'
          />
          <path
            fill='#D50000'
            d='M2234.13,906.33c0.02-4.55,3.14-8.27,6.88-8.2c3.72,0.07,7.1,3.87,7.19,8.09c0.09,4.38-3.52,8.58-7.32,8.51
			C2237.24,914.66,2234.11,910.77,2234.13,906.33z'
          />
          <path
            fill='#D50000'
            d='M2314.1,1101.75c0.09,4.33-3.08,8-6.97,8.1c-4.08,0.1-7.17-3.22-7.22-7.76c-0.05-4.69,2.74-7.95,6.9-8.06
			C2310.69,1093.93,2314.02,1097.45,2314.1,1101.75z'
          />
          <path
            fill='#D50000'
            d='M2292.15,906.5c-0.04,3.85-3.76,8.07-7.22,8.21c-3.78,0.15-7.02-3.89-6.94-8.64
			c0.08-4.31,2.98-7.79,6.61-7.91C2288.31,898.04,2292.2,902.34,2292.15,906.5z'
          />
          <path
            fill='#D50000'
            d='M1112.55,1432.81c3.83,0.07,6.91,3.76,6.83,8.19c-0.09,4.72-2.83,7.62-7.17,7.61
			c-3.84-0.01-7.18-3.59-7.23-7.75C1104.91,1436.44,1108.4,1432.74,1112.55,1432.81z'
          />
          <path
            fill='#D50000'
            d='M2335.07,906.58c-0.05,4.54-3.21,8.24-6.96,8.13c-3.74-0.1-7.43-4.55-7.24-8.74
			c0.18-4.09,3.94-7.98,7.56-7.83C2332.25,898.31,2335.12,901.95,2335.07,906.58z'
          />
          <path
            fill='#D50000'
            d='M2017.56,1733.46c-0.04-4.55,2.91-7.71,7.17-7.68c4.51,0.03,7.02,2.8,7.02,7.75c0,4.4-3.29,8.13-7.11,8.07
			C2020.76,1741.54,2017.6,1737.91,2017.56,1733.46z'
          />
          <path
            fill='#D50000'
            d='M1111.53,777.53c3.82-0.05,7.41,3.62,7.52,7.68c0.11,4.12-3.31,7.98-7.18,8.11
			c-4.23,0.14-7.03-2.95-7.05-7.79C1104.79,780.51,1107.25,777.58,1111.53,777.53z'
          />
          <path
            fill='#D50000'
            d='M836.9,735.44c-0.06,4.68-2.59,7.68-6.54,7.77c-4.14,0.09-7.77-3.84-7.58-8.2c0.18-4.11,3.8-7.76,7.59-7.64
			C834.76,727.5,836.97,730.23,836.9,735.44z'
          />
          <path
            fill='#D50000'
            d='M1366.3,591.64c0.05-4.59,2.96-7.93,6.94-7.98c3.93-0.04,7.39,3.9,7.3,8.32c-0.09,4.21-3.49,7.62-7.47,7.49
			C1368.61,599.34,1366.24,596.6,1366.3,591.64z'
          />
          <path
            fill='#D50000'
            d='M1133.53,1895.28c3.93-0.05,7.4,3.58,7.46,7.79c0.06,4.02-3.44,7.96-7.11,8.01c-4.48,0.06-7.01-2.66-7.06-7.6
			C1126.77,1898.67,1129.51,1895.32,1133.53,1895.28z'
          />
          <path
            fill='#D50000'
            d='M1089.85,743.2c-3.88,0.12-7.3-3.41-7.41-7.65c-0.11-4.45,2.82-7.97,6.79-8.19c4.05-0.22,7.76,3.5,7.8,7.82
			C1097.07,739.47,1093.83,743.08,1089.85,743.2z'
          />
          <path
            fill='#D50000'
            d='M1054.26,1318.65c-0.04,4.33-3.3,7.65-7.43,7.57c-4.54-0.09-6.76-2.65-6.76-7.81c0-4.21,3.25-8.17,6.75-8.25
			C1050.49,1310.09,1054.3,1314.43,1054.26,1318.65z'
          />
          <path
            fill='#D50000'
            d='M1901.85,1222.74c-0.1,4.38-3.25,7.5-7.51,7.45c-4.01-0.05-7.27-3.53-7.24-7.74
			c0.03-4.27,3.94-8.34,7.75-8.07C1898.42,1214.63,1901.95,1218.84,1901.85,1222.74z'
          />
          <path
            fill='#D50000'
            d='M2226.26,1102.14c-0.05,4.83-2.78,7.81-7.07,7.72c-3.78-0.08-7.03-3.86-7.01-8.16
			c0.02-4.14,3.54-7.77,7.44-7.67C2223.87,1094.13,2226.31,1097.12,2226.26,1102.14z'
          />
          <path
            fill='#D50000'
            d='M1683.62,1027.45c0.06,4.85-2.59,7.88-6.93,7.95c-4.46,0.07-7.05-2.72-7.12-7.69
			c-0.06-4.27,3.24-8.27,6.87-8.34C1680.18,1019.3,1683.57,1023.11,1683.62,1027.45z'
          />
          <path
            fill='#D50000'
            d='M2046.46,1335.86c3.91,0,7.16,3.6,7.19,7.95c0.03,4.6-2.89,7.83-7.09,7.85c-4.32,0.02-7.03-2.95-7.05-7.73
			C2039.49,1339.44,2042.58,1335.86,2046.46,1335.86z'
          />
          <path
            fill='#D50000'
            d='M2024.59,1335.86c3.93-0.07,7.14,3.48,7.15,7.92c0.01,4.83-2.66,7.85-6.98,7.88
			c-4.18,0.03-7.13-3.13-7.21-7.73C2017.48,1339.56,2020.64,1335.94,2024.59,1335.86z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1101.06c-0.07,4.79-2.84,7.77-7.17,7.72c-4.07-0.05-7.03-3.4-7.02-7.94
			c0.01-4.38,3.26-7.92,7.23-7.9C2028.67,1092.96,2031.81,1096.64,2031.75,1101.06z'
          />
          <path
            fill='#D50000'
            d='M2495.31,1199.34c0.03-4.53,3.11-8.19,6.92-8.21c3.5-0.02,7.18,4.11,7.27,8.15c0.09,4.22-3.75,8.57-7.45,8.43
			C2498.23,1207.57,2495.28,1203.91,2495.31,1199.34z'
          />
          <path
            fill='#D50000'
            d='M967.6,980.23c-0.07,4.25-3.48,8.09-7.16,8.07c-3.92-0.02-7.29-4.06-7.16-8.56c0.14-4.51,3.2-7.6,7.4-7.47
			C964.86,972.4,967.68,975.64,967.6,980.23z'
          />
          <path
            fill='#D50000'
            d='M1538.8,420.18c-0.04-4.6,2.87-8.04,6.8-8.02c4.19,0.02,7.48,3.69,7.39,8.22c-0.08,3.92-3.75,7.68-7.44,7.62
			C1541.17,427.93,1538.84,425.24,1538.8,420.18z'
          />
          <path
            fill='#D50000'
            d='M1836.35,687.55c-0.05,4.77-2.77,7.78-6.99,7.73c-3.94-0.05-7.2-3.63-7.19-7.88c0.01-4.24,3.73-8.1,7.7-7.98
			C1834.02,679.55,1836.4,682.53,1836.35,687.55z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1050.61c0.08,4.6-2.67,7.91-6.71,8.07c-4.24,0.17-7.44-3.19-7.48-7.86
			c-0.04-4.32,3.09-7.88,7.01-7.97C2028.46,1042.75,2031.67,1046.23,2031.75,1050.61z'
          />
          <path
            fill='#D50000'
            d='M2335.07,808.52c-0.06,4.55-3.19,8.22-6.95,8.13c-3.74-0.09-7.42-4.53-7.24-8.73
			c0.18-4.11,3.9-7.97,7.54-7.81C2332.23,800.28,2335.13,803.94,2335.07,808.52z'
          />
          <path
            fill='#D50000'
            d='M2248.2,930.75c0.09,4.33-3.02,8.15-6.84,8.39c-3.75,0.23-7.12-3.43-7.23-7.87c-0.11-4.65,3.18-8.77,6.94-8.7
			C2244.69,922.65,2248.11,926.57,2248.2,930.75z'
          />
          <path
            fill='#D50000'
            d='M2132.96,1205.74c-4.19-0.03-7.07-3.14-7.14-7.71c-0.06-4.21,3.26-8.07,7.02-8.15
			c4.02-0.09,7.62,3.86,7.51,8.22C2140.25,1202.21,2136.8,1205.77,2132.96,1205.74z'
          />
          <path
            fill='#D50000'
            d='M2141.07,590.2c0.05,3.97-3.56,8.07-7.15,8.13c-3.83,0.07-7.24-3.47-7.46-7.75c-0.2-3.95,3.23-7.98,6.89-8.09
			C2137.72,582.36,2141.02,585.65,2141.07,590.2z'
          />
          <path
            fill='#D50000'
            d='M1901.89,1319.47c0.05,4.26-3.19,7.65-7.34,7.67c-4.07,0.03-7.37-3.31-7.46-7.53
			c-0.09-4.26,3.69-8.41,7.55-8.31C1898.16,1311.4,1901.85,1315.55,1901.89,1319.47z'
          />
          <path
            fill='#D50000'
            d='M1531.09,420.31c-0.09,5.03-2.89,7.88-7.53,7.69c-3.66-0.15-6.71-3.64-6.72-7.7
			c-0.01-4.53,3.36-8.2,7.47-8.15C1528.2,412.21,1531.17,415.78,1531.09,420.31z'
          />
          <path
            fill='#D50000'
            d='M1531.04,541.18c0.07,4.79-2.71,8.12-6.79,8.13c-3.98,0-7.29-3.47-7.41-7.79c-0.11-4.07,3.31-7.96,7.08-8.05
			C1528.14,533.37,1530.97,536.43,1531.04,541.18z'
          />
          <path
            fill='#D50000'
            d='M2162.38,1099.15c0.05,4.5-3.4,8.65-7.07,8.53c-3.67-0.12-6.99-4.03-6.98-8.22c0.01-4.84,2.62-7.8,6.94-7.85
			C2159.75,1091.55,2162.32,1094.29,2162.38,1099.15z'
          />
          <path
            fill='#D50000'
            d='M720.52,1042.84c4.18-0.02,7.32,3.2,7.38,7.6c0.05,3.8-3.7,8.13-7.15,8.24c-3.92,0.13-7.69-3.93-7.61-8.19
			C713.22,1046.16,716.4,1042.86,720.52,1042.84z'
          />
          <path
            fill='#D50000'
            d='M1828.99,938.58c-4.25-0.1-6.9-3.19-6.84-7.96c0.05-4.17,3.51-8.19,7.03-8.18c3.83,0.01,7.26,4.12,7.16,8.59
			C1836.24,935.54,1833.19,938.67,1828.99,938.58z'
          />
          <path
            fill='#D50000'
            d='M2154.55,1205.74c-4-0.14-6.95-3.64-6.8-8.07c0.14-4.21,3.66-7.9,7.41-7.78c3.84,0.13,7.16,3.89,7.13,8.09
			C2162.26,1202.3,2158.69,1205.88,2154.55,1205.74z'
          />
          <path
            fill='#D50000'
            d='M2162.38,1002.5c-0.04,4.34-3.33,8.14-7,8.09c-3.93-0.05-7.18-4.05-7.05-8.67c0.13-4.37,3.5-7.9,7.38-7.73
			C2159.66,994.37,2162.42,997.8,2162.38,1002.5z'
          />
          <path
            fill='#D50000'
            d='M2162.38,929.96c-0.07,4.58-3.24,8.19-7.03,8.01c-3.97-0.19-7.02-3.74-7.02-8.19
			c0.01-4.58,3.6-8.65,7.41-8.38C2159.45,921.66,2162.44,925.52,2162.38,929.96z'
          />
          <path
            fill='#D50000'
            d='M1748.48,930.56c0.04,4.98-2.43,7.82-6.87,7.9c-4.37,0.08-7.12-2.86-7.2-7.7c-0.06-4.19,3.33-8.21,7.01-8.31
			C1745.09,922.34,1748.45,926.2,1748.48,930.56z'
          />
          <path
            fill='#D50000'
            d='M1119.42,1585.47c0.11,4.52-2.76,7.91-6.82,8.05c-4.03,0.14-7.62-3.57-7.63-7.89
			c-0.02-4.24,3.19-7.79,7.18-7.94C1116.02,1577.53,1119.31,1581.06,1119.42,1585.47z'
          />
          <path
            fill='#D50000'
            d='M2105.42,929.53c0.02-4.37,3.29-8.17,7-8.13c3.66,0.04,6.99,3.95,7.05,8.27c0.07,4.47-3.55,8.51-7.45,8.33
			C2108.21,937.82,2105.4,934.21,2105.42,929.53z'
          />
          <path
            fill='#D50000'
            d='M1814.38,930.51c0.06,4.93-2.34,7.76-6.73,7.94c-4.52,0.19-7.42-2.9-7.41-7.88c0.01-4.09,3.51-8.09,7.11-8.14
			C1811,922.39,1814.33,926.22,1814.38,930.51z'
          />
          <path
            fill='#D50000'
            d='M2097.56,929.12c-0.06,4.82-2.97,8.14-6.98,7.96c-3.99-0.18-7.07-3.69-7.13-8.11
			c-0.05-3.99,3.52-7.77,7.3-7.74C2095.15,921.26,2097.63,924.13,2097.56,929.12z'
          />
          <path
            fill='#D50000'
            d='M670.14,907.73c-0.1-4.83,2.56-7.83,6.96-7.84c4.28-0.01,6.88,2.65,7.09,7.26c0.21,4.64-3.04,8.67-7,8.7
			C673.34,915.88,670.24,912.3,670.14,907.73z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1634.79c0,5.15-2.22,7.69-6.74,7.75c-3.78,0.05-7.35-3.61-7.42-7.6c-0.08-4.42,3.4-8.27,7.44-8.24
			C1333.85,1626.73,1336.63,1630.07,1336.62,1634.79z'
          />
          <path
            fill='#D50000'
            d='M1285.82,1642.53c-3.8-0.06-7.2-3.82-7.14-7.89c0.07-4.23,3.43-7.88,7.31-7.94c3.93-0.06,7.44,4.03,7.23,8.42
			C1293,1639.75,1290.16,1642.59,1285.82,1642.53z'
          />
          <path
            fill='#D50000'
            d='M2213.94,590.29c-0.05-4.92,2.47-7.77,6.9-7.8c3.82-0.02,7.3,3.66,7.31,7.73c0.01,4.24-3.3,7.99-7.17,8.1
			C2217.07,598.45,2214,594.94,2213.94,590.29z'
          />
          <path
            fill='#D50000'
            d='M2278,930.72c0.04-4.3,3.43-8.23,6.96-8.07c3.5,0.17,7.17,4.36,7.19,8.21c0.03,4.24-3.75,8.35-7.55,8.24
			C2280.93,938.99,2277.96,935.23,2278,930.72z'
          />
          <path
            fill='#D50000'
            d='M2090.08,1205.74c-4.21,0-7.11-3.08-7.21-7.66c-0.09-4.18,3.24-8.06,7.01-8.19c4.01-0.13,7.68,3.82,7.58,8.17
			C2097.37,1202.14,2093.91,1205.75,2090.08,1205.74z'
          />
          <path
            fill='#D50000'
            d='M2335.07,931.31c-0.06,4.16-3.11,7.72-6.68,7.79c-3.87,0.08-7.62-4.06-7.53-8.31
			c0.09-3.9,3.83-8.04,7.33-8.11C2332.09,922.61,2335.14,926.44,2335.07,931.31z'
          />
          <path
            fill='#D50000'
            d='M873.36,1230.15c-3.92,0.08-7.62-3.98-7.55-8.29c0.06-3.88,3.38-7.4,7.09-7.51c4.27-0.13,7.89,3.49,7.79,7.78
			C880.59,1226.15,877,1230.07,873.36,1230.15z'
          />
          <path
            fill='#D50000'
            d='M901.8,1222.2c0,4.22-3.44,8.05-7.13,7.92c-3.87-0.14-7.12-3.86-7.12-8.15c0-4.36,3.23-7.68,7.4-7.62
			C899.39,1214.42,901.8,1217.18,901.8,1222.2z'
          />
          <path
            fill='#D50000'
            d='M1097.15,1634.67c0.06,5.09-2.31,7.83-6.8,7.86c-3.89,0.02-7.39-3.64-7.35-7.71
			c0.04-4.39,3.19-7.95,7.16-8.11C1094.18,1626.54,1097.09,1629.84,1097.15,1634.67z'
          />
          <path
            fill='#D50000'
            d='M2313.83,906.42c-0.02,3.72-3.94,8.22-7.24,8.3c-3.36,0.09-7.66-4.82-7.5-8.56c0.16-3.82,4.18-8.11,7.5-8.01
			C2310,898.25,2313.85,902.65,2313.83,906.42z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1637.14c-0.06-4.43,3.01-8.21,6.76-8.3c3.77-0.1,7.29,3.64,7.38,7.83c0.1,4.65-2.72,7.9-6.92,8
			C2042.36,1644.76,2039.58,1641.86,2039.52,1637.14z'
          />
          <path
            fill='#D50000'
            d='M2226.26,906.21c0.05,4.51-2.9,8.36-6.55,8.54c-3.69,0.19-7.5-4.07-7.53-8.4c-0.03-4.2,3.29-8.02,7.12-8.19
			C2223.08,898,2226.21,901.61,2226.26,906.21z'
          />
          <path
            fill='#D50000'
            d='M2487.99,930.71c0.08,3.97-3.34,8.19-6.79,8.37c-3.32,0.17-7.47-4.01-7.7-7.76
			c-0.23-3.82,3.81-8.63,7.29-8.68C2484.2,922.61,2487.91,926.75,2487.99,930.71z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1198.11c-0.05-4.3,3.09-8.1,6.77-8.21c3.83-0.11,7.23,3.46,7.38,7.73c0.17,4.87-2.73,8.14-7.19,8.11
			C2042,1205.71,2039.58,1203.05,2039.52,1198.11z'
          />
          <path
            fill='#D50000'
            d='M2090.55,913.81c-3.76-0.07-7.16-3.95-7.09-8.1c0.07-4.31,3.44-7.82,7.44-7.74c4.03,0.08,6.98,3.56,6.9,8.14
			C2097.72,910.76,2094.77,913.89,2090.55,913.81z'
          />
          <path
            fill='#D50000'
            d='M2061.47,905.4c0.08-4.88,2.66-7.54,7.24-7.45c4.38,0.08,7,3.11,6.88,7.94c-0.1,4.14-3.79,8.1-7.36,7.9
			C2064.52,913.58,2061.41,909.72,2061.47,905.4z'
          />
          <path
            fill='#D50000'
            d='M1068.75,1651.16c3.88,0.12,7.34,3.9,7.32,7.99c-0.03,4.48-3.6,8.06-7.81,7.83
			c-3.94-0.22-7.31-4.08-7.04-8.09C1061.5,1654.72,1065.09,1651.04,1068.75,1651.16z'
          />
          <path
            fill='#D50000'
            d='M2046.32,897.93c4.51-0.04,7.27,2.8,7.34,7.57c0.06,4.2-3.27,8.14-7.01,8.29c-3.65,0.14-7.08-3.7-7.14-8.02
			C2039.44,900.64,2041.76,897.97,2046.32,897.93z'
          />
          <path
            fill='#D50000'
            d='M2010.5,1222.47c0.12,4.22-3.14,7.68-7.29,7.73c-3.99,0.05-7.41-3.37-7.53-7.52
			c-0.12-3.95,3.37-8.07,7.04-8.31C2006.53,1214.12,2010.37,1218.12,2010.5,1222.47z'
          />
          <path
            fill='#D50000'
            d='M1075.88,1245.04c0.08,3.89-4.02,8.63-7.44,8.6c-3.28-0.03-7.24-4.42-7.32-8.15
			c-0.09-3.98,3.4-7.85,7.19-7.98C1072.33,1237.38,1075.79,1240.82,1075.88,1245.04z'
          />
          <path
            fill='#D50000'
            d='M1618.43,1245.89c-0.02,4.31-3.2,7.7-7.3,7.78c-3.85,0.08-7.27-3.53-7.34-7.73c-0.08-4.52,3.35-8.2,7.53-8.11
			C1615.12,1237.92,1618.45,1241.69,1618.43,1245.89z'
          />
          <path
            fill='#D50000'
            d='M1705.49,1246.22c-0.3,4.41-4.05,7.84-8.14,7.44c-3.65-0.35-6.58-4.05-6.42-8.11
			c0.17-4.52,3.81-8.11,7.82-7.73C1702.61,1238.19,1705.77,1242.12,1705.49,1246.22z'
          />
          <path
            fill='#D50000'
            d='M1053.29,882.51c-0.06,4-3.26,7.68-6.73,7.75c-4.08,0.09-7.51-3.63-7.48-8.11c0.03-4.44,3.04-7.69,7.12-7.7
			C1050.76,874.44,1053.36,877.4,1053.29,882.51z'
          />
          <path
            fill='#D50000'
            d='M866.64,590.32c-0.03-5,2.39-7.77,6.86-7.82c4.01-0.04,7.31,3.43,7.32,7.7c0.01,4.35-3.17,7.96-7.15,8.12
			C869.94,598.46,866.67,594.74,866.64,590.32z'
          />
          <path
            fill='#D50000'
            d='M1264.03,560.27c4.22-0.08,7.72,3.61,7.62,8.05c-0.1,4.29-3.39,7.76-7.39,7.79
			c-3.97,0.03-7.23-3.46-7.34-7.83C1256.82,563.98,1260.04,560.34,1264.03,560.27z'
          />
          <path
            fill='#D50000'
            d='M1858.31,614.97c0,4.46-3.07,8.02-6.89,8.01c-3.64-0.01-7.23-3.89-7.35-7.93c-0.12-4.13,3.2-7.86,7.05-7.91
			C1855.97,607.07,1858.31,609.62,1858.31,614.97z'
          />
          <path
            fill='#D50000'
            d='M1727.23,1245.88c-0.05,4.35-3.22,7.75-7.27,7.79c-3.86,0.04-7.28-3.53-7.4-7.72
			c-0.12-4.41,3.44-8.24,7.56-8.12C1723.93,1237.93,1727.27,1241.72,1727.23,1245.88z'
          />
          <path
            fill='#D50000'
            d='M1814.39,1197.96c0.01,4.92-2.49,7.72-6.95,7.77c-4.29,0.05-7.16-3.03-7.19-7.71
			c-0.03-4.38,3.17-8.04,7.09-8.11C1811.1,1189.83,1814.37,1193.57,1814.39,1197.96z'
          />
          <path
            fill='#D50000'
            d='M1793.11,1245.98c-0.1,4.4-3.24,7.68-7.37,7.69c-3.84,0.01-7.26-3.64-7.31-7.82
			c-0.06-4.49,3.51-8.22,7.67-8.03C1789.9,1238,1793.2,1241.84,1793.11,1245.98z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1245.57c0.08,4.72-2.65,8-6.73,8.1c-4.21,0.1-7.25-3.01-7.42-7.59c-0.16-4.35,2.95-8.11,6.85-8.26
			C1811.05,1237.66,1814.31,1241.13,1814.38,1245.57z'
          />
          <path
            fill='#D50000'
            d='M2024.55,607.14c4.4-0.03,7.28,3.03,7.21,7.67c-0.07,4.24-3.52,8.18-7.15,8.16
			c-3.75-0.02-7.02-3.79-7.05-8.12C2017.53,609.88,2019.98,607.17,2024.55,607.14z'
          />
          <path
            fill='#D50000'
            d='M2141.12,614.91c-0.1,3.89-3.92,8.08-7.37,8.07c-3.88-0.01-7.47-4.19-7.28-8.48
			c0.19-4.28,3.45-7.46,7.56-7.37C2138.2,607.22,2141.23,610.54,2141.12,614.91z'
          />
          <path
            fill='#D50000'
            d='M1068.5,1602.1c3.77,0.04,7.26,3.85,7.3,7.98c0.05,4.48-2.96,7.78-7.15,7.82c-4.27,0.04-7.73-3.63-7.53-8.01
			C1061.32,1605.62,1064.69,1602.06,1068.5,1602.1z'
          />
          <path
            fill='#D50000'
            d='M945.89,932.39c0,3.87-3.75,8.11-7.11,8.07c-3.51-0.05-7.3-4.18-7.39-8.04c-0.1-4.35,3.32-7.78,7.71-7.74
			C943.57,924.72,945.89,927.35,945.89,932.39z'
          />
          <path
            fill='#D50000'
            d='M1988.75,1173.53c-0.09,4.32-3.39,7.67-7.49,7.62c-4.14-0.06-7.45-3.55-7.36-7.77
			c0.1-4.34,3.93-8.32,7.79-8.09C1985.56,1165.51,1988.84,1169.34,1988.75,1173.53z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1588.23c-0.07-4.36,3.02-8.19,6.75-8.39c3.71-0.2,7.26,3.58,7.36,7.84
			c0.12,4.85-2.49,7.89-6.84,7.97C2064.13,1595.74,2061.56,1593.11,2061.48,1588.23z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1588.1c-0.05-4.33,3.13-8.17,6.85-8.26c3.73-0.09,7.22,3.71,7.29,7.96
			c0.08,4.73-2.69,7.82-7.02,7.85C2042.01,1595.68,2039.58,1593.1,2039.52,1588.1z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1588.01c-0.01,4.9-2.6,7.68-7.13,7.65c-4.33-0.03-7.15-3.13-7.06-7.8c0.08-4.35,3.33-7.99,7.16-8.03
			C2028.4,1579.81,2031.75,1583.7,2031.75,1588.01z'
          />
          <path
            fill='#D50000'
            d='M1169.7,734.99c0.09-4.94,2.73-7.76,7.12-7.62c3.75,0.12,7.21,4.07,7.08,8.11c-0.14,4.35-3.52,7.86-7.48,7.75
			C1172.35,743.11,1169.61,739.76,1169.7,734.99z'
          />
          <path
            fill='#D50000'
            d='M2249.85,566.3c-0.1,4.29-3.35,7.66-7.37,7.66c-3.76,0-7.26-3.8-7.26-7.88c0-4.49,3.57-8.18,7.69-7.96
			C2246.67,558.32,2249.95,562.19,2249.85,566.3z'
          />
          <path
            fill='#D50000'
            d='M2313.81,881.69c0.11,3.75-3.63,8.16-7.1,8.39c-3.26,0.21-7.39-4.05-7.63-7.88c-0.24-3.71,3.96-8.64,7.4-8.67
			C2309.66,873.49,2313.7,877.98,2313.81,881.69z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1173.6c-0.06-4.18,3.24-8.2,6.85-8.33c3.69-0.13,7.22,3.65,7.35,7.87c0.14,4.65-2.68,7.91-6.92,7.98
			C1824.92,1181.21,1822.21,1178.4,1822.15,1173.6z'
          />
          <path
            fill='#D50000'
            d='M2234.13,881.29c0.14-4.08,3.36-7.74,6.86-7.8c3.81-0.06,7.34,4.15,7.2,8.6c-0.13,4.28-3.54,8.01-7.29,7.97
			C2236.96,890.03,2233.97,886.15,2234.13,881.29z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1245.56c0.06-4.44,3.32-7.9,7.29-7.74c3.92,0.16,6.95,3.83,6.83,8.27
			c-0.12,4.58-3.14,7.68-7.38,7.57C2064.07,1253.56,2061.41,1250.36,2061.47,1245.56z'
          />
          <path
            fill='#D50000'
            d='M2090.25,1237.82c4,0.11,7.23,3.7,7.19,8c-0.04,4.4-3.67,8.05-7.78,7.84c-3.98-0.21-7.06-3.89-6.85-8.18
			C2083.02,1241.11,2086.32,1237.72,2090.25,1237.82z'
          />
          <path
            fill='#D50000'
            d='M2162.38,881.57c0.04,4.72-2.63,8-6.7,8.2c-3.96,0.2-7.23-3.22-7.35-7.66c-0.12-4.58,3.22-8.66,7.06-8.64
			C2159.1,873.49,2162.34,877.24,2162.38,881.57z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1245.68c0.05,4.13-3.34,7.94-7.12,7.99c-4.19,0.06-7.28-3.06-7.47-7.55
			c-0.19-4.4,3.24-8.33,7.25-8.3C2116.08,1237.85,2119.34,1241.34,2119.39,1245.68z'
          />
          <path
            fill='#D50000'
            d='M2105.42,881.44c0.06-4.33,3.37-8.04,7.11-7.96c3.66,0.07,6.95,3.99,6.96,8.28c0.01,4.33-3.2,8-7.04,8.08
			C2108.27,889.92,2105.34,886.43,2105.42,881.44z'
          />
          <path
            fill='#D50000'
            d='M2140.36,1245.66c0.08,4.06-3.36,7.94-7.11,8.01c-4.2,0.08-7.24-2.99-7.45-7.53
			c-0.2-4.37,3.23-8.33,7.21-8.31C2137,1237.83,2140.28,1241.33,2140.36,1245.66z'
          />
          <path
            fill='#D50000'
            d='M2147.71,1245.46c0.24-4.38,3.52-7.75,7.44-7.63c4.07,0.12,7.5,4.22,7.13,8.52
			c-0.37,4.27-3.81,7.51-7.76,7.32C2150.63,1253.48,2147.48,1249.68,2147.71,1245.46z'
          />
          <path
            fill='#D50000'
            d='M2175.94,1237.82c3.99,0.03,7.23,3.51,7.31,7.85c0.08,4.35-3.51,8.13-7.58,8c-3.97-0.13-7.11-3.73-7-8.04
			C2168.77,1241.33,2172.06,1237.8,2175.94,1237.82z'
          />
          <path
            fill='#D50000'
            d='M2112.67,1140.88c3.83,0.37,7.03,4.4,6.72,8.46c-0.34,4.42-4.11,7.8-8.23,7.37c-3.89-0.4-6.73-4.33-6.2-8.59
			C2105.55,1143.37,2108.62,1140.49,2112.67,1140.88z'
          />
          <path
            fill='#D50000'
            d='M2097.45,1148.73c0.08,4.05-3.39,7.93-7.13,7.99c-4.22,0.07-7.27-3-7.48-7.52c-0.2-4.36,3.26-8.33,7.25-8.33
			C2094.07,1140.88,2097.37,1144.4,2097.45,1148.73z'
          />
          <path
            fill='#D50000'
            d='M2313.85,858.45c0,3.7-3.9,8.22-7.2,8.34c-3.33,0.12-7.7-4.79-7.56-8.51c0.14-3.78,4.17-8.14,7.46-8.08
			C2309.9,850.27,2313.85,854.73,2313.85,858.45z'
          />
          <path
            fill='#D50000'
            d='M2198.98,1253.67c-4.14,0.01-7.21-3.22-7.33-7.7c-0.11-4.49,3.31-8.26,7.4-8.15c4,0.11,7.18,3.66,7.16,7.99
			C2206.2,1249.97,2202.8,1253.65,2198.98,1253.67z'
          />
          <path
            fill='#D50000'
            d='M1119.1,1683.58c0.01,5.07-2.38,7.86-6.81,7.94c-3.84,0.07-7.32-3.58-7.33-7.69c0-4.33,3.21-8.03,7.06-8.13
			C1116.1,1675.6,1119.1,1678.94,1119.1,1683.58z'
          />
          <path
            fill='#D50000'
            d='M2270.18,858.27c0.11,4.02-3.85,8.57-7.43,8.53c-3.49-0.04-6.59-3.68-6.69-7.85
			c-0.12-4.92,2.86-8.79,6.74-8.75C2266.23,850.23,2270.07,854.43,2270.18,858.27z'
          />
          <path
            fill='#D50000'
            d='M1184.5,1683.77c-0.04,4.52-3.03,7.74-7.22,7.77c-3.95,0.03-7.27-3.45-7.35-7.71
			c-0.09-4.45,3.43-8.24,7.54-8.13C1181.3,1675.8,1184.54,1679.52,1184.5,1683.77z'
          />
          <path
            fill='#D50000'
            d='M2228.17,1245.83c-0.02,4.18-3.4,7.83-7.25,7.84c-4.12,0.01-7.21-3.24-7.32-7.7
			c-0.12-4.48,3.34-8.28,7.42-8.14C2225.01,1237.96,2228.2,1241.53,2228.17,1245.83z'
          />
          <path
            fill='#D50000'
            d='M2170.28,857.56c0.05-4.97,2.65-7.8,7.08-7.71c4.25,0.08,7.04,3.28,6.96,7.99c-0.07,4.25-3.54,8.13-7.19,8.02
			C2173.46,865.77,2170.24,861.86,2170.28,857.56z'
          />
          <path
            fill='#D50000'
            d='M2141.09,565.92c0.17,4.11-3.13,7.87-7.05,8.04c-3.82,0.17-7.33-3.32-7.58-7.51
			c-0.26-4.38,3.16-8.31,7.27-8.34C2137.57,558.08,2140.91,561.63,2141.09,565.92z'
          />
          <path
            fill='#D50000'
            d='M2162.38,857.55c0.06,4.29-3.15,8.19-6.84,8.32c-3.63,0.12-7.13-3.76-7.21-8.01
			c-0.09-4.69,2.71-7.92,6.95-8.02C2159.68,849.74,2162.31,852.59,2162.38,857.55z'
          />
          <path
            fill='#D50000'
            d='M2133.81,850.04c4.17,0.01,7.47,3.36,7.43,7.54c-0.04,3.81-3.85,8.2-7.2,8.31c-3.75,0.12-7.65-4.11-7.59-8.25
			C2126.49,853.42,2129.78,850.03,2133.81,850.04z'
          />
          <path
            fill='#D50000'
            d='M1858.31,639.69c-0.04,5.06-2.4,7.65-7,7.68c-3.91,0.02-7.27-3.62-7.24-7.86c0.03-4.09,3.63-7.97,7.39-7.96
			C1855.41,631.56,1858.35,635.05,1858.31,639.69z'
          />
          <path
            fill='#D50000'
            d='M1097.02,565.64c0.12,3.92-3.55,8.17-7.16,8.29c-3.51,0.11-7.22-3.92-7.4-8.04c-0.2-4.55,2.68-7.73,7.05-7.81
			C1093.62,558.02,1096.89,561.31,1097.02,565.64z'
          />
          <path
            fill='#D50000'
            d='M1293.31,1585.45c0.15,4.32-2.94,7.91-6.94,8.07c-4.04,0.16-7.72-3.63-7.7-7.93c0.02-4.3,3.31-7.85,7.31-7.9
			C1289.91,1577.64,1293.17,1581.09,1293.31,1585.45z'
          />
          <path
            fill='#D50000'
            d='M2234.13,955.53c0-4.59,3.03-8.33,6.77-8.34c3.95-0.01,7.4,4.02,7.3,8.52c-0.09,4.41-3.38,8.09-7.19,8.06
			C2237.24,963.74,2234.14,960.02,2234.13,955.53z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1585.62c0.06,5.11-2.3,7.87-6.76,7.9c-3.83,0.02-7.34-3.71-7.32-7.78c0.03-4.41,3.22-8.01,7.15-8.06
			C1203.18,1577.62,1205.83,1580.66,1205.89,1585.62z'
          />
          <path
            fill='#D50000'
            d='M1184.53,1585.44c0.14,4.32-2.98,7.93-6.97,8.07c-3.9,0.14-7.35-3.23-7.64-7.46
			c-0.3-4.39,3.12-8.33,7.27-8.38C1181.11,1577.63,1184.39,1581.1,1184.53,1585.44z'
          />
          <path
            fill='#D50000'
            d='M1097.16,1585.79c0.02,4.99-2.39,7.72-6.85,7.73c-3.76,0.01-7.25-3.67-7.31-7.7
			c-0.07-4.47,3.39-8.25,7.45-8.14C1094.58,1577.79,1097.14,1580.88,1097.16,1585.79z'
          />
          <path
            fill='#D50000'
            d='M1075.75,1585.49c0.09,4.42-2.98,7.93-7.03,8.02c-3.98,0.09-7.39-3.28-7.6-7.51
			c-0.22-4.47,3.14-8.27,7.37-8.32C1072.45,1577.64,1075.66,1581.08,1075.75,1585.49z'
          />
          <path
            fill='#D50000'
            d='M836.9,857.15c0.04,4.77-3.13,8.71-6.91,8.61c-3.44-0.09-7.25-4.5-7.2-8.33c0.05-4.21,3.49-7.78,7.48-7.75
			C834.46,849.7,836.86,852.41,836.9,857.15z'
          />
          <path
            fill='#D50000'
            d='M866.63,565.48c0.06-5.12,2.37-7.51,7.15-7.37c4.05,0.11,6.94,3.16,7.04,7.41c0.1,4.42-3.62,8.63-7.42,8.41
			C869.82,573.72,866.59,569.68,866.63,565.48z'
          />
          <path
            fill='#D50000'
            d='M858.86,565.56c-0.05,4.41-3.19,8.24-6.87,8.38c-3.81,0.14-7.43-4.08-7.29-8.49
			c0.16-4.97,2.69-7.48,7.41-7.34C856.47,558.24,858.91,560.93,858.86,565.56z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1270.52c-0.04-4.21,3.25-8.2,6.87-8.29c3.7-0.1,7.21,3.68,7.32,7.9c0.13,4.65-2.7,7.89-6.96,7.96
			C1824.9,1278.15,1822.2,1275.33,1822.15,1270.52z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1270.49c-0.08,4.79-2.83,7.66-7.27,7.58c-4.23-0.07-7.02-3.28-6.92-7.95c0.09-4.24,3.56-8,7.28-7.9
			C2028.44,1262.32,2031.81,1266.35,2031.75,1270.49z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1560.87c0.07-4.49,3.21-7.91,7.19-7.82c3.72,0.09,6.98,3.98,6.89,8.22
			c-0.1,4.63-3.08,7.73-7.31,7.61C1129.4,1568.77,1126.85,1565.75,1126.93,1560.87z'
          />
          <path
            fill='#D50000'
            d='M2132.99,1546.65c-4.24-0.03-7.18-3.19-7.21-7.74c-0.02-4.51,3.45-8.26,7.5-8.1
			c3.85,0.16,7.14,3.94,7.06,8.13C2140.26,1543.23,2136.98,1546.67,2132.99,1546.65z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1538.8c0.01,4.25-3.27,7.82-7.22,7.85c-4.25,0.03-7.28-3.07-7.39-7.58
			c-0.11-4.51,3.31-8.32,7.38-8.23C2116.1,1530.92,2119.38,1534.54,2119.39,1538.8z'
          />
          <path
            fill='#D50000'
            d='M684.19,955.54c-0.05,4.45-3.24,8.22-6.99,8.25c-4.02,0.03-7.17-3.81-7.06-8.61
			c0.11-4.62,2.67-7.34,6.93-7.37C681.55,947.79,684.25,950.72,684.19,955.54z'
          />
          <path
            fill='#D50000'
            d='M1046.37,1302.76c-4.55-0.21-6.53-2.93-6.31-8.64c0.15-3.81,3.76-7.44,7.2-7.26c3.74,0.2,7.31,4.72,6.99,8.84
			C1053.92,1300.01,1050.64,1302.95,1046.37,1302.76z'
          />
          <path
            fill='#D50000'
            d='M2097.56,977c-0.06,5-2.74,8.11-6.91,8c-4.01-0.11-7.17-3.64-7.2-8.03c-0.03-4.08,3.46-7.83,7.26-7.81
			C2095.22,969.19,2097.62,971.94,2097.56,977z'
          />
          <path
            fill='#D50000'
            d='M1184.51,1294.82c0.01,4.42-3.06,7.78-7.17,7.84c-3.9,0.06-7.28-3.43-7.42-7.66
			c-0.15-4.44,3.34-8.25,7.5-8.18C1181.28,1286.89,1184.5,1290.52,1184.51,1294.82z'
          />
          <path
            fill='#D50000'
            d='M1654.36,1286.83c3.81-0.14,7.21,3.5,7.32,7.83c0.12,4.7-2.65,7.92-6.87,8.01c-4.45,0.09-7.1-2.68-7.19-7.53
			C1647.52,1290.69,1650.55,1286.97,1654.36,1286.83z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1148.95c-0.03,4.71-2.83,7.8-7.05,7.76c-4.17-0.04-7.07-3.28-7.09-7.9
			c-0.02-4.35,3.25-7.96,7.18-7.94C1811.34,1140.89,1814.41,1144.45,1814.38,1148.95z'
          />
          <path
            fill='#D50000'
            d='M1785.81,1156.72c-3.9,0.03-7.29-3.52-7.39-7.74c-0.09-4.24,3.18-7.97,7.1-8.1c4.04-0.14,7.67,3.72,7.61,8.08
			C1793.07,1153.29,1789.87,1156.68,1785.81,1156.72z'
          />
          <path
            fill='#D50000'
            d='M2248.2,978.89c-0.06,4.31-3.34,8.02-7.15,8.1c-3.94,0.08-7.02-3.76-6.91-8.62c0.1-4.32,3.6-8.17,7.22-7.93
			C2245.06,970.69,2248.26,974.64,2248.2,978.89z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1538.81c0.02-4.43,3.21-8.03,7.07-7.99c3.81,0.05,7.08,3.81,7.05,8.09
			c-0.04,4.69-2.89,7.77-7.16,7.74C2064.09,1546.61,2061.45,1543.63,2061.48,1538.81z'
          />
          <path
            fill='#D50000'
            d='M945.69,640.28c0.05,4.53-2.92,8.13-6.76,8.19c-3.75,0.06-7.32-3.69-7.52-7.89c-0.18-3.86,3.45-7.8,7.26-7.88
			C943.26,632.61,945.63,635.17,945.69,640.28z'
          />
          <path
            fill='#D50000'
            d='M2299.09,978.65c0.1-3.64,4.18-8.14,7.41-8.2c3.34-0.05,7.48,4.86,7.32,8.68c-0.16,3.83-4.19,8-7.6,7.86
			C2302.82,986.85,2298.99,982.37,2299.09,978.65z'
          />
          <path
            fill='#D50000'
            d='M1727.23,1148.69c0.13,4.2-3.11,7.89-7.05,8.02c-3.88,0.13-7.35-3.28-7.62-7.5
			c-0.28-4.37,3.21-8.33,7.34-8.34C1723.73,1140.86,1727.1,1144.46,1727.23,1148.69z'
          />
          <path
            fill='#D50000'
            d='M1705.49,1294.78c0.01,4.14-3.37,7.84-7.19,7.88c-4.22,0.05-7.19-3.01-7.35-7.59
			c-0.16-4.43,3.33-8.35,7.33-8.24C1702.25,1286.95,1705.49,1290.51,1705.49,1294.78z'
          />
          <path
            fill='#D50000'
            d='M1719.77,1286.83c3.76-0.13,7.24,3.49,7.45,7.73c0.21,4.27-2.92,7.95-6.88,8.1c-4.18,0.16-7.75-3.41-7.78-7.8
			C1712.52,1290.77,1715.94,1286.96,1719.77,1286.83z'
          />
          <path
            fill='#D50000'
            d='M2024.75,1718.35c-4.15,0.04-7.22-3.24-7.19-7.68c0.03-4.43,3.2-8.1,7.04-8.16c3.81-0.06,7.14,3.69,7.15,8.02
			C2031.75,1715.37,2029.12,1718.3,2024.75,1718.35z'
          />
          <path
            fill='#D50000'
            d='M1213.73,663.33c-0.03-4.96,2.63-8.29,6.63-8.29c4.19,0,7.53,3.73,7.45,8.32c-0.06,3.73-3.55,7.48-7.01,7.55
			C1216.28,671,1213.76,668.29,1213.73,663.33z'
          />
          <path
            fill='#D50000'
            d='M1075.24,1732.89c0.02,4.99-2.34,7.62-6.89,7.66c-4.19,0.03-7.29-3.16-7.31-7.53
			c-0.03-4.57,3.28-8.31,7.34-8.3C1071.99,1724.72,1075.21,1728.56,1075.24,1732.89z'
          />
          <path
            fill='#D50000'
            d='M1286.09,1545.6c-3.9,0.02-7.27-3.47-7.4-7.65c-0.14-4.38,3.45-8.29,7.52-8.2c3.78,0.09,7.13,3.95,7.04,8.14
			C1293.16,1542.53,1290.32,1545.58,1286.09,1545.6z'
          />
          <path
            fill='#D50000'
            d='M1264.19,1529.76c3.96-0.1,7.4,3.43,7.53,7.71c0.13,4.06-3.29,8.02-7,8.11c-4.52,0.11-7.18-2.62-7.32-7.51
			C1257.27,1533.56,1260.29,1529.86,1264.19,1529.76z'
          />
          <path
            fill='#D50000'
            d='M866.63,833.15c0.02-4.9,2.63-7.77,7.03-7.72c3.78,0.04,7.2,3.84,7.16,7.97c-0.04,4.24-3.4,7.81-7.39,7.84
			C869.34,841.28,866.61,838.03,866.63,833.15z'
          />
          <path
            fill='#D50000'
            d='M1705.49,1149c-0.11,4.19-3.51,7.76-7.37,7.72c-4.12-0.04-7.15-3.34-7.18-7.82
			c-0.03-4.51,3.45-8.23,7.51-8.02C1702.42,1141.08,1705.6,1144.74,1705.49,1149z'
          />
          <path
            fill='#D50000'
            d='M1618.43,1148.86c0.01,4.31-3.2,7.8-7.22,7.86c-3.91,0.06-7.34-3.47-7.44-7.65c-0.11-4.5,3.33-8.26,7.48-8.19
			C1615.1,1140.95,1618.42,1144.64,1618.43,1148.86z'
          />
          <path
            fill='#D50000'
            d='M814.93,833.5c-0.03,4.6-2.62,7.67-6.53,7.75c-4.11,0.09-7.73-3.83-7.55-8.19c0.17-4.09,3.81-7.79,7.54-7.66
			C812.73,825.56,814.96,828.32,814.93,833.5z'
          />
          <path
            fill='#D50000'
            d='M786.53,841.24c-3.97,0.12-7.36-3.32-7.54-7.66c-0.17-4.03,3.28-8.07,6.99-8.18
			c4.22-0.13,7.78,3.46,7.78,7.85C793.76,837.35,790.35,841.12,786.53,841.24z'
          />
          <path
            fill='#D50000'
            d='M2024.45,671.99c-3.99-0.07-7.01-3.59-6.89-8.02c0.11-4.33,3.39-7.82,7.35-7.82c3.75,0,6.94,3.85,6.84,8.24
			C2031.64,669.06,2028.75,672.07,2024.45,671.99z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1537.73c0.06,5.07-2.34,7.82-6.84,7.85c-3.88,0.02-7.39-3.68-7.34-7.72c0.06-4.4,3.21-7.95,7.18-8.1
			C1094.18,1529.6,1097.12,1532.94,1097.17,1537.73z'
          />
          <path
            fill='#D50000'
            d='M2141.1,808.89c0.07,4.27-3.14,7.87-7.12,7.97c-4.04,0.11-7.61-3.73-7.53-8.09c0.08-4.29,3.38-7.74,7.42-7.75
			C2137.78,801.01,2141.03,804.55,2141.1,808.89z'
          />
          <path
            fill='#D50000'
            d='M2097.83,808.95c0.01,4.65-2.87,7.88-7.06,7.91c-3.85,0.02-7.26-3.65-7.31-7.87
			c-0.05-4.3,3.23-7.92,7.22-7.97C2094.74,800.97,2097.82,804.38,2097.83,808.95z'
          />
          <path
            fill='#D50000'
            d='M2039.52,808.75c-0.07-4.91,2.36-7.77,6.73-7.91c4.47-0.15,7.46,3.06,7.41,7.94
			c-0.04,4.12-3.55,8.06-7.16,8.06C2042.87,816.84,2039.58,813.01,2039.52,808.75z'
          />
          <path
            fill='#D50000'
            d='M1829.12,800.78c4.15-0.01,7.23,3.28,7.22,7.72c-0.01,4.55-3.46,8.48-7.32,8.35
			c-3.6-0.13-6.83-4.03-6.87-8.29C1822.11,803.76,1824.78,800.78,1829.12,800.78z'
          />
          <path
            fill='#D50000'
            d='M2089.74,1302.68c-4.09-0.11-6.98-3.48-6.85-7.98c0.13-4.27,3.55-7.94,7.32-7.86
			c3.86,0.08,7.21,3.79,7.23,8.01C2097.47,1299.09,2093.83,1302.79,2089.74,1302.68z'
          />
          <path
            fill='#D50000'
            d='M2112.03,1302.68c-4.23-0.02-7.1-3.09-7.18-7.67c-0.07-4.21,3.23-8.06,7-8.18c3.99-0.12,7.65,3.85,7.55,8.19
			C2119.3,1299.1,2115.83,1302.7,2112.03,1302.68z'
          />
          <path
            fill='#D50000'
            d='M858.86,808.72c0.04,4.3-3.21,8.08-6.98,8.13c-3.67,0.06-7.14-3.82-7.17-8.02c-0.04-4.81,2.64-7.79,7.01-7.82
			C856.05,800.99,858.81,803.97,858.86,808.72z'
          />
          <path
            fill='#D50000'
            d='M786.46,801.02c4.19,0.05,7.31,3.35,7.3,7.72c-0.01,3.95-3.64,8.03-7.22,8.12c-3.9,0.09-7.72-4.05-7.57-8.24
			C779.12,804.28,782.38,800.97,786.46,801.02z'
          />
          <path
            fill='#D50000'
            d='M720.59,816.86c-3.7,0.05-7.29-3.71-7.47-7.81c-0.18-4.21,3.05-7.89,7.03-8.03c4.43-0.15,7.8,3.24,7.72,7.78
			C727.8,812.83,724.21,816.8,720.59,816.86z'
          />
          <path
            fill='#D50000'
            d='M1205.91,1318.42c0.04,5.08-2.37,7.81-6.9,7.82c-3.9,0.01-7.05-3.22-7.19-7.38c-0.15-4.44,3.35-8.7,7.17-8.72
			C1202.47,1310.12,1205.87,1314.19,1205.91,1318.42z'
          />
          <path
            fill='#D50000'
            d='M2293.14,665.08c0.09,4.42-2.95,7.91-6.96,7.98c-4.33,0.08-7.1-2.87-7.18-7.63
			c-0.07-4.37,3.15-8.22,6.85-8.19C2289.79,657.27,2293.05,660.77,2293.14,665.08z'
          />
          <path
            fill='#D50000'
            d='M1169.67,687.53c-0.09-4.93,2.47-8.03,6.7-8.11c3.81-0.08,7.32,3.51,7.52,7.68c0.2,4.17-3.12,8.05-7.01,8.18
			C1172.78,695.42,1169.75,692.16,1169.67,687.53z'
          />
          <path
            fill='#D50000'
            d='M2097.45,1100.93c-0.03,4.19-3.41,7.84-7.26,7.85c-4.2,0.01-7.23-3.15-7.36-7.66
			c-0.13-4.48,3.32-8.28,7.41-8.18C2094.2,1093.04,2097.47,1096.66,2097.45,1100.93z'
          />
          <path
            fill='#D50000'
            d='M1198.41,695.28c-4,0.12-7.31-3.23-7.52-7.62c-0.19-4.05,3.17-8.05,6.89-8.22c4.36-0.2,7.66,3.16,7.68,7.84
			C1205.49,691.52,1202.28,695.16,1198.41,695.28z'
          />
          <path
            fill='#D50000'
            d='M1227.8,687.61c-0.14,4.33-3.55,7.81-7.52,7.67c-4.03-0.15-6.83-3.6-6.71-8.27c0.13-4.76,2.92-7.68,7.26-7.59
			C1224.45,679.49,1227.93,683.58,1227.8,687.61z'
          />
          <path
            fill='#D50000'
            d='M2270.18,808.09c0.13,3.97-3.83,8.61-7.35,8.61c-3.38,0-6.65-3.78-6.76-7.81c-0.14-4.88,2.82-8.77,6.67-8.79
			C2266.17,800.07,2270.05,804.24,2270.18,808.09z'
          />
          <path
            fill='#D50000'
            d='M1538.8,541.27c0.06-4.95,2.64-7.85,6.95-7.8c3.77,0.05,7.36,4,7.24,7.96c-0.14,4.48-3.24,7.8-7.36,7.87
			C1541.52,549.37,1538.74,546.1,1538.8,541.27z'
          />
          <path
            fill='#D50000'
            d='M2226.25,808.19c0.04,4.49-2.93,8.33-6.58,8.51c-3.67,0.17-7.48-4.11-7.49-8.42
			c-0.01-4.14,3.38-8.01,7.15-8.15C2223.11,799.99,2226.21,803.59,2226.25,808.19z'
          />
          <path
            fill='#D50000'
            d='M2031.75,687.31c-0.02,4.42-3.23,8.03-7.1,7.97c-3.91-0.06-7.15-3.71-7.1-8.02c0.05-4.39,3.22-7.84,7.17-7.82
			C2028.85,679.46,2031.77,682.73,2031.75,687.31z'
          />
          <path
            fill='#D50000'
            d='M1199.55,511.28c3.92-0.02,7.25,3.55,7.31,7.84c0.06,4.2-3.26,7.94-7.07,7.98c-4.39,0.04-6.93-2.77-7-7.74
			C1192.72,514.5,1195.39,511.31,1199.55,511.28z'
          />
          <path
            fill='#D50000'
            d='M1170.84,519.35c-0.05-4.87,2.6-8.03,6.76-8.06c3.92-0.03,7.19,3.5,7.29,7.89c0.1,4.13-3.31,7.91-7.14,7.93
			C1173.23,527.11,1170.9,524.5,1170.84,519.35z'
          />
          <path
            fill='#D50000'
            d='M1858.31,979.81c-0.01,4.68-2.8,7.74-7.08,7.78c-4.46,0.04-7.25-3.17-7.15-8.21c0.08-3.89,3.4-7.71,6.83-7.87
			C1854.85,971.31,1858.32,975.21,1858.31,979.81z'
          />
          <path
            fill='#D50000'
            d='M1560.74,518.11c-0.06-5.22,2.06-7.77,6.58-7.92c4.16-0.14,7.67,3.58,7.55,8c-0.11,4.09-3.61,7.8-7.4,7.84
			C1563.3,526.08,1560.8,523.14,1560.74,518.11z'
          />
          <path
            fill='#D50000'
            d='M1292.95,1513.15c0.07,4.99-2.43,7.83-6.89,7.83c-4.02,0-7.33-3.49-7.37-7.76c-0.03-4.13,3.41-7.98,7.24-8.09
			C1289.77,1505.02,1292.89,1508.59,1292.95,1513.15z'
          />
          <path
            fill='#D50000'
            d='M1068.7,1520.97c-4.11,0.06-7.4-3.19-7.57-7.48c-0.17-4.32,3.37-8.34,7.34-8.34c3.79,0,7.26,3.77,7.32,7.95
			C1075.86,1517.59,1072.86,1520.91,1068.7,1520.97z'
          />
          <path
            fill='#D50000'
            d='M1814.39,1101.03c-0.03,4.77-2.78,7.78-7.06,7.75c-4.21-0.03-7.05-3.21-7.09-7.9
			c-0.03-4.3,3.28-7.96,7.18-7.94C1811.33,1092.96,1814.41,1096.54,1814.39,1101.03z'
          />
          <path
            fill='#D50000'
            d='M1793.1,1100.79c0.13,4.25-3.06,7.85-7.06,7.99c-3.95,0.13-7.4-3.25-7.64-7.49
			c-0.25-4.38,3.26-8.35,7.37-8.34C1789.69,1092.97,1792.97,1096.48,1793.1,1100.79z'
          />
          <path
            fill='#D50000'
            d='M2119.47,1002.07c0.11,4.53-3.34,8.61-7.22,8.54c-3.53-0.06-6.71-3.66-6.83-7.74
			c-0.15-5.03,2.59-8.52,6.79-8.64C2116.11,994.11,2119.36,997.63,2119.47,1002.07z'
          />
          <path
            fill='#D50000'
            d='M1719.95,1092.94c3.82,0.02,7.19,3.67,7.28,7.9c0.09,4.54-3.26,8.03-7.61,7.94
			c-3.79-0.08-7.19-3.95-7.06-8.04C1712.68,1096.4,1715.98,1092.93,1719.95,1092.94z'
          />
          <path
            fill='#D50000'
            d='M779.02,688.09c0.04-4.01,3.67-7.61,7.58-7.51c4.07,0.1,7.32,3.72,7.12,7.93c-0.19,4.07-4.05,8.06-7.6,7.87
			C782.42,696.19,778.99,692.18,779.02,688.09z'
          />
          <path
            fill='#D50000'
            d='M1705.5,1100.86c0.02,4.19-3.31,7.88-7.16,7.92c-4.21,0.05-7.24-3.06-7.39-7.59
			c-0.15-4.46,3.26-8.32,7.3-8.25C1702.21,1093.01,1705.48,1096.58,1705.5,1100.86z'
          />
          <path
            fill='#D50000'
            d='M720.78,1010.75c-3.71,0.14-7.38-3.53-7.66-7.65c-0.28-4.17,2.92-7.98,6.88-8.18c4.45-0.23,7.82,3,7.9,7.58
			C727.97,1006.32,724.26,1010.62,720.78,1010.75z'
          />
          <path
            fill='#D50000'
            d='M1618.41,1100.77c0.13,4.33-2.94,7.83-7.03,8.02c-3.9,0.17-7.37-3.23-7.61-7.48
			c-0.26-4.39,3.18-8.32,7.32-8.36C1614.95,1092.91,1618.28,1096.47,1618.41,1100.77z'
          />
          <path
            fill='#D50000'
            d='M1031.38,1513.37c-0.02,5.04-2.39,7.64-6.95,7.61c-3.9-0.03-7.3-3.62-7.31-7.73
			c-0.01-4.31,3.69-8.28,7.55-8.11C1028.58,1505.31,1031.4,1508.77,1031.38,1513.37z'
          />
          <path
            fill='#D50000'
            d='M727.86,1100.93c0.01,4.5-3.42,7.95-7.77,7.86c-3.58-0.08-6.84-3.68-6.95-7.68
			c-0.12-4.48,3.32-8.21,7.53-8.16C724.52,1092.99,727.85,1096.68,727.86,1100.93z'
          />
          <path
            fill='#D50000'
            d='M2177.52,1091.63c4.36,0.12,7.03,3.29,6.81,8.08c-0.2,4.29-3.76,8.15-7.36,7.96
			c-3.57-0.19-6.73-4.22-6.68-8.54C2170.34,1094.24,2172.97,1091.51,2177.52,1091.63z'
          />
          <path
            fill='#D50000'
            d='M2105.42,785.74c0.02-4.28,3.23-8.16,6.79-8.22c3.76-0.07,7.28,4.05,7.27,8.5c-0.01,4.51-3.08,7.73-7.27,7.62
			C2107.9,793.53,2105.39,790.62,2105.42,785.74z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1491.15c-0.03-4.37,3.1-8.13,6.88-8.26c3.7-0.13,7.19,3.71,7.24,7.97c0.06,4.82-2.6,7.82-6.98,7.86
			C2064.1,1498.77,2061.51,1496.03,2061.47,1491.15z'
          />
          <path
            fill='#D50000'
            d='M1531.04,518.1c-0.01,4.58-2.99,7.94-7.02,7.94c-3.77-0.01-7.13-3.75-7.18-8c-0.05-4.23,3.29-7.83,7.27-7.85
			C1528.33,510.18,1531.06,513.28,1531.04,518.1z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1003.23c-0.02,4.88-2.55,7.68-6.98,7.71c-4.45,0.03-7.29-3.21-7.16-8.18
			c0.11-4.31,3.52-7.91,7.35-7.75C1811.43,995.16,1814.4,998.76,1814.38,1003.23z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1490.93c0.01-4.33,3.33-8.1,7.07-8.04c3.74,0.06,7.1,3.96,7.07,8.19c-0.04,4.82-2.78,7.71-7.24,7.64
			C2041.82,1498.65,2039.5,1496.04,2039.52,1490.93z'
          />
          <path
            fill='#D50000'
            d='M2097.43,1343.72c0.06,4.18-3.27,7.88-7.13,7.94c-4.28,0.06-7.25-2.93-7.45-7.51
			c-0.19-4.45,3.2-8.31,7.28-8.29C2094.11,1335.89,2097.37,1339.39,2097.43,1343.72z'
          />
          <path
            fill='#D50000'
            d='M2111.8,1335.87c3.99-0.13,7.34,3.23,7.58,7.59c0.22,4.13-3.05,8.06-6.84,8.21
			c-4.47,0.18-7.68-3.03-7.73-7.72C2104.77,1339.66,2107.95,1335.99,2111.8,1335.87z'
          />
          <path
            fill='#D50000'
            d='M2140.33,1343.64c0.12,4.14-3.22,7.96-7.02,8.03c-4.31,0.08-7.29-2.85-7.53-7.42
			c-0.23-4.37,3.17-8.35,7.15-8.38C2136.91,1335.84,2140.21,1339.31,2140.33,1343.64z'
          />
          <path
            fill='#D50000'
            d='M1582.71,493.43c0.04-5.13,2.4-7.87,6.76-7.88c3.71,0,7.3,3.85,7.34,7.88c0.04,4.17-3.35,7.88-7.28,7.95
			C1585.4,501.46,1582.67,498.28,1582.71,493.43z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1491.17c-0.06,4.92-2.64,7.61-7.23,7.55c-4.28-0.05-7.09-3.24-6.96-7.9
			c0.12-4.32,3.46-7.97,7.27-7.93C2028.5,1482.93,2031.8,1486.88,2031.75,1491.17z'
          />
          <path
            fill='#D50000'
            d='M1545.84,485.55c3.7,0.08,7.3,4.15,7.14,8.06c-0.18,4.42-3.39,7.77-7.45,7.77c-4.07,0-6.81-3.31-6.73-8.12
			C1538.88,488.41,1541.58,485.45,1545.84,485.55z'
          />
          <path
            fill='#D50000'
            d='M1356.59,1488.6c0.06,4.55-2.87,7.92-6.93,7.97c-4.26,0.05-7.18-3.03-7.26-7.66
			c-0.07-4.39,3.07-8.09,6.94-8.17C1353.21,1480.65,1356.53,1484.26,1356.59,1488.6z'
          />
          <path
            fill='#D50000'
            d='M2154.88,1335.87c3.97-0.03,7.28,3.42,7.42,7.75c0.14,4.15-3.18,7.96-7,8.05c-4.45,0.1-7.66-3.24-7.58-7.88
			C2147.79,1339.45,2151,1335.9,2154.88,1335.87z'
          />
          <path
            fill='#D50000'
            d='M851.78,793.34c-3.89-0.03-7.11-3.67-7.08-8.01c0.03-4.67,2.87-7.81,7.1-7.82c3.99-0.01,7.07,3.42,7.08,7.86
			C858.87,789.77,855.68,793.37,851.78,793.34z'
          />
          <path
            fill='#D50000'
            d='M2183.25,1343.84c0,4.2-3.33,7.81-7.22,7.82c-4.24,0.01-7.23-3.09-7.35-7.62c-0.12-4.49,3.31-8.28,7.4-8.18
			C2180.03,1335.96,2183.25,1339.55,2183.25,1343.84z'
          />
          <path
            fill='#D50000'
            d='M2424.83,1077.04c-0.01,4.36-3.21,7.98-7.01,7.92c-3.46-0.05-7.34-4.38-7.31-8.15
			c0.03-3.86,3.69-7.99,7.24-8.17C2421.53,1068.45,2424.84,1072.39,2424.83,1077.04z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1488.97c-0.03,5.02-2.51,7.7-7.06,7.6c-3.55-0.07-6.95-3.73-7.04-7.56
			c-0.11-4.58,3.34-8.42,7.43-8.28C1203.42,1480.87,1205.93,1483.98,1205.9,1488.97z'
          />
          <path
            fill='#D50000'
            d='M2335.07,688.48c0.03,4.76-2.74,7.91-6.95,7.91c-3.88,0.01-7.28-3.7-7.25-7.91c0.03-4.1,3.53-7.88,7.33-7.9
			C2332.43,680.56,2335.04,683.56,2335.07,688.48z'
          />
          <path
            fill='#D50000'
            d='M2349.69,680.59c3.93-0.04,7.31,3.59,7.31,7.84c0,4.34-3.23,7.93-7.16,7.96c-4.18,0.03-7.06-3.15-7.08-7.82
			C2342.74,683.96,2345.64,680.62,2349.69,680.59z'
          />
          <path
            fill='#D50000'
            d='M2313.76,1003.14c0.13,3.68-3.59,8.24-6.93,8.49c-3.4,0.25-7.72-4.37-7.74-8.28
			c-0.02-3.75,3.85-8.14,7.26-8.23C2309.74,995.02,2313.63,999.23,2313.76,1003.14z'
          />
          <path
            fill='#D50000'
            d='M1177.2,1480.73c3.91-0.05,7.18,3.42,7.32,7.77c0.13,4.29-3.01,7.93-6.97,8.06
			c-4.09,0.13-7.67-3.61-7.63-7.96C1169.97,1484.28,1173.21,1480.79,1177.2,1480.73z'
          />
          <path
            fill='#D50000'
            d='M1068.3,1496.57c-3.94-0.08-7.24-3.73-7.18-7.93c0.07-4.57,3.57-8.13,7.77-7.91
			c3.93,0.21,7.01,3.92,6.85,8.24C1075.57,1493.49,1072.47,1496.65,1068.3,1496.57z'
          />
          <path
            fill='#D50000'
            d='M1047.1,1358.05c3.64,0.08,7.19,4.07,7.17,8.06c-0.02,4.28-3.27,7.77-7.25,7.78
			c-4.67,0.01-7.19-2.87-7.07-8.07C1040.06,1361.28,1043.11,1357.96,1047.1,1358.05z'
          />
          <path
            fill='#D50000'
            d='M1067.88,1773.74c3.84,0.06,7.22,3.72,7.31,7.9c0.1,4.36-3.43,8.01-7.66,7.91c-4.36-0.1-6.61-2.82-6.59-7.96
			C1060.96,1776.94,1063.85,1773.67,1067.88,1773.74z'
          />
          <path
            fill='#D50000'
            d='M1366.3,1441.14c-0.05-5.26,2.59-8.44,6.92-8.32c3.93,0.1,7.18,3.61,7.29,7.87c0.1,4.07-3.28,7.83-7.14,7.93
			C1368.85,1448.73,1366.35,1446.09,1366.3,1441.14z'
          />
          <path
            fill='#D50000'
            d='M786.38,718.82c-3.72,0-7.3-3.83-7.4-7.92c-0.12-4.41,3.37-8.02,7.65-7.92c4.12,0.1,7.26,3.57,7.12,7.87
			C793.6,714.87,789.96,718.82,786.38,718.82z'
          />
          <path
            fill='#D50000'
            d='M1220.25,1789.55c-4.01,0.04-7.31-3.36-7.43-7.64c-0.12-4.4,3.43-8.27,7.51-8.18c3.8,0.08,7.07,3.79,7.1,8.04
			C1227.46,1786.24,1224.44,1789.51,1220.25,1789.55z'
          />
          <path
            fill='#D50000'
            d='M866.63,710.53c0.02-5.06,2.36-7.6,6.95-7.57c3.9,0.03,7.19,3.56,7.23,7.77c0.04,4.31-3.66,8.26-7.58,8.08
			C869.29,718.63,866.61,715.27,866.63,710.53z'
          />
          <path
            fill='#D50000'
            d='M1959.86,1360.34c3.7,0.04,7.17,3.96,7.14,8.08c-0.04,4.63-2.96,7.75-7.23,7.73
			c-4.25-0.03-7.13-3.19-7.07-7.79C1952.74,1364.1,1956.14,1360.3,1959.86,1360.34z'
          />
          <path
            fill='#D50000'
            d='M2446.75,1076.82c-0.01,4.54-2.91,7.99-6.79,8.09c-3.55,0.09-7.66-4.5-7.5-8.4c0.15-3.81,4.11-7.97,7.5-7.89
			C2443.61,1068.71,2446.76,1072.52,2446.75,1076.82z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1075.63c-0.08-4.3,3.07-8.24,6.72-8.43c3.66-0.19,7.3,3.65,7.4,7.8c0.11,4.84-2.52,7.98-6.78,8.09
			C2064.39,1083.21,2061.56,1080.33,2061.47,1075.63z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1075.16c0.04-4.26,3.46-8.05,7.17-7.96c3.64,0.09,7.05,4.14,6.97,8.3
			c-0.09,4.79-2.88,7.67-7.34,7.56C2041.84,1082.95,2039.46,1080.19,2039.52,1075.16z'
          />
          <path
            fill='#D50000'
            d='M1784.12,768.94c-4.54-0.03-6.87-2.65-6.82-7.67c0.05-4.47,3.16-8.12,6.93-8.12c4.01,0,7.3,3.88,7.19,8.48
			C1791.31,766.44,1788.79,768.96,1784.12,768.94z'
          />
          <path
            fill='#D50000'
            d='M1097.01,710.54c0.12,4.08-3.23,8.06-6.97,8.28c-3.83,0.22-7.58-3.74-7.65-8.08c-0.07-4.35,3-7.67,7.16-7.75
			C1093.58,702.9,1096.88,706.24,1097.01,710.54z'
          />
          <path
            fill='#D50000'
            d='M1104.83,710.9c-0.05-5.19,2.21-7.86,6.71-7.94c3.99-0.07,7.42,3.44,7.5,7.66c0.08,3.98-3.48,8.06-7.16,8.2
			C1107.96,718.96,1104.88,715.51,1104.83,710.9z'
          />
          <path
            fill='#D50000'
            d='M1531.04,467.96c-0.02,4.58-2.99,7.92-7.02,7.92c-3.74,0-7.13-3.77-7.19-7.99c-0.06-4.17,3.34-7.82,7.29-7.83
			C1528.29,460.04,1531.07,463.22,1531.04,467.96z'
          />
          <path
            fill='#D50000'
            d='M1089.83,768.93c-3.93,0.08-7.55-3.93-7.48-8.27c0.07-4.4,3.18-7.57,7.4-7.56c4.05,0.02,7.25,3.45,7.26,7.79
			C1097.03,765.05,1093.63,768.85,1089.83,768.93z'
          />
          <path
            fill='#D50000'
            d='M1364.29,467.57c0.11-4.72,2.87-7.6,7.21-7.52c3.87,0.07,7.09,3.7,7.04,7.92c-0.05,4.17-3.43,7.87-7.21,7.9
			C1367.18,475.91,1364.17,472.36,1364.29,467.57z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1027.45c0.06,5.06-2.68,8.12-7.21,8.04c-4.17-0.07-6.91-3.23-6.92-7.96
			c-0.01-4.11,3.45-8.07,7.11-8.12C1811.02,1019.35,1814.33,1023.14,1814.38,1027.45z'
          />
          <path
            fill='#D50000'
            d='M866.64,760.91c0.03-5.36,2.43-7.94,7.26-7.82c3.79,0.1,7.06,3.9,6.92,8.04c-0.14,4.23-3.48,7.78-7.33,7.79
			C869.54,768.94,866.62,765.53,866.64,760.91z'
          />
          <path
            fill='#D50000'
            d='M858.86,760.76c0.05,4.32-3.16,8.09-6.95,8.17c-3.72,0.08-7.15-3.7-7.22-7.98c-0.08-4.76,2.62-7.8,6.99-7.86
			C856,753.04,858.81,756.04,858.86,760.76z'
          />
          <path
            fill='#D50000'
            d='M1112.59,1382.68c3.83,0.1,6.9,3.83,6.78,8.22c-0.14,4.72-2.91,7.66-7.21,7.64
			c-3.89-0.02-7.43-4.14-7.18-8.34C1105.23,1385.9,1108.6,1382.58,1112.59,1382.68z'
          />
          <path
            fill='#D50000'
            d='M1639.73,443.49c-0.03,4.54-2.7,7.67-6.6,7.77c-4.15,0.11-7.51-3.57-7.47-8.16c0.04-4.1,3.55-7.76,7.36-7.67
			C1637.23,435.54,1639.77,438.58,1639.73,443.49z'
          />
          <path
            fill='#D50000'
            d='M1858.31,1075.73c-0.15,4.58-3.1,7.51-7.42,7.37c-4.24-0.13-6.9-3.24-6.81-7.97c0.08-4.13,3.59-8,7.19-7.93
			C1855.14,1067.28,1858.45,1071.3,1858.31,1075.73z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1075.44c-0.04,4.8-2.72,7.68-7.13,7.65c-4.32-0.03-7.06-3.11-7.01-7.88c0.05-4.14,3.62-8.08,7.25-8
			C1811.19,1067.29,1814.42,1071.15,1814.38,1075.44z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1027.77c-0.03-4.36,3.15-8.27,6.8-8.39c3.63-0.11,7.11,3.77,7.25,8.09
			c0.16,4.74-2.59,7.88-6.95,7.94C1650.23,1035.48,1647.64,1032.69,1647.61,1027.77z'
          />
          <path
            fill='#D50000'
            d='M1169.92,1390.79c-0.11-4.25,3.08-7.93,7.05-8.11c4.09-0.19,7.58,3.53,7.54,8.03
			c-0.04,4.42-3.16,7.8-7.21,7.83C1173.47,1398.56,1170.03,1394.95,1169.92,1390.79z'
          />
          <path
            fill='#D50000'
            d='M1727.24,1075.13c0.16,4.35-2.89,7.81-7.01,7.93c-4.15,0.13-7.37-2.98-7.65-7.37
			c-0.27-4.15,3.39-8.4,7.31-8.49C1723.41,1067.13,1727.08,1071.09,1727.24,1075.13z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1028.01c-0.11,4.76-2.85,7.58-7.26,7.45c-4.2-0.12-6.87-3.27-6.81-8.04
			c0.05-4.18,3.49-8.05,7.15-8.06C1745.32,1019.35,1748.59,1023.44,1748.48,1028.01z'
          />
          <path
            fill='#D50000'
            d='M727.84,1074.9c0.16,4.04-3.23,7.96-7.03,8.15c-3.91,0.2-7.32-3.14-7.66-7.51c-0.31-3.94,3.11-8.15,6.78-8.34
			C724.15,1066.97,727.67,1070.4,727.84,1074.9z'
          />
          <path
            fill='#D50000'
            d='M1271.71,1390.49c0.07,4.06-3.39,7.98-7.12,8.05c-4.33,0.08-7.13-2.9-7.23-7.68
			c-0.09-4.51,2.99-8.16,6.91-8.18C1268.27,1382.66,1271.63,1386.19,1271.71,1390.49z'
          />
          <path
            fill='#D50000'
            d='M2343.76,759.49c0.03-5.31,2.13-7.57,6.99-7.5c3.99,0.05,7.37,3.7,7.25,7.8c-0.12,3.97-3.99,8.12-7.47,8.02
			C2346.85,767.7,2343.73,763.87,2343.76,759.49z'
          />
          <path
            fill='#D50000'
            d='M1349.53,1456.09c3.74,0.01,7.04,3.79,7.06,8.06c0.01,4.5-3,7.8-7.12,7.79c-4.25-0.01-7.09-3.15-7.07-7.82
			C1342.42,1459.96,1345.87,1456.08,1349.53,1456.09z'
          />
          <path
            fill='#D50000'
            d='M1292.89,1464.77c-0.25,4.84-3.04,7.49-7.57,7.17c-3.69-0.26-6.76-3.97-6.63-7.99
			c0.14-4.2,3.69-7.94,7.47-7.86C1290.29,1456.17,1293.14,1459.85,1292.89,1464.77z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1464.34c-0.05-4.52,2.92-8.12,6.79-8.25c3.74-0.12,7.24,3.64,7.29,7.85
			c0.06,4.66-2.77,7.92-6.93,8.01C1129.68,1472.03,1126.98,1469.16,1126.93,1464.34z'
          />
          <path
            fill='#D50000'
            d='M2141.13,711.57c0.15,4.04-3.16,8.09-6.82,8.34c-4.09,0.28-7.73-3.35-7.85-7.82
			c-0.12-4.21,3.09-7.83,7.1-8.01C2137.6,703.89,2140.96,707.22,2141.13,711.57z'
          />
          <path
            fill='#D50000'
            d='M1538.8,443.62c-0.05-5.31,2.5-8.25,7.06-8.18c3.58,0.06,7.02,3.7,7.12,7.54c0.12,4.52-3.29,8.31-7.46,8.29
			C1541.65,451.25,1538.84,448.05,1538.8,443.62z'
          />
          <path
            fill='#D50000'
            d='M2024.45,1400.7c-4.25-0.09-7.04-3.33-6.89-7.98c0.14-4.34,3.51-7.93,7.35-7.86c3.7,0.07,6.93,4.02,6.84,8.35
			C2031.64,1398.15,2029.06,1400.8,2024.45,1400.7z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1393.04c-0.01-4.37,3.17-8.1,6.97-8.18c3.75-0.08,7.13,3.72,7.15,8.04c0.02,4.82-2.68,7.8-7.05,7.8
			C2064.04,1400.7,2061.48,1397.93,2061.47,1393.04z'
          />
          <path
            fill='#D50000'
            d='M2031.75,758.61c-0.04,4.1-3.58,8.13-7.12,8.09c-3.85-0.04-7.22-4.14-7.07-8.62
			c0.14-4.48,3.33-7.74,7.39-7.57C2029.14,750.7,2031.8,753.86,2031.75,758.61z'
          />
          <path
            fill='#D50000'
            d='M1858.31,758.35c0.02,4.25-3.11,8.18-6.63,8.35c-3.68,0.18-7.6-4.11-7.59-8.31c0.01-4.09,3.51-7.82,7.31-7.8
			C1855.81,750.62,1858.29,753.39,1858.31,758.35z'
          />
          <path
            fill='#D50000'
            d='M2271.17,711.88c0,4.4-3.13,8-6.98,8.03c-3.74,0.02-7.14-3.77-7.13-7.97c0.01-4.36,3.19-7.87,7.14-7.87
			C2268.49,704.07,2271.17,707.08,2271.17,711.88z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1441.92c-0.03,4.27-3.32,7.78-7.27,7.78c-4.26,0-7.24-3.12-7.33-7.64
			c-0.09-4.48,3.39-8.31,7.43-8.18C2116.09,1434,2119.43,1437.74,2119.39,1441.92z'
          />
          <path
            fill='#D50000'
            d='M670.14,1052.48c-0.06-4.82,2.65-7.78,7.07-7.73c4.26,0.05,6.85,2.78,6.98,7.37c0.13,4.68-3.17,8.68-7.12,8.6
			C673.25,1060.66,670.19,1057.02,670.14,1052.48z'
          />
          <path
            fill='#D50000'
            d='M2184.32,1027.48c0.02,4.72-2.69,7.64-7.12,7.67c-4.52,0.04-6.93-2.65-6.92-7.7c0-4.18,3.49-8.25,7-8.16
			C2180.98,1019.39,2184.31,1023.26,2184.32,1027.48z'
          />
          <path
            fill='#D50000'
            d='M2234.13,1027.6c0.05-4.32,3.48-8.28,7.04-8.14c3.64,0.14,7,4.11,7.03,8.3c0.03,4.42-3.6,8.46-7.46,8.32
			C2237.01,1035.93,2234.08,1032.17,2234.13,1027.6z'
          />
          <path
            fill='#D50000'
            d='M1865.97,1417.36c0.05-4.21,3.63-8.13,7.25-7.93c3.89,0.22,7.06,3.93,7.03,8.22
			c-0.03,4.51-3.01,7.57-7.37,7.54C1868.53,1425.18,1865.92,1422.22,1865.97,1417.36z'
          />
          <path
            fill='#D50000'
            d='M2242.71,728.48c3.8,0.01,7.21,3.74,7.17,7.87c-0.04,4.24-3.3,7.89-7.11,7.95c-4.01,0.06-7.38-3.35-7.53-7.63
			C2235.08,732.38,2238.64,728.48,2242.71,728.48z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1442.09c-0.06-4.45,3.01-8.14,6.83-8.21c3.86-0.08,7.23,3.55,7.29,7.84
			c0.06,4.73-2.69,7.9-6.93,7.98C2064.29,1449.79,2061.54,1446.88,2061.47,1442.09z'
          />
          <path
            fill='#D50000'
            d='M1067.95,1798.33c3.86,0.11,7.3,3.95,7.24,8.09c-0.05,4-3.68,7.73-7.49,7.71c-4.55-0.02-6.99-3.04-6.77-8.37
			C1061.11,1801.39,1064.11,1798.22,1067.95,1798.33z'
          />
          <path
            fill='#D50000'
            d='M2024.89,1449.7c-4.24,0.07-7.23-3.03-7.34-7.62c-0.1-4.33,3.04-8.06,6.92-8.21
			c3.84-0.15,7.19,3.46,7.27,7.83C2031.84,1446.53,2029.17,1449.64,2024.89,1449.7z'
          />
          <path
            fill='#D50000'
            d='M727.85,735.48c-0.18,4.23-3.61,7.79-7.47,7.73c-4.07-0.06-7.58-4.14-7.25-8.43
			c0.33-4.24,3.85-7.63,7.71-7.41C724.76,727.59,728.03,731.36,727.85,735.48z'
          />
          <path
            fill='#D50000'
            d='M1097.16,1441c0.01,4.99-2.4,7.64-6.93,7.61c-3.81-0.02-7.24-3.69-7.24-7.74c0-4.28,3.18-7.94,7.03-8.06
			C1094.35,1432.67,1097.15,1435.88,1097.16,1441z'
          />
          <path
            fill='#D50000'
            d='M786.19,743.2c-3.95-0.09-7.23-3.72-7.21-7.99c0.02-4.13,3.51-7.87,7.32-7.84c4.14,0.03,7.7,3.93,7.44,8.17
			C793.49,739.82,790.08,743.28,786.19,743.2z'
          />
          <path
            fill='#D50000'
            d='M873.68,727.37c3.75,0.03,7.18,3.87,7.14,7.99c-0.04,4.26-3.37,7.8-7.4,7.84c-4.08,0.05-6.79-3.19-6.79-8.13
			C866.64,730.2,869.26,727.33,873.68,727.37z'
          />
          <path
            fill='#D50000'
            d='M1140.3,735.2c0.01,4.57-3.08,8.08-7.06,8c-4.01-0.08-7.29-3.65-7.3-7.94c-0.01-4.12,3.47-7.87,7.3-7.89
			C1137.56,727.34,1140.28,730.36,1140.3,735.2z'
          />
          <path
            fill='#D50000'
            d='M2487.91,1028.42c-0.18,3.79-3.72,7.67-6.97,7.63c-3.59-0.05-7.71-4.77-7.43-8.53
			c0.29-3.91,4.23-8.05,7.54-7.9C2484.7,1019.78,2488.11,1024.16,2487.91,1028.42z'
          />
          <path
            fill='#D50000'
            d='M1089.21,1918.61c4.71-0.06,6.88,2.28,6.92,7.46c0.04,4.94-2.48,8.21-6.45,8.35
			c-4.08,0.14-7.64-3.6-7.67-8.05C1081.99,1922.31,1085.38,1918.65,1089.21,1918.61z'
          />
          <path
            fill='#D50000'
            d='M1589.5,412.15c3.89,0,7.32,3.72,7.31,7.94c-0.01,3.97-3.62,7.93-7.23,7.92c-4.43-0.02-6.85-2.75-6.87-7.76
			C1582.7,415.47,1585.48,412.15,1589.5,412.15z'
          />
          <path
            fill='#D50000'
            d='M1829.03,743.2c-4.14-0.08-6.95-3.39-6.87-8.11c0.07-4.63,3.01-7.79,7.2-7.73c3.79,0.05,7.03,3.79,6.99,8.07
			C1836.3,739.83,1833.05,743.28,1829.03,743.2z'
          />
          <path
            fill='#D50000'
            d='M2096.57,1027.88c0.01,4.44-3.19,8.27-6.83,8.18c-3.8-0.1-7.16-3.81-7.27-8.03
			c-0.11-4.22,3.68-8.63,7.33-8.53C2093.37,1019.6,2096.55,1023.54,2096.57,1027.88z'
          />
          <path
            fill='#D50000'
            d='M1184.5,1441.05c-0.18,4.74-3.42,7.83-7.91,7.57c-3.53-0.21-6.71-3.96-6.67-7.89
			c0.04-4.53,3.58-8.14,7.75-7.91C1181.44,1433.02,1184.65,1436.89,1184.5,1441.05z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1440.76c0.05,5.16-2.26,7.83-6.77,7.85c-3.88,0.02-7.35-3.65-7.33-7.74c0.03-4.4,3.24-8.02,7.15-8.06
			C1203.17,1432.77,1205.86,1435.85,1205.9,1440.76z'
          />
          <path
            fill='#D50000'
            d='M2133.92,1058.69c-3.85,0.03-7.3-3.44-7.47-7.52c-0.18-4.1,3.69-8.4,7.47-8.32c3.45,0.07,7.14,4.2,7.3,8.16
			C2141.37,1054.87,2137.78,1058.66,2133.92,1058.69z'
          />
          <path
            fill='#D50000'
            d='M2105.23,1050.65c0.07-4.24,3.12-7.73,6.81-7.79c4.01-0.07,7.6,3.99,7.43,8.39c-0.17,4.37-3.4,7.53-7.59,7.44
			C2107.49,1058.59,2105.15,1055.76,2105.23,1050.65z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1050.83c0-4.5,3.1-8.02,7.03-7.99c3.96,0.03,7.14,3.62,7.11,8.02c-0.04,4.54-3.04,7.81-7.18,7.82
			C2042.33,1058.69,2039.52,1055.52,2039.52,1050.83z'
          />
          <path
            fill='#D50000'
            d='M1264.34,1432.82c3.98,0.02,7.25,3.44,7.38,7.73c0.13,4.28-3.39,8.11-7.42,8.07
			c-4.25-0.04-6.95-3.08-6.96-7.8C1257.34,1436.33,1260.43,1432.8,1264.34,1432.82z'
          />
          <path
            fill='#D50000'
            d='M1349.54,1432.82c3.87,0.05,7.08,3.71,7.05,8.05c-0.03,4.63-2.93,7.77-7.13,7.75
			c-4.31-0.03-7.08-3.1-7.06-7.82C1342.42,1436.42,1345.69,1432.76,1349.54,1432.82z'
          />
          <path
            fill='#D50000'
            d='M1264,743.2c-3.92-0.08-7.28-3.78-7.26-7.99c0.02-4.1,3.53-7.86,7.31-7.85c4.01,0.01,7.48,3.54,7.53,7.65
			C1271.63,739.35,1267.98,743.28,1264,743.2z'
          />
          <path
            fill='#D50000'
            d='M1235.68,735.01c0.01-4.95,2.53-7.69,7.04-7.65c3.46,0.04,6.93,3.75,7.04,7.55c0.14,4.57-3.26,8.38-7.4,8.3
			C1238.19,743.13,1235.67,740.04,1235.68,735.01z'
          />
          <path
            fill='#D50000'
            d='M1220.72,727.33c3.68,0.03,7.17,4.01,7.08,8.08c-0.09,4.34-3.47,7.9-7.42,7.8c-4.07-0.09-6.89-3.49-6.82-8.18
			C1213.64,730.24,1216.39,727.29,1220.72,727.33z'
          />
          <path
            fill='#D50000'
            d='M1198.17,743.21c-3.94-0.03-7.24-3.59-7.3-7.87c-0.05-4.08,3.38-7.91,7.14-7.97
			c4.34-0.07,7.63,3.46,7.49,8.06C1205.37,739.69,1202.03,743.24,1198.17,743.21z'
          />
          <path
            fill='#D50000'
            d='M1328.2,412.16c3.89,0.07,7.55,4.23,7.32,8.33c-0.23,4.13-3.66,7.61-7.56,7.66
			c-3.77,0.05-7.56-4.25-7.36-8.37C1320.79,415.6,1324.29,412.09,1328.2,412.16z'
          />
          <path
            fill='#D50000'
            d='M1299.52,420.15c0.02-4.87,2.72-8.04,6.81-7.99c4.2,0.05,7.42,3.66,7.32,8.2c-0.09,3.99-3.64,7.67-7.36,7.63
			C1301.98,427.95,1299.5,425.08,1299.52,420.15z'
          />
          <path
            fill='#D50000'
            d='M1104.96,1972.7c0.12-4.78,2.82-7.46,7.31-7.25c3.75,0.18,7.05,4.19,6.79,8.27
			c-0.26,4.13-3.83,7.69-7.59,7.58C1107.09,1981.17,1104.82,1978.17,1104.96,1972.7z'
          />
          <path
            fill='#D50000'
            d='M1858.3,1050.91c-0.04,4.4-3.09,7.76-7.06,7.77c-4.3,0.01-7.26-3.37-7.15-8.17c0.1-4.35,3.37-7.8,7.27-7.66
			C1855.21,1042.98,1858.34,1046.62,1858.3,1050.91z'
          />
          <path
            fill='#D50000'
            d='M1698.11,1058.68c-4.04-0.04-7.12-3.41-7.16-7.85c-0.04-4.26,3.17-7.9,7.03-7.99
			c4.19-0.09,7.63,3.63,7.51,8.12C1705.38,1055.11,1701.92,1058.72,1698.11,1058.68z'
          />
          <path
            fill='#D50000'
            d='M1727.27,1050.59c0.16,4.09-3.17,7.93-7.02,8.1c-3.86,0.16-7.38-3.25-7.68-7.44
			c-0.32-4.35,3.13-8.34,7.26-8.39C1723.68,1042.8,1727.1,1046.35,1727.27,1050.59z'
          />
          <path
            fill='#D50000'
            d='M1829.09,1058.68c-4.12-0.07-6.96-3.33-6.93-7.98c0.02-4.44,3.29-8.01,7.18-7.86
			c3.95,0.16,7.06,3.77,7.01,8.12C1836.29,1055.37,1833.11,1058.75,1829.09,1058.68z'
          />
          <path
            fill='#D50000'
            d='M1793.09,1050.98c-0.12,4.39-3.27,7.7-7.34,7.7c-4.01,0-7.59-4.06-7.31-8.31c0.29-4.41,3.65-7.71,7.65-7.52
			C1789.88,1043.03,1793.2,1046.88,1793.09,1050.98z'
          />
          <path
            fill='#D50000'
            d='M1053.28,760.74c0.05,4.31-3.23,8.17-6.94,8.19c-3.84,0.01-7.17-3.62-7.27-7.93
			c-0.11-4.48,2.92-7.87,7.07-7.91C1050.66,753.04,1053.23,755.8,1053.28,760.74z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1050.54c0.08,4.7-2.66,8.03-6.7,8.14c-4.18,0.11-7.27-3.04-7.44-7.57
			c-0.17-4.36,2.93-8.12,6.82-8.27C1811.05,1042.68,1814.31,1046.11,1814.38,1050.54z'
          />
          <path
            fill='#D50000'
            d='M1154.86,2033.56c-3.87-0.14-7.15-3.97-6.9-8.07c0.26-4.21,3.8-7.88,7.5-7.77c3.59,0.11,7.16,4.22,7.12,8.21
			C1162.53,2030.68,1159.46,2033.72,1154.86,2033.56z'
          />
          <path
            fill='#D50000'
            d='M1227.4,2001.19c0.06,4.38-3.02,7.9-7,7.98c-4.04,0.09-7.65-3.76-7.55-8.04c0.1-4.3,3.38-7.79,7.35-7.8
			C1223.98,1993.31,1227.34,1996.98,1227.4,2001.19z'
          />
          <path
            fill='#D50000'
            d='M1291.69,420.11c0,4.92-2.44,7.78-6.74,7.88c-4.35,0.1-7.42-3.24-7.35-7.99c0.07-4.33,3.31-7.87,7.19-7.88
			C1288.68,412.12,1291.68,415.61,1291.69,420.11z'
          />
          <path
            fill='#D50000'
            d='M1560.76,419.85c0.07-4.4,2.88-7.64,6.66-7.69c4.01-0.06,7.67,4.04,7.45,8.34c-0.2,3.99-3.97,7.67-7.69,7.51
			C1562.8,427.8,1560.67,425.1,1560.76,419.85z'
          />
          <path
            fill='#D50000'
            d='M1618.42,1050.97c-0.11,4.34-3.35,7.72-7.39,7.71c-3.85-0.01-7.25-3.68-7.26-7.83
			c-0.01-4.52,3.49-8.18,7.65-8C1615.28,1043.02,1618.53,1046.79,1618.42,1050.97z'
          />
          <path
            fill='#D50000'
            d='M1120.09,1878.94c-0.03,5.06-2.4,7.58-7.11,7.55c-3.59-0.02-6.92-3.57-7.02-7.51
			c-0.12-4.5,3.35-8.38,7.43-8.3C1117.45,1870.77,1120.12,1874.06,1120.09,1878.94z'
          />
          <path
            fill='#D50000'
            d='M1155.43,1838.57c-4.01,0.05-7.35-3.3-7.47-7.49c-0.12-4.34,3.47-8.39,7.41-8.35c3.66,0.04,7.15,4,7.16,8.12
			C1162.54,1835.45,1159.72,1838.52,1155.43,1838.57z'
          />
          <path
            fill='#D50000'
            d='M1103.96,1830.66c0.07-4.28,3.47-8.01,7.21-7.92c3.71,0.09,6.98,4,6.91,8.27c-0.07,4.83-2.78,7.65-7.25,7.56
			C1106.53,1838.49,1103.88,1835.43,1103.96,1830.66z'
          />
          <path
            fill='#D50000'
            d='M1126.83,1806.5c-0.06-4.79,2.7-8.15,6.73-8.18c3.9-0.03,7.39,3.65,7.44,7.82c0.04,4.03-3.45,7.94-7.13,7.98
			C1129.39,1814.18,1126.89,1811.47,1126.83,1806.5z'
          />
          <path
            fill='#D50000'
            d='M1300.55,442.96c0.14-4.76,2.95-7.65,7.34-7.54c4.03,0.1,6.85,3.51,6.78,8.17c-0.07,4.35-3.43,7.84-7.39,7.68
			C1303.39,451.11,1300.42,447.45,1300.55,442.96z'
          />
          <path
            fill='#D50000'
            d='M2206.29,542.02c-0.02,4.51-3.17,7.95-7.21,7.86c-4.1-0.09-6.98-3.52-6.85-8.17c0.13-4.44,3.46-8.2,7.18-8.09
			C2203.18,533.75,2206.31,537.56,2206.29,542.02z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1050.49c0.07-4.39,3.41-7.85,7.38-7.64c3.86,0.2,6.91,3.97,6.75,8.35c-0.17,4.5-3.3,7.64-7.46,7.49
			C2064.07,1058.53,2061.39,1055.24,2061.47,1050.49z'
          />
          <path
            fill='#D50000'
            d='M1141.03,1733.4c-0.13,4.77-2.49,7.18-6.97,7.14c-4.02-0.03-7.26-3.6-7.13-7.87c0.13-4.4,3.35-7.92,7.29-7.96
			C1138.39,1724.67,1141.17,1728.22,1141.03,1733.4z'
          />
          <path
            fill='#D50000'
            d='M1089.47,534.59c3.83-0.07,7.33,3.4,7.55,7.48c0.23,4.23-3.42,8.36-7.37,8.35c-3.71-0.01-7.11-3.82-7.13-8.01
			C1082.51,537.71,1085.21,534.66,1089.47,534.59z'
          />
          <path
            fill='#D50000'
            d='M2053.64,1686.44c-0.1,4.41-2.9,7.27-7.09,7.25c-4.79-0.02-7.19-2.8-7.03-8.12c0.13-4.23,3.71-7.95,7.41-7.7
			C2050.55,1678.12,2053.73,1682.18,2053.64,1686.44z'
          />
          <path
            fill='#D50000'
            d='M2170.28,1050.54c0.04-4.86,2.69-7.74,7.08-7.7c3.97,0.04,7.04,3.56,6.98,8c-0.06,4.23-3.45,7.9-7.26,7.84
			C2173.1,1058.62,2170.24,1055.2,2170.28,1050.54z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1683.87c-0.01,5.13-2.36,7.73-6.95,7.67c-3.81-0.05-7.27-3.9-7.13-7.94
			c0.15-4.41,3.45-7.96,7.34-7.89C1203.31,1675.77,1205.9,1678.9,1205.89,1683.87z'
          />
          <path
            fill='#D50000'
            d='M1141.04,1683.67c0.06,5.13-2.22,7.76-6.8,7.85c-3.84,0.08-7.31-3.59-7.31-7.74c0-4.36,3.23-8.02,7.13-8.08
			C1138.22,1675.63,1140.98,1678.79,1141.04,1683.67z'
          />
          <path
            fill='#D50000'
            d='M2286.65,1043.93c3.76,0.12,7.62,4.64,7.32,8.58c-0.33,4.32-3.62,7.38-7.81,7.28
			c-3.75-0.09-7.2-4.04-7.04-8.05C2279.3,1047.6,2282.95,1043.81,2286.65,1043.93z'
          />
          <path
            fill='#D50000'
            d='M2162.38,1027.65c0.05,4.56-2.85,8.23-6.68,8.46c-3.92,0.24-7.4-3.7-7.37-8.34c0.03-4.28,3.29-8.11,7.03-8.27
			C2158.98,1019.35,2162.33,1023.23,2162.38,1027.65z'
          />
          <path
            fill='#D50000'
            d='M2271.19,566.04c0.02,4.64-2.82,7.89-6.9,7.91c-4.04,0.02-7.12-3.3-7.22-7.78c-0.09-4.31,3.13-8.01,7.03-8.06
			C2268.02,558.06,2271.17,561.59,2271.19,566.04z'
          />
          <path
            fill='#D50000'
            d='M2068.32,1653.46c3.63-0.11,7.05,3.35,7.27,7.36c0.26,4.8-2.68,8.42-6.91,8.5c-4.19,0.08-7.14-3.02-7.21-7.6
			C2061.4,1657.37,2064.55,1653.57,2068.32,1653.46z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1027.83c0.02,4.45-3.1,8.24-6.76,8.22c-3.65-0.02-7.3-3.88-7.43-7.85c-0.13-4.16,3.61-8.54,7.3-8.55
			C2506.26,1019.63,2509.49,1023.47,2509.51,1027.83z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1661.82c-0.16,4.65-3.1,7.6-7.42,7.48c-4-0.11-6.93-3.63-6.77-8.13c0.15-4.27,3.65-7.89,7.44-7.71
			C2028.81,1653.64,2031.9,1657.47,2031.75,1661.82z'
          />
          <path
            fill='#D50000'
            d='M2313.85,1027.88c-0.07,3.78-3.99,8.14-7.33,8.15c-3.59,0.01-7.67-4.68-7.44-8.54
			c0.22-3.69,4.38-8.04,7.6-7.94C2309.91,1019.65,2313.91,1024.31,2313.85,1027.88z'
          />
          <path
            fill='#D50000'
            d='M2292.14,1027.94c-0.05,4.02-3.62,7.99-7.26,8.08c-3.83,0.1-7-3.84-6.91-8.6c0.07-3.98,3.3-7.77,6.7-7.88
			C2288.24,1019.44,2292.2,1023.88,2292.14,1027.94z'
          />
          <path
            fill='#D50000'
            d='M2256.04,1027.57c0.06-4.34,3.4-8.19,6.98-8.05c3.41,0.13,7.11,4.4,7.16,8.26c0.05,4.16-3.9,8.42-7.62,8.22
			C2258.83,1035.81,2255.98,1032.12,2256.04,1027.57z'
          />
          <path
            fill='#D50000'
            d='M1248.79,568.32c-0.04,4.81-2.84,7.92-7.04,7.79c-3.82-0.11-7.06-3.88-7.04-8.16
			c0.03-4.14,3.53-7.76,7.42-7.67C1246.5,560.37,1248.83,563.19,1248.79,568.32z'
          />
          <path
            fill='#D50000'
            d='M2105.42,1028.26c-0.07-4.88,3.03-8.8,6.89-8.72c3.7,0.08,7.13,3.98,7.18,8.16c0.05,4.38-3.06,8.2-6.85,8.4
			C2108.84,1036.31,2105.48,1032.66,2105.42,1028.26z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1027.45c0.05-4.31,3.37-8.13,7-8.08c3.78,0.06,7.27,4.3,7.13,8.67c-0.14,4.38-2.95,7.39-6.94,7.45
			C2042.11,1035.56,2039.45,1032.59,2039.52,1027.45z'
          />
          <path
            fill='#D50000'
            d='M852.1,582.5c4.12,0.09,6.9,3.39,6.77,8.02c-0.13,4.35-3.59,8-7.39,7.79c-3.83-0.21-6.93-4.03-6.79-8.38
			C844.84,585.19,847.62,582.4,852.1,582.5z'
          />
          <path
            fill='#D50000'
            d='M1061.03,590.35c-0.03-5.1,2.3-7.81,6.77-7.86c4.07-0.05,7.42,3.4,7.41,7.64c-0.01,4.36-3.21,8.02-7.15,8.17
			C1064.29,598.46,1061.06,594.8,1061.03,590.35z'
          />
          <path
            fill='#D50000'
            d='M1786,1035.59c-3.68,0.11-7.32-3.6-7.57-7.73c-0.22-3.72,3.47-8.22,6.96-8.5c3.29-0.26,7.68,4.32,7.85,8.18
			C1793.4,1031.39,1789.72,1035.48,1786,1035.59z'
          />
          <path
            fill='#D50000'
            d='M1054.12,1659.3c-0.12,4.3-3.47,7.72-7.53,7.68c-4.03-0.04-7.34-3.53-7.4-7.79
			c-0.06-4.15,3.88-8.23,7.74-8.03C1050.75,1651.36,1054.23,1655.29,1054.12,1659.3z'
          />
          <path
            fill='#D50000'
            d='M2184.32,1637.42c-0.19,4.73-3.03,7.47-7.51,7.24c-4.17-0.21-6.74-3.48-6.53-8.27
			c0.19-4.25,3.75-7.81,7.56-7.56C2181.46,1629.07,2184.5,1633.1,2184.32,1637.42z'
          />
          <path
            fill='#D50000'
            d='M2024.78,1628.83c3.71,0.01,7,3.87,6.97,8.16c-0.04,4.8-2.77,7.73-7.15,7.67c-4.12-0.05-7.05-3.35-7.03-7.92
			C2017.57,1632.51,2020.94,1628.82,2024.78,1628.83z'
          />
          <path
            fill='#D50000'
            d='M1611,1067.21c3.59-0.14,7.2,3.7,7.43,7.88c0.24,4.36-2.78,7.83-6.93,7.97c-4.18,0.14-7.38-2.89-7.7-7.3
			C1603.5,1071.53,1607,1067.36,1611,1067.21z'
          />
          <path
            fill='#D50000'
            d='M2017.56,589.79c0.13-4.68,2.99-7.48,7.43-7.29c4.08,0.18,7.01,3.73,6.76,8.18c-0.24,4.18-3.39,7.6-7.06,7.63
			C2020.69,598.35,2017.42,594.45,2017.56,589.79z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1075.34c0-4.24,3.34-8.14,6.97-8.14c3.63,0,7.11,3.97,7.1,8.13c0,4.78-2.73,7.75-7.11,7.73
			C1650.06,1083.04,1647.61,1080.33,1647.61,1075.34z'
          />
          <path
            fill='#D50000'
            d='M1669.57,1075.46c-0.03-4.27,3.24-8.21,6.86-8.26c3.64-0.05,7.15,3.84,7.19,7.98
			c0.04,4.81-2.61,7.83-6.91,7.89C1672.3,1083.12,1669.6,1080.25,1669.57,1075.46z'
          />
          <path
            fill='#D50000'
            d='M1705.49,1075.08c0.12,4.26-3.05,7.86-7.05,7.98c-4.37,0.13-7.28-2.73-7.53-7.39
			c-0.23-4.22,3.39-8.47,7.21-8.47C1701.91,1067.2,1705.37,1070.9,1705.49,1075.08z'
          />
          <path
            fill='#D50000'
            d='M2069.25,582.5c3.81,0.04,7.21,3.74,7.22,7.85c0,4.02-3.54,7.89-7.32,7.99c-3.78,0.1-7.66-4.25-7.46-8.36
			C2061.91,585.69,2065.18,582.46,2069.25,582.5z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1075.51c-0.08,4.89-2.68,7.64-7.15,7.57c-4.33-0.08-7-3.14-6.92-7.93c0.08-4.16,3.64-8.05,7.28-7.95
			C1745.33,1067.3,1748.55,1071.24,1748.48,1075.51z'
          />
          <path
            fill='#D50000'
            d='M2097.53,590.32c0.01,4.67-3.01,8.16-6.92,8c-3.97-0.16-7.12-3.72-7.15-8.08c-0.02-3.74,3.39-7.67,6.73-7.75
			C2094.96,582.38,2097.52,585.11,2097.53,590.32z'
          />
          <path
            fill='#D50000'
            d='M1271.72,1634.79c-0.1,4.15-3.55,7.78-7.36,7.74c-4.35-0.04-7.02-3.06-6.98-7.89
			c0.04-4.52,3.26-8.09,7.18-7.95C1268.51,1626.84,1271.83,1630.58,1271.72,1634.79z'
          />
          <path
            fill='#D50000'
            d='M2249.89,590.4c-0.1,4.03-3.76,7.96-7.39,7.93c-3.82-0.02-7.21-3.73-7.26-7.94c-0.06-4.22,3.6-8.02,7.61-7.89
			C2246.9,582.62,2249.99,586.09,2249.89,590.4z'
          />
          <path
            fill='#D50000'
            d='M2052.66,614.84c-0.05,4.8-2.94,8.24-6.85,8.14c-3.79-0.1-7.2-3.89-7.27-8.11c-0.08-4.08,3.38-7.72,7.34-7.73
			C2050.62,607.12,2052.71,609.49,2052.66,614.84z'
          />
          <path
            fill='#D50000'
            d='M2192.22,614.4c0.16-4.91,2.73-7.43,7.42-7.26c4.25,0.15,6.85,3.33,6.65,8.12c-0.18,4.2-3.86,8.01-7.46,7.71
			C2195.2,622.67,2192.08,618.63,2192.22,614.4z'
          />
          <path
            fill='#D50000'
            d='M727.88,1027.35c-0.12,4.16-3.6,7.81-7.44,7.79c-4.07-0.02-7.6-4.05-7.31-8.37c0.28-4.21,3.82-7.66,7.65-7.46
			C724.7,1019.51,728,1023.24,727.88,1027.35z'
          />
          <path
            fill='#D50000'
            d='M2214.17,1587.24c0.05-5.07,2.43-7.55,7.13-7.41c3.52,0.1,6.84,3.75,6.94,7.63c0.11,4.41-3.49,8.3-7.58,8.18
			C2216.64,1595.53,2214.12,1592.27,2214.17,1587.24z'
          />
          <path
            fill='#D50000'
            d='M2198.95,1579.83c3.8,0.04,7.27,3.81,7.34,7.97c0.07,4.13-3.32,7.83-7.21,7.86c-4.69,0.04-6.88-2.4-6.85-7.64
			C2192.26,1583.09,2194.97,1579.79,2198.95,1579.83z'
          />
          <path
            fill='#D50000'
            d='M2184.33,1588.06c-0.05,4.88-2.63,7.63-7.12,7.59c-4.37-0.04-6.93-2.94-6.93-7.84c0-4.23,3.43-8.05,7.15-7.97
			C2181.18,1579.93,2184.37,1583.73,2184.33,1588.06z'
          />
          <path
            fill='#D50000'
            d='M2089.94,1595.65c-4.47-0.06-6.92-2.91-6.77-7.87c0.14-4.58,3.35-8.12,7.2-7.94c3.75,0.18,7.19,4.2,7.07,8.26
			C2097.32,1592.39,2094.02,1595.71,2089.94,1595.65z'
          />
          <path
            fill='#D50000'
            d='M683.19,1004.3c0.1,4.67-3.25,8.69-7.16,8.58c-3.63-0.09-6.74-3.59-6.89-7.76c-0.18-5.05,2.13-7.88,6.59-8.05
			C680.46,996.9,683.09,999.45,683.19,1004.3z'
          />
          <path
            fill='#D50000'
            d='M844.68,616.06c-0.08-4.99,2.28-7.71,6.79-7.83c4.68-0.12,7.31,2.45,7.38,7.2c0.07,4.67-3.07,8.53-7,8.59
			C848.15,624.08,844.75,620.29,844.68,616.06z'
          />
          <path
            fill='#D50000'
            d='M1336.63,1585.45c0.03,5.19-2.21,7.93-6.58,8.07c-3.95,0.12-7.72-3.92-7.57-8.12
			c0.15-4.24,3.63-7.79,7.56-7.72C1333.89,1577.76,1336.6,1580.96,1336.63,1585.45z'
          />
          <path
            fill='#D50000'
            d='M1271.72,1585.62c0.01,4.05-3.41,7.85-7.1,7.9c-4.48,0.06-7.35-3.12-7.24-8.04c0.09-4.27,3.01-7.68,6.68-7.79
			C1268.29,1577.55,1271.71,1581.09,1271.72,1585.62z'
          />
          <path
            fill='#D50000'
            d='M764.58,1011.84c-4.48-0.08-6.73-2.75-6.65-7.93c0.07-4.61,3.2-8.1,7.09-7.89c3.91,0.21,7.01,3.89,6.97,8.27
			C771.95,1008.73,768.83,1011.91,764.58,1011.84z'
          />
          <path
            fill='#D50000'
            d='M2010.4,1563.35c0.03,4.39-3.07,7.8-7.14,7.86c-4.01,0.05-7.37-3.31-7.54-7.54
			c-0.17-4.26,3.45-8.28,7.48-8.29C2006.98,1555.38,2010.37,1559.13,2010.4,1563.35z'
          />
          <path
            fill='#D50000'
            d='M1249.77,1561.34c-0.12,4.58-3.16,7.7-7.36,7.56c-4.3-0.15-6.8-3.16-6.7-8.1c0.09-4.44,3.32-7.88,7.28-7.77
			C1246.69,1553.14,1249.87,1557.03,1249.77,1561.34z'
          />
          <path
            fill='#D50000'
            d='M2090.08,1530.83c3.89-0.01,7.27,3.6,7.35,7.85c0.08,4.27-3.15,7.89-7.09,7.96
			c-4.48,0.09-7.59-3.23-7.45-7.93C2083.02,1534.31,2086.19,1530.84,2090.08,1530.83z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1538.76c0.04-4.44,3.27-8.04,7.11-7.95c3.83,0.09,7.1,3.88,7.03,8.14
			c-0.07,4.61-3.02,7.74-7.25,7.69C2042.15,1546.59,2039.47,1543.52,2039.52,1538.76z'
          />
          <path
            fill='#D50000'
            d='M1176.68,655.05c4.04,0.17,7.48,4.25,7.23,8.56c-0.23,3.98-4.01,7.5-7.81,7.3c-4.2-0.23-6.62-3.37-6.42-8.34
			C1169.86,658.21,1172.94,654.9,1176.68,655.05z'
          />
          <path
            fill='#D50000'
            d='M836.9,664c-0.05,4.5-3.28,8.17-7,7.97c-3.55-0.19-7.27-4.53-7.12-8.3c0.17-4.27,3.48-7.57,7.53-7.52
			C834.95,656.2,836.96,658.6,836.9,664z'
          />
          <path
            fill='#D50000'
            d='M851.67,656.15c4.61-0.05,7.21,2.62,7.19,7.4c-0.02,4.66-3.31,8.51-7.19,8.42c-3.68-0.09-7.01-3.97-6.98-8.16
			C844.73,658.81,847.11,656.19,851.67,656.15z'
          />
          <path
            fill='#D50000'
            d='M2039.52,663.96c0.06-4.21,3.12-7.77,6.71-7.81c4.17-0.05,7.58,3.72,7.44,8.24c-0.15,4.54-3.21,7.7-7.39,7.6
			C2042.15,671.89,2039.45,668.68,2039.52,663.96z'
          />
          <path
            fill='#D50000'
            d='M2105.42,665.25c-0.01-4.45,3.16-8.11,6.94-8c3.88,0.11,7.03,3.69,7.13,8.09c0.09,4.41-3.03,7.76-7.21,7.73
			C2107.85,673.02,2105.43,670.27,2105.42,665.25z'
          />
          <path
            fill='#D50000'
            d='M2140.34,1515.49c0.02,4.09-3.47,7.85-7.28,7.86c-4.23,0.01-7.18-3.15-7.23-7.74
			c-0.04-4.12,3.5-8.11,7.18-8.11C2136.86,1507.5,2140.32,1511.28,2140.34,1515.49z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1515.31c0.15,3.99-3.37,7.97-7.12,8.04c-4.41,0.08-7.18-2.77-7.33-7.56
			c-0.13-4.2,3.2-8.19,6.92-8.28C2115.75,1507.4,2119.23,1511.01,2119.39,1515.31z'
          />
          <path
            fill='#D50000'
            d='M2090.19,1507.51c3.87,0.07,7.31,3.91,7.23,8.09c-0.07,4.13-3.56,7.77-7.41,7.73
			c-4.26-0.04-7.15-3.21-7.12-7.82C2082.92,1511.37,2086.51,1507.44,2090.19,1507.51z'
          />
          <path
            fill='#D50000'
            d='M2228.17,665.39c-0.11,4.3-3.47,7.77-7.45,7.67c-4.37-0.11-7-3.18-6.82-7.98c0.18-4.72,3.19-8.01,7.19-7.85
			C2224.96,657.38,2228.27,661.19,2228.17,665.39z'
          />
          <path
            fill='#D50000'
            d='M1147.73,687.36c-0.03-4.94,2.54-7.95,6.8-7.95c3.8,0,7.3,3.7,7.4,7.82c0.1,4.19-3.22,7.89-7.2,8.04
			C1150.67,695.42,1147.76,692.14,1147.73,687.36z'
          />
          <path
            fill='#D50000'
            d='M2068.62,679.44c4.06,0.09,7.02,3.49,6.97,8c-0.04,4.35-3.35,7.92-7.27,7.85c-3.89-0.07-6.92-3.69-6.85-8.18
			C2061.54,682.47,2064.46,679.34,2068.62,679.44z'
          />
          <path
            fill='#D50000'
            d='M2089.83,1498.73c-4.23-0.09-6.99-3.23-6.91-7.84c0.08-4.19,3.55-8.03,7.22-7.99
			c3.88,0.04,7.53,4.29,7.28,8.46C2097.16,1495.65,2093.9,1498.82,2089.83,1498.73z'
          />
          <path
            fill='#D50000'
            d='M2271.19,688.32c0.02,4.46-3.07,8.06-6.94,8.07c-3.86,0.02-7.22-3.67-7.19-7.91c0.03-4.52,3.01-7.84,7.09-7.9
			C2268.3,680.52,2271.16,683.67,2271.19,688.32z'
          />
          <path
            fill='#D50000'
            d='M1366.3,1488.78c0.03-5,2.55-8.03,6.7-8.05c4.14-0.01,7.59,3.73,7.51,8.16c-0.07,4.1-3.54,7.68-7.42,7.67
			C1368.69,1496.55,1366.28,1493.79,1366.3,1488.78z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1489.15c-0.16,5.03-2.65,7.58-7.22,7.41c-3.59-0.13-7.16-4.4-6.92-8.27
			c0.26-4.29,3.77-7.72,7.72-7.56C1334.19,1480.9,1336.78,1484.29,1336.62,1489.15z'
          />
          <path
            fill='#D50000'
            d='M1786.08,1010.98c-3.87,0.09-7.7-3.92-7.66-8.03c0.03-3.67,3.54-7.75,6.87-7.99
			c3.55-0.26,7.68,3.96,7.89,8.05C1793.38,1006.87,1789.8,1010.89,1786.08,1010.98z'
          />
          <path
            fill='#D50000'
            d='M1756.36,1002.61c0.18-4.21,3.39-7.68,7.04-7.63c4.04,0.05,7.26,4.02,7.04,8.69
			c-0.22,4.72-3.11,7.46-7.62,7.24C1758.6,1010.7,1756.14,1007.54,1756.36,1002.61z'
          />
          <path
            fill='#D50000'
            d='M1264.33,1496.57c-4.2-0.05-6.97-3.15-6.98-7.82c-0.01-4.45,3.12-8.05,6.97-8.02
			c4.13,0.02,7.57,3.85,7.4,8.23C1271.56,1493.06,1268.1,1496.62,1264.33,1496.57z'
          />
          <path
            fill='#D50000'
            d='M1148.75,1488.84c-0.03-4.75,2.83-8.15,6.84-8.11c4,0.03,7.33,3.6,7.36,7.89c0.03,4.05-3.48,7.92-7.2,7.95
			C1151.3,1496.6,1148.78,1493.82,1148.75,1488.84z'
          />
          <path
            fill='#D50000'
            d='M1133.23,702.97c4.54,0,7.17,2.96,7.23,8.13c0.04,3.93-3.75,7.85-7.45,7.72c-3.71-0.13-7.12-4.07-7.07-8.14
			C1126,706.46,1129.3,702.96,1133.23,702.97z'
          />
          <path
            fill='#D50000'
            d='M2119.38,1466.59c-0.12,4.23-3.56,7.75-7.5,7.68c-4.08-0.08-7.21-3.61-7.09-8c0.11-4.22,3.66-7.94,7.43-7.8
			C2116.17,1458.62,2119.5,1462.39,2119.38,1466.59z'
          />
          <path
            fill='#D50000'
            d='M1705.48,1003.2c-0.12,4.55-3.53,7.94-7.8,7.75c-3.51-0.15-6.88-3.9-6.9-7.67c-0.02-3.97,4.05-8.43,7.62-8.35
			C1701.83,995.02,1705.58,999.4,1705.48,1003.2z'
          />
          <path
            fill='#D50000'
            d='M2003.2,1474.26c-4,0.02-7.34-3.37-7.48-7.59c-0.14-4.34,3.45-8.24,7.54-8.2c3.81,0.04,7.18,3.81,7.14,8
			C2010.37,1470.89,2007.27,1474.24,2003.2,1474.26z'
          />
          <path
            fill='#D50000'
            d='M757.93,1100.69c0.04-4.55,2.7-7.68,6.59-7.74c4.15-0.07,7.65,3.76,7.47,8.18c-0.17,4.21-3.63,7.76-7.46,7.66
			C760.25,1108.67,757.89,1105.78,757.93,1100.69z'
          />
          <path
            fill='#D50000'
            d='M1198.02,718.81c-3.79-0.1-7.15-3.89-7.14-8.08c0.01-4.28,3.29-7.8,7.27-7.79c4.48,0.01,7.58,3.44,7.3,8.08
			C1205.2,715.29,1201.75,718.91,1198.02,718.81z'
          />
          <path
            fill='#D50000'
            d='M1242.75,1456.08c3.76-0.01,7.03,3.77,7.01,8.08c-0.03,4.66-2.92,7.82-7.12,7.78
			c-4.38-0.04-6.95-2.95-6.93-7.86C1235.73,1459.52,1238.75,1456.09,1242.75,1456.08z'
          />
          <path
            fill='#D50000'
            d='M2105.42,712.08c-0.05-4.94,2.45-7.9,6.77-8.01c4.01-0.1,7.22,3.31,7.29,7.72c0.07,4.28-3.17,8.05-6.97,8.12
			C2108.46,719.99,2105.47,716.69,2105.42,712.08z'
          />
          <path
            fill='#D50000'
            d='M2090.32,1449.7c-4.29,0.08-7.33-2.95-7.51-7.48c-0.17-4.38,3.3-8.36,7.3-8.35c3.9,0,7.24,3.59,7.33,7.88
			C2097.52,1446,2094.27,1449.63,2090.32,1449.7z'
          />
          <path
            fill='#D50000'
            d='M852,743.21c-3.93,0.12-7.22-3.39-7.31-7.79c-0.09-4.63,2.74-7.94,6.88-8.05c4.06-0.11,7.21,3.19,7.29,7.63
			C858.95,739.48,855.93,743.09,852,743.21z'
          />
          <path
            fill='#D50000'
            d='M1147.75,735.18c0-4.94,2.59-7.87,6.91-7.83c3.82,0.04,7.27,3.79,7.29,7.92c0.02,4.23-3.33,7.86-7.32,7.93
			C1150.51,743.27,1147.75,740.06,1147.75,735.18z'
          />
          <path
            fill='#D50000'
            d='M1726.54,735.57c0.01,5.16-2.26,7.67-6.92,7.64c-4.7-0.03-7.24-2.7-7.15-7.54c0.08-4.33,3.38-8.19,7.05-8.25
			C1723.15,727.38,1726.53,731.31,1726.54,735.57z'
          />
          <path
            fill='#D50000'
            d='M2126.5,1001.64c0.48-4.1,4.42-7.83,7.85-7.45c3.58,0.4,7.17,4.89,6.82,8.53c-0.38,3.97-4.4,8.1-7.66,7.87
			C2129.9,1010.33,2126.06,1005.43,2126.5,1001.64z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1101.1c-0.05,4.76-2.82,7.76-7.09,7.68c-4.15-0.07-7-3.32-6.97-7.97c0.02-4.36,3.3-7.93,7.23-7.87
			C1745.53,1093,1748.53,1096.58,1748.48,1101.1z'
          />
          <path
            fill='#D50000'
            d='M1778.3,735.27c0.02-4.69,2.84-7.89,6.99-7.91c3.85-0.02,7.11,3.6,7.12,7.92c0.02,4.41-3.15,7.94-7.13,7.93
			C1781.12,743.2,1778.28,739.98,1778.3,735.27z'
          />
          <path
            fill='#D50000'
            d='M2170.28,1001.31c-0.02-4.72,2.68-7.97,6.76-8.13c3.89-0.15,7.2,3.35,7.29,7.74c0.1,4.59-3.27,8.63-7.13,8.53
			C2173.43,1009.36,2170.3,1005.68,2170.28,1001.31z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1440.95c-0.04-4.51,3-8.13,6.86-8.13c3.92-0.01,7.18,3.56,7.23,7.9c0.04,4.62-2.83,7.86-7,7.9
			C1129.67,1448.66,1126.97,1445.73,1126.93,1440.95z'
          />
          <path
            fill='#D50000'
            d='M2024.79,727.37c4.12,0.04,6.95,3.29,6.96,7.98c0.01,4.57-2.99,7.88-7.12,7.86c-3.96-0.02-7.13-3.6-7.07-8
			C2017.61,730.94,2020.95,727.33,2024.79,727.37z'
          />
          <path
            fill='#D50000'
            d='M2039.51,735.35c-0.01-4.76,2.72-7.93,6.88-7.98c3.83-0.05,7.21,3.61,7.26,7.84c0.05,4.4-3.1,7.97-7.08,8
			C2042.41,743.24,2039.53,740.03,2039.51,735.35z'
          />
          <path
            fill='#D50000'
            d='M2271.19,736.57c-0.03,4.37-3.26,7.83-7.22,7.74c-3.85-0.08-7.03-3.83-6.9-8.14c0.12-4.18,3.6-7.81,7.38-7.7
			C2268.51,728.58,2271.22,731.85,2271.19,736.57z'
          />
          <path
            fill='#D50000'
            d='M1356.59,1416.19c0.04,4.49-2.96,7.83-7.05,7.84c-4.25,0.02-7.13-3.11-7.14-7.75
			c-0.01-4.18,3.38-8.05,7.06-8.06C1353.27,1408.2,1356.55,1411.87,1356.59,1416.19z'
          />
          <path
            fill='#D50000'
            d='M1822.15,758.35c-0.02-4.84,2.58-7.53,7.23-7.47c4.12,0.05,6.91,3.01,6.96,7.38
			c0.05,4.53-3.43,8.57-7.29,8.45C1825.48,766.59,1822.17,762.59,1822.15,758.35z'
          />
          <path
            fill='#D50000'
            d='M2003.25,750.87c3.92,0.02,7.13,3.16,7.28,7.13c0.14,3.92-3.78,8.6-7.28,8.69c-3.58,0.09-7.65-4.44-7.54-8.4
			C1995.82,754.14,1999.16,750.86,2003.25,750.87z'
          />
          <path
            fill='#D50000'
            d='M2089.76,1400.69c-4.16-0.12-6.98-3.39-6.84-7.93c0.14-4.28,3.54-7.97,7.29-7.9
			c3.75,0.07,7.19,3.88,7.22,8.02C2097.47,1397.21,2093.93,1400.81,2089.76,1400.69z'
          />
          <path
            fill='#D50000'
            d='M2068.7,751.99c4.61,0.05,6.87,2.58,6.89,7.67c0.01,4.12-3.39,8.1-6.99,8.16c-3.8,0.07-7.18-3.97-7.14-8.51
			C2061.52,754.51,2064.07,751.93,2068.7,751.99z'
          />
          <path
            fill='#D50000'
            d='M2162.38,759.58c0.03,4.24-3.31,8.22-6.91,8.25c-3.63,0.03-7.1-3.91-7.14-8.11
			c-0.05-4.86,2.54-7.72,7.01-7.73C2160.02,751.98,2162.34,754.48,2162.38,759.58z'
          />
          <path
            fill='#D50000'
            d='M2170.28,759.38c0.03-4.97,2.47-7.47,7.2-7.39c4.41,0.07,6.98,3.03,6.85,7.89c-0.11,4.21-3.71,8.13-7.31,7.95
			C2173.44,767.65,2170.25,763.66,2170.28,759.38z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1101.04c-0.03-4.49,3.07-8.1,6.94-8.1c3.9,0.01,7.2,3.65,7.21,7.96c0,4.61-2.91,7.85-7.09,7.88
			C2042.29,1108.81,2039.55,1105.8,2039.52,1101.04z'
          />
          <path
            fill='#D50000'
            d='M2271.17,759.76c-0.05,4.27-3.46,8.15-7.08,8.07c-3.64-0.08-6.98-4.03-7.03-8.3
			c-0.05-4.74,2.73-7.62,7.27-7.54C2268.94,752.08,2271.24,754.69,2271.17,759.76z'
          />
          <path
            fill='#D50000'
            d='M2285.99,751.99c4.26-0.04,6.99,2.69,7.15,7.13c0.16,4.54-3.16,8.65-7.05,8.71c-3.57,0.06-7.1-3.99-7.09-8.13
			C2279.01,754.61,2281.34,752.03,2285.99,751.99z'
          />
          <path
            fill='#D50000'
            d='M1293,1390.64c0.01,4.79-2.7,7.86-6.97,7.9c-3.8,0.03-7.33-3.77-7.33-7.89c0-4.3,3.34-7.95,7.29-7.97
			C1290,1382.66,1292.98,1386.05,1293,1390.64z'
          />
          <path
            fill='#D50000'
            d='M1227.98,1391.11c-0.18,4.86-2.91,7.6-7.37,7.42c-3.61-0.15-7.07-4.35-6.85-8.3
			c0.25-4.34,3.69-7.73,7.68-7.55C1225.41,1382.85,1228.16,1386.41,1227.98,1391.11z'
          />
          <path
            fill='#D50000'
            d='M2488.58,1101.45c-0.03,3.75-3.99,8.17-7.35,8.21c-3.91,0.05-6.93-3.72-6.88-8.56
			c0.05-4.47,3.23-8.09,6.99-7.94C2484.72,1093.28,2488.61,1097.73,2488.58,1101.45z'
          />
          <path
            fill='#D50000'
            d='M1148.74,1390.85c-0.07-4.71,2.8-8.17,6.78-8.17c3.95,0,7.36,3.59,7.43,7.82c0.07,3.99-3.47,7.98-7.13,8.03
			C1151.41,1398.6,1148.81,1395.78,1148.74,1390.85z'
          />
          <path
            fill='#D50000'
            d='M2517.28,1101.51c-0.03-4.58,2.79-8.17,6.63-8.4c3.76-0.23,7.22,3.8,7.24,8.44c0.02,4.36-3.26,8.16-7.01,8.13
			C2520.34,1109.65,2517.31,1106.05,2517.28,1101.51z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1390.98c-0.05,4.85-2.65,7.63-7.07,7.55c-3.69-0.06-7.15-3.93-7.11-7.92
			c0.05-4.27,3.31-7.88,7.16-7.94C1094.48,1382.6,1097.23,1385.86,1097.17,1390.98z'
          />
          <path
            fill='#D50000'
            d='M836.9,760.99c-0.05,4.8-2.94,8.1-6.95,7.93c-3.77-0.16-7.27-4.15-7.17-8.17c0.11-4.29,3.49-7.77,7.45-7.66
			C834.82,753.22,836.96,755.75,836.9,760.99z'
          />
          <path
            fill='#D50000'
            d='M1061.03,760.84c0-5.13,2.21-7.68,6.72-7.75c3.96-0.06,7.4,3.4,7.47,7.51c0.07,4.34-3.52,8.37-7.43,8.32
			C1063.79,768.88,1061.03,765.59,1061.03,760.84z'
          />
          <path
            fill='#D50000'
            d='M2262.87,1109.86c-4.1,0.1-7.26-3.14-7.47-7.66c-0.2-4.22,2.99-7.98,6.94-8.17c4.2-0.2,7.77,3.42,7.76,7.88
			C2270.1,1106.17,2266.82,1109.77,2262.87,1109.86z'
          />
          <path
            fill='#D50000'
            d='M1140.33,760.6c0.09,4.36-3.02,8.19-6.75,8.33c-3.95,0.15-7.67-3.75-7.65-8.02c0.02-4.22,3.28-7.76,7.18-7.82
			C1137.55,753.04,1140.23,755.82,1140.33,760.6z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1368.35c0.01,4.7-2.77,7.77-7.07,7.79c-4.27,0.02-7.27-3.16-7.25-7.7c0.02-4.2,3.44-8.07,7.16-8.09
			C1919.67,1360.32,1923.14,1364.2,1923.15,1368.35z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1366.35c-0.08-4.55,2.82-8.13,6.73-8.31c3.8-0.17,7.24,3.47,7.32,7.76
			c0.09,4.69-2.68,7.99-6.81,8.09C1238.49,1374,1235.79,1371.2,1235.71,1366.35z'
          />
          <path
            fill='#D50000'
            d='M2234.13,783.92c-0.03-4.46,3.01-8.33,6.67-8.48c3.8-0.16,7.43,3.96,7.4,8.4c-0.03,4.31-3.33,8.09-7.09,8.13
			C2237.18,792.01,2234.16,788.53,2234.13,783.92z'
          />
          <path
            fill='#D50000'
            d='M2278,783.67c0.02-4.36,3.3-8.29,6.86-8.22c3.74,0.07,7.41,4.39,7.28,8.57c-0.12,3.86-4.03,8.01-7.44,7.92
			C2280.78,791.82,2277.97,788.36,2278,783.67z'
          />
          <path
            fill='#D50000'
            d='M1053.29,785.13c0.08,4.61-2.82,8.1-6.8,8.19c-3.88,0.09-7.25-3.43-7.41-7.74c-0.16-4.26,3.06-8.01,6.94-8.08
			C1050.23,777.43,1053.21,780.55,1053.29,785.13z'
          />
          <path
            fill='#D50000'
            d='M1235.68,785.19c0-4.89,2.56-7.7,7.02-7.68c3.68,0.02,7.19,4,7.06,8.02c-0.14,4.4-3.42,7.88-7.36,7.81
			C1238.2,793.26,1235.68,790.2,1235.68,785.19z'
          />
          <path
            fill='#D50000'
            d='M1256.75,784.76c0.47-4.45,4.3-7.75,8.39-7.22c3.62,0.47,6.74,4.48,6.37,8.19c-0.46,4.63-4.13,8.07-8.1,7.6
			C1259.64,792.88,1256.34,788.63,1256.75,784.76z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1343.29c-0.06,4.93-2.67,7.65-7.21,7.49c-3.87-0.13-6.88-3.48-6.89-7.67
			c-0.01-4.52,3.62-8.57,7.48-8.34C1202.98,1335,1205.96,1338.82,1205.9,1343.29z'
          />
          <path
            fill='#D50000'
            d='M1176.94,1350.78c-3.56-0.08-7.13-4.18-7.02-8.06c0.12-3.91,4-8.05,7.42-7.91c3.71,0.14,7.45,4.62,7.2,8.62
			C1184.28,1347.66,1180.95,1350.87,1176.94,1350.78z'
          />
          <path
            fill='#D50000'
            d='M1097.16,1342.89c0.05,4.94-2.4,7.81-6.73,7.9c-4.03,0.09-7.34-3.27-7.44-7.53c-0.11-4.5,3.29-8.47,7.25-8.46
			C1093.98,1334.81,1097.12,1338.48,1097.16,1342.89z'
          />
          <path
            fill='#D50000'
            d='M1075.83,1343.1c0,4.11-3.38,7.65-7.34,7.68c-3.71,0.03-7.31-3.73-7.37-7.7c-0.06-3.83,3.63-8.14,7.09-8.29
			C1071.82,1334.63,1075.83,1339.01,1075.83,1343.1z'
          />
          <path
            fill='#D50000'
            d='M836.9,785.59c-0.06,4.65-2.62,7.67-6.56,7.73c-4.11,0.07-7.77-3.91-7.55-8.22c0.2-4.09,3.8-7.65,7.65-7.58
			C834.73,777.6,836.97,780.4,836.9,785.59z'
          />
          <path
            fill='#D50000'
            d='M1844.08,1319.15c0.09-4.12,3.78-8.03,7.41-7.88c3.64,0.16,6.89,4.17,6.81,8.4
			c-0.09,4.61-2.99,7.51-7.44,7.47C1846.56,1327.1,1843.97,1324.05,1844.08,1319.15z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1319.63c-0.06-4.24,3.14-8.22,6.74-8.36c3.64-0.14,7.28,3.76,7.39,7.91
			c0.12,4.79-2.49,7.78-6.93,7.94C2064.18,1327.28,2061.54,1324.53,2061.47,1319.63z'
          />
          <path
            fill='#D50000'
            d='M2248.2,808.43c-0.04,4.13-3.46,8.13-7.06,8.25c-3.73,0.13-7.1-4.03-7.01-8.66c0.08-4.27,3.04-7.82,6.63-7.95
			C2244.6,799.94,2248.25,804.04,2248.2,808.43z'
          />
          <path
            fill='#D50000'
            d='M1227.86,1318.57c0,5.01-2.53,7.78-7.02,7.71c-3.85-0.06-6.97-3.4-7.06-7.54c-0.09-4.32,3.66-8.72,7.33-8.6
			C1224.57,1310.25,1227.86,1314.36,1227.86,1318.57z'
          />
          <path
            fill='#D50000'
            d='M670.13,808.65c0.01-4.73,2.77-7.68,7.12-7.63c4.31,0.05,6.98,3.08,6.94,7.88c-0.04,4.28-3.42,8.04-7.17,7.96
			C673.3,816.78,670.12,813,670.13,808.65z'
          />
          <path
            fill='#D50000'
            d='M1148.86,1318.18c0.07-4.18,3.65-8.22,7.1-8.02c3.58,0.21,7.01,4.38,6.98,8.48
			c-0.04,4.37-3.34,7.72-7.48,7.59C1150.96,1326.09,1148.78,1323.42,1148.86,1318.18z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1318.51c0.01,4.94-2.49,7.78-6.88,7.79c-3.99,0.01-7.3-3.5-7.3-7.76c-0.01-4.4,3.64-8.53,7.4-8.38
			C1093.86,1310.29,1097.16,1314.36,1097.17,1318.51z'
          />
          <path
            fill='#D50000'
            d='M1140.35,808.61c0.05,4.32-3.14,8.15-6.86,8.24c-3.96,0.1-7.61-3.82-7.56-8.11c0.05-4.23,3.32-7.72,7.25-7.73
			C1137.6,801,1140.3,803.86,1140.35,808.61z'
          />
          <path
            fill='#D50000'
            d='M1858.31,1125.9c-0.17,4.53-3.29,7.53-7.58,7.32c-4.19-0.21-6.86-3.5-6.65-8.2c0.18-4.15,3.9-7.89,7.57-7.61
			C1855.29,1117.7,1858.47,1121.74,1858.31,1125.9z'
          />
          <path
            fill='#D50000'
            d='M1777.31,809.05c0.02-4.4,3.42-8.16,7.2-7.96c3.94,0.21,6.98,3.87,6.92,8.33c-0.06,4.74-2.87,7.68-7.28,7.63
			C1779.82,817,1777.29,814.04,1777.31,809.05z'
          />
          <path
            fill='#D50000'
            d='M1799.24,832.63c0.04-4.28,3.32-8.23,6.89-8.28c3.86-0.05,7.3,3.97,7.26,8.49c-0.04,4.69-2.82,7.59-7.24,7.56
			C1801.76,840.36,1799.2,837.48,1799.24,832.63z'
          />
          <path
            fill='#D50000'
            d='M720.41,841.26c-3.93-0.03-7.24-3.62-7.27-7.9c-0.03-4.14,3.4-7.95,7.18-7.95c4.17-0.01,7.72,3.79,7.56,8.1
			C727.73,837.67,724.25,841.28,720.41,841.26z'
          />
          <path
            fill='#D50000'
            d='M851.75,825.42c4.02-0.04,7.08,3.34,7.1,7.82c0.02,4.46-3.09,8-7.04,8.03c-3.89,0.03-7.08-3.56-7.1-7.97
			C844.69,828.67,847.57,825.46,851.75,825.42z'
          />
          <path
            fill='#D50000'
            d='M1053.28,833.2c0.04,4.59-2.94,8.05-6.94,8.06c-3.84,0.01-7.2-3.65-7.26-7.91c-0.07-4.27,3.2-7.92,7.09-7.93
			C1050.33,825.4,1053.24,828.58,1053.28,833.2z'
          />
          <path
            fill='#D50000'
            d='M1858.29,833.53c-0.04,4.01-3.17,7.62-6.68,7.71c-3.97,0.1-7.56-3.82-7.53-8.23c0.03-4.15,3.37-7.61,7.35-7.6
			C1856.15,825.42,1858.35,828.02,1858.29,833.53z'
          />
          <path
            fill='#D50000'
            d='M1155.78,1286.84c3.9,0.06,7.26,3.84,7.16,8.06c-0.09,4.13-3.59,7.79-7.43,7.76
			c-4.35-0.03-6.85-2.96-6.79-7.95C1148.79,1289.89,1151.59,1286.78,1155.78,1286.84z'
          />
          <path
            fill='#D50000'
            d='M1068.31,1302.83c-3.69-0.01-7.25-3.9-7.19-7.86c0.06-3.84,3.86-8.1,7.26-8.12c3.62-0.03,7.56,4.46,7.47,8.5
			C1075.76,1299.47,1072.35,1302.85,1068.31,1302.83z'
          />
          <path
            fill='#D50000'
            d='M2278.01,833.83c0.01-4.31,3.28-8.23,6.84-8.2c3.66,0.03,7.4,4.39,7.29,8.52c-0.11,3.94-3.92,8-7.42,7.91
			C2280.85,841.95,2278,838.45,2278.01,833.83z'
          />
          <path
            fill='#D50000'
            d='M2206.21,1270.22c0.03,4.26-3.21,7.81-7.18,7.86c-4.28,0.05-7.28-2.99-7.42-7.54
			c-0.13-4.25,3.57-8.42,7.36-8.31C2202.82,1262.34,2206.19,1266.06,2206.21,1270.22z'
          />
          <path
            fill='#D50000'
            d='M2175.8,1278.07c-4.15-0.06-7.22-3.39-7.17-7.8c0.04-4.07,3.56-8.01,7.18-8.05c3.95-0.04,7.56,3.98,7.43,8.29
			C2183.12,1274.78,2179.82,1278.13,2175.8,1278.07z'
          />
          <path
            fill='#D50000'
            d='M2154.92,1278.08c-4.17-0.02-7.25-3.3-7.26-7.72c-0.01-4.01,3.53-8.05,7.11-8.13c4.02-0.09,7.59,3.82,7.5,8.2
			C2162.19,1274.74,2158.97,1278.1,2154.92,1278.08z'
          />
          <path
            fill='#D50000'
            d='M2133.19,1278.07c-4.3,0.08-7.26-2.91-7.41-7.5c-0.15-4.3,3.45-8.41,7.3-8.34c3.79,0.07,7.22,3.84,7.27,7.97
			C2140.39,1274.46,2137.17,1278,2133.19,1278.07z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1270c0.15,4.23-3.03,7.92-6.96,8.07c-4.36,0.16-7.26-2.65-7.55-7.31c-0.27-4.32,3.16-8.47,7.04-8.53
			C2115.69,1262.17,2119.24,1265.86,2119.39,1270z'
          />
          <path
            fill='#D50000'
            d='M2003.32,1278.08c-4.15,0.06-7.39-3.12-7.61-7.47c-0.21-4.23,3.45-8.36,7.41-8.38
			c3.72-0.02,7.27,3.87,7.3,7.99C2010.46,1274.74,2007.51,1278.02,2003.32,1278.08z'
          />
          <path
            fill='#D50000'
            d='M757.93,857.72c-0.07-5.12,2.33-8.02,6.66-8.05c4.12-0.02,7.37,3.38,7.4,7.75c0.03,4.32-3.18,8.15-7,8.35
			C761.39,865.96,757.99,862.09,757.93,857.72z'
          />
          <path
            fill='#D50000'
            d='M1727.28,1270.49c-0.1,4.41-3.22,7.6-7.42,7.58c-4-0.02-7.28-3.49-7.31-7.74c-0.03-4.24,3.82-8.3,7.7-8.11
			C1723.71,1262.39,1727.37,1266.69,1727.28,1270.49z'
          />
          <path
            fill='#D50000'
            d='M1697.91,1262.22c3.81-0.13,7.31,3.4,7.57,7.65c0.26,4.16-2.93,7.99-6.82,8.2c-4.43,0.24-7.79-3.05-7.83-7.67
			C1690.8,1266.46,1694.36,1262.34,1697.91,1262.22z'
          />
          <path
            fill='#D50000'
            d='M1683.62,1270.37c-0.03,4.73-2.81,7.75-7.09,7.7c-4.32-0.05-7.01-3.05-6.97-7.79
			c0.03-4.26,3.41-8.11,7.07-8.06C1680.25,1262.28,1683.64,1266.23,1683.62,1270.37z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1270.23c0.03-4.24,3.41-8.06,7.08-8c3.61,0.05,7.02,4.06,6.98,8.22c-0.04,4.68-2.91,7.71-7.22,7.63
			C1650.01,1277.99,1647.58,1275.2,1647.61,1270.23z'
          />
          <path
            fill='#D50000'
            d='M1618.45,1270.1c0.15,4.37-2.9,7.85-6.99,7.98c-4.16,0.13-7.39-2.97-7.67-7.35c-0.28-4.22,3.29-8.4,7.26-8.5
			C1614.64,1262.14,1618.32,1266.05,1618.45,1270.1z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1269.75c-0.07,4.92-2.62,7.58-7.16,7.44c-4.03-0.12-7.19-3.7-7.03-7.97
			c0.16-4.26,3.52-8.05,7.18-8.09C1093.82,1261.08,1097.23,1265.28,1097.17,1269.75z'
          />
          <path
            fill='#D50000'
            d='M858.87,857.37c-0.01,4.69-3.36,8.56-7.28,8.39c-3.71-0.15-6.86-3.95-6.9-8.32
			c-0.04-4.64,2.87-7.84,7.06-7.77C856.17,849.75,858.88,852.67,858.87,857.37z'
          />
          <path
            fill='#D50000'
            d='M684.19,857.41c-0.02,4.39-3.23,8.26-6.91,8.35c-3.95,0.09-7.2-3.8-7.14-8.56c0.06-4.6,2.61-7.36,6.86-7.42
			C681.51,849.72,684.21,852.6,684.19,857.41z'
          />
          <path
            fill='#D50000'
            d='M2517.27,1248.45c0-4.6,2.88-8.07,6.74-8.11c3.5-0.04,7.3,4.07,7.42,8.01c0.13,4.13-3.7,8.39-7.52,8.38
			C2520.26,1256.72,2517.27,1253,2517.27,1248.45z'
          />
          <path
            fill='#D50000'
            d='M2147.3,1148.02c0.01-4.48,3.07-8.11,6.86-8.12c3.99-0.02,7.37,3.96,7.22,8.49c-0.14,4.16-3.73,7.98-7.43,7.9
			C2150.11,1156.2,2147.28,1152.69,2147.3,1148.02z'
          />
          <path
            fill='#D50000'
            d='M2226.24,858.47c0,4.56-3,8.28-6.72,8.32c-3.76,0.04-7.44-4.24-7.34-8.54c0.09-4.12,3.65-8.04,7.31-8.03
			C2223.28,850.22,2226.24,853.84,2226.24,858.47z'
          />
          <path
            fill='#D50000'
            d='M2234.13,858.71c-0.06-4.67,2.75-8.31,6.58-8.5c3.8-0.19,7.51,3.92,7.49,8.31c-0.02,4.18-3.35,8.09-7.04,8.28
			C2237.48,866.97,2234.19,863.18,2234.13,858.71z'
          />
          <path
            fill='#D50000'
            d='M2292.14,858.69c-0.1,3.78-3.97,8.06-7.32,8.11c-3.85,0.05-6.93-3.91-6.82-8.75
			c0.09-4.22,3.17-7.82,6.71-7.84C2288.36,850.17,2292.25,854.62,2292.14,858.69z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1245.84c-0.02-4.49,3.08-8.05,7-8.02c3.95,0.03,7.16,3.63,7.14,8c-0.02,4.61-2.96,7.84-7.14,7.85
			C2042.31,1253.67,2039.54,1250.57,2039.52,1245.84z'
          />
          <path
            fill='#D50000'
            d='M786.63,874.45c3.94,0.06,7.28,3.75,7.11,7.84c-0.15,3.75-4.22,7.99-7.61,7.94c-3.48-0.05-7.17-4.38-7.1-8.36
			C779.08,877.92,782.71,874.38,786.63,874.45z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1245.29c-0.03,4.24-3.28,8.25-6.76,8.33c-3.83,0.09-7.41-3.94-7.42-8.36
			c-0.01-4.36,3.14-7.69,7.29-7.67C1094.81,1237.6,1097.21,1240.28,1097.17,1245.29z'
          />
          <path
            fill='#D50000'
            d='M1719.98,912.99c-3.55,0.02-7.28-3.91-7.42-7.81c-0.13-3.8,3.61-8.18,7.07-8.28c3.54-0.1,7.65,4.31,7.7,8.26
			C1727.38,909,1723.64,912.97,1719.98,912.99z'
          />
          <path
            fill='#D50000'
            d='M1858.31,1222.65c-0.03,4.58-2.91,7.53-7.36,7.54c-4.31,0.01-6.95-3.02-6.89-7.88
			c0.06-4.11,3.7-8.06,7.33-7.96C1855.06,1214.45,1858.34,1218.38,1858.31,1222.65z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1222.3c0.08-4.19,3.71-8.15,7.28-7.93c3.85,0.23,6.95,3.95,6.91,8.28
			c-0.05,4.55-2.99,7.56-7.37,7.55C1824.62,1230.18,1822.05,1227.21,1822.15,1222.3z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1222.46c0.02,4.87-2.53,7.74-6.88,7.74c-4.46-0.01-7.23-2.83-7.32-7.45
			c-0.08-4.49,3.27-8.41,7.18-8.39C2028.34,1214.37,2031.73,1218.28,2031.75,1222.46z'
          />
          <path
            fill='#D50000'
            d='M2226.25,978.83c-0.04,4.62-3.06,8.24-6.82,8.17c-3.67-0.07-7.16-3.97-7.24-8.09
			c-0.09-4.25,3.77-8.65,7.43-8.48C2223.29,970.59,2226.29,974.39,2226.25,978.83z'
          />
          <path
            fill='#D50000'
            d='M2017.56,905.68c-0.07-4.67,2.7-7.68,7.14-7.74c4.51-0.06,7.05,2.69,7.06,7.67c0.01,4.25-3.44,8.24-7.06,8.18
			C2020.88,913.73,2017.62,910.03,2017.56,905.68z'
          />
          <path
            fill='#D50000'
            d='M2256.06,906.29c0.03-4.57,3.11-8.23,6.84-8.14c3.51,0.09,7.22,4.24,7.28,8.14c0.06,4.02-3.98,8.54-7.51,8.41
			C2259.01,914.58,2256.03,910.79,2256.06,906.29z'
          />
          <path
            fill='#D50000'
            d='M640.22,907.88c-0.05,3.94-3.99,8.13-7.48,7.97c-3.66-0.17-7.01-4.19-6.96-8.35c0.05-4.56,3.1-7.77,7.28-7.69
			C636.86,899.89,640.27,903.73,640.22,907.88z'
          />
          <path
            fill='#D50000'
            d='M2306.61,947.43c3.49,0.08,7.43,4.38,7.38,8.05c-0.05,3.91-4.38,8.48-7.83,8.28
			c-3.19-0.19-7.2-5.01-7.06-8.49C2299.26,951.22,2302.97,947.34,2306.61,947.43z'
          />
          <path
            fill='#D50000'
            d='M2517.27,1199.64c-0.86-4.65,2.68-8.08,6.99-8.39c3.43-0.24,7.2,4.42,7.17,8.24
			c-0.03,3.86-3.83,8.13-7.28,8.21C2520.39,1207.78,2517.31,1204.17,2517.27,1199.64z'
          />
          <path
            fill='#D50000'
            d='M814.93,907.6c0.02,4.49-3.02,8.21-6.75,8.26c-3.53,0.05-7.23-4.04-7.34-8.09c-0.1-4.11,3.32-7.88,7.23-7.97
			C812.44,899.69,814.9,902.5,814.93,907.6z'
          />
          <path
            fill='#D50000'
            d='M836.9,907.47c0.02,4.54-2.88,8.22-6.61,8.39c-3.44,0.15-7.35-4.02-7.5-8c-0.15-4.06,3.29-7.92,7.18-8.05
			C834.42,899.66,836.88,902.38,836.9,907.47z'
          />
          <path
            fill='#D50000'
            d='M858.86,955.67c-0.1,4.48-3.2,8.05-7.04,8.11c-3.87,0.06-7.22-3.94-7.14-8.53c0.08-4.61,3.07-7.68,7.29-7.48
			C856.44,947.98,858.97,950.88,858.86,955.67z'
          />
          <path
            fill='#D50000'
            d='M814.93,955.67c-0.02,4.45-3.2,8.18-6.92,8.11c-3.52-0.06-7.2-4.29-7.18-8.25c0.03-4.08,3.55-7.81,7.39-7.84
			C812.56,947.67,814.95,950.52,814.93,955.67z'
          />
          <path
            fill='#D50000'
            d='M857.87,1198.94c0.03,5.22-2.16,7.77-6.74,7.85c-3.93,0.07-7.36-3.51-7.41-7.74
			c-0.05-4.16,3.37-7.96,7.25-8.07C855.06,1190.87,857.84,1194.08,857.87,1198.94z'
          />
          <path
            fill='#D50000'
            d='M2133.64,921.42c3.1-0.11,7.39,4.43,7.6,8.05c0.22,3.81-4,8.61-7.51,8.54c-3.32-0.07-7.25-4.53-7.26-8.26
			C2126.45,925.94,2130.24,921.54,2133.64,921.42z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1148.88c-0.01-4.5,3.11-8.06,7.02-8.01c3.94,0.05,7.15,3.66,7.12,8.03
			c-0.03,4.55-3.02,7.81-7.17,7.82C2042.27,1156.72,2039.52,1153.63,2039.52,1148.88z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1149.03c-0.07-4.5,2.96-8.11,6.87-8.16c3.95-0.05,7.19,3.47,7.24,7.87
			c0.05,4.59-2.83,7.89-6.98,7.98C2064.35,1156.8,2061.56,1153.8,2061.48,1149.03z'
          />
          <path
            fill='#D50000'
            d='M1785.79,938.66c-3.67,0-7.25-3.87-7.38-7.95c-0.11-3.73,3.76-8.2,7.18-8.3c3.33-0.09,7.59,4.61,7.62,8.4
			C1793.24,934.75,1789.54,938.66,1785.79,938.66z'
          />
          <path
            fill='#D50000'
            d='M1756.37,1198.01c-0.04-4.35,3.26-8.19,6.97-8.11c3.92,0.08,7.06,3.64,7.1,8.03
			c0.04,4.66-2.79,7.79-7.05,7.78C1758.9,1205.72,1756.42,1202.99,1756.37,1198.01z'
          />
          <path
            fill='#D50000'
            d='M2249.2,1175.37c0.02,4.63-2.84,7.99-6.85,8.08c-3.74,0.08-7.19-3.69-7.21-7.88
			c-0.03-4.37,3.13-7.92,7.06-7.94C2246.54,1167.61,2249.18,1170.53,2249.2,1175.37z'
          />
          <path
            fill='#D50000'
            d='M806.74,1157.78c-4.59-0.08-6.95-2.75-6.9-7.81c0.05-4.42,3.38-8.12,7.2-7.98c3.83,0.14,6.91,3.85,6.88,8.32
			C813.9,1155.29,811.42,1157.87,806.74,1157.78z'
          />
          <path
            fill='#D50000'
            d='M2112.17,1165.28c3.77,0.1,7.21,3.92,7.23,8.02c0.02,4.46-3.46,7.98-7.71,7.83c-4.11-0.15-7.06-3.61-6.84-8
			C2105.06,1168.81,2108.45,1165.19,2112.17,1165.28z'
          />
          <path
            fill='#D50000'
            d='M923.74,932.33c0,4.17-3.42,8.16-6.97,8.12c-3.54-0.04-7.23-4.19-7.3-8.19c-0.07-4.2,3.2-7.53,7.44-7.57
			C921.59,924.63,923.74,927.04,923.74,932.33z'
          />
          <path
            fill='#D50000'
            d='M2141.2,952.98c0,4.11-3.4,7.6-7.42,7.63c-3.83,0.03-7.27-3.58-7.32-7.69c-0.06-4.4,3.56-8.28,7.59-8.13
			C2137.67,944.92,2141.2,948.96,2141.2,952.98z'
          />
          <path
            fill='#D50000'
            d='M1669.57,1173.28c0.05-4.24,3.47-8.09,7.11-8.01c3.63,0.09,6.99,4.05,6.94,8.2
			c-0.05,4.72-2.85,7.71-7.14,7.65C1672.16,1181.06,1669.51,1178.06,1669.57,1173.28z'
          />
          <path
            fill='#D50000'
            d='M2170.28,953.85c-0.09-5,2.31-7.85,6.71-7.97c4.28-0.12,7.23,2.9,7.33,7.51c0.09,4.17-3.2,8.18-6.83,8.34
			C2173.85,961.88,2170.36,958.07,2170.28,953.85z'
          />
          <path
            fill='#D50000'
            d='M1814.38,954.77c-0.21,4.67-3.09,7.39-7.56,7.13c-4.11-0.23-6.74-3.53-6.58-8.26
			c0.15-4.28,3.64-7.89,7.43-7.69C1811.61,946.18,1814.59,950.09,1814.38,954.77z'
          />
          <path
            fill='#D50000'
            d='M1756.36,954.31c-0.04-4.49,3-8.23,6.81-8.37c3.76-0.13,7.15,3.58,7.27,7.97c0.13,4.72-2.6,7.9-6.89,8
			C1759.16,962.02,1756.4,959.1,1756.36,954.31z'
          />
          <path
            fill='#D50000'
            d='M1785.81,961.98c-3.6-0.02-7.28-3.93-7.39-7.84c-0.11-3.79,3.7-8.16,7.14-8.2c3.6-0.04,7.67,4.37,7.66,8.31
			C1793.2,958.15,1789.52,962,1785.81,961.98z'
          />
          <path
            fill='#D50000'
            d='M2198.84,1158.89c-4.49-0.03-6.88-2.76-6.83-7.84c0.04-4.41,3.37-8.11,7.19-7.99
			c3.74,0.12,7.22,4.15,7.1,8.22C2206.17,1155.42,2202.75,1158.92,2198.84,1158.89z'
          />
          <path
            fill='#D50000'
            d='M2249.2,1151.1c0.04,5.07-2.36,7.86-6.81,7.93c-4.28,0.06-7.18-3.03-7.25-7.72c-0.06-4.2,3.32-8.19,6.97-8.24
			C2245.88,1143.01,2249.16,1146.73,2249.2,1151.1z'
          />
          <path
            fill='#D50000'
            d='M2270.18,783.71c-0.02,3.81-3.81,8.14-7.19,8.21c-4.02,0.09-7.01-3.56-6.93-8.45c0.07-4.33,3.48-8.24,7-8.03
			C2266.47,775.65,2270.2,779.98,2270.18,783.71z'
          />
          <path
            fill='#D50000'
            d='M1227.93,1441.51c-0.3,4.96-2.91,7.41-7.58,7.1c-3.45-0.23-6.69-4.18-6.59-8.03c0.12-4.52,3.79-8.1,7.96-7.77
			C1225.68,1433.13,1228.22,1436.69,1227.93,1441.51z'
          />
          <path
            fill='#D50000'
            d='M749.04,1150.99c-0.01,5.23-2.22,7.82-6.71,7.86c-3.92,0.04-7.28-3.53-7.33-7.79
			c-0.05-4.16,3.32-7.91,7.2-8.01C746.3,1142.95,749.05,1146.14,749.04,1150.99z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1997.91c-0.02-5.16,2.23-7.92,6.56-8.06c3.92-0.12,7.64,3.91,7.5,8.13
			c-0.15,4.26-3.6,7.79-7.53,7.69C1129.57,2005.57,1126.95,2002.45,1126.93,1997.91z'
          />
          <path
            fill='#D50000'
            d='M1558.76,1173.43c-0.02-4.18,3.36-8.17,6.91-8.14c3.71,0.03,7.19,3.9,7.23,8.04c0.04,4.52-2.97,7.8-7.14,7.79
			C1561.31,1181.12,1558.78,1178.33,1558.76,1173.43z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1974.66c0.02-4.43,3.27-8.18,6.98-8.06c3.63,0.11,7.09,4.15,7.1,8.27c0,4.36-3.15,7.59-7.4,7.58
			C1129.01,1982.44,1126.9,1979.99,1126.93,1974.66z'
          />
          <path
            fill='#D50000'
            d='M1618.51,1173.47c-0.02,4.34-3.2,7.65-7.37,7.65c-4.05,0.01-7.32-3.39-7.36-7.65
			c-0.05-4.33,3.62-8.29,7.57-8.2C1614.81,1165.37,1618.52,1169.61,1618.51,1173.47z'
          />
          <path
            fill='#D50000'
            d='M1096.14,1949.71c-0.04,4.57-3.17,8.2-6.93,8.04c-3.74-0.16-7.22-4.12-7.21-8.2
			c0.01-3.94,3.59-7.58,7.43-7.57C1094.21,1942,1096.19,1944.29,1096.14,1949.71z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1173.26c0.04-4.25,3.44-8.07,7.11-7.98c3.62,0.09,7.02,4.12,6.96,8.26
			c-0.06,4.67-2.95,7.69-7.25,7.59C1649.99,1181.03,1647.56,1178.22,1647.61,1173.26z'
          />
          <path
            fill='#D50000'
            d='M1242.69,451.27c-4.11,0.13-7.07-3.01-7.22-7.67c-0.16-4.79,2.42-7.99,6.57-8.17
			c4.27-0.18,7.65,3.24,7.73,7.81C1249.84,447.45,1246.59,451.14,1242.69,451.27z'
          />
          <path
            fill='#D50000'
            d='M1719.82,1165.28c3.56-0.09,7.21,3.79,7.4,7.87c0.21,4.33-2.86,7.85-6.95,7.98
			c-4.27,0.14-7.76-3.41-7.72-7.83C1712.6,1169.23,1716.09,1165.37,1719.82,1165.28z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1173.28c0.02,4.94-2.48,7.79-6.87,7.85c-4.35,0.06-7.17-2.93-7.2-7.64c-0.03-4.16,3.36-8.14,7-8.21
			C1745.1,1165.2,1748.47,1169.02,1748.48,1173.28z'
          />
          <path
            fill='#D50000'
            d='M1183.94,1831.04c-0.02,5.07-2.41,7.61-7.05,7.53c-3.7-0.07-6.99-3.56-7.03-7.47
			c-0.05-4.49,3.44-8.44,7.4-8.37C1181.14,1822.8,1183.96,1826.3,1183.94,1831.04z'
          />
          <path
            fill='#D50000'
            d='M1270.9,443.53c-0.01,4.76-2.78,7.83-6.97,7.73c-3.9-0.09-7.23-3.73-7.25-7.92
			c-0.02-4.01,3.47-7.87,7.18-7.92C1268.41,435.35,1270.92,438.24,1270.9,443.53z'
          />
          <path
            fill='#D50000'
            d='M1814.39,1173.36c0,4.84-2.61,7.74-6.99,7.77c-4.36,0.03-7.14-2.98-7.14-7.73c0-4.2,3.43-8.12,7.11-8.12
			C1811.04,1165.28,1814.39,1169.13,1814.39,1173.36z'
          />
          <path
            fill='#D50000'
            d='M1719.82,961.98c-3.58-0.06-7.25-4.07-7.26-7.93c-0.01-3.87,3.79-8.13,7.23-8.11
			c3.59,0.02,7.64,4.54,7.56,8.42C1727.27,958.28,1723.54,962.04,1719.82,961.98z'
          />
          <path
            fill='#D50000'
            d='M1697.87,961.98c-3.69-0.09-7.25-4.13-7.06-8.01c0.2-3.89,3.95-7.99,7.38-8.05c3.41-0.06,7.26,4.21,7.29,8.07
			C1705.5,958.43,1702.03,962.08,1697.87,961.98z'
          />
          <path
            fill='#D50000'
            d='M1683.62,954.07c0.01,4.82-2.7,7.83-7.04,7.85c-4.39,0.02-6.96-2.85-7.01-7.84
			c-0.05-4.42,3.17-8.16,6.99-8.13C1680.45,945.99,1683.61,949.63,1683.62,954.07z'
          />
          <path
            fill='#D50000'
            d='M1191.6,1806.16c0.07-4.6,3.21-8.03,7.19-7.83c3.91,0.2,7.24,4.05,7.06,8.17c-0.18,4.12-3.7,7.73-7.45,7.64
			C1194.07,1814.03,1191.52,1811.04,1191.6,1806.16z'
          />
          <path
            fill='#D50000'
            d='M1183.94,1806.55c-0.01,4.94-2.54,7.66-7.05,7.58c-3.71-0.07-7.16-4-7.03-8.01c0.14-4.32,3.48-7.84,7.42-7.8
			C1181.3,1798.36,1183.94,1801.63,1183.94,1806.55z'
          />
          <path
            fill='#D50000'
            d='M945.87,1029.26c0.04,4.23-3.38,8.19-7.03,8.13c-3.73-0.06-7.36-3.96-7.46-8.01
			c-0.1-3.96,3.47-7.72,7.38-7.75C943.34,1021.6,945.82,1024.26,945.87,1029.26z'
          />
          <path
            fill='#D50000'
            d='M1233.71,467.75c0.05-4.84,2.7-7.72,7.1-7.7c4.02,0.02,7.05,3.5,6.96,7.98c-0.09,4.26-3.49,7.94-7.26,7.85
			C1236.52,475.8,1233.66,472.38,1233.71,467.75z'
          />
          <path
            fill='#D50000'
            d='M1183.94,1781.87c0.02,5.14-2.31,7.74-6.91,7.68c-3.94-0.05-7.28-3.72-7.17-7.88
			c0.12-4.35,3.44-7.97,7.29-7.93C1181.26,1773.77,1183.92,1776.95,1183.94,1781.87z'
          />
          <path
            fill='#D50000'
            d='M2010.41,1173.45c-0.07,4.55-3.05,7.71-7.27,7.71c-4.04,0-7.37-3.43-7.45-7.67
			c-0.08-4.27,3.65-8.29,7.62-8.21C2007.02,1165.36,2010.47,1169.34,2010.41,1173.45z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1173.81c-0.18,4.77-3.04,7.55-7.52,7.33c-4.17-0.21-6.88-3.54-6.67-8.21
			c0.19-4.23,3.8-7.89,7.54-7.64C2028.68,1165.53,2031.9,1169.67,2031.75,1173.81z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1173.56c-0.05-4.28,3.16-8.17,6.83-8.28c3.67-0.11,7.25,3.78,7.32,7.95c0.07,4.75-2.69,7.86-7,7.9
			C2042.12,1181.18,2039.58,1178.48,2039.52,1173.56z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1173.61c-0.05-4.31,3.11-8.19,6.8-8.33c3.65-0.14,7.25,3.74,7.32,7.87c0.07,4.8-2.59,7.9-6.87,7.98
			C2064.33,1181.22,2061.53,1178.32,2061.47,1173.61z'
          />
          <path
            fill='#D50000'
            d='M857.87,1150.45c-0.07,5.11-2.36,7.46-7.15,7.33c-3.81-0.1-7.19-3.97-6.99-8.02
			c0.18-3.82,3.72-7.69,7.11-7.77C854.76,1141.9,857.94,1145.72,857.87,1150.45z'
          />
          <path
            fill='#D50000'
            d='M828.53,1157.78c-4.52-0.01-6.77-2.62-6.76-7.83c0.01-4.33,3.45-8.15,7.17-7.95c3.9,0.21,7.01,3.91,6.97,8.3
			C835.86,1154.86,832.97,1157.79,828.53,1157.78z'
          />
          <path
            fill='#D50000'
            d='M1560.75,467.5c0.08-5.03,2.57-7.61,7.18-7.44c3.69,0.13,6.84,3.65,6.93,7.73c0.09,4.26-3.72,8.25-7.74,8.09
			C1563.02,475.72,1560.66,472.62,1560.75,467.5z'
          />
          <path
            fill='#D50000'
            d='M1589.5,460.06c3.91-0.03,7.32,3.6,7.32,7.82c0.01,4.07-3.5,7.9-7.31,8c-4.11,0.11-6.8-3.05-6.8-7.99
			C1582.72,462.77,1585.04,460.09,1589.5,460.06z'
          />
          <path
            fill='#D50000'
            d='M1617.77,467.58c0.02,5.14-2.36,8.2-6.45,8.3c-4.02,0.1-7.65-3.8-7.59-8.15c0.06-4.05,3.24-7.56,6.95-7.68
			C1615.27,459.91,1617.75,462.54,1617.77,467.58z'
          />
          <path
            fill='#D50000'
            d='M2162.38,492.16c0.09,4.85-2.5,8-6.68,8.13c-3.94,0.13-7.26-3.3-7.37-7.61c-0.11-4.24,3.09-8.09,6.85-8.24
			C2159.17,484.28,2162.3,487.63,2162.38,492.16z'
          />
          <path
            fill='#D50000'
            d='M901.8,1174.49c-0.02,4.86-2.63,7.76-7.01,7.76c-3.99,0-7.34-3.61-7.25-7.83c0.09-4.19,3.48-7.91,7.28-7.99
			C898.66,1166.36,901.81,1170,901.8,1174.49z'
          />
          <path
            fill='#D50000'
            d='M1698.16,1165.27c3.78,0,7.21,3.68,7.33,7.87c0.13,4.47-3.27,8.07-7.54,7.99c-4.1-0.08-7.17-3.5-7.06-7.89
			C1690.98,1169.11,1694.5,1165.27,1698.16,1165.27z'
          />
          <path
            fill='#D50000'
            d='M2177.39,484.43c3.73,0.09,7.02,3.94,6.95,8.15c-0.08,4.35-3.34,7.8-7.29,7.7c-4.26-0.11-6.82-3.15-6.76-8.02
			C2170.34,487.72,2173.4,484.34,2177.39,484.43z'
          />
          <path
            fill='#D50000'
            d='M2162.38,516.83c-0.02,4.15-3.52,8.17-7.05,8.1c-3.61-0.08-6.92-3.99-6.99-8.27
			c-0.08-4.59,2.82-7.64,7.18-7.57C2160.1,509.16,2162.4,511.75,2162.38,516.83z'
          />
          <path
            fill='#D50000'
            d='M1538.8,518.17c-0.04-4.93,2.48-7.91,6.75-7.98c3.96-0.07,7.33,3.44,7.42,7.72c0.09,3.99-3.48,8.05-7.13,8.12
			C1541.78,526.11,1538.83,522.83,1538.8,518.17z'
          />
          <path
            fill='#D50000'
            d='M1582.71,518.11c-0.01-5.21,2.23-7.85,6.72-7.92c3.93-0.06,7.38,3.56,7.4,7.77c0.02,4.09-3.43,7.94-7.24,8.08
			C1585.45,526.18,1582.73,523.04,1582.71,518.11z'
          />
          <path
            fill='#D50000'
            d='M2242.49,526.69c-3.35-0.12-7.27-4.6-7.21-8.25c0.06-3.74,4.05-8.13,7.4-8.11c3.52,0.02,7.66,4.81,7.4,8.57
			C2249.83,522.69,2245.81,526.81,2242.49,526.69z'
          />
          <path
            fill='#D50000'
            d='M1227.99,1732.78c0.01,5.03-2.43,7.76-6.95,7.78c-3.87,0.02-7.35-3.7-7.28-7.79
			c0.07-4.29,3.37-7.97,7.23-8.05C1224.92,1724.63,1227.98,1728.15,1227.99,1732.78z'
          />
          <path
            fill='#D50000'
            d='M1119.07,1732.72c0.07,5.13-2.25,7.79-6.79,7.83c-4.28,0.03-7.33-3.15-7.32-7.64
			c0.01-4.37,3.17-8.03,7.06-8.19C1115.88,1724.57,1119.01,1728.13,1119.07,1732.72z'
          />
          <path
            fill='#D50000'
            d='M1583.71,1197.89c0.02-4.38,3.33-8.12,7.08-7.99c3.88,0.13,7.12,3.89,7.04,8.17c-0.09,4.66-3,7.73-7.27,7.67
			C1586.06,1205.67,1583.69,1202.96,1583.71,1197.89z'
          />
          <path
            fill='#D50000'
            d='M1089.46,1740.56c-4.47-0.13-6.67-2.84-6.55-8.07c0.11-4.7,3.22-8.05,7.22-7.76c3.74,0.26,7.21,4.41,7,8.36
			C1096.9,1737.34,1093.48,1740.68,1089.46,1740.56z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1198.07c-0.03,4.89-2.6,7.66-7.1,7.66c-4.2,0-7.04-3.19-6.97-7.84c0.06-4.38,3.24-7.95,7.13-7.99
			C1636.51,1189.86,1639.76,1193.69,1639.73,1198.07z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1197.2c0.22-4.02,3.71-7.53,7.26-7.3c4.04,0.26,7.15,4.32,6.8,8.86c-0.34,4.4-3.21,7.1-7.39,6.97
			C1649.75,1205.59,1647.33,1202.48,1647.61,1197.2z'
          />
          <path
            fill='#D50000'
            d='M873.79,534.59c3.86,0.05,7.19,3.83,7.03,7.97c-0.17,4.34-3.53,7.89-7.44,7.86
			c-3.65-0.03-6.78-3.88-6.74-8.31C866.67,537.23,869.24,534.53,873.79,534.59z'
          />
          <path
            fill='#D50000'
            d='M2017.56,542.44c-0.05-4.96,2.42-7.8,6.85-7.86c4.27-0.05,7.3,3.09,7.35,7.6c0.04,4.34-3.17,8.13-6.98,8.22
			C2020.96,550.49,2017.6,546.78,2017.56,542.44z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1685.89c0.06,4.93-2.44,7.74-6.95,7.8c-4.35,0.06-7.26-3-7.24-7.61c0.02-4.3,3.27-8.13,6.99-8.22
			C2028.2,1677.78,2031.69,1681.67,2031.75,1685.89z'
          />
          <path
            fill='#D50000'
            d='M836.9,565.89c-0.04,4.38-3.32,8.19-6.92,8.04c-3.58-0.16-7.28-4.38-7.2-8.24c0.08-4.18,3.46-7.61,7.46-7.58
			C834.92,558.14,836.94,560.5,836.9,565.89z'
          />
          <path
            fill='#D50000'
            d='M2097.52,566.21c-0.07,5-2.58,7.79-6.97,7.74c-3.7-0.04-7.14-3.94-7.09-8.02c0.05-4.29,3.33-7.8,7.33-7.82
			C2094.9,558.08,2097.59,561.32,2097.52,566.21z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1683.61c0.03-4.43,3.28-8.04,7.1-7.91c3.86,0.13,7.03,3.84,6.96,8.14c-0.07,4.61-3,7.73-7.2,7.68
			C1238.27,1691.46,1235.67,1688.47,1235.71,1683.61z'
          />
          <path
            fill='#D50000'
            d='M757.93,1052.59c-0.02-4.93,2.58-8.1,6.65-8.12c3.89-0.02,7.29,3.55,7.41,7.78c0.13,4.51-3.25,8.49-7.21,8.49
			C761.09,1060.73,757.95,1057,757.93,1052.59z'
          />
          <path
            fill='#D50000'
            d='M2220.64,558.12c4.05-0.1,7.34,3.27,7.52,7.69c0.16,4.13-3.22,8.06-7.01,8.15c-4.34,0.11-7.2-2.9-7.25-7.62
			C2213.85,561.57,2216.6,558.22,2220.64,558.12z'
          />
          <path
            fill='#D50000'
            d='M2285.81,573.96c-4.05-0.1-6.86-3.43-6.81-8.06c0.05-4.35,3.38-7.94,7.22-7.78c3.84,0.16,7.01,3.91,6.92,8.17
			C2293.05,570.45,2289.6,574.05,2285.81,573.96z'
          />
          <path
            fill='#D50000'
            d='M2335.07,1028.07c-0.06,4.5-3.33,8.16-7.07,7.95c-3.74-0.21-7.1-4.09-7.12-8.22
			c-0.02-4.17,3.97-8.47,7.67-8.25C2332.24,1019.77,2335.13,1023.54,2335.07,1028.07z'
          />
          <path
            fill='#D50000'
            d='M814.93,590.18c0,4.68-2.85,8.12-6.75,8.16c-3.76,0.03-7.35-3.87-7.33-7.97c0.02-3.99,3.57-7.83,7.27-7.86
			C812.71,582.45,814.93,584.96,814.93,590.18z'
          />
          <path
            fill='#D50000'
            d='M836.9,590.41c-0.03,4.54-3.14,8.06-6.98,7.91c-3.78-0.14-7.26-4.15-7.13-8.2c0.13-4,3.74-7.66,7.52-7.63
			C834.84,582.54,836.94,585.06,836.9,590.41z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1658.86c0.1-4.5,3.27-7.85,7.29-7.72c3.76,0.12,6.89,3.96,6.77,8.29c-0.12,4.53-3.23,7.71-7.37,7.55
			C1238.15,1666.81,1235.6,1663.72,1235.71,1658.86z'
          />
          <path
            fill='#D50000'
            d='M2192.01,1054.18c0.02-4.6,3.08-8.13,7.02-8.07c3.79,0.05,7.27,3.9,7.26,8.02c-0.01,4.21-3.36,7.81-7.27,7.81
			C2194.53,1061.94,2192,1059.13,2192.01,1054.18z'
          />
          <path
            fill='#D50000'
            d='M692.09,1075.19c0-5.14,2.31-7.91,6.66-8c3.67-0.08,7.24,3.68,7.38,7.77c0.14,4.21-3.13,7.95-7.09,8.09
			C694.89,1083.2,692.09,1080.03,692.09,1075.19z'
          />
          <path
            fill='#D50000'
            d='M1756.37,1027.57c0.04-4.29,3.45-8.25,7.05-8.19c3.7,0.06,6.99,3.95,7.03,8.3c0.04,4.71-2.79,7.78-7.16,7.76
			C1758.93,1035.43,1756.32,1032.46,1756.37,1027.57z'
          />
          <path
            fill='#D50000'
            d='M2226.26,930.95c0,4.53-3,8.14-6.79,8.2c-3.65,0.05-7.21-3.86-7.29-8.02c-0.08-4.31,3.61-8.57,7.38-8.51
			C2223.26,922.67,2226.26,926.4,2226.26,930.95z'
          />
          <path
            fill='#D50000'
            d='M1584.72,590.91c-0.06-4.29,3.19-8.28,6.8-8.35c3.57-0.07,7.18,3.91,7.31,8.07c0.16,4.8-2.39,7.59-7.03,7.7
			C1587.07,598.45,1584.78,596.06,1584.72,590.91z'
          />
          <path
            fill='#D50000'
            d='M1604.7,590.91c-0.05-4.29,3.2-8.3,6.78-8.35c3.64-0.06,7.13,3.82,7.28,8.07c0.17,4.8-2.37,7.59-7,7.71
			C1607.04,598.45,1604.76,596.06,1604.7,590.91z'
          />
          <path
            fill='#D50000'
            d='M2313.8,931.03c-0.07,3.79-4.03,8.12-7.38,8.07c-3.7-0.05-7.3-4.04-7.34-8.1c-0.04-3.84,4.34-8.64,7.67-8.42
			C2310.03,922.79,2313.87,927.39,2313.8,931.03z'
          />
          <path
            fill='#D50000'
            d='M2256.06,930.85c0.01-4.37,3.16-8.2,6.76-8.22c3.54-0.01,7.46,4.55,7.36,8.57c-0.09,3.89-3.89,7.92-7.44,7.91
			C2259.11,939.1,2256.05,935.32,2256.06,930.85z'
          />
          <path
            fill='#D50000'
            d='M1626.66,590.93c-0.06-4.28,3.19-8.3,6.75-8.36c3.64-0.07,7.14,3.79,7.3,8.05c0.18,4.84-2.31,7.59-6.98,7.72
			C1629,598.46,1626.74,596.09,1626.66,590.93z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1075.37c-0.01,4.88-2.59,7.7-7.05,7.69c-4.33-0.01-7.05-3.03-7.01-7.79
			c0.04-4.25,3.39-8.06,7.09-8.07C1636.37,1067.19,1639.73,1071.13,1639.73,1075.37z'
          />
          <path
            fill='#D50000'
            d='M1756.37,930.89c-0.04-4.32,3.17-8.3,6.79-8.44c3.86-0.15,7.36,3.96,7.28,8.53c-0.08,4.52-2.72,7.39-6.9,7.49
			C1759.1,938.57,1756.41,935.73,1756.37,930.89z'
          />
          <path
            fill='#D50000'
            d='M757.93,955.76c-0.07-5.1,2.36-8.03,6.67-8.05c4.1-0.02,7.35,3.39,7.39,7.76c0.05,4.36-3.11,8.11-6.99,8.32
			C761.3,963.97,757.99,960.22,757.93,955.76z'
          />
          <path
            fill='#D50000'
            d='M829.63,1190.98c4.08,0.11,7.55,4.17,7.25,8.49c-0.27,4-3.6,7.35-7.28,7.32c-4.76-0.04-7.05-2.82-6.84-8.28
			C822.94,1193.99,825.77,1190.88,829.63,1190.98z'
          />
          <path
            fill='#D50000'
            d='M1249.77,1635.04c-0.1,4.71-2.94,7.6-7.34,7.48c-4.41-0.12-6.82-2.99-6.72-7.99
			c0.09-4.36,3.53-8.07,7.28-7.83C1246.86,1626.95,1249.86,1630.65,1249.77,1635.04z'
          />
          <path
            fill='#D50000'
            d='M1227.98,1635.07c-0.14,4.95-2.72,7.59-7.29,7.46c-3.49-0.1-6.91-3.87-6.93-7.65
			c-0.03-4.58,3.41-8.29,7.58-8.18C1225.33,1626.81,1228.11,1630.32,1227.98,1635.07z'
          />
          <path
            fill='#D50000'
            d='M692.09,907.82c-0.04-5.18,2.27-7.94,6.67-7.96c4.15-0.02,7.37,3.35,7.38,7.74c0.01,4.29-3.25,8.1-7.06,8.25
			C695.35,915.99,692.13,912.3,692.09,907.82z'
          />
          <path
            fill='#D50000'
            d='M2192.21,955.58c-0.04-4.53,2.93-8.31,6.59-8.37c3.58-0.06,7.57,4.45,7.49,8.45
			c-0.08,3.92-3.79,8.06-7.26,8.11C2195.44,963.84,2192.25,960,2192.21,955.58z'
          />
          <path
            fill='#D50000'
            d='M2256.05,955.47c-0.01-4.56,3.06-8.33,6.72-8.26c3.64,0.07,7.53,4.58,7.41,8.57
			c-0.13,3.96-3.77,7.9-7.39,7.98C2259.24,963.84,2256.06,959.93,2256.05,955.47z'
          />
          <path
            fill='#D50000'
            d='M2278,955.49c0.02-4.7,2.91-8.24,6.76-8.25c3.49-0.01,7.31,4.15,7.4,8.05c0.09,4.17-3.75,8.59-7.38,8.49
			C2281.01,963.67,2277.98,959.96,2278,955.49z'
          />
          <path
            fill='#D50000'
            d='M2285.84,582.5c4.14-0.09,7.28,3.15,7.31,7.55c0.03,4.38-3.08,8.1-6.9,8.26c-3.75,0.15-7.19-3.59-7.24-7.9
			C2278.94,585.57,2281.51,582.59,2285.84,582.5z'
          />
          <path
            fill='#D50000'
            d='M764.84,899.82c4.06,0.07,7.25,3.62,7.16,7.96c-0.09,4.36-3.44,8.11-7.22,8.09
			c-3.91-0.02-6.98-3.96-6.84-8.77C758.07,902.44,760.63,899.74,764.84,899.82z'
          />
          <path
            fill='#D50000'
            d='M749.05,907.59c0.01,4.51-3.04,8.26-6.74,8.27c-3.49,0.02-7.25-4.14-7.32-8.11
			c-0.07-4.11,3.34-7.88,7.22-7.98C746.54,899.67,749.04,902.53,749.05,907.59z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1075.26c0.03-4.17,3.54-8.12,7.15-8.05c3.71,0.07,7.05,3.96,7.04,8.19c0,4.66-2.89,7.74-7.21,7.7
			C1824.7,1083.05,1822.12,1080.14,1822.15,1075.26z'
          />
          <path
            fill='#D50000'
            d='M2112.58,607.14c4.15,0.06,6.9,3.01,6.9,7.42c0.01,4.53-3.58,8.62-7.37,8.41c-3.64-0.2-6.78-4.14-6.7-8.4
			C2105.52,609.52,2107.88,607.07,2112.58,607.14z'
          />
          <path
            fill='#D50000'
            d='M1314.68,1610.3c-0.03,5.09-2.4,7.69-6.97,7.63c-3.62-0.04-7.07-3.61-7.18-7.41
			c-0.12-4.38,3.46-8.46,7.42-8.44C1311.88,1602.11,1314.71,1605.56,1314.68,1610.3z'
          />
          <path
            fill='#D50000'
            d='M2220.77,607.13c4.07-0.03,7.37,3.38,7.39,7.62c0.02,4.12-3.41,8.07-7.13,8.22
			c-3.81,0.15-6.98-3.45-7.09-8.06C2213.83,609.84,2216.18,607.17,2220.77,607.13z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1610.34c-0.06-4.23,3.2-8.17,6.84-8.26c3.68-0.09,7.13,3.7,7.22,7.94c0.1,4.74-2.61,7.84-6.89,7.9
			C1238.42,1617.99,1235.78,1615.19,1235.71,1610.34z'
          />
          <path
            fill='#D50000'
            d='M1184.52,1610.37c-0.12,4.53-3.15,7.62-7.41,7.56c-3.97-0.06-7.23-3.62-7.19-7.86c0.04-4.38,3.75-8.24,7.69-8
			C1181.31,1602.3,1184.63,1606.29,1184.52,1610.37z'
          />
          <path
            fill='#D50000'
            d='M1039.94,1610.02c0.05-4.49,3.29-8.08,7.16-7.92c3.72,0.15,7.29,4.25,7.16,8.23
			c-0.13,4.27-3.49,7.65-7.51,7.59C1042.31,1617.84,1039.88,1615.02,1039.94,1610.02z'
          />
          <path
            fill='#D50000'
            d='M692.09,1027.08c0.05-5.02,2.48-7.77,6.85-7.77c3.63,0,7.18,3.91,7.19,7.93c0.01,4.23-3.35,7.87-7.29,7.89
			C694.75,1035.15,692.04,1031.92,692.09,1027.08z'
          />
          <path
            fill='#D50000'
            d='M814.93,1004.97c-0.05,4.3-3.46,8.09-7.12,7.91c-3.66-0.18-7.03-4.23-6.98-8.37c0.05-4.4,3.18-7.48,7.53-7.43
			C813.01,997.13,815,999.52,814.93,1004.97z'
          />
          <path
            fill='#D50000'
            d='M786.52,997.08c4.03,0.02,7.36,3.59,7.22,7.73c-0.13,3.81-4.18,8.17-7.49,8.08c-3.52-0.09-7.2-4.29-7.24-8.27
			C778.96,1000.55,782.44,997.06,786.52,997.08z'
          />
          <path
            fill='#D50000'
            d='M2271.17,614.73c0.01,4.29-3.23,8.15-6.9,8.23c-3.78,0.08-7.3-4.12-7.2-8.59c0.09-4.37,2.91-7.25,7.08-7.24
			C2268.78,607.15,2271.17,609.72,2271.17,614.73z'
          />
          <path
            fill='#D50000'
            d='M2285.84,607.14c4.39-0.09,7.34,2.95,7.31,7.53c-0.03,4.36-3.19,8.15-6.93,8.29c-3.6,0.14-7.16-3.8-7.22-7.99
			C2278.92,609.93,2281.28,607.23,2285.84,607.14z'
          />
          <path
            fill='#D50000'
            d='M814.93,615.77c0.01,4.5-2.91,8.09-6.73,8.26c-3.6,0.16-7.34-3.9-7.35-7.98c-0.02-4.33,3.15-7.78,7.18-7.82
			C812.84,608.19,814.92,610.46,814.93,615.77z'
          />
          <path
            fill='#D50000'
            d='M836.9,615.77c0.01,4.67-2.87,8.23-6.68,8.26c-3.61,0.03-7.36-3.99-7.43-7.97c-0.07-4.13,3.3-7.79,7.22-7.84
			C834.81,608.18,836.89,610.46,836.9,615.77z'
          />
          <path
            fill='#D50000'
            d='M653.81,997.08c3.97,0.04,7.5,3.76,7.32,7.73c-0.17,3.83-4.24,8.19-7.54,8.09c-3.32-0.11-7.38-4.73-7.31-8.33
			C646.34,1000.42,649.74,997.04,653.81,997.08z'
          />
          <path
            fill='#D50000'
            d='M1169.84,640.1c-0.81-4.51,2.67-7.7,6.63-8.29c3.54-0.52,7.53,4.25,7.45,8.39c-0.08,4.03-3.71,8.22-7.32,8.02
			C1173.38,648.05,1168.94,644.93,1169.84,640.1z'
          />
          <path
            fill='#D50000'
            d='M1822.15,639.75c-0.02-4.23,3.31-8.19,6.9-8.21c3.91-0.02,7.38,3.98,7.29,8.4c-0.08,4.15-3.15,7.38-7.05,7.42
			C1824.84,647.41,1822.18,644.58,1822.15,639.75z'
          />
          <path
            fill='#D50000'
            d='M2031.75,640.06c-0.15,4.63-3.1,7.51-7.52,7.3c-4.05-0.19-6.92-3.72-6.67-8.21c0.23-4.21,3.82-7.81,7.57-7.6
			C2028.68,631.75,2031.88,635.87,2031.75,640.06z'
          />
          <path
            fill='#D50000'
            d='M2192.02,1004.05c0-4.67,3.01-8.14,6.99-8.05c3.92,0.09,7.3,3.8,7.27,7.99c-0.03,4.09-3.51,7.85-7.25,7.84
			C2194.58,1011.83,2192.02,1008.98,2192.02,1004.05z'
          />
          <path
            fill='#D50000'
            d='M1227.97,1586.03c-0.14,4.94-2.77,7.65-7.27,7.49c-3.66-0.13-7.14-4.25-6.94-8.21
			c0.22-4.32,3.64-7.75,7.6-7.63C1225.35,1577.8,1228.1,1581.28,1227.97,1586.03z'
          />
          <path
            fill='#D50000'
            d='M1148.75,1585.82c-0.06-4.73,2.8-8.15,6.79-8.14c4.02,0.01,7.34,3.53,7.4,7.84c0.06,4.01-3.48,7.97-7.15,8
			C1151.33,1593.56,1148.81,1590.81,1148.75,1585.82z'
          />
          <path
            fill='#D50000'
            d='M2126.09,1563.72c-0.08-4.64,2.76-8.21,6.64-8.34c3.92-0.13,7.38,3.35,7.6,7.65
			c0.21,4.11-3.11,8.02-6.94,8.18C2129.01,1571.39,2126.17,1568.48,2126.09,1563.72z'
          />
          <path
            fill='#D50000'
            d='M2119.37,1563.53c-0.11,4.22-3.55,7.76-7.49,7.69c-4.08-0.07-7.19-3.58-7.09-8c0.09-4.18,3.69-7.97,7.43-7.82
			C2116.14,1555.54,2119.48,1559.34,2119.37,1563.53z'
          />
          <path
            fill='#D50000'
            d='M2068.5,1555.41c3.82,0.01,7.13,3.74,7.1,8.01c-0.03,4.61-2.91,7.77-7.1,7.79c-4.17,0.02-7.03-3.14-7.03-7.76
			C2061.47,1559.08,2064.69,1555.4,2068.5,1555.41z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1563.2c0.05-4.27,3.45-7.93,7.24-7.79c3.79,0.14,7.02,4.02,6.89,8.27
			c-0.14,4.57-3.15,7.63-7.42,7.53C2042.03,1571.11,2039.46,1568.05,2039.52,1563.2z'
          />
          <path
            fill='#D50000'
            d='M2105.42,1076.48c-0.07-4.86,2.49-7.99,6.64-8.14c4.02-0.14,7.3,3.2,7.43,7.58c0.13,4.32-3.01,8.1-6.86,8.25
			C2108.55,1084.33,2105.48,1081.06,2105.42,1076.48z'
          />
          <path
            fill='#D50000'
            d='M1845.07,1563.33c0.01-4.19,3.47-8.03,7.16-7.96c3.76,0.07,7.08,3.89,7.07,8.12
			c-0.01,4.34-3.27,7.8-7.27,7.71C1847.69,1571.11,1845.06,1568.13,1845.07,1563.33z'
          />
          <path
            fill='#D50000'
            d='M2335.07,978.66c0.02,4.69-2.72,8.01-6.75,8.21c-3.8,0.18-7.56-4.02-7.45-8.33c0.11-3.93,3.94-8.14,7.36-8.1
			C2331.97,970.49,2335.05,974.2,2335.07,978.66z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1561.18c-0.03,5.06-2.42,7.73-6.91,7.71c-3.88-0.02-7.35-3.82-7.21-7.89
			c0.15-4.22,3.62-7.98,7.34-7.95C1311.85,1553.09,1314.69,1556.48,1314.66,1561.18z'
          />
          <path
            fill='#D50000'
            d='M1039.92,1561.18c-0.02-4.54,2.9-8.03,6.8-8.13c3.9-0.1,7.63,3.96,7.53,8.19c-0.1,4.25-3.43,7.7-7.38,7.65
			C1042.44,1568.83,1039.95,1566.06,1039.92,1561.18z'
          />
          <path
            fill='#D50000'
            d='M2220.91,1530.81c3.9,0.04,7.28,3.69,7.34,7.92c0.05,4.18-3.34,7.9-7.22,7.91c-4.44,0.01-7.05-2.84-7.06-7.73
			C2213.95,1534.28,2216.96,1530.77,2220.91,1530.81z'
          />
          <path
            fill='#D50000'
            d='M1705.48,904.93c0.04,4.25-3.22,7.92-7.08,7.97c-3.88,0.05-7.43-3.42-7.61-7.46
			c-0.17-3.89,3.8-8.49,7.39-8.56C1701.61,896.82,1705.45,901.05,1705.48,904.93z'
          />
          <path
            fill='#D50000'
            d='M1669.55,905c-0.02-4.4,3.25-8.13,7.06-8.06c3.88,0.07,7.02,3.73,7,8.16c-0.02,4.81-2.76,7.81-7.1,7.8
			C1672.18,912.89,1669.58,909.94,1669.55,905z'
          />
          <path
            fill='#D50000'
            d='M980.92,632.7c4.01-0.04,7.43,3.47,7.46,7.65c0.03,4.23-3.33,8-7.23,8.12c-3.83,0.12-7.06-3.5-7.1-7.96
			C974,635.52,976.44,632.75,980.92,632.7z'
          />
          <path
            fill='#D50000'
            d='M749.04,663.65c0.01,4.47-2.96,8.17-6.68,8.32c-3.59,0.15-7.32-3.9-7.35-7.98c-0.03-4.32,3.11-7.79,7.12-7.85
			C746.94,656.08,749.03,658.34,749.04,663.65z'
          />
          <path
            fill='#D50000'
            d='M757.93,663.85c-0.05-5.26,2.1-7.7,6.78-7.71c4.42-0.01,7.31,3,7.28,7.58c-0.02,4.25-3.43,8.23-7.07,8.25
			C761.3,672,757.97,668.15,757.93,663.85z'
          />
          <path
            fill='#D50000'
            d='M814.93,663.9c-0.03,4.38-3.2,8.07-6.94,8.07c-3.57,0-7.27-4.24-7.15-8.21c0.13-4.41,3.25-7.62,7.4-7.61
			C812.96,656.15,814.97,658.48,814.93,663.9z'
          />
          <path
            fill='#D50000'
            d='M1249.76,1537.74c0.06,4.73-2.68,7.81-6.97,7.83c-4.54,0.02-7.02-2.65-7.08-7.63
			c-0.05-4.36,3.2-8.21,6.92-8.19C1246.51,1529.77,1249.7,1533.35,1249.76,1537.74z'
          />
          <path
            fill='#D50000'
            d='M1227.95,1538.34c-0.24,4.94-2.85,7.47-7.48,7.24c-3.43-0.17-6.8-4.13-6.71-7.87
			c0.11-4.61,3.66-8.23,7.79-7.96C1225.5,1530.02,1228.17,1533.61,1227.95,1538.34z'
          />
          <path
            fill='#D50000'
            d='M2097.52,665.3c-0.05,5.17-2.32,7.72-6.89,7.75c-3.8,0.03-7.17-3.67-7.18-7.86c0-4.23,3.33-7.9,7.25-7.95
			C2094.78,657.18,2097.57,660.48,2097.52,665.3z'
          />
          <path
            fill='#D50000'
            d='M2199.23,657.23c3.88,0.08,7.06,3.7,7.06,8.04c0,4.76-2.72,7.78-7.02,7.79c-4.33,0.01-7.05-2.97-7.05-7.73
			C2192.22,660.97,2195.53,657.15,2199.23,657.23z'
          />
          <path
            fill='#D50000'
            d='M1714.46,687.78c-0.05-4.21,3.34-8.29,6.89-8.28c3.68,0,7.06,3.84,7.17,8.14c0.13,4.81-2.4,7.56-7.02,7.65
			C1716.83,695.38,1714.53,692.92,1714.46,687.78z'
          />
          <path
            fill='#D50000'
            d='M2046.35,679.42c4.05-0.05,7.22,3.29,7.31,7.7c0.08,4.3-3.06,7.94-7.03,8.15c-3.8,0.2-7.09-3.44-7.12-7.88
			C2039.49,682.74,2042.29,679.47,2046.35,679.42z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1513.37c-0.06-4.23,3.23-8.16,6.87-8.23c3.68-0.07,7.11,3.74,7.18,7.96
			c0.08,4.72-2.64,7.81-6.93,7.88C1238.4,1521.06,1235.77,1518.25,1235.71,1513.37z'
          />
          <path
            fill='#D50000'
            d='M1177.2,1505.14c3.74-0.05,7.19,3.69,7.31,7.9c0.13,4.41-2.9,7.86-6.98,7.95c-4.26,0.1-7.7-3.49-7.61-7.94
			C1170,1508.86,1173.38,1505.19,1177.2,1505.14z'
          />
          <path
            fill='#D50000'
            d='M1162.94,1513.17c-0.01,4.18-3.38,7.81-7.26,7.81c-4.49,0-6.98-2.75-6.97-7.73c0-4.57,3.07-8.16,6.95-8.12
			C1159.39,1505.17,1162.95,1509.1,1162.94,1513.17z'
          />
          <path
            fill='#D50000'
            d='M1039.93,1513.14c0.02-4.5,3.22-8.11,7.1-8c3.72,0.1,7.32,4.16,7.24,8.16c-0.09,4.25-3.45,7.71-7.44,7.67
			C1042.36,1520.94,1039.91,1518.15,1039.93,1513.14z'
          />
          <path
            fill='#D50000'
            d='M814.93,688.45c-0.04,4.48-3.18,8.08-6.93,7.94c-3.8-0.14-7.28-4.12-7.14-8.15c0.15-4.03,3.71-7.69,7.45-7.65
			C812.85,680.62,814.98,683.15,814.93,688.45z'
          />
          <path
            fill='#D50000'
            d='M836.9,688.12c0.02,4.63-2.82,8.17-6.62,8.27c-3.68,0.1-7.36-3.76-7.49-7.84c-0.12-3.93,3.43-7.87,7.17-7.96
			C834.61,680.48,836.88,682.94,836.9,688.12z'
          />
          <path
            fill='#D50000'
            d='M2132.87,1498.72c-4.23-0.04-7.07-3.14-7.06-7.71c0.01-4.13,3.47-8.08,7.11-8.12
			c3.9-0.04,7.56,4.08,7.41,8.33C2140.19,1495.5,2136.94,1498.76,2132.87,1498.72z'
          />
          <path
            fill='#D50000'
            d='M2111.89,1498.73c-4.24-0.06-7.06-3.18-7.02-7.76c0.03-4.16,3.48-8.07,7.13-8.09
			c3.88-0.02,7.55,4.14,7.38,8.37C2119.2,1495.52,2115.92,1498.79,2111.89,1498.73z'
          />
          <path
            fill='#D50000'
            d='M873.61,680.58c3.98,0,7.27,3.55,7.21,7.78c-0.06,4.32-3.32,7.92-7.26,8c-3.7,0.08-6.91-3.69-6.92-8.12
			C866.62,683.25,869.04,680.58,873.61,680.58z'
          />
          <path
            fill='#D50000'
            d='M1140.27,688.19c0.09,4.49-3.03,8.18-6.92,8.21c-3.86,0.03-7.34-3.69-7.4-7.91
			c-0.06-4.03,3.41-7.86,7.19-7.93C1137.59,680.47,1140.17,683.23,1140.27,688.19z'
          />
          <path
            fill='#D50000'
            d='M2133.62,680.58c4.08-0.02,7.43,3.43,7.48,7.7c0.05,4.12-3.42,8.04-7.18,8.1c-3.96,0.06-7.34-3.46-7.46-7.79
			C2126.34,684.44,2129.77,680.6,2133.62,680.58z'
          />
          <path
            fill='#D50000'
            d='M1292.9,1489.34c-0.27,4.84-3.05,7.49-7.57,7.22c-3.48-0.21-6.71-4.12-6.64-8.03
			c0.09-4.55,3.78-8.18,7.94-7.79C1290.63,1481.11,1293.17,1484.59,1292.9,1489.34z'
          />
          <path
            fill='#D50000'
            d='M1227.98,1489c-0.11,4.95-2.69,7.67-7.2,7.57c-3.65-0.08-7.14-4.12-7.01-8.12c0.14-4.3,3.53-7.78,7.52-7.72
			C1225.27,1480.79,1228.08,1484.27,1227.98,1489z'
          />
          <path
            fill='#D50000'
            d='M2335.07,881.67c0.02,4.72-2.67,8.04-6.69,8.25c-3.77,0.2-7.58-4.01-7.51-8.28c0.07-3.94,3.79-8.06,7.33-8.11
			C2331.89,873.47,2335.05,877.22,2335.07,881.67z'
          />
          <path
            fill='#D50000'
            d='M2308.11,696.38c-3.64,0.12-6.99-3.31-7.2-7.36c-0.25-4.84,2.66-8.37,6.95-8.44c4.03-0.06,7.2,3.3,7.24,7.69
			C2315.15,692.58,2311.98,696.26,2308.11,696.38z'
          />
          <path
            fill='#D50000'
            d='M2226.25,881.93c-0.03,4.65-2.91,8.01-6.88,8.02c-3.69,0.01-7.14-3.86-7.19-8.06
			c-0.05-4.25,3.85-8.83,7.47-8.34C2223.72,874.1,2227.11,877.95,2226.25,881.93z'
          />
          <path
            fill='#D50000'
            d='M2192.21,881.54c0.07-4.3,3.52-8.26,7-8.04c3.47,0.22,7.1,4.49,7.07,8.32c-0.04,4.2-4.01,8.47-7.65,8.21
			C2194.86,889.76,2192.13,886.15,2192.21,881.54z'
          />
          <path
            fill='#D50000'
            d='M2126.5,881.27c0.36-3.94,4.41-8.03,7.69-7.78c3.43,0.27,7.36,5.34,6.95,8.95c-0.47,4.11-4.42,7.8-7.86,7.35
			C2129.53,889.3,2126.15,885.05,2126.5,881.27z'
          />
          <path
            fill='#D50000'
            d='M2249.86,688.12c0.16,3.94-3.23,8.03-6.87,8.26c-4.06,0.26-7.74-3.51-7.76-7.93
			c-0.01-3.92,3.17-7.63,6.74-7.87C2246.2,680.3,2249.67,683.62,2249.86,688.12z'
          />
          <path
            fill='#D50000'
            d='M2061.47,881.03c0.02-4.89,2.66-7.88,6.97-7.89c4.12-0.01,7.14,3.32,7.15,7.89c0.01,4.39-3.28,8.15-7.08,8.12
			C2064.67,889.11,2061.45,885.39,2061.47,881.03z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1488.83c-0.03-4.52,3.03-8.1,6.9-8.1c3.91,0.01,7.17,3.61,7.18,7.95c0.01,4.61-2.89,7.86-7.05,7.89
			C1129.7,1496.6,1126.96,1493.59,1126.93,1488.83z'
          />
          <path
            fill='#D50000'
            d='M836.9,710.99c-0.06,4.5-2.79,7.76-6.55,7.83c-3.89,0.07-7.73-4.13-7.57-8.29c0.16-4.26,3.62-7.72,7.55-7.55
			C834.91,703.17,836.98,705.7,836.9,710.99z'
          />
          <path
            fill='#D50000'
            d='M858.86,710.79c-0.02,4.25-3.38,8.04-7.11,8.02c-3.64-0.02-7.05-3.97-7.04-8.15c0.01-4.76,2.77-7.74,7.13-7.7
			C856.13,703.01,858.88,706.07,858.86,710.79z'
          />
          <path
            fill='#D50000'
            d='M2236.13,1245.43c0.1-4.34,3.04-7.61,6.84-7.61c4.07,0,7.39,3.85,7.22,8.39c-0.16,4.41-3.39,7.64-7.48,7.46
			C2238.64,1253.49,2236.02,1250.21,2236.13,1245.43z'
          />
          <path
            fill='#D50000'
            d='M2184.34,1148.32c-0.12,3.88-3.95,8.3-7.39,7.96c-3.59-0.36-7.19-2.91-6.75-8.64
			c0.34-4.36,3.57-8.01,7.27-7.73C2181.11,1140.18,2184.46,1144.29,2184.34,1148.32z'
          />
          <path
            fill='#D50000'
            d='M2192.22,858.16c0.07-4.53,3.26-8.16,6.97-7.93c3.4,0.2,7.13,4.59,7.09,8.33c-0.04,3.6-4.04,8.16-7.23,8.23
			C2195.24,866.88,2192.14,862.96,2192.22,858.16z'
          />
          <path
            fill='#D50000'
            d='M1719.25,1010.94c-3.5-0.26-7.04-4.73-6.69-8.44c0.36-3.71,4.15-7.64,7.31-7.56c3.54,0.08,7.6,4.7,7.48,8.51
			C1727.22,1007.63,1723.3,1011.25,1719.25,1010.94z'
          />
          <path
            fill='#D50000'
            d='M692.1,1100.64c0.06-4.57,2.71-7.67,6.61-7.7c4.15-0.03,7.64,3.85,7.43,8.26c-0.2,4.18-3.71,7.71-7.55,7.58
			C694.25,1108.65,692.03,1105.86,692.1,1100.64z'
          />
          <path
            fill='#D50000'
            d='M2090.03,1474.26c-4.17-0.03-7.23-3.39-7.18-7.88c0.05-4.24,3.51-7.99,7.3-7.91
			c3.96,0.08,7.35,3.81,7.29,8.01C2097.38,1470.66,2093.93,1474.29,2090.03,1474.26z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1466.66c-0.04-4.39,3.09-8.13,6.86-8.2c3.85-0.07,7.23,3.59,7.25,7.86
			c0.03,4.65-2.78,7.86-6.94,7.94C2064.42,1474.34,2061.52,1471.27,2061.48,1466.66z'
          />
          <path
            fill='#D50000'
            d='M1727.22,857.13c0.04,4.15-3.42,7.88-7.26,7.83c-3.73-0.05-7.36-3.89-7.4-7.82c-0.04-3.85,3.9-8.23,7.32-8.15
			C1723.38,849.07,1727.19,853.3,1727.22,857.13z'
          />
          <path
            fill='#D50000'
            d='M2046.71,1458.46c3.8,0.11,7.05,3.96,6.94,8.22c-0.12,4.6-3.09,7.66-7.36,7.57c-4.4-0.08-6.99-3.31-6.77-8.45
			C2039.68,1461.85,2043.1,1458.36,2046.71,1458.46z'
          />
          <path
            fill='#D50000'
            d='M1669.58,857c-0.01-4.41,3.27-8.1,7.11-7.98c3.9,0.12,6.98,3.77,6.94,8.22c-0.04,4.81-2.66,7.57-7.17,7.55
			C1671.93,864.77,1669.59,862.13,1669.58,857z'
          />
          <path
            fill='#D50000'
            d='M749.04,1101.07c-0.08,5.17-2.4,7.77-6.89,7.72c-3.73-0.04-7.2-3.93-7.14-8.01c0.07-4.26,3.43-7.83,7.38-7.83
			C746.51,1092.94,749.11,1096.12,749.04,1101.07z'
          />
          <path
            fill='#D50000'
            d='M1822.15,710.44c0.12-4.69,3.06-7.67,7.4-7.48c3.9,0.17,6.94,3.86,6.79,8.26c-0.14,4.23-3.68,7.8-7.52,7.6
			C1824.91,718.61,1822.04,715.01,1822.15,710.44z'
          />
          <path
            fill='#D50000'
            d='M2031.75,710.93c-0.05,4.43-3.21,7.91-7.18,7.89c-3.75-0.02-7.06-3.85-7.01-8.1
			c0.05-4.36,3.29-7.82,7.28-7.76C2029.13,703.03,2031.8,706.1,2031.75,710.93z'
          />
          <path
            fill='#D50000'
            d='M1676.25,719.91c-4.45-0.03-6.76-2.75-6.69-7.9c0.06-4.17,3.11-7.74,6.73-7.88c3.92-0.15,7.39,3.84,7.32,8.43
			C1683.55,717.3,1680.91,719.94,1676.25,719.91z'
          />
          <path
            fill='#D50000'
            d='M1741.11,719.91c-4.41-0.03-6.77-2.83-6.7-7.92c0.07-4.18,3.16-7.78,6.78-7.88c4.02-0.11,7.39,3.79,7.29,8.46
			C1748.38,717.35,1745.78,719.94,1741.11,719.91z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1270.38c-0.04,4.9-2.61,7.68-7.08,7.68c-4.3,0-7.05-3.07-6.99-7.8c0.06-4.24,3.43-8.06,7.11-8.04
			C1636.43,1262.24,1639.77,1266.15,1639.73,1270.38z'
          />
          <path
            fill='#D50000'
            d='M1583.72,1101.04c-0.05-4.57,2.93-8.08,6.88-8.1c3.89-0.02,7.19,3.6,7.24,7.93c0.05,4.54-2.92,7.87-7.07,7.91
			C1586.51,1108.83,1583.77,1105.82,1583.72,1101.04z'
          />
          <path
            fill='#D50000'
            d='M2097.52,711.72c0,4.9-2.64,8.14-6.68,8.19c-3.85,0.04-7.28-3.59-7.39-7.82c-0.1-4.22,3.21-7.96,7.09-8.02
			C2095.09,704,2097.52,706.66,2097.52,711.72z'
          />
          <path
            fill='#D50000'
            d='M2162.38,711.84c0.01,4.35-3.14,8.02-6.94,8.07c-3.78,0.05-7.09-3.63-7.11-7.93
			c-0.02-4.39,3.11-7.91,7.05-7.91C2159.7,704.06,2162.36,707.02,2162.38,711.84z'
          />
          <path
            fill='#D50000'
            d='M1047.02,1456.1c3.72,0.05,7.29,4.04,7.24,8.11c-0.04,4.26-3.45,7.8-7.43,7.74c-4.46-0.08-6.94-2.9-6.9-7.87
			C1039.97,1459.52,1043.05,1456.05,1047.02,1456.1z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1270.26c0.02,4.93-2.5,7.77-6.9,7.82c-4.32,0.04-7.14-2.98-7.17-7.67
			c-0.02-4.15,3.39-8.12,7.03-8.18C1745.12,1262.17,1748.46,1265.99,1748.48,1270.26z'
          />
          <path
            fill='#D50000'
            d='M2170.28,711.92c0.01-4.88,2.61-7.83,6.94-7.85c3.92-0.02,7.1,3.51,7.1,7.89c0,4.28-3.29,7.95-7.12,7.95
			C2173.21,719.91,2170.28,716.52,2170.28,711.92z'
          />
          <path
            fill='#D50000'
            d='M2192.23,711.85c0.01-4.65,2.86-7.81,7.03-7.78c3.92,0.03,7.05,3.54,7.04,7.91c-0.01,4.22-3.36,7.97-7.1,7.93
			C2195.44,719.87,2192.22,716.14,2192.23,711.85z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1270.49c-0.05,4.81-2.74,7.64-7.18,7.58c-4.3-0.06-7.04-3.18-6.96-7.93
			c0.07-4.18,3.64-8.04,7.31-7.92C1811.23,1262.34,1814.43,1266.22,1814.38,1270.49z'
          />
          <path
            fill='#D50000'
            d='M2242.44,704.08c3.98-0.12,7.41,3.34,7.49,7.58c0.08,4-3.31,8.07-6.9,8.26c-4.09,0.22-7.76-3.51-7.8-7.91
			C2235.19,707.69,2238.36,704.2,2242.44,704.08z'
          />
          <path
            fill='#D50000'
            d='M2279,711.89c0.01-4.83,2.7-7.83,7.02-7.82c3.97,0.01,7.12,3.52,7.11,7.92c-0.01,4.32-3.28,7.93-7.17,7.92
			C2281.95,719.89,2278.99,716.48,2279,711.89z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1100.99c-0.03,4.77-2.76,7.8-7.03,7.8c-4.04,0-7-3.32-7.04-7.87c-0.03-4.37,3.15-7.95,7.09-7.97
			C1636.61,1092.92,1639.76,1096.55,1639.73,1100.99z'
          />
          <path
            fill='#D50000'
            d='M2039.51,1442.02c-0.02-4.48,3.07-8.13,6.9-8.14c3.85-0.01,7.19,3.65,7.24,7.94c0.05,4.6-2.86,7.85-7.04,7.88
			C2042.27,1449.74,2039.54,1446.77,2039.51,1442.02z'
          />
          <path
            fill='#D50000'
            d='M692.09,735.46c-0.05-5.09,2.27-7.96,6.55-8.09c3.74-0.12,7.31,3.52,7.49,7.62c0.19,4.37-3.36,8.27-7.48,8.22
			C694.74,743.16,692.14,740.09,692.09,735.46z'
          />
          <path
            fill='#D50000'
            d='M1698.17,843.58c-4.19,0-7.24-3.21-7.25-7.63c-0.01-3.74,3.86-8.09,7.26-8.16c3.39-0.07,7.34,4.35,7.29,8.16
			C1705.43,840.32,1702.3,843.59,1698.17,843.58z'
          />
          <path
            fill='#D50000'
            d='M749.05,735.56c-0.06,4.63-2.66,7.63-6.62,7.65c-3.94,0.02-7.34-3.54-7.43-7.79
			c-0.09-4.26,3.63-8.2,7.61-8.05C746.86,727.52,749.11,730.39,749.05,735.56z'
          />
          <path
            fill='#D50000'
            d='M757.93,735.29c0.01-5.06,2.41-7.88,6.77-7.92c3.72-0.04,7.21,3.69,7.29,7.8c0.09,4.48-3.45,8.2-7.63,8.03
			C760.47,743.04,757.92,739.91,757.93,735.29z'
          />
          <path
            fill='#D50000'
            d='M814.93,735.48c-0.04,4.62-2.62,7.66-6.56,7.72c-3.95,0.07-7.39-3.47-7.52-7.72
			c-0.13-4.22,3.63-8.24,7.58-8.12C812.75,727.51,814.97,730.27,814.93,735.48z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1101.16c-0.07-4.48,2.97-8.17,6.78-8.22c3.89-0.06,7.22,3.52,7.29,7.84
			c0.08,4.56-2.83,7.92-6.92,8.01C1650.5,1108.88,1647.69,1105.88,1647.61,1101.16z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1270.12c0.05-4.23,3.5-8.02,7.21-7.89c3.66,0.12,7.02,4.15,6.93,8.32c-0.1,4.73-2.97,7.66-7.37,7.53
			C2041.86,1277.95,2039.46,1275.13,2039.52,1270.12z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1270.31c0.02-4.31,3.33-8.11,7.04-8.09c3.68,0.02,7.1,3.94,7.08,8.11
			c-0.02,4.79-2.75,7.77-7.11,7.75C2064.13,1278.06,2061.44,1275.08,2061.47,1270.31z'
          />
          <path
            fill='#D50000'
            d='M1104.84,735.1c0.06-5.04,2.56-7.81,6.98-7.73c3.73,0.06,7.32,4.05,7.22,8.02c-0.11,4.29-3.52,7.85-7.49,7.82
			C1107.36,743.17,1104.78,740.06,1104.84,735.1z'
          />
          <path
            fill='#D50000'
            d='M1682.62,735.71c-0.07,5.12-2.39,7.58-7.07,7.5c-4.59-0.08-7.12-2.86-6.98-7.68c0.12-4.3,3.53-8.14,7.18-8.1
			C1679.39,727.48,1682.68,731.45,1682.62,735.71z'
          />
          <path
            fill='#D50000'
            d='M1336.63,1440.73c-0.01,5.17-2.25,7.8-6.74,7.89c-3.79,0.07-7.34-3.64-7.41-7.75
			c-0.07-4.16,3.3-7.92,7.22-8.05C1333.79,1432.69,1336.63,1435.93,1336.63,1440.73z'
          />
          <path
            fill='#D50000'
            d='M1292.9,1441.39c-0.23,4.84-2.99,7.48-7.53,7.22c-3.55-0.2-6.76-4.04-6.67-7.97c0.11-4.56,3.76-8.18,7.9-7.82
			C1290.62,1433.17,1293.14,1436.59,1292.9,1441.39z'
          />
          <path
            fill='#D50000'
            d='M1748.47,735.54c0.05,5.15-2.21,7.68-6.84,7.67c-4.69-0.01-7.27-2.69-7.21-7.5c0.05-4.3,3.34-8.17,7.05-8.28
			C1745.1,727.33,1748.43,731.19,1748.47,735.54z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1125.41c0.04-4.32,3.34-8.06,7.09-8c3.72,0.05,7.07,3.92,7.03,8.12c-0.04,4.73-2.85,7.75-7.16,7.71
			C2064.11,1133.2,2061.43,1130.18,2061.47,1125.41z'
          />
          <path
            fill='#D50000'
            d='M945.86,980.19c0.01,4-3.62,8.12-7.14,8.09c-3.6-0.03-7.37-4.2-7.34-8.12c0.04-4.22,3.32-7.52,7.49-7.56
			C943.5,972.55,945.84,975.1,945.86,980.19z'
          />
          <path
            fill='#D50000'
            d='M2228.17,1270.2c0.04,4.27-3.17,7.81-7.16,7.88c-4.27,0.08-7.26-2.97-7.41-7.55
			c-0.14-4.33,3.47-8.39,7.39-8.31C2224.8,1262.3,2228.13,1266,2228.17,1270.2z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1293.32c-0.04,4.27-3.34,8.2-6.94,8.24c-3.6,0.05-7.09-3.94-7.16-8.19
			c-0.07-4.4,3.12-7.88,7.22-7.88C1203.53,1285.49,1205.94,1288.23,1205.89,1293.32z'
          />
          <path
            fill='#D50000'
            d='M2112.17,728.48c3.82-0.08,7.23,3.54,7.32,7.75c0.09,4.39-3.04,8.02-6.97,8.09c-4.15,0.07-7.07-3.14-7.1-7.78
			C2105.39,731.72,2108.03,728.56,2112.17,728.48z'
          />
          <path
            fill='#D50000'
            d='M2133.95,842.4c-3.93,0.12-7.32-3.34-7.49-7.63c-0.16-4.1,3.14-8.01,6.94-8.21c4.03-0.21,7.62,3.46,7.73,7.91
			C2141.23,838.56,2137.86,842.28,2133.95,842.4z'
          />
          <path
            fill='#D50000'
            d='M2105.42,834.66c-0.04-4.85,2.58-7.99,6.74-8.1c3.84-0.11,7.26,3.51,7.32,7.75c0.07,4.34-3.1,8.01-6.97,8.08
			C2108.27,842.48,2105.45,839.41,2105.42,834.66z'
          />
          <path
            fill='#D50000'
            d='M2133.79,744.31c-4.02-0.04-7.31-3.53-7.33-7.8c-0.03-4.43,3.53-8.19,7.61-8.05
			c3.79,0.12,7.19,4.01,7.08,8.08C2141.03,740.8,2137.67,744.35,2133.79,744.31z'
          />
          <path
            fill='#D50000'
            d='M2162.38,736.38c0.04,4.56-2.92,7.92-6.99,7.94c-3.92,0.02-7.09-3.55-7.06-7.93
			c0.03-4.25,3.35-7.94,7.12-7.91C2159.6,728.49,2162.34,731.62,2162.38,736.38z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1125.69c-0.1,4.72-2.95,7.65-7.32,7.53c-4.22-0.11-7.01-3.35-6.87-7.99
			c0.13-4.27,3.57-7.94,7.34-7.82C2028.56,1117.53,2031.84,1121.49,2031.75,1125.69z'
          />
          <path
            fill='#D50000'
            d='M1097.16,1295.22c-0.01,4.94-2.49,7.65-6.97,7.62c-4.1-0.03-7.24-3.4-7.21-7.75c0.03-4.5,3.61-8.43,7.49-8.23
			C1094.2,1287.05,1097.17,1290.77,1097.16,1295.22z'
          />
          <path
            fill='#D50000'
            d='M1119.1,1294.95c0.04,5.07-2.36,7.86-6.81,7.9c-4.04,0.04-7.34-3.45-7.33-7.74c0.02-4.24,3.38-8.15,7.09-8.25
			C1115.75,1286.76,1119.07,1290.57,1119.1,1294.95z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1295.06c0.01-4.42,3.07-8.08,6.85-8.2c3.78-0.12,7.15,3.55,7.23,7.89c0.1,5.1-2.64,8.17-7.22,8.09
			C1129.32,1302.77,1126.91,1300.04,1126.93,1295.06z'
          />
          <path
            fill='#D50000'
            d='M2010.42,1125.65c-0.13,4.51-3.18,7.64-7.4,7.59c-4.02-0.04-7.36-3.57-7.34-7.75
			c0.02-4.35,3.75-8.25,7.73-8.07C2007.2,1117.59,2010.54,1121.52,2010.42,1125.65z'
          />
          <path
            fill='#D50000'
            d='M2256.06,833.66c0.06-4.3,3.44-8.2,6.97-8.05c3.4,0.14,7.15,4.47,7.14,8.23c-0.01,3.94-3.67,8.15-7.14,8.21
			C2259.05,842.13,2256,838.46,2256.06,833.66z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1294.87c-0.02,4.87-2.63,7.78-7,7.79c-4.26,0.02-7.06-3.06-7.06-7.75c0-4.36,3.19-8.02,7.03-8.07
			C1636.51,1286.8,1639.75,1290.48,1639.73,1294.87z'
          />
          <path
            fill='#D50000'
            d='M2170.28,736.56c-0.04-4.76,2.65-8.01,6.73-8.11c3.79-0.1,7.22,3.53,7.32,7.74c0.1,4.4-2.99,8.03-6.9,8.12
			C2173.26,744.4,2170.32,741.21,2170.28,736.56z'
          />
          <path
            fill='#D50000'
            d='M1988.74,1417.32c0.09,4.17-3.28,7.85-7.25,7.9c-4.4,0.06-7.22-2.89-7.28-7.61c-0.06-4.14,3.4-8.14,7.08-8.18
			C1985.14,1409.38,1988.65,1413.1,1988.74,1417.32z'
          />
          <path
            fill='#D50000'
            d='M1836.34,979.59c0.09,4.59-2.71,7.83-6.89,7.97c-4.33,0.15-7.2-2.79-7.3-7.45c-0.09-4.51,3.29-8.63,7.06-8.62
			C1832.89,971.51,1836.26,975.34,1836.34,979.59z'
          />
          <path
            fill='#D50000'
            d='M2105.42,979.22c0-4.96,2.5-7.83,6.85-7.86c4.14-0.03,7.16,3.22,7.21,7.74c0.05,4.31-3.21,8.06-7.04,8.11
			C2108.62,987.26,2105.42,983.62,2105.42,979.22z'
          />
          <path
            fill='#D50000'
            d='M2133.87,971.39c4.04-0.01,7.13,3.29,7.23,7.72c0.09,4.07-3.37,8.03-7.08,8.1c-4.08,0.07-7.69-3.82-7.56-8.15
			C2126.59,974.85,2129.92,971.4,2133.87,971.39z'
          />
          <path
            fill='#D50000'
            d='M764.72,825.4c3.73-0.04,7.18,3.69,7.26,7.83c0.08,4.24-3.16,7.87-7.15,8.02c-4.1,0.15-6.93-3.09-6.91-7.92
			C757.95,828.37,760.45,825.45,764.72,825.4z'
          />
          <path
            fill='#D50000'
            d='M1952.69,1417.62c-0.03-4.26,3.26-8.09,7.01-8.18c3.7-0.09,7.24,3.78,7.29,7.96c0.05,4.51-2.88,7.7-7.15,7.81
			C1955.51,1425.33,1952.72,1422.36,1952.69,1417.62z'
          />
          <path
            fill='#D50000'
            d='M1930.75,1417.4c0.04-4.18,3.62-8.14,7.21-7.97c3.85,0.18,7.15,3.97,7.11,8.19c-0.04,4.5-3.07,7.61-7.41,7.6
			C1933.3,1425.21,1930.7,1422.27,1930.75,1417.4z'
          />
          <path
            fill='#D50000'
            d='M692.09,833.53c-0.05-5.28,2.5-8.22,7.03-8.13c3.6,0.07,7.09,4.12,7.01,8.13c-0.09,4.24-3.5,7.78-7.46,7.73
			C694.79,841.21,692.14,838.09,692.09,833.53z'
          />
          <path
            fill='#D50000'
            d='M1923.15,1417.74c-0.06,4.78-2.82,7.62-7.27,7.48c-4.29-0.14-7.18-3.39-7.05-7.92
			c0.12-4.22,3.61-7.92,7.43-7.86C1919.89,1409.48,1923.21,1413.48,1923.15,1417.74z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1416.04c0.06-4.54,3.15-7.9,7.18-7.83c3.77,0.07,6.96,3.86,6.88,8.18
			c-0.08,4.58-3.08,7.73-7.27,7.63C1238.16,1423.92,1235.64,1420.95,1235.71,1416.04z'
          />
          <path
            fill='#D50000'
            d='M1133.8,1408.21c3.79-0.08,7.18,3.63,7.21,7.89c0.03,4.63-2.85,7.88-7,7.92c-4.4,0.04-7.06-2.85-7.08-7.68
			C1126.91,1411.74,1129.82,1408.3,1133.8,1408.21z'
          />
          <path
            fill='#D50000'
            d='M1777.31,832.8c-0.03-4.26,3.15-8.26,6.69-8.45c3.81-0.2,7.39,3.81,7.43,8.31c0.04,4.67-2.7,7.66-7.07,7.71
			C1779.89,840.43,1777.34,837.68,1777.31,832.8z'
          />
          <path
            fill='#D50000'
            d='M2039.52,758.27c0.05-4.98,2.54-7.53,7.21-7.4c4.45,0.13,7.06,3.11,6.94,7.93c-0.1,4.12-3.84,8.09-7.44,7.91
			C2042.57,766.52,2039.48,762.62,2039.52,758.27z'
          />
          <path
            fill='#D50000'
            d='M2105.42,759.63c-0.04-5.08,2.28-7.66,6.88-7.64c4.35,0.02,7.04,2.69,7.17,7.12
			c0.14,4.47-3.32,8.71-7.11,8.72C2108.78,767.83,2105.46,763.91,2105.42,759.63z'
          />
          <path
            fill='#D50000'
            d='M2192.23,759.57c0.05-5.03,2.44-7.59,7.09-7.58c4.53,0.01,7.13,2.89,6.98,7.72
			c-0.14,4.35-3.52,8.19-7.13,8.13C2195.56,767.78,2192.19,763.75,2192.23,759.57z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1100.9c0-4.46,3.15-7.99,7.09-7.94c3.91,0.04,7.08,3.69,7.02,8.09c-0.05,4.7-2.91,7.78-7.18,7.75
			C2064.14,1108.75,2061.48,1105.72,2061.48,1100.9z'
          />
          <path
            fill='#D50000'
            d='M2206.29,1102.24c-0.16,4.23-3.55,7.7-7.47,7.63c-4.42-0.08-6.9-2.99-6.8-7.97c0.09-4.6,3.26-8.06,7.21-7.87
			C2203.04,1094.21,2206.44,1098.17,2206.29,1102.24z'
          />
          <path
            fill='#D50000'
            d='M2249.2,1102.38c-0.09,4.94-2.61,7.55-7.2,7.47c-4.3-0.08-7-3.2-6.87-7.97c0.11-4.28,3.54-7.94,7.35-7.84
			C2246.21,1094.13,2249.28,1097.95,2249.2,1102.38z'
          />
          <path
            fill='#D50000'
            d='M1770.44,809.12c0.05,5.13-2.25,7.73-6.86,7.75c-4.57,0.02-7.16-2.7-7.23-7.56
			c-0.06-4.37,3.15-8.13,7.01-8.21C1767.2,801.02,1770.4,804.64,1770.44,809.12z'
          />
          <path
            fill='#D50000'
            d='M1741.33,816.87c-4.45-0.06-6.98-2.93-6.91-7.85c0.06-4.4,3.4-8.04,7.26-7.91c3.87,0.13,6.84,3.74,6.8,8.3
			C1748.44,814.41,1746.03,816.93,1741.33,816.87z'
          />
          <path
            fill='#D50000'
            d='M1242.35,753.08c3.98-0.05,7.37,3.44,7.42,7.63c0.04,4.23-3.21,8.04-7.03,8.22
			c-4.02,0.19-6.97-3.12-7.05-7.91C1235.59,755.82,1237.84,753.14,1242.35,753.08z'
          />
          <path
            fill='#D50000'
            d='M1770.44,761.18c0.07,5.11-2.23,7.71-6.85,7.76c-4.64,0.05-7.15-2.57-7.22-7.53
			c-0.06-4.42,3.08-8.13,6.97-8.24C1767.18,753.06,1770.39,756.68,1770.44,761.18z'
          />
          <path
            fill='#D50000'
            d='M1822.15,1125.26c0.07-4.18,3.59-7.98,7.28-7.84c3.74,0.13,6.98,4.02,6.91,8.26
			c-0.08,4.59-3.09,7.68-7.36,7.56C1824.64,1133.11,1822.07,1130.12,1822.15,1125.26z'
          />
          <path
            fill='#D50000'
            d='M1104.83,808.83c-0.02-5.19,2.27-7.79,6.84-7.81c3.98-0.01,7.37,3.56,7.39,7.78
			c0.01,4.06-3.56,7.99-7.31,8.06C1107.76,816.92,1104.84,813.54,1104.83,808.83z'
          />
          <path
            fill='#D50000'
            d='M1067.83,801.01c3.94-0.03,7.39,3.51,7.4,7.58c0.01,4.33-3.63,8.34-7.5,8.26c-3.94-0.08-6.71-3.45-6.69-8.15
			C1061.05,803.62,1063.32,801.05,1067.83,801.01z'
          />
          <path
            fill='#D50000'
            d='M1053.28,808.84c0,4.26-3.4,8.1-7.1,8.01c-3.8-0.09-7.08-3.83-7.11-8.11c-0.03-4.49,3.04-7.77,7.24-7.74
			C1050.77,801.05,1053.28,803.86,1053.28,808.84z'
          />
          <path
            fill='#D50000'
            d='M836.9,809.07c-0.07,4.49-2.81,7.72-6.6,7.78c-3.92,0.06-7.71-4.13-7.52-8.32c0.2-4.3,3.62-7.69,7.59-7.52
			C834.92,801.22,836.99,803.77,836.9,809.07z'
          />
          <path
            fill='#D50000'
            d='M1937.68,1360.35c3.67-0.12,7.31,3.76,7.39,7.87c0.09,4.59-2.83,7.86-7.07,7.92
			c-4.23,0.06-7.06-2.7-7.25-7.06C1930.56,1364.63,1933.85,1360.48,1937.68,1360.35z'
          />
          <path
            fill='#D50000'
            d='M1901.83,1368.52c-0.05,4.41-3.16,7.63-7.35,7.62c-4.05-0.01-7.31-3.38-7.41-7.66
			c-0.1-4.24,3.69-8.25,7.68-8.13C1898.39,1360.47,1901.88,1364.49,1901.83,1368.52z'
          />
          <path
            fill='#D50000'
            d='M2039.52,783.12c-0.05-4.97,2.46-7.9,6.83-7.97c4.19-0.07,7.26,3.16,7.31,7.71c0.05,4.41-3.14,8.19-6.97,8.25
			C2042.84,791.16,2039.56,787.5,2039.52,783.12z'
          />
          <path
            fill='#D50000'
            d='M1814.39,1125.43c0.01,4.86-2.59,7.79-6.93,7.82c-4.32,0.03-7.2-3.04-7.21-7.69
			c-0.01-4.25,3.34-8.1,7.08-8.14C1811.07,1117.38,1814.38,1121.13,1814.39,1125.43z'
          />
          <path
            fill='#D50000'
            d='M1119.1,1318.77c-0.06,4.66-2.58,7.49-6.74,7.55c-3.97,0.06-7.4-3.56-7.4-7.79c0-4.14,3.45-8.23,7.05-8.38
			C1115.62,1310.01,1119.16,1314.31,1119.1,1318.77z'
          />
          <path
            fill='#D50000'
            d='M2226.24,783.81c-0.02,4.61-3.02,8.26-6.77,8.22c-3.56-0.04-7.19-4.07-7.29-8.09
			c-0.1-4.21,3.68-8.54,7.41-8.5C2223.18,775.48,2226.26,779.35,2226.24,783.81z'
          />
          <path
            fill='#D50000'
            d='M1119.43,1366.26c-0.03,4.61-2.86,7.62-7.19,7.63c-3.92,0.01-7.25-3.54-7.27-7.76
			c-0.02-4.37,3.59-8.24,7.55-8.08C1116.27,1358.2,1119.45,1361.98,1119.43,1366.26z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1366.13c0.07,5.05-2.35,7.76-6.93,7.76c-3.77,0-7.05-3.34-7.25-7.37c-0.21-4.38,3.27-8.43,7.28-8.47
			C1094.27,1358.01,1097.1,1361.33,1097.17,1366.13z'
          />
          <path
            fill='#D50000'
            d='M2430.45,783.94c-0.03-4.76,3.17-8.64,7-8.48c2.73,0.11,6.88,5.68,6.93,9.31c0.05,3.2-3.85,7.15-7.02,7.13
			C2433.44,791.88,2430.48,788.46,2430.45,783.94z'
          />
          <path
            fill='#D50000'
            d='M1061.03,785.31c0-5.03,2.47-7.81,6.9-7.81c3.69,0.01,7.4,4.04,7.29,7.92c-0.12,4.33-3.47,7.94-7.34,7.91
			C1063.66,793.3,1061.03,790.21,1061.03,785.31z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1343.62c0.07-4.41,3.36-7.92,7.28-7.76c3.86,0.16,6.98,3.91,6.85,8.26
			c-0.14,4.67-3.06,7.65-7.37,7.54C2063.99,1351.55,2061.39,1348.45,2061.47,1343.62z'
          />
          <path
            fill='#D50000'
            d='M1154.47,777.51c3.76-0.06,7.36,3.64,7.5,7.7c0.14,4.12-3.21,7.92-7.15,8.12c-4.02,0.21-7.02-3.1-7.1-7.84
			C1147.64,780.55,1150.15,777.58,1154.47,777.51z'
          />
          <path
            fill='#D50000'
            d='M2075.59,786c-0.13,4.75-2.93,7.73-7.18,7.66c-4.2-0.07-6.98-3.22-6.94-7.88c0.04-4.28,3.29-8.16,6.92-8.26
			C2072.13,777.42,2075.71,781.63,2075.59,786z'
          />
          <path
            fill='#D50000'
            d='M1227.86,1343.15c-0.03,4.98-2.58,7.73-7.07,7.63c-3.84-0.08-6.94-3.42-7.02-7.55
			c-0.09-4.42,3.57-8.63,7.36-8.46C1224.84,1334.94,1227.89,1338.73,1227.86,1343.15z'
          />
          <path
            fill='#D50000'
            d='M2162.38,785.71c-0.02,4.88-2.7,7.95-6.96,7.94c-4.19-0.01-7.09-3.25-7.08-7.92c0.01-4.26,3.38-8.2,7.03-8.21
			C2158.96,777.5,2162.39,781.51,2162.38,785.71z'
          />
          <path
            fill='#D50000'
            d='M1583.72,1125.64c-0.06-4.18,3.23-8.17,6.79-8.22c3.84-0.06,7.39,4.01,7.33,8.42
			c-0.06,4.17-3.07,7.34-7.01,7.38C1586.38,1133.26,1583.78,1130.49,1583.72,1125.64z'
          />
          <path
            fill='#D50000'
            d='M2010.51,1319.67c-0.04,4.26-3.33,7.52-7.55,7.48c-3.94-0.04-7.35-3.68-7.28-7.76
			c0.06-4.01,3.68-8.02,7.3-8.1C2006.74,1311.2,2010.55,1315.44,2010.51,1319.67z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1319.54c-0.02,4.85-2.62,7.67-7.01,7.6c-4.39-0.07-7.16-3.01-7.18-7.6
			c-0.02-4.46,3.46-8.4,7.31-8.26C2028.44,1311.4,2031.77,1315.39,2031.75,1319.54z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1319.37c0.03-4.25,3.47-8.21,7.05-8.09c3.76,0.12,7.09,3.98,7.08,8.21
			c-0.01,4.66-2.86,7.66-7.25,7.65C2041.91,1327.13,2039.49,1324.39,2039.52,1319.37z'
          />
          <path
            fill='#D50000'
            d='M1148.87,1342.68c0.07-4.37,3.44-8.08,7.16-7.88c3.67,0.2,7.02,4.3,6.92,8.46c-0.11,4.4-3.34,7.6-7.57,7.49
			C1150.95,1350.64,1148.79,1347.96,1148.87,1342.68z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1342.92c0.02-4.43,3.09-8.04,6.9-8.13c3.78-0.09,7.13,3.61,7.18,7.92c0.06,5.02-2.76,8.15-7.26,8.05
			C1129.3,1350.66,1126.9,1347.9,1126.93,1342.92z'
          />
          <path
            fill='#D50000'
            d='M1119.1,1343.59c-0.14,4.59-2.73,7.23-7.03,7.18c-4.08-0.05-7.25-3.6-7.1-7.96c0.14-4.23,3.67-8.11,7.32-8.04
			C1116.15,1334.85,1119.25,1338.86,1119.1,1343.59z'
          />
          <path
            fill='#D50000'
            d='M786.15,777.51c4.17-0.06,7.7,3.62,7.62,7.94c-0.08,4.11-3.57,7.84-7.37,7.88c-3.91,0.04-7.32-3.56-7.4-7.82
			C778.91,781.42,782.35,777.57,786.15,777.51z'
          />
          <path
            fill='#D50000'
            d='M764.5,1143.05c3.89-0.08,7.34,3.45,7.5,7.67c0.16,4.17-3.12,8.01-6.96,8.13c-4.56,0.14-6.99-2.43-7.11-7.52
			C757.82,1146.52,760.51,1143.14,764.5,1143.05z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1733.01c-0.04,5.06-2.45,7.63-7.07,7.55c-3.63-0.07-6.98-3.64-7.03-7.5
			c-0.06-4.52,3.38-8.38,7.43-8.33C1203.2,1724.76,1205.93,1728.16,1205.89,1733.01z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1050.57c0.07,4.77-2.59,8-6.67,8.11c-4.29,0.12-7.44-3.31-7.39-8.07c0.04-4.12,3.09-7.6,6.81-7.77
			C1745.21,1042.66,1748.42,1046.07,1748.48,1050.57z'
          />
          <path
            fill='#D50000'
            d='M1836.34,1319.64c-0.05,4.53-3.04,7.54-7.43,7.49c-4.38-0.05-6.88-2.99-6.76-7.95
			c0.09-3.94,3.43-7.79,6.85-7.9C1832.93,1311.15,1836.4,1315.09,1836.34,1319.64z'
          />
          <path
            fill='#D50000'
            d='M2192.23,1636.06c0.04-4.57,3.25-8.32,6.94-8.13c3.35,0.17,7.21,4.77,7.11,8.47c-0.1,3.73-4.21,8.2-7.46,8.11
			C2195.06,1644.42,2192.18,1640.73,2192.23,1636.06z'
          />
          <path
            fill='#D50000'
            d='M1314.69,1635.1c-0.1,4.99-2.51,7.53-7.08,7.44c-3.78-0.07-7.25-3.96-7.07-7.91c0.21-4.6,3.68-8.16,7.75-7.94
			C1312.17,1626.9,1314.78,1630.34,1314.69,1635.1z'
          />
          <path
            fill='#D50000'
            d='M1669.55,1050.64c0.04-4.42,3.28-7.93,7.2-7.8c3.9,0.13,6.95,3.78,6.86,8.2c-0.09,4.46-3.2,7.72-7.27,7.64
			C1672.26,1058.6,1669.51,1055.35,1669.55,1050.64z'
          />
          <path
            fill='#D50000'
            d='M1705.48,1027.62c-0.05,4.16-3.41,7.85-7.24,7.93c-3.82,0.08-7.4-3.63-7.44-7.72
			c-0.04-3.8,4.18-8.57,7.5-8.49C1701.67,1019.42,1705.53,1023.87,1705.48,1027.62z'
          />
          <path
            fill='#D50000'
            d='M814.93,785.51c-0.02,4.65-2.54,7.7-6.47,7.83c-4.1,0.14-7.76-3.78-7.61-8.14c0.15-4.12,3.67-7.71,7.52-7.67
			C812.68,777.57,814.95,780.33,814.93,785.51z'
          />
          <path
            fill='#D50000'
            d='M2184.32,1172.09c0.06,4.26-3.2,7.84-7.21,7.91c-4.43,0.08-7.06-2.8-7.06-7.71c0-4.56,3.02-8.1,6.95-8.14
			C2180.72,1164.11,2184.26,1167.95,2184.32,1172.09z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1634.77c0.01,5.1-2.35,7.76-6.88,7.76c-3.81,0-7.33-3.83-7.22-7.83c0.12-4.42,3.3-7.93,7.24-8.01
			C1203.06,1626.62,1205.88,1629.95,1205.9,1634.77z'
          />
          <path
            fill='#D50000'
            d='M1639.72,1027.74c-0.02,4.99-2.39,7.45-7.15,7.4c-4.64-0.05-6.99-2.67-6.91-7.72c0.07-4.3,3.5-8.11,7.22-8.03
			C1636.5,1019.47,1639.73,1023.41,1639.72,1027.74z'
          />
          <path
            fill='#D50000'
            d='M1148.75,1634.97c-0.07-4.75,2.72-8.2,6.71-8.28c3.91-0.08,7.36,3.51,7.48,7.78
			c0.11,4.01-3.38,7.99-7.06,8.05C1151.32,1642.59,1148.83,1639.96,1148.75,1634.97z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1634.59c0.08-4.41,3.42-8.06,7.2-7.88c3.83,0.18,6.97,3.97,6.88,8.3c-0.1,4.73-2.93,7.63-7.34,7.52
			C1129.23,1642.41,1126.84,1639.59,1126.93,1634.59z'
          />
          <path
            fill='#D50000'
            d='M2097.43,1319.45c-0.06,4.22-3.43,7.7-7.45,7.68c-4.28-0.02-7.24-3.26-7.15-7.8
			c0.08-4.05,3.84-8.15,7.38-8.05C2093.99,1311.39,2097.49,1315.35,2097.43,1319.45z'
          />
          <path
            fill='#D50000'
            d='M786.12,899.77c3.89-0.12,7.29,3.28,7.59,7.59c0.25,3.63-3.53,8.24-6.97,8.48c-3.37,0.24-7.81-4.56-7.74-8.37
			C779.07,903.76,782.67,899.87,786.12,899.77z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1612.72c-0.07-4.3,3.18-8.23,6.81-8.27c3.88-0.04,7.25,3.61,7.31,7.89c0.06,4.55-2.84,7.83-7,7.91
			C2042.16,1620.36,2039.59,1617.64,2039.52,1612.72z'
          />
          <path
            fill='#D50000'
            d='M720.43,899.73c3.78-0.03,7.31,3.66,7.45,7.8c0.12,3.62-3.89,8.25-7.21,8.32c-3.25,0.07-7.39-4.37-7.53-8.09
			C713,903.97,716.82,899.76,720.43,899.73z'
          />
          <path
            fill='#D50000'
            d='M2097.52,614.84c-0.02,4.56-3.12,8.22-6.88,8.14c-3.78-0.08-7.1-3.81-7.19-8.08
			c-0.09-4.14,3.31-7.81,7.2-7.76C2095.31,607.19,2097.55,609.71,2097.52,614.84z'
          />
          <path
            fill='#D50000'
            d='M720.36,793.33c-3.96-0.05-7.21-3.63-7.23-7.95c-0.02-4.15,3.39-7.84,7.26-7.86
			c4.17-0.02,7.66,3.75,7.49,8.09C727.71,789.82,724.24,793.38,720.36,793.33z'
          />
          <path
            fill='#D50000'
            d='M2170.28,785.82c-0.05-4.16,3.29-8.18,6.89-8.29c3.54-0.11,7.03,3.83,7.16,8.1c0.15,4.72-2.48,7.61-7.01,7.7
			C2172.68,793.42,2170.34,790.93,2170.28,785.82z'
          />
          <path
            fill='#D50000'
            d='M2162.38,615.02c-0.08,4.25-3.56,8.1-7.18,7.94c-3.66-0.16-6.85-4.04-6.87-8.35
			c-0.02-4.62,2.88-7.59,7.31-7.47C2160.17,607.26,2162.47,609.95,2162.38,615.02z'
          />
          <path
            fill='#D50000'
            d='M2177.1,607.14c4.24-0.08,7.05,2.66,7.22,7.06c0.18,4.5-3.12,8.67-6.95,8.77c-3.63,0.09-7.05-3.79-7.09-8.04
			C2170.23,609.8,2172.48,607.22,2177.1,607.14z'
          />
          <path
            fill='#D50000'
            d='M1155.5,1960.13c-3.88,0.1-7.33-3.36-7.54-7.56c-0.22-4.31,3.32-8.28,7.39-8.29c3.82,0,7.24,3.71,7.24,7.86
			C1162.59,1956.51,1159.46,1960.03,1155.5,1960.13z'
          />
          <path
            fill='#D50000'
            d='M1257.38,1610.45c-0.11-4.6,3.2-8.44,7.2-8.37c3.74,0.06,7.12,3.84,7.14,7.98c0.03,4.2-3.23,7.81-7.1,7.87
			C1260.18,1618,1257.49,1615.21,1257.38,1610.45z'
          />
          <path
            fill='#D50000'
            d='M749.05,955.57c0,4.48-3.11,8.23-6.8,8.21c-3.52-0.02-7.22-4.18-7.25-8.15c-0.03-4.18,3.32-7.84,7.26-7.93
			C746.57,947.6,749.05,950.47,749.05,955.57z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1610.52c-0.09,4.95-2.59,7.52-7.19,7.41c-3.78-0.09-7.16-4.06-6.94-8.15
			c0.23-4.1,4.01-7.92,7.63-7.71C1333.96,1602.31,1336.71,1605.88,1336.62,1610.52z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1173.45c-0.04,4.89-2.65,7.71-7.1,7.67c-4.29-0.04-7.04-3.14-6.96-7.86
			c0.07-4.25,3.41-8.01,7.1-7.99C1636.38,1165.28,1639.76,1169.25,1639.73,1173.45z'
          />
          <path
            fill='#D50000'
            d='M2313.77,808.38c-0.03,3.74-3.92,8.22-7.18,8.28c-3.42,0.06-7.63-4.76-7.48-8.56
			c0.15-3.87,4.11-8.09,7.5-7.99C2310.01,800.2,2313.8,804.58,2313.77,808.38z'
          />
          <path
            fill='#D50000'
            d='M1126.81,1927.66c0.05-4.69,3.06-8.11,7.04-7.98c3.73,0.12,7.25,4.13,7.15,8.16
			c-0.11,4.25-3.46,7.73-7.39,7.67C1129.09,1935.45,1126.75,1932.75,1126.81,1927.66z'
          />
          <path
            fill='#D50000'
            d='M1140.36,785.51c-0.09,4.49-3.36,8-7.3,7.82c-3.98-0.18-7.23-3.88-7.12-8.1c0.11-4.17,3.56-7.72,7.47-7.7
			C1137.57,777.55,1140.45,780.86,1140.36,785.51z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1125.55c-0.03,4.83-2.68,7.72-7.05,7.68c-4.26-0.04-7.06-3.17-7.01-7.85
			c0.05-4.22,3.48-8.04,7.18-7.98C1745.31,1117.46,1748.51,1121.25,1748.48,1125.55z'
          />
          <path
            fill='#D50000'
            d='M1104.96,1925.96c0.1-5.06,2.51-7.52,7.21-7.36c3.61,0.12,6.93,3.82,6.91,7.69
			c-0.03,4.54-3.62,8.36-7.67,8.15C1107.46,1934.24,1104.87,1930.82,1104.96,1925.96z'
          />
          <path
            fill='#D50000'
            d='M866.63,785.64c-0.06-5.02,2.36-7.97,6.65-8.13c3.96-0.15,7.63,3.81,7.52,8.12c-0.1,4.24-3.55,7.79-7.48,7.7
			C869.33,793.23,866.69,790.19,866.63,785.64z'
          />
          <path
            fill='#D50000'
            d='M757.94,785.73c-0.08-5.26,2.46-8.25,6.98-8.23c3.66,0.02,7.11,3.94,7.06,8.02c-0.05,4.26-3.38,7.8-7.34,7.82
			C760.73,793.35,758.01,790.26,757.94,785.73z'
          />
          <path
            fill='#D50000'
            d='M2170.28,930.15c-0.01-5.01,2.43-7.78,6.88-7.8c4.21-0.02,7.14,3.12,7.17,7.68c0.03,4.35-3.11,8.01-7,8.16
			C2173.67,938.34,2170.29,934.49,2170.28,930.15z'
          />
          <path
            fill='#D50000'
            d='M670.14,785c0.12-4.6,3.13-7.69,7.31-7.5c3.99,0.18,6.87,3.67,6.75,8.18c-0.12,4.36-3.49,7.86-7.38,7.67
			C672.99,793.16,670.02,789.45,670.14,785z'
          />
          <path
            fill='#D50000'
            d='M1583.71,1075.35c0-4.22,3.36-8.16,6.93-8.13c3.64,0.03,7.07,3.9,7.19,8.1c0.12,4.39-3,7.77-7.17,7.74
			C1586.2,1083.03,1583.71,1080.27,1583.71,1075.35z'
          />
          <path
            fill='#D50000'
            d='M2299.09,783.65c0.09-3.63,4.17-8.14,7.4-8.18c3.46-0.04,7.43,4.71,7.22,8.64c-0.21,3.99-4.03,8.02-7.48,7.89
			C2302.81,791.87,2299,787.41,2299.09,783.65z'
          />
          <path
            fill='#D50000'
            d='M727.87,1125.35c0.08,4.27-3.05,7.68-7.22,7.86c-3.97,0.17-7.35-3.23-7.51-7.54c-0.16-4.25,3.5-8.34,7.4-8.27
			C724.28,1117.47,727.79,1121.28,727.87,1125.35z'
          />
          <path
            fill='#D50000'
            d='M2226.26,1151.2c-0.07,5.06-2.52,7.74-7,7.65c-3.88-0.07-7.27-3.9-7.08-8c0.2-4.36,3.54-7.88,7.41-7.81
			C2223.7,1143.13,2226.33,1146.33,2226.26,1151.2z'
          />
          <path
            fill='#D50000'
            d='M1617.77,420.37c0,5.01-2.46,7.71-6.95,7.62c-3.7-0.07-7.22-4.05-7.09-8.02c0.14-4.32,3.48-7.85,7.4-7.82
			C1615.14,412.2,1617.77,415.45,1617.77,420.37z'
          />
          <path
            fill='#D50000'
            d='M1184.65,1318.41c0.1,4.14-3.29,7.75-7.36,7.83c-3.78,0.08-7.36-3.74-7.37-7.85
			c-0.01-3.76,4.03-8.29,7.34-8.24C1180.38,1310.19,1184.56,1314.85,1184.65,1318.41z'
          />
          <path
            fill='#D50000'
            d='M1793.17,1125.47c0.02,4.32-3.15,7.71-7.26,7.77c-4,0.06-7.33-3.31-7.49-7.58c-0.16-4.26,3.52-8.3,7.52-8.26
			C1789.49,1117.44,1793.16,1121.53,1793.17,1125.47z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1610.3c-0.03-4.26,3.22-8.15,6.87-8.21c3.7-0.06,7.16,3.78,7.21,7.99c0.06,4.76-2.65,7.8-6.99,7.85
			C1129.59,1617.97,1126.97,1615.15,1126.93,1610.3z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1610.27c0.04,4.93-2.43,7.67-6.9,7.64c-3.87-0.02-7.26-3.59-7.28-7.67
			c-0.02-4.37,3.55-8.29,7.44-8.16C1094.38,1602.21,1097.13,1605.55,1097.17,1610.27z'
          />
          <path
            fill='#D50000'
            d='M2242.75,607.13c4.23,0.07,7.2,3.29,7.13,7.7c-0.06,3.89-3.83,8.11-7.27,8.14c-3.84,0.04-7.55-4.19-7.37-8.41
			C2235.43,610.26,2238.65,607.06,2242.75,607.13z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1222.39c0.04,5.09-2.34,7.8-6.82,7.79c-4.5-0.02-7.21-2.85-7.25-7.58
			c-0.03-4.17,3.29-8.09,7.01-8.26C1744.95,1214.17,1748.45,1218.17,1748.48,1222.39z'
          />
          <path
            fill='#D50000'
            d='M1793.19,1222.57c0.03,4.25-3.19,7.59-7.36,7.62c-3.96,0.03-7.32-3.41-7.41-7.6
			c-0.09-4.21,3.75-8.38,7.59-8.24C1789.53,1214.49,1793.15,1218.64,1793.19,1222.57z'
          />
          <path
            fill='#D50000'
            d='M1639.72,420.07c-0.01,5-2.44,7.84-6.78,7.93c-3.98,0.08-7.29-3.41-7.27-7.68c0.02-4.64,3.37-8.3,7.49-8.17
			C1636.67,412.26,1639.73,415.96,1639.72,420.07z'
          />
          <path
            fill='#D50000'
            d='M836.9,1005.15c-0.08,4.12-3.14,7.62-6.75,7.73c-3.55,0.1-7.35-4.02-7.36-8.01
			c-0.02-4.53,3.34-7.92,7.72-7.79C835.02,997.21,837.01,999.72,836.9,1005.15z'
          />
          <path
            fill='#D50000'
            d='M1126.81,1878.81c-0.03-4.73,2.85-8.16,6.83-8.12c3.91,0.04,7.33,3.71,7.37,7.9c0.04,4.23-3.29,7.87-7.21,7.9
			C1129.24,1886.52,1126.84,1883.88,1126.81,1878.81z'
          />
          <path
            fill='#D50000'
            d='M1814.38,1222.56c-0.02,5.03-2.49,7.71-7.01,7.63c-4.42-0.08-7.18-3.07-7.13-7.75
			c0.04-4.22,3.44-8.03,7.21-8.08C1811.02,1214.3,1814.4,1218.31,1814.38,1222.56z'
          />
          <path
            fill='#D50000'
            d='M662.24,907.69c-0.03,4.51-3.16,8.24-6.87,8.18c-3.4-0.06-7.22-4.44-7.19-8.26c0.02-4.03,3.59-7.86,7.37-7.91
			C659.78,899.65,662.27,902.62,662.24,907.69z'
          />
          <path
            fill='#D50000'
            d='M626.56,955.5c-0.02-4.26,3.34-7.8,7.36-7.77c3.85,0.03,7.41,3.93,7.27,7.97c-0.14,4.06-3.9,8.13-7.45,8.08
			C630.3,963.72,626.57,959.43,626.56,955.5z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1198.03c-0.01-4.4,3.16-8.08,7-8.13c3.81-0.06,7.13,3.69,7.12,8.03c-0.01,4.77-2.75,7.79-7.08,7.81
			C2064.07,1205.75,2061.49,1202.92,2061.48,1198.03z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1368.37c0.03-4.35,3.29-8.09,7.03-8.05c3.77,0.04,7.11,3.85,7.08,8.08
			c-0.03,4.73-2.82,7.76-7.13,7.74S2061.45,1373.12,2061.48,1368.37z'
          />
          <path
            fill='#D50000'
            d='M692.09,955.69c-0.04-5.16,2.31-7.92,6.73-7.91c4.17,0.01,7.35,3.39,7.32,7.78c-0.03,4.29-3.34,8.12-7.1,8.22
			C695.31,963.89,692.12,960.17,692.09,955.69z'
          />
          <path
            fill='#D50000'
            d='M786.21,608.23c4.25-0.06,7.25,2.88,7.47,7.31c0.19,3.95-3.76,8.5-7.39,8.5c-3.47,0-7.05-4-7.26-8.09
			C778.83,611.97,782.26,608.29,786.21,608.23z'
          />
          <path
            fill='#D50000'
            d='M2192.21,906.27c0.02-4.54,3.07-8.18,6.81-8.13c3.47,0.05,7.19,4.22,7.27,8.14c0.08,3.96-4.06,8.6-7.52,8.43
			C2195.11,914.54,2192.19,910.78,2192.21,906.27z'
          />
          <path
            fill='#D50000'
            d='M1191.63,1830.66c0.07-4.58,3.23-8.09,7.15-7.93c3.71,0.14,7.2,4.2,7.07,8.21c-0.14,4.24-3.52,7.71-7.43,7.64
			C1193.95,1838.49,1191.55,1835.69,1191.63,1830.66z'
          />
          <path
            fill='#D50000'
            d='M2170.27,906.3c0.01-4.54,3.04-8.2,6.76-8.17c3.46,0.03,7.21,4.2,7.31,8.13c0.09,3.95-4.06,8.65-7.5,8.49
			C2173.22,914.57,2170.26,910.77,2170.27,906.3z'
          />
          <path
            fill='#D50000'
            d='M2170.28,977.97c0.06-4.81,2.73-7.73,7.05-7.7c4.05,0.03,7.04,3.41,7,7.9c-0.04,4.35-3.32,7.98-7.16,7.95
			C2173.34,986.07,2170.23,982.39,2170.28,977.97z'
          />
          <path
            fill='#D50000'
            d='M1756.36,1173.38c0-4.22,3.39-8.12,7.03-8.1c3.69,0.02,7.05,3.89,7.06,8.12c0.01,4.69-2.8,7.75-7.1,7.74
			C1758.89,1181.12,1756.36,1178.3,1756.36,1173.38z'
          />
          <path
            fill='#D50000'
            d='M1097.13,1659.36c-0.06,4.71-2.89,7.7-7.21,7.62c-4.09-0.08-6.97-3.42-6.92-8.02
			c0.05-4.31,3.43-7.94,7.25-7.8C1094.01,1651.3,1097.18,1655.09,1097.13,1659.36z'
          />
          <path
            fill='#D50000'
            d='M1981.51,1376.14c-4.24,0.05-7.24-3.02-7.36-7.54c-0.11-4.16,3.13-8.06,6.87-8.25c4.06-0.21,7.73,3.59,7.74,8
			C1988.75,1372.59,1985.5,1376.09,1981.51,1376.14z'
          />
          <path
            fill='#D50000'
            d='M1147.88,640.14c-0.78-4.3,2.32-7.66,6.56-8.31c3.52-0.54,7.59,4.24,7.52,8.33
			c-0.07,3.98-3.76,8.26-7.31,8.05C1151.63,648.03,1147.06,645.27,1147.88,640.14z'
          />
          <path
            fill='#D50000'
            d='M2126.51,904.59c0.5-4.26,4.32-7.9,7.84-7.48c3.48,0.42,7.16,5.05,6.83,8.6c-0.36,3.82-4.55,8.12-7.68,7.88
			C2130.05,913.32,2126.09,908.21,2126.51,904.59z'
          />
          <path
            fill='#D50000'
            d='M1154.79,753.1c3.88,0.05,7.25,3.77,7.17,7.93c-0.08,4.08-3.71,7.96-7.41,7.91
			c-3.94-0.05-6.86-3.57-6.82-8.22C1147.77,755.7,1150.23,753.04,1154.79,753.1z'
          />
          <path
            fill='#D50000'
            d='M1126.81,1830.87c0.01-4.68,2.95-8.15,6.9-8.13c3.71,0.02,7.31,3.98,7.29,8.02
			c-0.02,4.23-3.36,7.83-7.25,7.82C1129.2,1838.56,1126.8,1835.9,1126.81,1830.87z'
          />
          <path
            fill='#D50000'
            d='M1111.87,753.1c3.95,0.07,7.32,3.82,7.18,7.99c-0.14,4.15-3.73,7.91-7.51,7.85
			c-3.94-0.06-6.78-3.53-6.72-8.23C1104.88,755.56,1107.24,753.01,1111.87,753.1z'
          />
          <path
            fill='#D50000'
            d='M1748.48,954.11c0.02,4.9-2.58,7.82-6.98,7.82c-4.29,0-7.07-3.08-7.09-7.85c-0.02-4.37,3.25-8.1,7.1-8.1
			C1745.37,945.96,1748.47,949.58,1748.48,954.11z'
          />
          <path
            fill='#D50000'
            d='M1291.69,443.56c-0.06,4.57-3.16,7.88-7.24,7.71c-3.87-0.16-6.96-3.9-6.83-8.27c0.13-4.46,3.3-7.7,7.43-7.58
			C1289.19,435.54,1291.75,438.68,1291.69,443.56z'
          />
          <path
            fill='#D50000'
            d='M2061.48,1295.16c-0.08-4.41,2.96-8.14,6.8-8.33c3.81-0.19,7.23,3.49,7.31,7.84
			c0.09,4.78-2.62,7.92-6.88,7.99C2064.17,1302.75,2061.57,1300.05,2061.48,1295.16z'
          />
          <path
            fill='#D50000'
            d='M814.93,760.76c0.05,4.81-2.68,8.12-6.73,8.17c-3.74,0.05-7.3-3.8-7.35-7.94c-0.05-4.2,3.32-7.9,7.19-7.89
			C812.67,753.11,814.88,755.56,814.93,760.76z'
          />
          <path
            fill='#D50000'
            d='M631.67,997.08c4-0.03,7.47,3.36,7.63,7.46c0.15,3.86-3.57,8.25-7.08,8.35c-3.8,0.11-6.96-3.46-7.02-7.94
			C625.12,999.34,626.94,997.12,631.67,997.08z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1222.37c0.04-4.23,3.55-8.2,7.11-8.03c3.76,0.18,7.06,4.07,7.02,8.27c-0.04,4.64-2.89,7.6-7.31,7.59
			C2041.89,1230.18,2039.47,1227.4,2039.52,1222.37z'
          />
          <path
            fill='#D50000'
            d='M757.94,761.01c-0.04-5.18,2.18-7.8,6.7-7.91c3.9-0.1,7.23,3.37,7.36,7.67c0.13,4.09-3.27,8.02-7.06,8.16
			C760.92,769.09,757.97,765.76,757.94,761.01z'
          />
          <path
            fill='#D50000'
            d='M749.04,761.1c-0.06,4.75-3.01,8.03-7.05,7.83c-3.72-0.18-7.15-4.23-6.99-8.26c0.17-4.24,3.59-7.7,7.49-7.58
			C746.98,753.25,749.11,755.84,749.04,761.1z'
          />
          <path
            fill='#D50000'
            d='M2075.59,1222.6c-0.05,4.73-2.81,7.6-7.29,7.59c-4.42-0.01-6.88-2.85-6.83-7.86c0.05-4.2,3.57-8.17,7.09-8
			C2072.29,1214.51,2075.64,1218.44,2075.59,1222.6z'
          />
          <path
            fill='#D50000'
            d='M1242.35,1382.67c3.92-0.14,7.29,3.36,7.41,7.69c0.12,4.5-2.78,8.02-6.75,8.17c-4.25,0.16-7.16-2.84-7.31-7.5
			C1235.57,1386.58,1238.57,1382.81,1242.35,1382.67z'
          />
          <path
            fill='#D50000'
            d='M692.09,761.21c-0.08-5.54,2.13-8.12,6.96-8.12c3.82,0,7.15,3.75,7.08,7.95c-0.07,4.17-3.58,7.93-7.35,7.88
			C694.92,768.89,692.16,765.7,692.09,761.21z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1585.4c0.08-4.19,3.13-7.67,6.78-7.72c4.1-0.06,7.42,3.73,7.28,8.3c-0.14,4.53-3.21,7.67-7.37,7.54
			C1238.24,1593.39,1235.61,1590.2,1235.71,1585.4z'
          />
          <path
            fill='#D50000'
            d='M1155.16,1798.33c3.79-0.11,7.28,3.52,7.42,7.72c0.15,4.3-2.97,7.95-6.91,8.08
			c-4.16,0.14-7.72-3.47-7.72-7.84C1147.96,1802.05,1151.23,1798.44,1155.16,1798.33z'
          />
          <path
            fill='#D50000'
            d='M2039.52,639.38c0.06-3.97,3.31-7.78,6.69-7.86c3.88-0.09,7.54,3.98,7.44,8.29c-0.1,4.57-3.12,7.66-7.37,7.54
			C2042.08,647.25,2039.44,644.13,2039.52,639.38z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1585.69c0-4.5,3.11-8.07,6.98-8.01c3.9,0.06,7.12,3.69,7.1,8.03c-0.02,4.57-2.98,7.81-7.13,7.81
			C1129.67,1593.52,1126.93,1590.43,1126.93,1585.69z'
          />
          <path
            fill='#D50000'
            d='M2235.22,759.6c0.05-4.24,3.34-7.61,7.44-7.61c4.24-0.01,7.33,3.31,7.16,7.66c-0.15,3.83-4.07,8.21-7.31,8.18
			C2239.1,767.8,2235.17,763.36,2235.22,759.6z'
          />
          <path
            fill='#D50000'
            d='M2097.43,1563.52c-0.12,4.22-3.57,7.76-7.5,7.69c-4.1-0.08-7.19-3.56-7.09-7.99
			c0.09-4.18,3.68-7.97,7.42-7.83C2094.18,1555.53,2097.54,1559.34,2097.43,1563.52z'
          />
          <path
            fill='#D50000'
            d='M2162.38,808.92c0.01,4.76-2.72,7.91-6.87,7.94c-3.98,0.02-7.11-3.4-7.18-7.83c-0.06-4.4,3.09-8,7.02-8.01
			C2159.48,801.01,2162.37,804.26,2162.38,808.92z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1222.32c0.06-4.2,3.61-8.18,7.14-8c3.71,0.19,6.98,4.1,6.93,8.28c-0.06,4.65-2.89,7.58-7.3,7.56
			C1649.87,1230.14,1647.54,1227.44,1647.61,1222.32z'
          />
          <path
            fill='#D50000'
            d='M1669.55,979.77c-0.07-4.25,3.26-8.24,6.92-8.28c3.74-0.04,7.12,3.78,7.15,8.09c0.04,4.8-2.68,7.91-6.96,7.96
			C1672.27,987.58,1669.63,984.7,1669.55,979.77z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1563.57c-0.09,4.69-2.92,7.66-7.25,7.64c-4.03-0.02-7.03-3.46-6.94-7.94
			c0.09-4.28,3.48-7.96,7.26-7.87C2028.61,1555.48,2031.83,1559.28,2031.75,1563.57z'
          />
          <path
            fill='#D50000'
            d='M2170.28,808.71c0.07-4.6,3.09-7.81,7.24-7.69c3.89,0.11,6.96,3.82,6.81,8.22c-0.15,4.39-3.4,7.73-7.4,7.62
			C2172.85,816.74,2170.21,813.5,2170.28,808.71z'
          />
          <path
            fill='#D50000'
            d='M1756.36,1222.52c-0.02-4.2,3.41-8.21,7.02-8.18c3.75,0.03,7.03,3.78,7.07,8.09
			c0.04,4.66-2.73,7.71-7.06,7.76C1758.88,1230.24,1756.39,1227.52,1756.36,1222.52z'
          />
          <path
            fill='#D50000'
            d='M902.5,640.27c0.01,3.86-3.68,8.09-7.17,8.21c-3.78,0.14-7.61-4-7.61-8.23c0-4.1,3.42-7.59,7.41-7.55
			C899.26,632.73,902.49,636.04,902.5,640.27z'
          />
          <path
            fill='#D50000'
            d='M1748.48,979.82c-0.03,4.9-2.66,7.77-7.1,7.73c-4.26-0.04-7.01-3.19-6.97-8c0.03-4.17,3.62-8.15,7.26-8.06
			C1745.4,971.58,1748.51,975.39,1748.48,979.82z'
          />
          <path
            fill='#D50000'
            d='M1118.08,1806.63c-0.06,4.9-2.66,7.61-7.18,7.5c-3.88-0.1-7.11-3.85-6.94-8.07c0.17-4.32,3.53-7.79,7.47-7.73
			C1115.36,1798.39,1118.14,1801.86,1118.08,1806.63z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1661.28c0.05-4.28,3.44-7.94,7.25-7.82c3.78,0.11,7.02,4.02,6.89,8.28
			c-0.14,4.56-3.2,7.68-7.41,7.57C2042.04,1669.2,2039.46,1666.13,2039.52,1661.28z'
          />
          <path
            fill='#D50000'
            d='M1785.91,987.7c-3.62,0.06-7.35-3.87-7.49-7.88c-0.13-3.79,3.57-8.17,7.11-8.4c3.24-0.22,7.55,4.42,7.7,8.28
			C1793.37,983.52,1789.6,987.64,1785.91,987.7z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1148.76c0.03,4.77-2.66,7.91-6.83,7.96c-4.15,0.05-7.12-3.11-7.24-7.72
			c-0.11-4.32,3.07-8.04,6.96-8.13C1745.31,1140.78,1748.45,1144.26,1748.48,1148.76z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1560.89c0.04,5.17-2.19,7.86-6.59,7.99c-3.94,0.12-7.42-3.39-7.54-7.6
			c-0.11-4.25,3.67-8.35,7.59-8.23C1333.77,1553.17,1336.59,1556.54,1336.62,1560.89z'
          />
          <path
            fill='#D50000'
            d='M1582.72,443.31c0.03-5.2,2.3-7.84,6.76-7.88c3.77-0.03,7.32,3.75,7.36,7.84c0.04,4.17-3.36,7.91-7.27,7.99
			C1585.43,451.35,1582.69,448.16,1582.72,443.31z'
          />
          <path
            fill='#D50000'
            d='M1617.77,443.68c-0.15,4.86-2.53,7.58-6.64,7.59c-4.13,0.01-7.64-3.94-7.4-8.33
			c0.23-4.24,3.75-7.69,7.65-7.51C1615.69,435.64,1617.93,438.53,1617.77,443.68z'
          />
          <path
            fill='#D50000'
            d='M1039.94,1416.08c0.05-4.53,3.17-7.96,7.17-7.87c3.71,0.08,7.24,4.1,7.16,8.14c-0.09,4.28-3.49,7.76-7.5,7.67
			C1042.36,1423.92,1039.89,1421.05,1039.94,1416.08z'
          />
          <path
            fill='#D50000'
            d='M2126.46,1076.41c-0.12-4.11,3.22-7.95,7.01-8.06c4.22-0.12,7.71,3.49,7.67,7.93
			c-0.04,4.14-3.48,7.86-7.29,7.89C2129.91,1084.19,2126.58,1080.69,2126.46,1076.41z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1416.6c-0.03,4.69-2.45,7.34-6.78,7.42c-3.89,0.07-7.39-3.57-7.4-7.69
			c-0.01-4.22,3.3-7.99,7.11-8.11C1094.32,1408.09,1097.2,1411.52,1097.17,1416.6z'
          />
          <path
            fill='#D50000'
            d='M1119.42,1416.07c0.12,4.58-2.74,7.81-7.01,7.95c-3.95,0.12-7.37-3.39-7.45-7.66
			c-0.08-4.17,3.33-8.07,7.1-8.14C1115.98,1408.15,1119.31,1411.71,1119.42,1416.07z'
          />
          <path
            fill='#D50000'
            d='M2205.3,979.42c0.06,5.02-2.32,7.74-6.82,7.8c-4.02,0.05-7.09-2.99-7.24-7.19c-0.17-4.44,3.24-8.59,7.11-8.66
			C2202.23,971.31,2205.24,974.8,2205.3,979.42z'
          />
          <path
            fill='#D50000'
            d='M1148.75,1683.72c0.01-4.73,2.93-8.11,6.93-8.02c3.91,0.08,7.3,3.81,7.26,7.99
			c-0.04,4.17-3.46,7.85-7.29,7.83C1151.19,1691.5,1148.74,1688.74,1148.75,1683.72z'
          />
          <path
            fill='#D50000'
            d='M1177.06,1408.22c3.85-0.04,7.26,3.51,7.43,7.75c0.19,4.65-2.92,8.09-7.32,8.07
			c-3.96-0.01-7.27-3.57-7.25-7.8C1169.93,1412.05,1173.32,1408.25,1177.06,1408.22z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1561.27c0.04,4.98-2.51,7.72-7.08,7.62c-3.73-0.08-6.99-3.53-7.1-7.52c-0.13-4.42,3.41-8.4,7.4-8.32
			C1094.41,1553.13,1097.14,1556.44,1097.17,1561.27z'
          />
          <path
            fill='#D50000'
            d='M749.05,1052.65c-0.04,4.46-3.25,8.2-6.94,8.08c-3.56-0.12-7.17-4.32-7.11-8.3c0.06-4.14,3.54-7.83,7.4-7.85
			C746.61,1044.57,749.09,1047.57,749.05,1052.65z'
          />
          <path
            fill='#D50000'
            d='M662.55,833.01c0.05,4.74-2.72,8.12-6.75,8.23c-4.01,0.11-7.36-3.29-7.55-7.67
			c-0.18-4.13,3.18-8.04,7.01-8.17C659.54,825.27,662.5,828.35,662.55,833.01z'
          />
          <path
            fill='#D50000'
            d='M1286.02,1408.22c3.86-0.03,7.01,3.56,7.05,8.02c0.04,4.67-2.74,7.73-7.08,7.79
			c-3.95,0.06-7.31-3.56-7.28-7.82C1278.75,1412.03,1282.21,1408.24,1286.02,1408.22z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1416.36c-0.05,5.07-2.42,7.69-6.93,7.66c-3.83-0.03-7.28-3.83-7.19-7.93
			c0.09-4.12,3.6-7.85,7.41-7.87C1311.87,1408.2,1314.71,1411.65,1314.66,1416.36z'
          />
          <path
            fill='#D50000'
            d='M1183.92,2025.77c0.05,5.16-2.22,7.78-6.77,7.79c-3.95,0.02-7.37-3.61-7.29-7.7
			c0.09-4.27,3.39-8.03,7.14-8.14C1180.91,2017.61,1183.87,2021.06,1183.92,2025.77z'
          />
          <path
            fill='#D50000'
            d='M2271.18,640.83c-0.02,4.69-2.8,7.71-7.07,7.68c-4.16-0.03-7.1-3.3-7.05-7.84c0.04-4.18,3.45-7.94,7.22-7.98
			C2267.99,632.65,2271.2,636.44,2271.18,640.83z'
          />
          <path
            fill='#D50000'
            d='M2307.5,632.69c3.92-0.07,7.22,3.33,7.43,7.66c0.21,4.32-3.37,8.21-7.49,8.15c-3.98-0.06-7.29-3.68-7.27-7.94
			C2300.2,636.25,2303.45,632.77,2307.5,632.69z'
          />
          <path
            fill='#D50000'
            d='M2162.38,1075.99c0.07,4.49-2.93,8.09-6.82,8.17c-3.92,0.09-7.22-3.48-7.23-7.82
			c-0.01-4.53,2.94-7.96,6.91-8.03C2159.46,1068.24,2162.3,1071.3,2162.38,1075.99z'
          />
          <path
            fill='#D50000'
            d='M1313.65,467.7c0.12,4.09-3.18,7.98-6.94,8.18c-3.95,0.21-7.52-3.61-7.54-8.09
			c-0.02-4.56,2.81-7.63,7.14-7.73C1310.32,459.97,1313.52,463.29,1313.65,467.7z'
          />
          <path
            fill='#D50000'
            d='M2170.28,1076.3c0-4.76,2.7-7.92,6.84-7.98c4-0.05,7.17,3.36,7.21,7.76c0.04,4.39-3.11,8.03-6.99,8.09
			C2173.26,1084.23,2170.28,1080.91,2170.28,1076.3z'
          />
          <path
            fill='#D50000'
            d='M1538.8,467.93c-0.01-4.92,2.56-7.86,6.87-7.87c3.96-0.01,7.28,3.54,7.31,7.81c0.02,4.02-3.57,8-7.23,8.02
			C1541.72,475.9,1538.8,472.56,1538.8,467.93z'
          />
          <path
            fill='#D50000'
            d='M1901.85,1417.63c-0.03,4.31-3.22,7.59-7.39,7.59c-3.95,0-7.33-3.51-7.37-7.65
			c-0.04-4.27,3.78-8.34,7.64-8.14C1898.34,1409.61,1901.88,1413.69,1901.85,1417.63z'
          />
          <path
            fill='#D50000'
            d='M677.2,825.4c4.05,0.05,7.03,3.43,7,7.93c-0.03,4.41-3.24,7.99-7.12,7.93c-3.92-0.06-6.96-3.61-6.93-8.11
			C670.16,828.53,673.06,825.35,677.2,825.4z'
          />
          <path
            fill='#D50000'
            d='M1227.97,1684.23c-0.2,4.91-2.85,7.53-7.43,7.3c-3.7-0.18-7.07-4.33-6.77-8.34c0.32-4.3,3.83-7.68,7.75-7.49
			C1225.41,1675.9,1228.16,1679.53,1227.97,1684.23z'
          />
          <path
            fill='#D50000'
            d='M749.05,833.22c0.05,4.93-2.58,8.07-6.73,8.03c-3.91-0.04-7.31-3.7-7.32-7.89c-0.01-4.04,3.53-7.94,7.22-7.96
			C746.58,825.38,748.99,828.15,749.05,833.22z'
          />
          <path
            fill='#D50000'
            d='M1683.62,1294.9c-0.04,4.77-2.78,7.78-7.06,7.76c-4.28-0.01-7.01-3.05-7-7.79c0-4.4,3.22-8.08,7.04-8.04
			C1680.44,1286.87,1683.66,1290.57,1683.62,1294.9z'
          />
          <path
            fill='#D50000'
            d='M1147.76,663.23c-0.03-4.79,2.83-8.23,6.8-8.17c3.9,0.05,7.32,3.73,7.37,7.93c0.05,3.96-3.52,7.89-7.21,7.94
			C1150.25,670.97,1147.8,668.26,1147.76,663.23z'
          />
          <path
            fill='#D50000'
            d='M749.04,1004.46c-0.06,5.08-2.41,7.52-7.12,7.37c-3.65-0.11-7.09-4.18-6.91-8.17
			c0.19-4.21,3.81-7.85,7.61-7.66C746.6,996.21,749.09,999.49,749.04,1004.46z'
          />
          <path
            fill='#D50000'
            d='M1191.62,1781.66c0.06-4.63,3.14-8.08,7.09-7.92c3.87,0.15,7.22,3.96,7.14,8.11c-0.09,4.21-3.47,7.74-7.4,7.7
			C1194,1789.51,1191.56,1786.69,1191.62,1781.66z'
          />
          <path
            fill='#D50000'
            d='M727.87,955.46c0.12,3.61-3.91,8.27-7.21,8.33c-3.21,0.06-7.4-4.44-7.53-8.1c-0.14-3.79,3.7-8.02,7.31-8.05
			C724.24,947.59,727.74,951.27,727.87,955.46z'
          />
          <path
            fill='#D50000'
            d='M2228.16,736.21c0.16,4.21-3.08,7.9-7.09,8.09c-4.02,0.19-7.1-3.12-7.21-7.75c-0.11-4.65,2.7-7.95,6.88-8.07
			C2224.59,728.37,2228,731.92,2228.16,736.21z'
          />
          <path
            fill='#D50000'
            d='M2192.23,736.13c0.09-4.5,3.16-7.77,7.19-7.65c3.75,0.11,6.93,3.87,6.87,8.11c-0.06,4.34-3.37,7.85-7.27,7.72
			C2195.17,744.19,2192.14,740.54,2192.23,736.13z'
          />
          <path
            fill='#D50000'
            d='M1669.55,1148.65c0.07-4.42,3.35-7.95,7.25-7.78c3.88,0.17,6.93,3.87,6.82,8.27
			c-0.12,4.54-3.15,7.69-7.31,7.59C1672.17,1156.62,1669.48,1153.39,1669.55,1148.65z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1125.72c-0.07-4.27,3.11-8.18,6.75-8.31c3.7-0.14,7.27,3.65,7.38,7.85c0.12,4.7-2.64,7.89-6.92,7.98
			C2042.26,1133.34,2039.59,1130.56,2039.52,1125.72z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1537.58c0.02,5.43-2.33,8.06-7.11,7.98c-3.55-0.06-7.02-3.79-7.01-7.55c0-4.55,3.49-8.38,7.52-8.27
			C1311.65,1529.85,1314.64,1533.41,1314.66,1537.58z'
          />
          <path
            fill='#D50000'
            d='M2162.38,834.34c0.06,4.73-2.68,7.97-6.79,8.06c-3.92,0.08-7.2-3.43-7.25-7.76c-0.05-4.26,3.17-8,6.96-8.08
			C2159.36,826.48,2162.32,829.72,2162.38,834.34z'
          />
          <path
            fill='#D50000'
            d='M2090.78,728.48c4.15,0.04,7.08,3.34,7.04,7.93c-0.04,4.56-3.1,7.94-7.17,7.9c-3.94-0.03-7.25-3.72-7.18-7.98
			C2083.53,732.13,2086.97,728.44,2090.78,728.48z'
          />
          <path
            fill='#D50000'
            d='M923.74,980.44c-0.03,4.11-3.64,8.04-7.19,7.86c-3.65-0.19-7.16-4.33-7.08-8.34c0.08-4.2,3.4-7.38,7.66-7.37
			C921.74,972.6,923.79,975.02,923.74,980.44z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1270.77c-0.07-4.67,3.21-8.53,7.16-8.4c3.7,0.12,6.9,3.98,6.92,8.32c0.02,4.42-3.13,8.02-7.06,8.05
			C1129.91,1278.77,1127,1275.46,1126.93,1270.77z'
          />
          <path
            fill='#D50000'
            d='M786.21,656.14c4.23-0.07,7.27,2.91,7.48,7.32c0.18,3.89-3.83,8.5-7.4,8.51c-3.46,0.01-7.07-4.02-7.27-8.11
			C778.82,659.83,782.2,656.21,786.21,656.14z'
          />
          <path
            fill='#D50000'
            d='M1148.73,1440.65c0.07-4.73,3.06-8.01,7.11-7.83c3.88,0.17,7.24,4.03,7.1,8.14c-0.15,4.19-3.6,7.72-7.47,7.65
			C1151.06,1448.54,1148.65,1445.69,1148.73,1440.65z'
          />
          <path
            fill='#D50000'
            d='M1756.37,1100.94c0-4.46,3.17-8.06,7.02-7.98c3.92,0.08,7.07,3.67,7.05,8.03c-0.02,4.55-3,7.81-7.11,7.8
			C1759.06,1108.77,1756.37,1105.74,1756.37,1100.94z'
          />
          <path
            fill='#D50000'
            d='M2097.44,1125.24c0.11,4.16-3.19,7.89-7.06,8c-4.29,0.11-7.23-2.81-7.47-7.43c-0.22-4.36,3.24-8.44,7.14-8.4
			C2093.9,1117.44,2097.34,1121.08,2097.44,1125.24z'
          />
          <path
            fill='#D50000'
            d='M1126.81,1781.71c0.03-4.74,2.96-8.09,6.98-7.97c3.83,0.11,7.28,3.97,7.21,8.07
			c-0.08,4.27-3.43,7.78-7.38,7.74C1129.09,1789.5,1126.79,1786.85,1126.81,1781.71z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1440.68c0.04-4.44,3.26-8,7.12-7.87c3.9,0.14,7,3.79,6.93,8.16c-0.08,4.63-2.99,7.71-7.21,7.65
			C1238.27,1448.55,1235.67,1445.52,1235.71,1440.68z'
          />
          <path
            fill='#D50000'
            d='M2052.66,566.3c0,4.89-2.63,7.75-7.05,7.66c-3.72-0.08-7.25-4.12-7.08-8.11c0.18-4.29,3.58-7.78,7.55-7.73
			C2050.34,558.16,2052.66,561.04,2052.66,566.3z'
          />
          <path
            fill='#D50000'
            d='M1118.08,1781.47c0.09,5.15-2.19,7.93-6.62,8.08c-3.95,0.14-7.42-3.33-7.5-7.5c-0.09-4.53,3.34-8.4,7.38-8.32
			C1115.15,1773.81,1118.01,1777.09,1118.08,1781.47z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1440.88c-0.05,5.2-2.29,7.72-6.86,7.74c-3.79,0.02-7.24-3.73-7.25-7.88
			c-0.01-4.21,3.41-7.92,7.31-7.92C1311.92,1432.82,1314.71,1436.13,1314.66,1440.88z'
          />
          <path
            fill='#D50000'
            d='M1140.33,565.72c0.03,4.18-3.48,8.26-7.08,8.22c-3.69-0.04-7.27-4-7.31-8.09c-0.04-4.12,3.35-7.73,7.29-7.76
			C1137.75,558.06,1140.29,560.79,1140.33,565.72z'
          />
          <path
            fill='#D50000'
            d='M873.42,1182.25c-4.31,0.08-7.58-3.38-7.62-8.03c-0.03-3.93,3.77-7.83,7.56-7.76
			c3.74,0.07,7.23,3.85,7.34,7.96C880.81,1178.56,877.46,1182.18,873.42,1182.25z'
          />
          <path
            fill='#D50000'
            d='M1669.55,1100.75c0.07-4.42,3.34-7.96,7.21-7.81c3.85,0.14,6.94,3.86,6.85,8.24
			c-0.09,4.55-3.11,7.7-7.28,7.61C1672.15,1108.69,1669.48,1105.53,1669.55,1100.75z'
          />
          <path
            fill='#D50000'
            d='M2170.28,834.51c0-4.66,2.86-7.94,6.91-7.95c3.8-0.01,7.13,3.67,7.14,7.91c0.01,4.32-3.21,7.93-7.08,7.93
			C2173.12,842.4,2170.28,839.18,2170.28,834.51z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1245.74c0,4.71-2.76,7.88-6.92,7.92c-4.06,0.04-7.08-3.24-7.14-7.77c-0.06-4.42,3.03-8,6.98-8.08
			C1636.52,1237.74,1639.72,1241.32,1639.73,1245.74z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1537.83c0.01,5.1-2.35,7.77-6.87,7.76c-3.81,0-7.33-3.82-7.21-7.83c0.13-4.45,3.27-7.93,7.24-8.01
			C1203.06,1529.67,1205.88,1532.99,1205.89,1537.83z'
          />
          <path
            fill='#D50000'
            d='M2335.07,833.63c0.04,4.9-3.03,8.56-7.07,8.4c-3.66-0.14-7.16-4.16-7.13-8.2c0.02-3.97,3.6-8.02,7.22-8.18
			C2331.79,825.49,2335.03,829.19,2335.07,833.63z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1076.76c0.02,4.53-2.97,8.16-6.78,8.21c-3.74,0.05-7.52-4.24-7.41-8.41
			c0.1-3.92,3.79-7.94,7.31-7.97C2506.23,1068.56,2509.49,1072.43,2509.51,1076.76z'
          />
          <path
            fill='#D50000'
            d='M1148.73,1537.56c0.08-4.68,3.15-8.02,7.18-7.8c3.84,0.21,7.23,4.16,7.04,8.23c-0.2,4.17-3.69,7.69-7.52,7.59
			C1150.99,1545.46,1148.64,1542.65,1148.73,1537.56z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1537.38c0.15-4.12,3.38-7.65,6.96-7.62c4.05,0.03,7.32,3.97,7.12,8.55
			c-0.21,4.68-3.13,7.47-7.59,7.26C1129.1,1545.36,1126.75,1542.39,1126.93,1537.38z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1197.87c0.05,4.97-2.39,7.79-6.83,7.86c-4.31,0.07-7.19-2.96-7.23-7.63
			c-0.04-4.33,3.13-8.06,6.97-8.2C1745.14,1189.76,1748.43,1193.46,1748.48,1197.87z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1515.78c-0.05-4.35,3.15-8.24,6.82-8.27c3.9-0.04,7.25,3.58,7.31,7.9c0.06,4.53-2.86,7.83-7.01,7.91
			C2042.17,1523.42,2039.58,1520.67,2039.52,1515.78z'
          />
          <path
            fill='#D50000'
            d='M2271.19,665.22c0.03,4.73-2.69,7.8-6.93,7.84c-4.21,0.03-7.17-3.13-7.19-7.7c-0.03-4.26,3.21-7.98,7.06-8.12
			C2267.9,657.1,2271.16,660.78,2271.19,665.22z'
          />
          <path
            fill='#D50000'
            d='M662.24,857.85c-0.1,4.1-3.23,7.82-6.66,7.92c-3.53,0.1-7.61-4.62-7.41-8.57c0.21-4.2,3.69-7.65,7.63-7.56
			C660.04,849.75,662.37,852.71,662.24,857.85z'
          />
          <path
            fill='#D50000'
            d='M1248.83,544.86c-0.18,4.24-3.78,8.13-7.3,7.88c-3.7-0.27-6.89-4.25-6.82-8.52c0.07-4.29,3.52-7.79,7.51-7.59
			C1246.48,536.84,1249.04,540.03,1248.83,544.86z'
          />
          <path
            fill='#D50000'
            d='M851.85,874.45c4.51,0.06,7.12,2.92,7.02,7.68c-0.1,4.48-3.3,8.13-7.11,8.09c-3.94-0.04-7.24-4.1-7.07-8.7
			C844.86,877.04,847.54,874.39,851.85,874.45z'
          />
          <path
            fill='#D50000'
            d='M2221.15,704.07c3.94,0.07,7.16,3.8,7.01,8.12c-0.15,4.23-3.61,7.78-7.51,7.72c-3.91-0.06-6.8-3.59-6.76-8.25
			C2213.94,706.88,2216.7,703.99,2221.15,704.07z'
          />
          <path
            fill='#D50000'
            d='M2487.6,1175.18c-0.06,4.24-3.05,7.67-6.78,7.77c-3.65,0.1-7.4-3.86-7.47-7.88
			c-0.07-4.23,3.76-8.46,7.56-8.35C2484.62,1166.83,2487.66,1170.67,2487.6,1175.18z'
          />
          <path
            fill='#D50000'
            d='M1756.36,1270.42c-0.04-4.2,3.31-8.16,6.93-8.2c3.72-0.04,7.09,3.74,7.15,8c0.06,4.72-2.7,7.81-7,7.84
			C1758.95,1278.09,1756.4,1275.35,1756.36,1270.42z'
          />
          <path
            fill='#D50000'
            d='M1349.44,1520.97c-4.32-0.04-7.09-3.12-7.04-7.82c0.05-4.11,3.57-8,7.25-8c3.58,0,6.96,3.96,6.94,8.13
			C1356.57,1517.93,1353.7,1521.01,1349.44,1520.97z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1513.22c0.01,5.12-2.25,7.68-6.83,7.74c-3.86,0.05-7.31-3.6-7.32-7.76
			c-0.01-4.01,3.63-8.04,7.3-8.06C1333.59,1505.11,1336.62,1508.69,1336.62,1513.22z'
          />
          <path
            fill='#D50000'
            d='M1097.15,1464.56c-0.04,4.73-2.45,7.34-6.8,7.38c-3.96,0.04-7.4-3.59-7.36-7.75c0.04-4.21,3.4-8.02,7.15-8.11
			C1094.34,1455.99,1097.2,1459.44,1097.15,1464.56z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1513.29c-0.04,5.09-2.32,7.63-6.88,7.67c-3.82,0.04-7.22-3.65-7.22-7.83c0-4.04,3.62-7.98,7.32-7.97
			C1311.73,1505.16,1314.69,1508.72,1314.66,1513.29z'
          />
          <path
            fill='#D50000'
            d='M1654.41,1010.76c-4.52-0.02-6.81-2.65-6.8-7.79c0.01-4.44,3.26-8.09,7.11-7.98
			c3.86,0.11,6.97,3.82,6.96,8.28C1661.66,1008.09,1659.05,1010.78,1654.41,1010.76z'
          />
          <path
            fill='#D50000'
            d='M749.05,688.65c-0.08,4.36-2.95,7.7-6.64,7.74c-3.8,0.04-7.36-3.74-7.39-7.87c-0.03-4.22,3.64-8.04,7.62-7.93
			C747.06,680.7,749.14,683.32,749.05,688.65z'
          />
          <path
            fill='#D50000'
            d='M1669.55,1197.85c0.04-4.35,3.35-8.03,7.16-7.96c3.78,0.07,6.98,3.87,6.91,8.21c-0.08,4.74-2.88,7.7-7.2,7.63
			C1672.06,1205.67,1669.5,1202.73,1669.55,1197.85z'
          />
          <path
            fill='#D50000'
            d='M836.9,881.73c0.04,4.69-3.22,8.63-7.02,8.48c-3.48-0.14-6.97-3.96-7.09-7.77c-0.14-4.39,3.24-7.98,7.54-8
			C834.78,874.43,836.86,876.73,836.9,881.73z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1513.4c-0.05-4.25,3.18-8.16,6.82-8.27c3.67-0.11,7.19,3.75,7.26,7.95
			c0.07,4.75-2.65,7.85-6.94,7.91C1129.62,1521.05,1126.99,1518.25,1126.93,1513.4z'
          />
          <path
            fill='#D50000'
            d='M1097.17,1514.14c-0.2,4.46-2.88,7-7.22,6.85c-3.81-0.13-7.11-3.93-6.96-8.02c0.15-4.16,3.57-7.86,7.26-7.85
			C1094.51,1505.14,1097.41,1508.92,1097.17,1514.14z'
          />
          <path
            fill='#D50000'
            d='M757.93,688.31c-0.03-5.03,2.35-7.71,6.85-7.73c4.04-0.02,7.25,3.43,7.2,7.71c-0.05,4.33-3.25,7.95-7.13,8.09
			C761.25,696.52,757.96,692.68,757.93,688.31z'
          />
          <path
            fill='#D50000'
            d='M814.93,882.39c-0.06,4.08-3.15,7.71-6.68,7.84c-3.76,0.13-7.53-4.18-7.41-8.47
			c0.13-4.31,3.31-7.36,7.65-7.32C813.03,874.49,815.01,876.94,814.93,882.39z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1464.24c-0.04,5.09-2.39,7.73-6.89,7.71c-3.87-0.02-7.29-3.76-7.23-7.91
			c0.06-4.08,3.62-7.93,7.36-7.95C1311.79,1456.06,1314.69,1459.56,1314.66,1464.24z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1464.08c0.01,5.13-2.27,7.79-6.72,7.86c-3.92,0.07-7.38-3.54-7.42-7.73
			c-0.04-4.03,3.51-8.01,7.23-8.11C1333.68,1456,1336.62,1459.39,1336.62,1464.08z'
          />
          <path
            fill='#D50000'
            d='M1698.42,719.91c-4.52,0.04-6.87-2.59-6.89-7.7c-0.02-4.44,3.22-8.16,7.03-8.07
			c3.84,0.09,6.99,3.78,7.02,8.21C1705.61,717.13,1703.01,719.87,1698.42,719.91z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1149.02c-0.06-4.49,3-8.13,6.84-8.15c3.89-0.02,7.18,3.58,7.22,7.9c0.05,4.53-2.91,7.9-6.98,7.95
			C1650.44,1156.77,1647.67,1153.75,1647.61,1149.02z'
          />
          <path
            fill='#D50000'
            d='M757.93,882.14c-0.04-5.18,2.19-7.68,6.83-7.69c4.24-0.01,7.27,3.19,7.22,7.61
			c-0.05,4.33-3.33,8.11-7.09,8.17C761.23,890.29,757.97,886.49,757.93,882.14z'
          />
          <path
            fill='#D50000'
            d='M814.93,857.65c-0.03,4.4-3.27,8.21-6.91,8.12c-3.5-0.09-7.19-4.35-7.18-8.28c0.01-4.12,3.49-7.78,7.4-7.8
			C812.58,849.66,814.96,852.5,814.93,857.65z'
          />
          <path
            fill='#D50000'
            d='M1235.68,710.48c0.03-5.04,2.48-7.64,7.08-7.51c3.73,0.1,6.92,3.53,7,7.51c0.09,4.42-3.48,8.43-7.44,8.34
			C1238.33,718.73,1235.65,715.36,1235.68,710.48z'
          />
          <path
            fill='#D50000'
            d='M749.03,882.48c-0.08,4.07-3.23,7.7-6.74,7.76c-3.79,0.05-7.5-4.28-7.3-8.54c0.2-4.36,3.34-7.33,7.68-7.25
			C747.11,874.52,749.13,877.08,749.03,882.48z'
          />
          <path
            fill='#D50000'
            d='M1669.55,1002.99c0-4.4,3.32-8.12,7.13-8c3.9,0.12,6.98,3.78,6.93,8.23c-0.05,4.83-2.75,7.74-7.17,7.7
			C1672.08,1010.88,1669.55,1007.97,1669.55,1002.99z'
          />
          <path
            fill='#D50000'
            d='M1169.66,710.61c0.05-4.97,2.6-7.74,7.06-7.65c3.93,0.08,7.23,3.73,7.17,7.92c-0.06,4.1-3.63,7.95-7.37,7.93
			C1172.66,718.8,1169.62,715.16,1169.66,710.61z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1732.92c-0.05-4.35,3.21-8.21,6.92-8.2c3.84,0.01,7.06,3.61,7.14,8c0.08,4.71-2.69,7.82-6.98,7.83
			C1238.23,1740.57,1235.76,1737.91,1235.71,1732.92z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1466.53c-0.04,4.69-2.85,7.73-7.14,7.73c-4.1,0-7.09-3.31-7.05-7.82c0.04-4.27,3.37-7.97,7.16-7.98
			C2028.48,1458.46,2031.79,1462.25,2031.75,1466.53z'
          />
          <path
            fill='#D50000'
            d='M1697.98,864.79c-3.82-0.04-7.08-3.4-7.09-7.32c-0.01-4.23,3.83-8.56,7.51-8.48
			c3.53,0.08,7.26,4.47,7.09,8.34C1705.29,861.75,1702.18,864.83,1697.98,864.79z'
          />
          <path
            fill='#D50000'
            d='M2191.96,1175.58c-0.04-4.81,2.6-7.85,6.93-7.96c3.89-0.1,7.35,3.53,7.39,7.75c0.04,4.19-3.36,8.02-7.18,8.08
			C2195.02,1183.52,2192,1180.19,2191.96,1175.58z'
          />
          <path
            fill='#D50000'
            d='M720.52,874.44c4.03,0.03,7.34,3.47,7.34,7.64c0.01,3.66-4,8.08-7.39,8.15c-3.36,0.07-7.24-4.25-7.33-8.17
			C713.06,878.2,716.71,874.42,720.52,874.44z'
          />
          <path
            fill='#D50000'
            d='M692.09,881.66c0.08-4.82,2.34-7.22,6.77-7.21c4.27,0.01,7.31,3.19,7.28,7.6c-0.04,4.24-3.4,8.11-7.11,8.18
			C695.18,890.3,692.01,886.39,692.09,881.66z'
          />
          <path
            fill='#D50000'
            d='M1147.72,710.55c0.05-4.98,2.57-7.67,7.1-7.58c3.92,0.07,7.19,3.74,7.11,7.97c-0.08,4.09-3.7,7.93-7.43,7.88
			C1150.64,718.77,1147.67,715.15,1147.72,710.55z'
          />
          <path
            fill='#D50000'
            d='M677.02,874.45c4.53,0.01,7.22,2.84,7.17,7.54c-0.05,4.48-3.19,8.19-6.96,8.23c-3.99,0.04-7.18-3.8-7.09-8.56
			C670.23,876.97,672.64,874.44,677.02,874.45z'
          />
          <path
            fill='#D50000'
            d='M662.25,882.32c-0.07,4.09-3.19,7.8-6.63,7.91c-3.64,0.12-7.38-3.87-7.43-7.91
			c-0.06-4.52,3.21-7.93,7.55-7.88C660.37,874.51,662.35,876.9,662.25,882.32z'
          />
          <path
            fill='#D50000'
            d='M2206.29,688.73c-0.1,4.32-3.48,7.82-7.39,7.67c-3.78-0.15-6.8-3.91-6.68-8.33c0.13-4.6,3.1-7.63,7.33-7.48
			C2203.56,680.72,2206.4,684.15,2206.29,688.73z'
          />
          <path
            fill='#D50000'
            d='M814.93,710.62c0.05,4.74-2.74,8.16-6.69,8.2c-3.71,0.04-7.32-3.83-7.38-7.92c-0.07-4.2,3.29-7.93,7.15-7.94
			C812.54,702.95,814.87,705.52,814.93,710.62z'
          />
          <path
            fill='#D50000'
            d='M2220.93,696.39c-3.99,0.08-7.02-3.29-7.1-7.9c-0.08-4.64,2.75-7.84,7.02-7.91c3.88-0.06,7.3,3.59,7.3,7.81
			C2228.16,692.71,2224.91,696.31,2220.93,696.39z'
          />
          <path
            fill='#D50000'
            d='M858.87,542.23c0,4.38-3.19,8.1-7,8.17c-3.8,0.07-7.2-3.71-7.18-8c0.02-4.79,2.69-7.75,7.04-7.81
			C855.94,534.53,858.87,537.66,858.87,542.23z'
          />
          <path
            fill='#D50000'
            d='M2279.01,688.5c-0.02-4.65,2.79-7.87,6.9-7.92c4.03-0.05,7.18,3.31,7.23,7.71c0.05,4.48-3.41,8.18-7.53,8.07
			C2282.15,696.28,2279.02,692.55,2279.01,688.5z'
          />
          <path
            fill='#D50000'
            d='M2039.52,881.13c-0.03-4.89,2.58-7.92,6.86-7.99c4.09-0.06,7.21,3.28,7.28,7.79
			c0.07,4.37-3.17,8.17-7.01,8.21C2042.83,889.19,2039.54,885.49,2039.52,881.13z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1003.76c-0.17,4.56-2.82,7.23-7.11,7.18c-4.44-0.06-7.25-3.49-6.95-8.48
			c0.25-4.11,3.59-7.56,7.22-7.47C1745.67,995.09,1748.67,998.93,1748.48,1003.76z'
          />
          <path
            fill='#D50000'
            d='M2097.89,881.5c-0.02,4.61-2.96,7.7-7.29,7.66c-3.95-0.04-7.22-3.71-7.13-7.99c0.09-4.2,3.6-7.92,7.39-7.85
			C2094.7,873.39,2097.91,877.13,2097.89,881.5z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1148.9c-0.02,4.7-2.82,7.82-7.02,7.82c-4.03,0-7.03-3.33-7.05-7.85c-0.02-4.41,3.12-7.96,7.07-8
			C1636.58,1140.83,1639.75,1144.47,1639.73,1148.9z'
          />
          <path
            fill='#D50000'
            d='M791.97,1150.26c-0.02,5.26-2.13,7.57-6.91,7.51c-3.98-0.05-7.17-3.54-7.14-7.83
			c0.03-4.07,3.77-8.11,7.37-7.96C789.14,1142.15,791.98,1145.67,791.97,1150.26z'
          />
          <path
            fill='#D50000'
            d='M1242.38,1496.57c-4.17-0.14-6.77-3.32-6.67-8.14c0.1-4.41,3.46-7.95,7.3-7.7c3.89,0.26,6.9,3.97,6.76,8.33
			C1249.62,1493.6,1246.55,1496.71,1242.38,1496.57z'
          />
          <path
            fill='#D50000'
            d='M1314.66,1488.77c-0.05,5.15-2.36,7.78-6.84,7.79c-3.71,0.01-7.29-3.86-7.29-7.88c0-4.26,3.37-7.93,7.29-7.95
			C1311.92,1480.72,1314.71,1484,1314.66,1488.77z'
          />
          <path
            fill='#D50000'
            d='M632.59,874.46c4.09-0.06,7.71,3.68,7.62,7.89c-0.07,3.62-3.71,7.7-7.03,7.86c-3.53,0.17-7.17-3.74-7.31-7.86
			C625.72,877.6,628.34,874.52,632.59,874.46z'
          />
          <path
            fill='#D50000'
            d='M2170.28,882.71c0.03-4.33,3.37-8.24,6.99-8.18c3.69,0.06,7.02,3.97,7.06,8.29c0.05,4.65-2.89,7.86-7.15,7.82
			C2172.87,890.61,2170.25,887.59,2170.28,882.71z'
          />
          <path
            fill='#D50000'
            d='M1271.71,1561.07c0,4.29-3.33,7.87-7.29,7.82c-4.35-0.05-7.14-3.12-7.1-7.81c0.04-4.38,3.3-8.06,7.15-8.05
			C1268.23,1553.05,1271.71,1556.9,1271.71,1561.07z'
          />
          <path
            fill='#D50000'
            d='M1148.75,1732.68c0.05-4.7,3.05-8.1,7.05-7.97c3.82,0.13,7.27,4.04,7.15,8.12c-0.12,4.24-3.49,7.76-7.39,7.71
			C1151.1,1740.5,1148.7,1737.72,1148.75,1732.68z'
          />
          <path
            fill='#D50000'
            d='M1669.55,1125.72c-0.07-4.3,3.09-8.18,6.77-8.31c3.71-0.13,7.22,3.62,7.29,7.81c0.08,4.74-2.6,7.9-6.8,8.01
			C1672.49,1133.34,1669.63,1130.39,1669.55,1125.72z'
          />
          <path
            fill='#D50000'
            d='M2177.34,582.5c4.12,0.02,7.11,3.37,6.99,7.84c-0.12,4.46-3.35,8.05-7.17,7.98c-3.8-0.07-6.92-3.8-6.88-8.21
			C2170.33,585.24,2172.9,582.48,2177.34,582.5z'
          />
          <path
            fill='#D50000'
            d='M757.93,710.58c0.08-5.1,2.42-7.63,7.04-7.61c3.82,0.01,7.13,3.78,7.03,7.99c-0.1,4.16-3.63,7.93-7.37,7.86
			C760.65,718.75,757.86,715.31,757.93,710.58z'
          />
          <path
            fill='#D50000'
            d='M1748.48,785.88c-0.07,4.91-2.7,7.79-7.07,7.76c-4.29-0.03-7.02-3.13-7-7.94c0.02-4.25,3.48-8.13,7.2-8.08
			C1745.37,777.68,1748.55,781.5,1748.48,785.88z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1710.35c0.08-4.32,3.5-8,7.28-7.84c3.81,0.17,6.94,3.97,6.86,8.36c-0.07,4.48-3.18,7.59-7.46,7.46
			C2041.84,1718.2,2039.42,1715.31,2039.52,1710.35z'
          />
          <path
            fill='#D50000'
            d='M2162.38,688.58c-0.04,4.4-3.29,7.94-7.17,7.81c-3.88-0.12-7-3.82-6.88-8.16c0.12-4.48,3.22-7.74,7.28-7.66
			C2159.72,680.67,2162.42,683.87,2162.38,688.58z'
          />
          <path
            fill='#D50000'
            d='M1583.71,1148.78c0.01-4.23,2.95-7.78,6.56-7.91c4.14-0.15,7.5,3.49,7.56,8.17c0.06,4.37-3.12,7.72-7.27,7.68
			C1586.45,1156.67,1583.71,1153.49,1583.71,1148.78z'
          />
          <path
            fill='#D50000'
            d='M2105.42,688.24c0.07-4.68,2.89-7.74,7.08-7.66c3.82,0.08,6.93,3.39,6.99,7.46c0.07,4.57-3.31,8.42-7.31,8.35
			C2108.28,696.32,2105.35,692.79,2105.42,688.24z'
          />
          <path
            fill='#D50000'
            d='M793.79,955.47c0.09,3.6-3.93,8.23-7.23,8.31c-3.2,0.08-7.38-4.42-7.54-8.1c-0.16-3.82,3.65-8,7.32-8.02
			C790.16,947.65,793.68,951.34,793.79,955.47z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1125.79c-0.13,4.77-2.89,7.56-7.33,7.42c-4.18-0.13-6.9-3.38-6.73-8.05
			c0.15-4.26,3.64-7.92,7.37-7.75C1636.67,1117.58,1639.84,1121.54,1639.73,1125.79z'
          />
          <path
            fill='#D50000'
            d='M692.09,857.32c0.04-4.9,2.28-7.49,6.54-7.56c4.22-0.07,7.42,3.17,7.5,7.59c0.08,4.45-3.6,8.72-7.43,8.39
			C695.74,865.49,691.22,862.13,692.09,857.32z'
          />
          <path
            fill='#D50000'
            d='M2466.65,1077.03c-0.1,3.62-4.35,8.08-7.64,8c-3.84-0.09-6.72-3.98-6.6-8.89c0.1-4.11,3.42-7.67,6.97-7.49
			C2462.73,1068.83,2466.75,1073.45,2466.65,1077.03z'
          />
          <path
            fill='#D50000'
            d='M1583.71,1173.2c0.04-4.16,3.59-8.09,7.13-7.9c3.69,0.2,6.97,4.08,6.99,8.28c0.02,4.42-3.14,7.66-7.37,7.54
			C1586.1,1181,1583.66,1178.14,1583.71,1173.2z'
          />
          <path
            fill='#D50000'
            d='M1227.81,710.67c0.06,4.09-3.42,8.05-7.16,8.14c-3.87,0.1-7.09-3.53-7.13-8.02c-0.04-4.94,2.5-7.82,6.94-7.86
			C1224.41,702.91,1227.75,706.42,1227.81,710.67z'
          />
          <path
            fill='#D50000'
            d='M858.87,688.27c0,4.35-3.26,8.09-7.06,8.1c-3.78,0.02-7.13-3.78-7.12-8.05c0.02-4.77,2.7-7.7,7.09-7.73
			C856,680.55,858.87,683.66,858.87,688.27z'
          />
          <path
            fill='#D50000'
            d='M749.05,1030.25c-0.02,4.42-3.19,8.22-6.8,8.15c-3.39-0.07-7.18-4.36-7.25-8.2
			c-0.07-4.17,3.89-8.81,7.53-8.28C745.86,1022.41,749.83,1024.9,749.05,1030.25z'
          />
          <path
            fill='#D50000'
            d='M2119.38,1319.3c0.07,4.19-3.29,7.81-7.27,7.83c-4.3,0.02-7.31-3.11-7.32-7.65c-0.02-3.96,3.71-8.2,7.22-8.2
			C2115.8,1311.27,2119.3,1315.09,2119.38,1319.3z'
          />
          <path
            fill='#D50000'
            d='M2271.17,590.05c0.06,4.41-3,8.12-6.82,8.25c-3.78,0.14-7.1-3.48-7.28-7.92c-0.18-4.41,2.87-7.84,7-7.88
			C2268.56,582.46,2271.11,585.17,2271.17,590.05z'
          />
          <path
            fill='#D50000'
            d='M1271.72,1463.95c0.11,4.24-3.21,7.96-7.13,8c-4.34,0.04-7.28-3.02-7.31-7.64c-0.03-4.29,3.24-8.12,7.02-8.22
			C1268.07,1455.99,1271.61,1459.74,1271.72,1463.95z'
          />
          <path
            fill='#D50000'
            d='M2452.41,1125.78c0.03-4.46,3.27-8.22,6.92-8.03c3.36,0.17,7.41,4.77,7.32,8.31c-0.1,3.61-4.33,8.1-7.59,8.04
			C2455.38,1134.03,2452.38,1130.28,2452.41,1125.78z'
          />
          <path
            fill='#D50000'
            d='M1112.29,1505.13c4.01,0.07,7.26,4,7.06,8.54c-0.21,4.78-2.92,7.45-7.43,7.31c-3.83-0.12-7.08-3.89-6.94-8.04
			C1105.11,1508.79,1108.61,1505.06,1112.29,1505.13z'
          />
          <path
            fill='#D50000'
            d='M1227.98,1464.59c-0.16,4.83-2.91,7.56-7.4,7.35c-3.67-0.17-6.82-3.78-6.82-7.8c0-4.34,3.83-8.34,7.72-8.06
			C1225.39,1456.37,1228.14,1459.97,1227.98,1464.59z'
          />
          <path
            fill='#D50000'
            d='M1698.81,1117.42c3.94,0.46,7.22,4.9,6.63,8.99c-0.62,4.35-4.07,7.23-8.16,6.8c-3.98-0.41-6.66-4.11-6.16-8.5
			C1691.65,1120.09,1694.93,1116.97,1698.81,1117.42z'
          />
          <path
            fill='#D50000'
            d='M1756.38,1245.66c0.03-4.42,3.3-8,7.16-7.84c3.92,0.17,6.99,3.81,6.91,8.19c-0.09,4.49-3.17,7.74-7.26,7.66
			C1758.99,1253.58,1756.34,1250.46,1756.38,1245.66z'
          />
          <path
            fill='#D50000'
            d='M1198.33,584.81c3.97,0,7.25,3.38,7.43,7.65c0.18,4.27-3.42,8.24-7.44,8.2c-3.88-0.04-7.49-3.54-7.35-7.71
			C1191.13,588.35,1194.23,584.32,1198.33,584.81z'
          />
          <path
            fill='#D50000'
            d='M1756.36,1075.14c0.05-4.17,3.6-8.08,7.21-7.93c3.68,0.14,6.94,4.08,6.88,8.3c-0.06,4.7-2.91,7.67-7.27,7.57
			C1758.76,1082.98,1756.29,1080.11,1756.36,1075.14z'
          />
          <path
            fill='#D50000'
            d='M1748.48,1245.98c-0.06,4.71-2.87,7.75-7.11,7.68c-4.06-0.07-6.98-3.42-6.96-8c0.02-4.34,3.32-7.92,7.24-7.85
			C1745.56,1237.88,1748.54,1241.44,1748.48,1245.98z'
          />
          <path
            fill='#D50000'
            d='M1148.71,1464.24c-0.03-4.67,2.84-8.07,6.85-8.13c3.73-0.05,7.31,3.78,7.37,7.9c0.06,4.22-3.3,7.93-7.19,7.93
			C1151.28,1471.95,1148.74,1469.17,1148.71,1464.24z'
          />
          <path
            fill='#D50000'
            d='M1119.41,1464.42c-0.07,4.59-3.08,7.63-7.43,7.53c-3.9-0.1-7.22-3.94-7.01-8.12
			c0.21-4.27,3.74-7.89,7.54-7.73C1116.32,1456.25,1119.48,1460.07,1119.41,1464.42z'
          />
          <path
            fill='#D50000'
            d='M2075.59,1612.69c0.02,4.49-2.96,7.6-7.26,7.58c-4.32-0.02-6.88-2.94-6.86-7.82c0.02-4.3,3.36-8.04,7.12-7.97
			C2072.37,1604.54,2075.57,1608.3,2075.59,1612.69z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1050.94c-0.04-4.53,2.99-8.09,6.88-8.1c3.92-0.01,7.16,3.56,7.18,7.92
			c0.02,4.44-3.02,7.87-7.02,7.92C1650.48,1058.73,1647.65,1055.62,1647.61,1050.94z'
          />
          <path
            fill='#D50000'
            d='M876.83,1125.44c0,5.17-2.26,7.76-6.81,7.78c-4.45,0.02-7.44-3.04-7.37-7.55c0.06-4.24,3.45-8.16,7.13-8.23
			C873.4,1117.35,876.83,1121.24,876.83,1125.44z'
          />
          <path
            fill='#D50000'
            d='M669.14,1029.35c0-5.04,2.37-7.68,6.94-7.72c4.54-0.04,7.12,2.72,7.12,7.62c0,4.22-3.38,8.18-7.04,8.22
			C672.51,1037.51,669.14,1033.61,669.14,1029.35z'
          />
          <path
            fill='#D50000'
            d='M2066.24,1010.99c-4.16,0.06-6.8-2.73-6.93-7.31c-0.14-4.89,2.83-8.65,6.9-8.72c3.44-0.06,7.4,4.3,7.37,8.11
			C2073.55,1007.32,2070.19,1010.94,2066.24,1010.99z'
          />
          <path
            fill='#D50000'
            d='M1720.11,1035.57c-3.67,0.09-7.33-3.67-7.53-7.73c-0.18-3.64,3.6-8.26,6.96-8.49
			c3.21-0.23,7.65,4.43,7.83,8.21C1727.55,1031.37,1723.82,1035.49,1720.11,1035.57z'
          />
          <path
            fill='#D50000'
            d='M1639.73,1051.1c-0.13,4.64-3.05,7.68-7.26,7.59c-3.9-0.09-6.87-3.63-6.81-8.11c0.07-4.4,3.33-7.85,7.31-7.74
			C1636.87,1042.96,1639.85,1046.61,1639.73,1051.1z'
          />
          <path
            fill='#D50000'
            d='M2206.28,808.41c-0.03,3.64-4.13,8.27-7.34,8.27c-3.59,0-6.73-3.91-6.72-8.39c0-4.58,3.13-8.32,6.83-8.19
			C2202.45,800.23,2206.31,804.67,2206.28,808.41z'
          />
          <path
            fill='#D50000'
            d='M2262.96,1166.42c3.86,0.11,7.16,3.8,7.14,8c-0.02,4.24-3.4,7.87-7.31,7.84c-4.17-0.02-7.58-3.84-7.36-8.26
			C2255.65,1169.6,2258.92,1166.31,2262.96,1166.42z'
          />
          <path
            fill='#D50000'
            d='M2293.9,1077.21c0.04,4-3.46,7.95-7.16,8.09c-3.87,0.15-7.42-3.42-7.61-7.64c-0.19-4.35,3.31-8.18,7.45-8.14
			C2290.68,1069.56,2293.86,1072.91,2293.9,1077.21z'
          />
          <path
            fill='#D50000'
            d='M1676.34,1237.82c3.93-0.1,7.22,3.43,7.27,7.8c0.06,4.59-2.83,7.97-6.86,8.05c-4.26,0.08-7.1-2.93-7.21-7.63
			C1669.45,1241.6,1672.49,1237.92,1676.34,1237.82z'
          />
          <path
            fill='#D50000'
            d='M1583.71,1222.47c-0.01-4.2,3.4-8.18,6.97-8.11c3.7,0.06,7.06,3.88,7.16,8.13c0.11,4.58-2.84,7.74-7.17,7.67
			C1586.09,1230.09,1583.72,1227.47,1583.71,1222.47z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1515.36c0.07-4.28,3.53-8.01,7.28-7.84c3.8,0.17,6.97,4.03,6.84,8.33c-0.13,4.56-3.08,7.54-7.4,7.48
			C2063.93,1523.27,2061.39,1520.26,2061.47,1515.36z'
          />
          <path
            fill='#D50000'
            d='M2097.54,785.91c-0.01,4.96-2.58,7.81-6.99,7.75c-3.94-0.05-7.19-3.73-7.09-8.05
			c0.09-4.16,3.69-8.15,7.29-8.08C2094.24,777.6,2097.55,781.68,2097.54,785.91z'
          />
          <path
            fill='#D50000'
            d='M2249.2,1077.39c0.06,4.93-2.39,7.83-6.72,7.93c-4.24,0.1-7.27-3.01-7.35-7.54
			c-0.08-4.24,3.17-8.12,6.92-8.26C2245.9,1069.39,2249.15,1072.96,2249.2,1077.39z'
          />
          <path
            fill='#D50000'
            d='M1610.96,1035.14c-3.83-0.06-7.08-3.37-7.16-7.29c-0.08-3.91,4.16-8.73,7.51-8.52
			c3.41,0.21,7.32,5.12,7.13,8.94C1618.24,1032.3,1615.07,1035.21,1610.96,1035.14z'
          />
          <path
            fill='#D50000'
            d='M2170.28,665.22c0.01-4.42,3.27-8.13,7-7.98c3.9,0.15,7.07,3.78,7.04,8.04c-0.03,4.55-3,7.79-7.13,7.77
			C2172.94,673.04,2170.27,670.01,2170.28,665.22z'
          />
          <path
            fill='#D50000'
            d='M2162.38,665.14c0.04,4.82-2.59,7.87-6.84,7.92c-4.22,0.05-7.17-3.07-7.21-7.64
			c-0.04-4.55,3.37-8.3,7.44-8.19C2159.27,657.33,2162.34,661,2162.38,665.14z'
          />
          <path
            fill='#D50000'
            d='M1264.54,536.91c3.92,0.11,7.28,3.83,7.1,7.89c-0.17,3.92-3.96,7.95-7.45,7.93c-3.63-0.02-7.6-4.64-7.39-8.58
			C1257.04,539.97,1260.43,536.8,1264.54,536.91z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1245.79c0.01-4.47,3.16-8.05,7.02-7.97c3.86,0.07,7.09,3.77,7.05,8.07
			c-0.04,4.52-3.05,7.8-7.16,7.78C1650.34,1253.65,1647.6,1250.53,1647.61,1245.79z'
          />
          <path
            fill='#D50000'
            d='M1799.25,785.54c0.05-3.97,3.36-7.84,6.8-7.95c3.9-0.13,7.42,3.97,7.33,8.51c-0.09,4.59-3.15,7.75-7.38,7.61
			C1801.76,793.57,1799.19,790.46,1799.25,785.54z'
          />
          <path
            fill='#D50000'
            d='M2162.38,1051.04c-0.05,4.88-2.75,7.87-7.04,7.83c-4.03-0.04-7.04-3.47-7.01-7.99
			c0.02-4.39,3.3-8.09,7.12-8.03C2159.25,1042.9,2162.42,1046.66,2162.38,1051.04z'
          />
          <path
            fill='#D50000'
            d='M1162.25,1756.94c0.09,4.84-2.44,7.98-6.58,8.14c-4.13,0.16-7.74-3.48-7.75-7.8
			c-0.01-4.21,3.27-7.89,7.14-8.02C1159.21,1749.12,1162.16,1752.28,1162.25,1756.94z'
          />
          <path
            fill='#D50000'
            d='M1118.08,1949.66c0.02,4.24-3.41,8.16-7.1,8.1c-3.79-0.07-7.01-3.81-7.02-8.19
			c-0.02-4.78,2.63-7.61,7.09-7.61C1115.52,1941.96,1118.05,1944.74,1118.08,1949.66z'
          />
          <path
            fill='#D50000'
            d='M1748.48,855.9c0.06,4.93-2.47,7.89-6.82,7.98c-4.31,0.09-7.24-3.03-7.25-7.69c0-4.43,3.13-8.15,6.96-8.27
			C1745.2,847.79,1748.43,851.42,1748.48,855.9z'
          />
          <path
            fill='#D50000'
            d='M1617.77,493.4c-0.05,5.22-2.43,8.03-6.74,7.97c-3.95-0.05-7.24-3.63-7.29-7.92
			c-0.05-4.09,3.46-7.91,7.25-7.91C1615.35,485.56,1617.82,488.42,1617.77,493.4z'
          />
          <path
            fill='#D50000'
            d='M1531.04,493.36c0.03,4.72-2.86,8.08-6.9,8.01c-3.98-0.07-7.26-3.61-7.3-7.88c-0.04-4.09,3.42-7.91,7.19-7.94
			C1528.18,485.52,1531.01,488.66,1531.04,493.36z'
          />
          <path
            fill='#D50000'
            d='M2226.24,834.09c-0.05,4.61-3.06,8.06-6.94,7.94c-3.64-0.11-7.11-4.1-7.11-8.16c0-4.25,3.85-8.44,7.57-8.24
			C2223.42,825.83,2226.29,829.57,2226.24,834.09z'
          />
          <path
            fill='#D50000'
            d='M857.87,1125.84c-0.06,5.15-2.33,7.51-7.09,7.37c-3.8-0.12-7.23-4.03-7.04-8.02c0.19-4,4.03-7.9,7.63-7.76
			C855.02,1117.58,857.92,1121.33,857.87,1125.84z'
          />
          <path
            fill='#D50000'
            d='M692.09,1002.87c-0.03-5.43,2.31-8.05,7.11-7.96c3.6,0.07,6.79,3.57,6.92,7.61c0.14,4.05-3.28,8.06-7.01,8.23
			C695.26,1010.91,692.12,1007.4,692.09,1002.87z'
          />
          <path
            fill='#D50000'
            d='M1639.72,1222.89c-0.17,4.95-2.77,7.5-7.39,7.27c-4.29-0.21-6.92-3.42-6.67-8.12
			c0.23-4.28,3.74-7.92,7.45-7.7C1636.64,1214.55,1639.86,1218.72,1639.72,1222.89z'
          />
          <path
            fill='#D50000'
            d='M2452.41,833.57c0.05-4.18,3.06-7.78,6.61-7.9c3.1-0.1,7.19,5.29,7.22,9.51c0.02,3.09-3.66,7.1-6.78,6.76
			C2455.13,841.46,2451.68,838.81,2452.41,833.57z'
          />
          <path
            fill='#D50000'
            d='M692.09,785.32c0.03-5.07,2.41-7.8,6.79-7.82c3.66-0.02,7.21,3.85,7.25,7.9c0.04,4.19-3.31,7.85-7.26,7.95
			C694.81,793.43,692.06,790.18,692.09,785.32z'
          />
          <path
            fill='#D50000'
            d='M2192.22,833.64c0.05-4.35,3.38-8.2,6.95-8.05c3.46,0.14,7.13,4.4,7.11,8.26c-0.02,4.04-3.58,8.14-7.12,8.19
			C2195.12,842.1,2192.16,838.52,2192.22,833.64z'
          />
          <path
            fill='#D50000'
            d='M807.4,1182.26c-3.95,0.06-7.48-3.59-7.42-7.68c0.06-4.18,3.39-7.96,7.18-8.15c3.77-0.19,7.68,3.97,7.62,8.12
			C814.72,1178.87,811.53,1182.2,807.4,1182.26z'
          />
          <path
            fill='#D50000'
            d='M1763.37,1117.41c3.72,0,7.04,3.78,7.07,8.05c0.04,4.64-2.81,7.77-7.08,7.76c-4.43-0.01-7-2.85-7.01-7.74
			C1756.35,1121.23,1759.67,1117.41,1763.37,1117.41z'
          />
          <path
            fill='#D50000'
            d='M1148.73,1610.37c-0.05-4.62,2.86-8.19,6.76-8.28c3.73-0.08,7.36,3.74,7.45,7.85c0.1,4.16-3.24,7.9-7.09,7.97
			C1151.28,1617.99,1148.78,1615.34,1148.73,1610.37z'
          />
          <path
            fill='#D50000'
            d='M2105.42,565.91c0.04-4.46,3.22-7.93,7.15-7.8c3.86,0.12,7,3.83,6.92,8.17c-0.08,4.17-3.51,7.75-7.35,7.67
			C2108.07,573.88,2105.38,570.65,2105.42,565.91z'
          />
          <path
            fill='#D50000'
            d='M2162.38,566.19c-0.03,4.63-2.9,7.79-7.04,7.76c-3.98-0.03-7.01-3.45-7.02-7.93
			c-0.01-4.36,3.21-7.93,7.15-7.91C2159.33,558.13,2162.41,561.73,2162.38,566.19z'
          />
          <path
            fill='#D50000'
            d='M1720.11,890.74c-3.66,0.08-7.34-3.73-7.52-7.77c-0.17-3.72,3.52-8.19,6.98-8.46
			c3.26-0.25,7.58,4.3,7.77,8.19C1727.53,886.5,1723.79,890.66,1720.11,890.74z'
          />
          <path
            fill='#D50000'
            d='M2162.38,979.08c0.07,4.41-3.07,8.11-6.89,8.14c-3.86,0.02-7.12-3.6-7.16-7.96
			c-0.03-4.57,2.87-7.86,6.97-7.89C2159.6,971.33,2162.3,974.28,2162.38,979.08z'
          />
          <path
            fill='#D50000'
            d='M692.09,808.77c0.04-5.21,2.22-7.69,6.83-7.75c3.85-0.05,7.2,3.58,7.22,7.82c0.01,4.09-3.51,7.99-7.24,8.02
			C694.91,816.89,692.06,813.51,692.09,808.77z'
          />
          <path
            fill='#D50000'
            d='M2335.07,640.76c-0.04,4.71-2.94,7.84-7.15,7.74c-3.81-0.1-7.22-4.02-7.06-8.11
			c0.16-4.19,3.65-7.76,7.51-7.68C2332.6,632.79,2335.11,635.81,2335.07,640.76z'
          />
          <path
            fill='#D50000'
            d='M1111.55,1617.92c-3.69-0.27-6.94-4.44-6.58-8.42c0.38-4.21,4.04-7.72,7.7-7.42c4.05,0.34,6.95,4.26,6.58,8.9
			C1118.87,1615.8,1116.14,1618.26,1111.55,1617.92z'
          />
          <path
            fill='#D50000'
            d='M1271.71,1416.28c-0.04,4.27-3.44,7.85-7.37,7.75c-4.32-0.12-7.13-3.27-7.03-7.91
			c0.09-4.41,3.39-7.99,7.27-7.91C1268.35,1408.28,1271.74,1412.13,1271.71,1416.28z'
          />
          <path
            fill='#D50000'
            d='M1814.38,979.87c-0.04,4.79-2.73,7.72-7.06,7.71c-4.18-0.01-7.03-3.16-7.07-7.83
			c-0.04-4.38,3.66-8.47,7.47-8.25C1811.43,971.72,1814.42,975.48,1814.38,979.87z'
          />
          <path
            fill='#D50000'
            d='M1148.71,1366.34c-0.07-4.7,2.73-8.16,6.71-8.29c3.75-0.12,7.36,3.62,7.52,7.78c0.16,4.2-3.17,8-7.05,8.07
			C1151.34,1373.97,1148.78,1371.28,1148.71,1366.34z'
          />
          <path
            fill='#D50000'
            d='M749.05,808.83c0,4.74-2.87,8.09-6.86,8.03c-3.69-0.06-7.21-4.01-7.19-8.07c0.03-4.21,3.42-7.82,7.29-7.76
			C746.85,801.09,749.05,803.62,749.05,808.83z'
          />
          <path
            fill='#D50000'
            d='M2097.56,952.07c0.07,5.16-2.74,8.6-6.96,8.54c-3.77-0.06-7.1-3.8-7.15-8.06c-0.05-4.19,3.3-7.77,7.27-7.77
			C2095.08,944.77,2097.5,947.36,2097.56,952.07z'
          />
          <path
            fill='#D50000'
            d='M2517.28,1223.74c0.03-4.54,3.05-7.93,6.97-7.84c3.49,0.07,7.2,4.31,7.19,8.2c-0.01,3.78-3.85,8.3-7.28,8.12
			C2520.76,1232.05,2516.33,1228.8,2517.28,1223.74z'
          />
          <path
            fill='#D50000'
            d='M2177.28,558.1c3.85,0.04,7.06,3.71,7.04,8.02c-0.03,4.19-3.37,7.85-7.15,7.83
			c-4.12-0.03-6.88-3.19-6.89-7.89C2170.27,561.66,2173.44,558.06,2177.28,558.1z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1366.13c0.05,5.08-2.31,7.75-6.87,7.77c-3.75,0.01-7.06-3.35-7.22-7.34
			c-0.18-4.41,3.29-8.48,7.24-8.5C1203.08,1358.03,1205.85,1361.3,1205.9,1366.13z'
          />
          <path
            fill='#D50000'
            d='M1112.29,1553.04c3.79,0.02,7.14,3.81,7.17,8.1c0.02,4.59-2.91,7.74-7.23,7.76c-3.93,0.01-7.32-3.67-7.26-7.9
			C1105.02,1556.83,1108.53,1553.02,1112.29,1553.04z'
          />
          <path
            fill='#D50000'
            d='M1148.71,1416.14c0.05-4.59,3.07-7.97,7.06-7.92c3.73,0.05,7.21,3.97,7.16,8.09
			c-0.05,4.23-3.48,7.82-7.38,7.72C1151.15,1423.92,1148.66,1421.05,1148.71,1416.14z'
          />
          <path
            fill='#D50000'
            d='M1148.72,1561.05c0.02-4.64,2.98-8.04,6.98-8.02c3.71,0.02,7.25,3.96,7.24,8.05
			c-0.02,4.21-3.44,7.86-7.32,7.81C1151.21,1568.83,1148.7,1565.98,1148.72,1561.05z'
          />
          <path
            fill='#D50000'
            d='M2199.42,558.11c3.88,0.08,6.96,3.74,6.87,8.15c-0.09,4.41-3.25,7.76-7.25,7.69
			c-4.05-0.07-6.86-3.38-6.82-8.03C2192.27,561.52,2195.48,558.03,2199.42,558.11z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1561.4c-0.03,4.96-2.59,7.64-7.15,7.5c-3.65-0.12-6.94-3.73-6.95-7.63
			c-0.01-4.46,3.59-8.39,7.54-8.23C1203.31,1553.19,1205.93,1556.53,1205.9,1561.4z'
          />
          <path
            fill='#D50000'
            d='M1227.95,1561.72c-0.24,4.84-3.02,7.48-7.53,7.18c-3.59-0.25-6.74-4.02-6.65-7.97
			c0.1-4.15,3.67-7.95,7.4-7.88C1225.3,1553.12,1228.19,1556.81,1227.95,1561.72z'
          />
          <path
            fill='#D50000'
            d='M2279.01,736.39c0.01-4.66,2.84-7.88,6.98-7.92c3.79-0.04,7.13,3.64,7.15,7.9c0.02,4.36-3.18,7.93-7.12,7.94
			C2281.87,744.32,2279,741.08,2279.01,736.39z'
          />
          <path
            fill='#D50000'
            d='M2162.38,590.24c0,4.38-3.23,8.1-7.01,8.07c-3.84-0.03-6.98-3.65-7.04-8.11c-0.06-4.49,2.93-7.73,7.11-7.7
			C2159.84,582.53,2162.37,585.36,2162.38,590.24z'
          />
          <path
            fill='#D50000'
            d='M1228,1366.61c-0.2,4.86-2.92,7.52-7.46,7.28c-3.65-0.19-6.81-3.84-6.78-7.85c0.03-4.37,3.83-8.28,7.79-7.99
			C1225.45,1358.33,1228.18,1361.95,1228,1366.61z'
          />
          <path
            fill='#D50000'
            d='M2495.31,1223.92c0.02-4.62,2.91-7.97,6.95-8.04c3.67-0.07,7.17,3.8,7.24,7.99c0.07,4.3-3.68,8.51-7.47,8.37
			C2498.23,1232.11,2495.29,1228.46,2495.31,1223.92z'
          />
          <path
            fill='#D50000'
            d='M1096.45,1806.53c-0.11,4.75-2.93,7.68-7.31,7.6c-3.78-0.07-7.27-3.99-7.12-8c0.17-4.3,3.59-7.87,7.49-7.81
			C1093.39,1798.37,1096.55,1802.11,1096.45,1806.53z'
          />
          <path
            fill='#D50000'
            d='M1836.34,1368.47c-0.03,4.71-2.83,7.72-7.13,7.68c-4.3-0.04-7.07-3.12-7.06-7.85
			c0.01-4.21,3.45-7.99,7.23-7.97C1833.06,1360.35,1836.37,1364.22,1836.34,1368.47z'
          />
          <path
            fill='#D50000'
            d='M2184.33,1661.79c-0.1,4.55-3.09,7.64-7.27,7.53c-3.99-0.1-6.84-3.5-6.78-8.06c0.06-4.26,3.44-7.85,7.32-7.78
			C2181.3,1653.53,2184.43,1657.4,2184.33,1661.79z'
          />
          <path
            fill='#D50000'
            d='M2039.52,1368.17c0.05-4.25,3.48-7.99,7.21-7.85c3.75,0.14,7.02,4.05,6.93,8.29
			c-0.1,4.63-3.06,7.65-7.39,7.53C2041.91,1376.02,2039.46,1373.12,2039.52,1368.17z'
          />
          <path
            fill='#D50000'
            d='M2076.48,640.75c-0.02,4.19-3.37,7.73-7.34,7.75c-4.18,0.02-7.33-3.18-7.44-7.57
			c-0.1-4.17,3.7-8.34,7.53-8.25C2072.99,632.77,2076.5,636.68,2076.48,640.75z'
          />
          <path
            fill='#D50000'
            d='M1800.24,639.41c0.02-4.6,3.09-8,7.11-7.87c3.72,0.12,7.1,4.04,7.02,8.14c-0.08,4.26-3.48,7.8-7.37,7.69
			C1802.45,647.24,1800.23,644.63,1800.24,639.41z'
          />
          <path
            fill='#D50000'
            d='M1720.12,987.68c-3.68,0.08-7.33-3.69-7.53-7.77c-0.18-3.68,3.54-8.2,6.99-8.48c3.2-0.27,7.62,4.38,7.79,8.21
			C1727.53,983.45,1723.8,987.59,1720.12,987.68z'
          />
          <path
            fill='#D50000'
            d='M866.63,932.14c0.01-5.17,2.24-7.53,7.06-7.46c4.14,0.06,7.02,2.99,7.13,7.26c0.11,4.34-3.54,8.58-7.37,8.53
			C869.96,940.43,866.63,936.36,866.63,932.14z'
          />
          <path
            fill='#D50000'
            d='M2097.53,759.68c-0.04,4.18-3.52,8.22-7.01,8.15c-3.65-0.08-7.07-4.09-7.06-8.28
			c0.01-4.61,2.86-7.56,7.31-7.56C2095.51,751.98,2097.58,754.34,2097.53,759.68z'
          />
          <path
            fill='#D50000'
            d='M2126.46,759.82c-0.11-4.22,3.15-7.77,7.22-7.83c4.18-0.07,7.66,3.35,7.58,7.45
			c-0.07,3.62-4.11,8.27-7.29,8.39C2130.55,767.96,2126.57,763.71,2126.46,759.82z'
          />
          <path
            fill='#D50000'
            d='M2097.43,1368.49c-0.12,4.3-3.5,7.75-7.51,7.65c-4.21-0.1-7.19-3.48-7.03-7.97c0.15-4.28,3.65-7.99,7.4-7.85
			C2094.15,1360.47,2097.54,1364.35,2097.43,1368.49z'
          />
          <path
            fill='#D50000'
            d='M2206.28,930.99c-0.01,4.01-3.56,8.01-7.21,8.12c-3.67,0.11-6.83-3.61-6.87-8.08
			c-0.03-4.41,2.97-8.29,6.54-8.45C2202.23,922.41,2206.29,926.96,2206.28,930.99z'
          />
          <path
            fill='#D50000'
            d='M2111.89,1376.14c-4.15-0.08-7.23-3.52-7.09-7.94c0.13-4.24,3.67-8.01,7.41-7.88
			c3.86,0.13,7.24,3.99,7.17,8.17C2119.3,1372.76,2115.92,1376.21,2111.89,1376.14z'
          />
          <path
            fill='#D50000'
            d='M2140.33,1368.53c-0.14,4.33-3.46,7.69-7.53,7.61c-4.16-0.08-7.17-3.5-7.01-7.97
			c0.15-4.29,3.67-8.01,7.43-7.85C2137.07,1360.48,2140.47,1364.4,2140.33,1368.53z'
          />
          <path
            fill='#D50000'
            d='M1865.96,1368.49c-0.02-4.15,3.37-8.08,7.01-8.14c3.74-0.06,7.19,3.71,7.27,7.94
			c0.08,4.45-3.01,7.85-7.12,7.85C1868.73,1376.14,1865.99,1373.21,1865.96,1368.49z'
          />
          <path
            fill='#D50000'
            d='M764.89,801.02c3.85-0.01,7.17,3.69,7.11,7.92c-0.05,4.15-3.54,7.92-7.31,7.92c-4,0-6.81-3.4-6.76-8.16
			C757.99,803.53,760.26,801.03,764.89,801.02z'
          />
          <path
            fill='#D50000'
            d='M2214.17,1563.49c0.01-4.88,2.7-8.1,6.75-8.09c3.86,0.01,7.29,3.72,7.31,7.9c0.02,4.1-3.43,7.92-7.15,7.91
			C2216.6,1571.2,2214.15,1568.47,2214.17,1563.49z'
          />
          <path
            fill='#D50000'
            d='M1154.98,801c3.82,0.09,7.16,3.98,6.98,8.12c-0.19,4.11-3.89,7.89-7.58,7.73c-3.92-0.16-6.73-3.72-6.63-8.4
			C1147.85,803.43,1150.31,800.9,1154.98,801z'
          />
          <path
            fill='#D50000'
            d='M1169.68,808.76c0-4.94,2.54-7.8,6.97-7.83c3.79-0.03,7.31,3.77,7.32,7.9c0.01,4.02-3.6,7.98-7.33,8.03
			C1172.71,816.9,1169.68,813.38,1169.68,808.76z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1390.53c0.04-4.46,3.26-7.99,7.15-7.85c3.89,0.14,7.03,3.86,6.93,8.21c-0.1,4.5-3.17,7.72-7.31,7.65
			C1129.6,1398.47,1126.89,1395.26,1126.93,1390.53z'
          />
          <path
            fill='#D50000'
            d='M793.7,640.19c0.09,3.87-3.66,8.22-7.15,8.3c-3.65,0.08-7.26-3.71-7.52-7.91c-0.24-3.99,3.26-7.85,7.16-7.9
			C790.41,632.65,793.6,635.83,793.7,640.19z'
          />
          <path
            fill='#D50000'
            d='M2105.42,809.15c-0.07-4.78,2.62-7.99,6.78-8.13c3.87-0.12,7.21,3.44,7.29,7.78c0.07,4.2-3.21,7.99-6.99,8.06
			C2108.3,816.94,2105.48,813.86,2105.42,809.15z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1222.38c0.06,4.16-3.36,7.81-7.31,7.81c-4.29,0-7.33-3.19-7.32-7.68c0.01-3.98,3.74-8.17,7.27-8.16
			C2115.84,1214.37,2119.33,1218.18,2119.39,1222.38z'
          />
          <path
            fill='#D50000'
            d='M1763.32,1140.87c3.9,0.04,7.11,3.64,7.12,7.98c0.01,4.52-2.98,7.86-7.04,7.87
			c-4.42,0.01-7.19-3.25-7.03-8.29C1756.5,1144.27,1759.67,1140.83,1763.32,1140.87z'
          />
          <path
            fill='#D50000'
            d='M2105.42,952.68c0.02-4.04,3.17-7.79,6.64-7.89c4.05-0.13,7.52,3.74,7.43,8.28
			c-0.09,4.53-3.08,7.61-7.34,7.55C2107.74,960.54,2105.4,957.78,2105.42,952.68z'
          />
          <path
            fill='#D50000'
            d='M2089.92,1230.21c-4.24-0.06-7.22-3.37-7.09-7.88c0.11-4.09,3.88-8.12,7.44-7.96
			c3.84,0.17,7.27,4.1,7.18,8.21C2097.34,1226.79,2093.91,1230.27,2089.92,1230.21z'
          />
          <path
            fill='#D50000'
            d='M662.6,955.33c0.07,4.25-3.16,8.26-6.81,8.46c-3.41,0.18-7.4-4.01-7.54-7.91c-0.14-3.99,3.32-8,7.07-8.17
			C659.45,947.51,662.52,950.72,662.6,955.33z'
          />
          <path
            fill='#D50000'
            d='M2162.38,952.95c-0.05,4.95-2.57,7.7-7.03,7.66c-4.13-0.03-7.06-3.3-7.02-7.82c0.04-4.37,3.23-7.94,7.14-7.99
			C2159.16,944.74,2162.42,948.59,2162.38,952.95z'
          />
          <path
            fill='#D50000'
            d='M779.01,979.83c0.14-4.05,3.67-7.36,7.76-7.26c4,0.1,7.23,3.78,6.95,7.94c-0.25,3.71-4.49,8.04-7.7,7.85
			C782.59,988.15,778.88,983.64,779.01,979.83z'
          />
          <path
            fill='#D50000'
            d='M1148.7,1659.09c0.03-4.62,3.02-7.98,7.05-7.94c3.77,0.03,7.24,3.93,7.19,8.07c-0.05,4.19-3.5,7.83-7.37,7.78
			C1151.24,1666.93,1148.67,1663.97,1148.7,1659.09z'
          />
          <path
            fill='#D50000'
            d='M814.93,640.01c0.06,4.96-2.92,8.6-6.93,8.46c-3.59-0.12-7-3.77-7.15-7.65c-0.17-4.21,3.44-8.15,7.44-8.13
			C812.75,632.72,814.87,635.06,814.93,640.01z'
          />
          <path
            fill='#D50000'
            d='M836.9,640.34c0,4.59-2.96,8.16-6.75,8.15c-3.8-0.02-7.33-3.84-7.36-7.98c-0.03-3.98,3.52-7.78,7.29-7.81
			C834.77,632.65,836.91,635.04,836.9,640.34z'
          />
          <path
            fill='#D50000'
            d='M1184.51,1659.34c-0.16,4.75-3.41,7.91-7.83,7.64c-3.67-0.23-6.76-3.79-6.76-7.79c0-4.44,3.64-8.25,7.66-8.03
			C1181.32,1651.36,1184.65,1655.29,1184.51,1659.34z'
          />
          <path
            fill='#D50000'
            d='M814.93,808.69c0.05,4.74-2.75,8.14-6.73,8.16c-3.75,0.02-7.3-3.82-7.35-7.94c-0.05-4.2,3.32-7.9,7.18-7.89
			C812.65,801.03,814.88,803.51,814.93,808.69z'
          />
          <path
            fill='#D50000'
            d='M1271.71,1659.24c-0.07,4.25-3.5,7.85-7.38,7.74c-4.25-0.12-7.1-3.35-7.01-7.94c0.08-4.41,3.35-7.96,7.27-7.9
			C1268.39,1651.2,1271.77,1655.05,1271.71,1659.24z'
          />
          <path
            fill='#D50000'
            d='M2140.35,1222.19c0.16,4.13-3.22,7.92-7.12,8c-4.44,0.08-7.28-2.79-7.4-7.5c-0.11-4,3.44-8.24,6.98-8.34
			C2136.57,1214.24,2140.18,1218.01,2140.35,1222.19z'
          />
          <path
            fill='#D50000'
            d='M1756.37,1050.93c-0.05-4.44,3.07-8.1,6.9-8.09c3.92,0.01,7.14,3.56,7.17,7.92c0.03,4.45-3.01,7.9-6.99,7.92
			C1759.3,1058.71,1756.42,1055.56,1756.37,1050.93z'
          />
          <path
            fill='#D50000'
            d='M2220.9,751.99c3.98,0.03,7.32,3.56,7.27,7.7c-0.04,3.81-3.94,8.16-7.3,8.14c-3.49-0.01-7-4.16-7.02-8.28
			C2213.84,754.61,2216.31,751.95,2220.9,751.99z'
          />
          <path
            fill='#D50000'
            d='M873.8,972.57c4.21,0.07,7.22,3.44,7.02,7.85c-0.19,4.13-3.86,8.03-7.45,7.9c-3.65-0.13-6.76-3.99-6.73-8.37
			C866.67,974.91,869.02,972.49,873.8,972.57z'
          />
          <path
            fill='#D50000'
            d='M1683.62,1222.69c-0.08,4.72-2.85,7.52-7.35,7.47c-4.38-0.05-6.82-2.94-6.72-7.97
			c0.08-4.11,3.74-8.08,7.25-7.87C1680.49,1214.56,1683.69,1218.49,1683.62,1222.69z'
          />
          <path
            fill='#D50000'
            d='M1770.44,979.77c0.03,4.68-2.74,7.75-7.03,7.79c-4.33,0.04-7-2.86-7.05-7.65c-0.05-4.5,3.43-8.57,7.19-8.41
			C1767.28,971.64,1770.41,975.41,1770.44,979.77z'
          />
          <path
            fill='#D50000'
            d='M2003.11,1360.34c3.73-0.04,7.25,3.76,7.3,7.91c0.06,4.47-2.94,7.81-7.09,7.89
			c-4.11,0.08-7.41-3.14-7.62-7.44C1995.5,1364.43,1999.08,1360.38,2003.11,1360.34z'
          />
          <path
            fill='#D50000'
            d='M692.09,1050.48c0.06-5.16,2.3-7.64,6.93-7.63c3.84,0,7.18,3.72,7.11,7.9c-0.07,4.11-3.62,7.97-7.32,7.93
			C694.96,1058.64,692.04,1055.08,692.09,1050.48z'
          />
          <path
            fill='#D50000'
            d='M2075.59,737.48c-0.08,4.31-3.62,8.03-7.39,7.78c-3.92-0.26-6.84-3.89-6.74-8.39
			c0.11-4.87,2.78-7.59,7.29-7.42C2073.13,729.62,2075.69,732.63,2075.59,737.48z'
          />
          <path
            fill='#D50000'
            d='M2126.47,1027.98c-0.08-3.52,3.85-8.22,7.07-8.46c3.28-0.24,7.75,4.63,7.72,8.39
			c-0.03,3.67-4.1,8.13-7.43,8.15C2130.28,1036.08,2126.56,1031.99,2126.47,1027.98z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1416.49c-0.02,4.94-2.57,7.64-7.1,7.54c-3.65-0.09-6.95-3.66-6.99-7.56
			c-0.05-4.47,3.5-8.38,7.48-8.25C1203.31,1408.34,1205.91,1411.61,1205.89,1416.49z'
          />
          <path
            fill='#D50000'
            d='M1336.62,1416.09c0.02,5.17-2.21,7.83-6.66,7.93c-3.92,0.09-7.39-3.47-7.47-7.65
			c-0.08-4.27,3.7-8.29,7.65-8.15C1333.91,1408.35,1336.61,1411.63,1336.62,1416.09z'
          />
          <path
            fill='#D50000'
            d='M1647.61,979.57c0.04-4.35,3.39-8.18,7.06-8.06c3.69,0.12,7.01,4.06,7.01,8.33c0,4.71-2.88,7.76-7.25,7.67
			C1650,987.42,1647.56,984.58,1647.61,979.57z'
          />
          <path
            fill='#D50000'
            d='M2053.66,1417.29c0.09,4.6-2.74,7.82-6.97,7.94c-4.49,0.13-7.1-2.58-7.17-7.48c-0.06-4.3,3.17-8.29,6.76-8.34
			C2050.11,1409.35,2053.58,1413.06,2053.66,1417.29z'
          />
          <path
            fill='#D50000'
            d='M1844.06,1417.48c0.02-4.21,3.5-8.16,7.1-8.05c3.85,0.12,7.11,3.83,7.15,8.11c0.04,4.5-2.96,7.65-7.27,7.66
			C1846.58,1425.21,1844.04,1422.4,1844.06,1417.48z'
          />
          <path
            fill='#D50000'
            d='M2010.5,1417.57c0.07,4.19-3.24,7.63-7.36,7.65c-3.98,0.03-7.36-3.43-7.42-7.59c-0.06-4.04,3.37-8,7.11-8.2
			C2006.62,1409.22,2010.43,1413.26,2010.5,1417.57z'
          />
          <path
            fill='#D50000'
            d='M1719.9,1230.18c-3.93-0.01-7.31-3.53-7.35-7.67c-0.04-3.97,3.49-8,7.17-8.15c3.65-0.15,7.63,4.16,7.62,8.27
			C1727.33,1226.84,1724.03,1230.19,1719.9,1230.18z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1417.64c-0.03,4.81-2.73,7.66-7.16,7.58c-4.3-0.08-7.11-3.19-7.04-7.8c0.07-4.3,3.39-7.97,7.23-8
			C2028.42,1409.39,2031.78,1413.36,2031.75,1417.64z'
          />
          <path
            fill='#D50000'
            d='M1228,1610.41c-0.08,4.92-2.67,7.62-7.22,7.51c-3.83-0.09-7.18-3.94-7.02-8.06c0.17-4.18,3.76-7.93,7.47-7.79
			C1225.16,1602.23,1228.07,1605.82,1228,1610.41z'
          />
          <path
            fill='#D50000'
            d='M1336.63,1537.69c0.03,5.16-2.18,7.76-6.71,7.87c-3.78,0.09-7.34-3.58-7.44-7.69
			c-0.1-4.09,3.37-8.03,7.16-8.12C1333.6,1529.66,1336.6,1533.06,1336.63,1537.69z'
          />
          <path
            fill='#D50000'
            d='M2097.56,1000.21c-0.03,4.33-3.37,8.17-7.03,8.07c-3.8-0.1-7.07-3.9-7.08-8.22c-0.01-4.18,3.46-7.94,7.29-7.9
			C2094.89,992.2,2097.6,995.4,2097.56,1000.21z'
          />
          <path
            fill='#D50000'
            d='M1618.56,1222.53c0.08,4.16-3.25,7.61-7.38,7.63c-4.05,0.03-7.34-3.33-7.41-7.55
			c-0.07-4.01,3.39-8.07,7.04-8.27C1614.51,1214.15,1618.48,1218.34,1618.56,1222.53z'
          />
          <path
            fill='#D50000'
            d='M1639.73,468.15c-0.06,4.61-3.03,7.82-7.16,7.74c-3.65-0.08-6.94-3.97-6.91-8.19
			c0.02-4.17,3.47-7.73,7.38-7.64C1637.19,460.15,1639.8,463.31,1639.73,468.15z'
          />
          <path
            fill='#D50000'
            d='M1089.2,1765.07c-3.84-0.07-7.23-3.77-7.21-7.86c0.02-4.19,3.4-7.91,7.22-7.94c4.26-0.04,7.4,3.53,7.24,8.22
			C1096.29,1762.22,1093.49,1765.15,1089.2,1765.07z'
          />
          <path
            fill='#D50000'
            d='M1037.93,1708.1c0.05-4.58,3.2-8.02,7.2-7.86c3.89,0.16,7.28,4.03,7.15,8.16c-0.13,4.16-3.6,7.69-7.51,7.63
			C1040.4,1715.97,1037.87,1713.03,1037.93,1708.1z'
          />
          <path
            fill='#D50000'
            d='M1220.65,536.91c3.77,0.1,6.99,3.48,7.05,7.4c0.06,4.06-3.84,8.46-7.47,8.42c-3.63-0.04-7.58-4.68-7.32-8.61
			C1213.18,539.9,1216.51,536.8,1220.65,536.91z'
          />
          <path
            fill='#D50000'
            d='M2270.09,1077.51c-0.04,4.23-3.43,7.84-7.32,7.81c-4.16-0.04-7.54-3.84-7.35-8.26
			c0.19-4.37,3.49-7.67,7.54-7.53C2266.91,1069.66,2270.14,1073.28,2270.09,1077.51z'
          />
          <path
            fill='#D50000'
            d='M2133.83,793.65c-3.68,0.09-7.25-3.67-7.37-7.77c-0.11-3.68,3.67-8.11,7.11-8.33
			c3.32-0.22,7.58,4.42,7.68,8.36C2141.33,789.78,2137.73,793.54,2133.83,793.65z'
          />
          <path
            fill='#D50000'
            d='M1770.44,856.3c-0.07,4.79-2.87,7.73-7.24,7.6c-4.18-0.12-6.94-3.4-6.85-8.14c0.09-4.31,3.56-8.01,7.36-7.83
			C1767.58,848.11,1770.51,851.75,1770.44,856.3z'
          />
          <path
            fill='#D50000'
            d='M2299.91,1077.18c0.01-4.87,2.99-8.07,7.42-7.95c3.88,0.11,6.72,3.26,6.78,7.54
			c0.07,4.56-3.25,8.52-7.14,8.51C2303.31,1085.27,2299.9,1081.35,2299.91,1077.18z'
          />
          <path
            fill='#D50000'
            d='M720.38,849.65c3.9-0.07,7.27,3.4,7.46,7.71c0.16,3.57-3.84,8.31-7.11,8.41c-3.13,0.1-7.4-4.43-7.59-8.07
			C712.94,853.88,716.68,849.72,720.38,849.65z'
          />
          <path
            fill='#D50000'
            d='M749.05,857.5c0,4.41-3.16,8.28-6.75,8.28c-3.48,0-7.23-4.2-7.3-8.16c-0.07-4.14,3.31-7.84,7.23-7.93
			C746.61,849.59,749.05,852.39,749.05,857.5z'
          />
          <path
            fill='#D50000'
            d='M1248.78,592.44c0.07,4.92-2.43,8.01-6.59,8.18c-3.8,0.15-7.34-3.45-7.48-7.61
			c-0.13-4.14,3.19-8.08,6.92-8.19C1245.92,584.69,1248.72,587.66,1248.78,592.44z'
          />
          <path
            fill='#D50000'
            d='M1271.72,1513.26c-0.07,4.22-3.34,7.72-7.25,7.73c-4.34,0.02-7.13-2.96-7.17-7.63
			c-0.03-4.48,3.49-8.38,7.42-8.22C1268.44,1505.29,1271.78,1509.17,1271.72,1513.26z'
          />
          <path
            fill='#D50000'
            d='M2509.5,1175.16c-0.12,4.11-3.78,8.03-7.37,7.9c-4.11-0.15-6.96-3.77-6.81-8.65
			c0.13-4.18,3.23-7.67,6.83-7.69C2506.08,1166.71,2509.63,1170.78,2509.5,1175.16z'
          />
          <path
            fill='#D50000'
            d='M2184.33,1612.47c0.01,4.87-2.53,7.74-6.9,7.8c-4.2,0.06-7.1-3.05-7.15-7.65c-0.04-4.37,3.13-8.08,6.97-8.15
			C2181.03,1604.41,2184.32,1608.12,2184.33,1612.47z'
          />
          <path
            fill='#D50000'
            d='M1227.99,1513.54c-0.13,4.94-2.74,7.61-7.29,7.46c-3.78-0.12-7.13-4.07-6.92-8.18
			c0.21-4.18,3.85-7.89,7.55-7.7C1225.25,1505.33,1228.1,1508.95,1227.99,1513.54z'
          />
          <path
            fill='#D50000'
            d='M2270.09,1126.54c-0.06,4.21-3.4,7.82-7.27,7.85c-4.16,0.04-7.63-3.79-7.4-8.17
			c0.23-4.41,3.43-7.67,7.51-7.65C2266.81,1118.6,2270.15,1122.32,2270.09,1126.54z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1464.14c0.05,5.05-2.35,7.79-6.84,7.81c-3.74,0.01-7.08-3.36-7.25-7.32
			c-0.19-4.38,3.31-8.52,7.21-8.54C1203.04,1456.06,1205.85,1459.36,1205.9,1464.14z'
          />
          <path
            fill='#D50000'
            d='M2509.51,1126.16c-0.04,4.19-3.09,7.83-6.65,7.94c-3.81,0.12-7.67-4.15-7.55-8.33
			c0.12-4.03,3.85-7.99,7.43-7.89C2506.66,1117.97,2509.55,1121.51,2509.51,1126.16z'
          />
          <path
            fill='#D50000'
            d='M786.2,849.67c3.91-0.09,7.33,3.37,7.54,7.63c0.18,3.59-3.71,8.26-7.04,8.46c-3.13,0.19-7.42-4.28-7.67-7.99
			C778.77,853.91,782.44,849.76,786.2,849.67z'
          />
          <path
            fill='#D50000'
            d='M2286.39,1134.39c-3.87-0.01-7.24-3.62-7.25-7.77c-0.01-3.98,3.62-7.98,7.29-8.02
			c3.75-0.04,7.7,4.32,7.55,8.35C2293.84,1131.11,2290.48,1134.4,2286.39,1134.39z'
          />
          <path
            fill='#D50000'
            d='M2126.44,1099.43c0.01-4.06,3.56-7.88,7.28-7.85c3.94,0.03,7.53,3.66,7.53,7.62c0,3.75-4.29,8.53-7.61,8.47
			C2130.32,1107.6,2126.43,1103.16,2126.44,1099.43z'
          />
          <path
            fill='#D50000'
            d='M749.04,785.53c-0.03,4.68-2.53,7.7-6.45,7.79c-4.16,0.09-7.73-3.74-7.57-8.12c0.15-4.09,3.71-7.76,7.48-7.7
			C746.73,777.55,749.07,780.42,749.04,785.53z'
          />
          <path
            fill='#D50000'
            d='M2105.42,590.37c-0.02-5.01,2.37-7.78,6.78-7.87c4.21-0.08,7.29,3.13,7.27,7.58
			c-0.02,4.45-3.16,8.16-6.96,8.22C2108.68,598.36,2105.43,594.74,2105.42,590.37z'
          />
          <path
            fill='#D50000'
            d='M2097.52,688.64c-0.03,4.53-2.71,7.7-6.57,7.75c-3.99,0.06-7.36-3.41-7.48-7.68
			c-0.12-4.31,3.44-8.2,7.44-8.12C2095.2,680.67,2097.56,683.54,2097.52,688.64z'
          />
          <path
            fill='#D50000'
            d='M1647.61,1125.35c0.04-4.29,3.39-8.04,7.1-7.94c3.65,0.09,7,4.04,6.96,8.21c-0.04,4.61-2.97,7.67-7.25,7.59
			C1650.01,1133.13,1647.56,1130.31,1647.61,1125.35z'
          />
          <path
            fill='#D50000'
            d='M2192.22,590.07c0.09-4.8,2.76-7.62,7.18-7.57c4.11,0.05,6.94,3.3,6.89,7.91c-0.05,4.35-3.4,8.02-7.21,7.9
			C2195.27,598.19,2192.14,594.43,2192.22,590.07z'
          />
          <path
            fill='#D50000'
            d='M1697.98,987.67c-3.75-0.05-7.38-4.08-7.22-8.02c0.14-3.56,4.17-8.12,7.27-8.23c3.5-0.12,7.65,4.63,7.45,8.52
			C1705.26,984.19,1701.83,987.72,1697.98,987.67z'
          />
          <path
            fill='#D50000'
            d='M749.05,710.59c0.06,4.8-2.67,8.19-6.65,8.23c-3.7,0.04-7.28-3.77-7.39-7.88c-0.12-4.14,3.24-7.91,7.09-7.95
			C746.7,702.94,748.98,705.43,749.05,710.59z'
          />
          <path
            fill='#D50000'
            d='M661.9,1078.12c0.17,4.14-3.52,8.34-7.36,8.38c-3.74,0.04-7.1-3.58-7.28-7.83c-0.17-4.19,3.12-7.93,7.04-7.98
			C658.56,1070.63,661.72,1073.72,661.9,1078.12z'
          />
          <path
            fill='#D50000'
            d='M895.14,924.68c4.11,0,7.41,3.32,7.41,7.46c0,3.7-4.38,8.43-7.71,8.33c-3.36-0.1-7.11-4.46-7.15-8.31
			C887.65,928.12,891.08,924.68,895.14,924.68z'
          />
          <path
            fill='#D50000'
            d='M2242.25,648.51c-3.86-0.09-7.19-3.9-7.02-8.04c0.18-4.22,3.66-7.87,7.43-7.77c3.79,0.09,7.4,4.35,7.17,8.46
			C2249.58,645.63,2246.52,648.61,2242.25,648.51z'
          />
          <path
            fill='#D50000'
            d='M1227.74,592.53c0.1,4.17-3.23,7.97-7.1,8.1c-4.05,0.13-7.63-3.56-7.74-7.99c-0.1-4.08,3.42-7.81,7.36-7.81
			C1224.32,584.84,1227.64,588.26,1227.74,592.53z'
          />
          <path
            fill='#D50000'
            d='M2132.82,1327.13c-4.23-0.07-7.17-3.36-7.04-7.89c0.11-4.07,3.9-8.13,7.44-7.98
			c3.77,0.16,7.21,4.17,7.11,8.27C2140.22,1323.79,2136.85,1327.2,2132.82,1327.13z'
          />
          <path
            fill='#D50000'
            d='M1618.47,1125.35c0.09,4.34-3.02,7.78-7.1,7.86c-4.12,0.08-7.35-3.09-7.58-7.43
			c-0.22-4.22,3.39-8.33,7.35-8.38C1614.73,1117.36,1618.38,1121.32,1618.47,1125.35z'
          />
          <path
            fill='#D50000'
            d='M1126.93,1950.68c0-5.03,2.46-7.84,6.88-7.85c3.83-0.01,7.31,3.87,7.18,8c-0.13,4.15-3.75,8.16-7.32,8.11
			C1130.1,1958.89,1126.93,1955.01,1126.93,1950.68z'
          />
          <path
            fill='#D50000'
            d='M1205.9,1610.35c-0.01,5.01-2.48,7.66-7.05,7.58c-3.67-0.07-6.96-3.56-7.05-7.48
			c-0.1-4.39,3.49-8.46,7.38-8.37C1203.22,1602.17,1205.91,1605.48,1205.9,1610.35z'
          />
          <path
            fill='#D50000'
            d='M791.97,1125.6c0.02,5.31-2.07,7.65-6.79,7.61c-3.87-0.03-7.28-3.69-7.26-7.78c0.03-3.98,3.68-7.97,7.34-8.01
			C788.89,1117.38,791.95,1121.1,791.97,1125.6z'
          />
          <path
            fill='#D50000'
            d='M749.05,1125.1c0.08,5.36-2.33,8.16-6.99,8.12c-3.61-0.04-6.86-3.5-7.04-7.53c-0.2-4.29,3.4-8.32,7.4-8.28
			C746.29,1117.43,748.98,1120.56,749.05,1125.1z'
          />
          <path
            fill='#D50000'
            d='M662.25,932.33c-0.05,4.2-3.53,8.27-6.95,8.13c-3.65-0.16-7.22-4.3-7.11-8.26c0.12-4.46,3.13-7.53,7.36-7.52
			C660.38,924.69,662.32,926.9,662.25,932.33z'
          />
          <path
            fill='#D50000'
            d='M720.45,924.67c4.19,0.01,7.38,3.26,7.4,7.54c0.02,3.45-4.16,8.2-7.3,8.3c-3.19,0.11-7.25-4.38-7.4-8.17
			C713,928.37,716.52,924.67,720.45,924.67z'
          />
          <path
            fill='#D50000'
            d='M757.94,932c0.03-5.11,2.32-7.46,7.13-7.33c4.13,0.11,6.9,3.07,6.92,7.39c0.03,4.42-3.64,8.57-7.43,8.41
			C761.07,940.32,757.91,936.28,757.94,932z'
          />
          <path
            fill='#D50000'
            d='M2031.75,1368.52c-0.07,4.73-2.88,7.7-7.23,7.62c-4.23-0.08-7.06-3.3-6.96-7.93
			c0.09-4.23,3.53-7.97,7.26-7.89C2028.48,1360.4,2031.8,1364.34,2031.75,1368.52z'
          />
          <path
            fill='#D50000'
            d='M1119.1,1659.23c-0.05,4.95-2.63,7.79-7.02,7.75c-3.84-0.03-7.24-3.83-7.11-7.95
			c0.14-4.3,3.48-7.91,7.29-7.88C1116.33,1651.2,1119.14,1654.52,1119.1,1659.23z'
          />
          <path
            fill='#D50000'
            d='M1141.03,1659.54c-0.06,4.89-2.69,7.59-7.25,7.44c-3.56-0.12-6.84-3.82-6.84-7.71c0-4.27,3.29-8.04,7.07-8.12
			C1138.29,1651.05,1141.09,1654.4,1141.03,1659.54z'
          />
          <path
            fill='#D50000'
            d='M1089.4,1862.02c-3.92-0.01-7.32-3.55-7.39-7.69c-0.07-4.4,3.49-8.21,7.57-8.11c4.09,0.1,6.97,3.45,6.91,8.03
			C1096.43,1858.93,1093.6,1862.03,1089.4,1862.02z'
          />
          <path
            fill='#D50000'
            d='M2192.03,1029.97c-0.13-4.62,2.74-8.19,6.68-8.33c3.85-0.13,7.41,3.48,7.57,7.69
			c0.15,4.07-3.22,7.99-6.99,8.13C2194.9,1037.62,2192.16,1034.79,2192.03,1029.97z'
          />
          <path
            fill='#D50000'
            d='M786.75,1021.63c3.87,0.09,6.88,3.34,6.95,7.49c0.07,3.99-4.14,8.54-7.7,8.32c-3.52-0.22-7.05-4.51-6.99-8.48
			C779.09,1024.91,782.66,1021.53,786.75,1021.63z'
          />
          <path
            fill='#D50000'
            d='M1227.99,1659.53c-0.13,4.83-2.87,7.62-7.29,7.46c-3.77-0.14-7.16-4.16-6.92-8.21
			c0.25-4.26,3.82-7.84,7.62-7.63C1225.37,1651.37,1228.12,1654.85,1227.99,1659.53z'
          />
          <path
            fill='#D50000'
            d='M1227.97,1416.73c-0.18,4.81-2.96,7.53-7.44,7.29c-3.6-0.19-6.79-3.89-6.77-7.84
			c0.03-4.37,3.82-8.26,7.77-7.97C1225.45,1408.5,1228.15,1412.08,1227.97,1416.73z'
          />
          <path
            fill='#D50000'
            d='M2199.01,1555.4c3.83,0.03,7.28,3.8,7.28,7.94c-0.01,4.11-3.47,7.89-7.2,7.87c-4.45-0.03-6.88-2.79-6.85-7.76
			C2192.25,1558.58,2194.96,1555.37,2199.01,1555.4z'
          />
          <path
            fill='#D50000'
            d='M2061.47,1417.32c0.05-4.22,3.58-8.07,7.23-7.9c3.75,0.18,7.01,4.11,6.9,8.32c-0.12,4.62-2.98,7.53-7.38,7.49
			C2063.93,1425.2,2061.41,1422.24,2061.47,1417.32z'
          />
          <path
            fill='#D50000'
            d='M2488.57,1125.88c0.04,3.95-3.66,8.14-7.29,8.24c-3.72,0.11-6.92-3.64-6.92-8.11
			c-0.01-4.67,2.8-8.1,6.73-8.21C2484.48,1117.7,2488.53,1122.08,2488.57,1125.88z'
          />
          <path
            fill='#D50000'
            d='M873.76,632.69c3.95,0.06,7.21,3.71,7.06,7.92c-0.15,4.24-3.61,7.9-7.44,7.86c-3.64-0.03-6.8-3.91-6.75-8.29
			C866.69,635.19,869.12,632.63,873.76,632.69z'
          />
          <path
            fill='#D50000'
            d='M1126.79,1757.17c0.02-4.71,2.9-7.94,7.04-7.9c3.82,0.04,7.25,3.91,7.17,8.07c-0.09,4.13-3.53,7.71-7.44,7.74
			C1129.28,1765.1,1126.77,1762.16,1126.79,1757.17z'
          />
          <path
            fill='#D50000'
            d='M1067.71,1749.28c3.85-0.02,7.43,3.74,7.49,7.87c0.06,4.07-3.42,7.84-7.31,7.92
			c-4.35,0.09-6.93-2.76-6.97-7.73C1060.88,1752.39,1063.48,1749.3,1067.71,1749.28z'
          />
          <path
            fill='#D50000'
            d='M2249.2,1126.62c0,5.08-2.36,7.76-6.83,7.77c-4.3,0.01-7.2-3.04-7.24-7.62c-0.04-4.33,3.16-8.04,7.04-8.18
			C2245.8,1118.45,2249.2,1122.35,2249.2,1126.62z'
          />
          <path
            fill='#D50000'
            d='M2531.43,1174.99c-0.06,3.76-4.04,8.14-7.35,8.11c-4-0.04-6.86-3.62-6.8-8.53c0.06-4.37,3.37-8.08,7.04-7.85
			C2527.95,1166.94,2531.49,1171.06,2531.43,1174.99z'
          />
          <path
            fill='#D50000'
            d='M632.85,924.68c3.98,0.02,7.35,3.54,7.37,7.67c0.01,3.68-4.07,8.21-7.37,8.17c-3.33-0.04-6.98-4.31-7.03-8.22
			C625.74,927.54,628.4,924.65,632.85,924.68z'
          />
          <path
            fill='#D50000'
            d='M2191.94,1126.46c0.07-4.22,3.12-7.77,6.77-7.87c4.03-0.1,7.76,3.96,7.57,8.23c-0.19,4.18-3.6,7.62-7.5,7.57
			C2194.46,1134.33,2191.86,1131.32,2191.94,1126.46z'
          />
          <path
            fill='#D50000'
            d='M2299.91,1126.28c0.1-4.49,3.27-7.83,7.29-7.69c3.91,0.14,7,3.79,6.9,8.14c-0.1,4.26-3.48,7.78-7.36,7.65
			C2302.72,1134.25,2299.81,1130.79,2299.91,1126.28z'
          />
          <path
            fill='#D50000'
            d='M2213.95,542.19c-0.07-4.34,3-8.3,6.62-8.54c3.35-0.22,7.4,3.97,7.58,7.84c0.2,4.2-3.47,8.32-7.41,8.31
			C2216.88,549.8,2214.03,546.6,2213.95,542.19z'
          />
          <path
            fill='#D50000'
            d='M625.2,1029.04c0-5.21,2.18-7.52,6.96-7.42c3.64,0.08,6.98,3.57,7.12,7.45c0.15,4.1-3.81,8.48-7.56,8.35
			C628.07,1037.31,625.2,1033.62,625.2,1029.04z'
          />
          <path
            fill='#D50000'
            d='M749.04,1078.39c0.03,4.81-2.72,8.13-6.73,8.11c-3.76-0.02-7.24-3.79-7.31-7.91
			c-0.06-4.16,3.33-7.9,7.16-7.91C746.69,1070.68,749,1073.27,749.04,1078.39z'
          />
          <path
            fill='#D50000'
            d='M2488.57,1076.72c0.05,3.83-3.86,8.2-7.32,8.2c-3.79-0.01-6.82-3.32-6.9-7.55c-0.09-4.87,2.91-8.75,6.78-8.76
			C2484.63,1068.6,2488.52,1072.84,2488.57,1076.72z'
          />
          <path
            fill='#D50000'
            d='M2162.38,640.53c0.07,4.82-2.54,7.89-6.77,7.98c-4.21,0.08-7.23-3.06-7.28-7.57
			c-0.05-4.45,3.45-8.36,7.37-8.25C2159.19,632.79,2162.32,636.47,2162.38,640.53z'
          />
          <path
            fill='#D50000'
            d='M2170.28,688.41c0.01-4.68,2.79-7.81,6.94-7.82c3.97-0.02,7.09,3.4,7.11,7.8c0.03,4.48-3.48,8.19-7.58,8.01
			C2173.29,696.25,2170.27,692.52,2170.28,688.41z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1513.18c0.05,5.08-2.34,7.83-6.81,7.83c-3.88,0-7.34-3.71-7.28-7.79c0.06-4.23,3.43-8.02,7.16-8.07
			C1202.98,1505.09,1205.83,1508.4,1205.89,1513.18z'
          />
          <path
            fill='#D50000'
            d='M1705.49,882.77c-0.07,4.47-3.61,7.96-7.91,7.81c-3.49-0.12-6.61-3.6-6.65-7.42
			c-0.05-3.99,3.92-8.71,7.29-8.68C1701.6,874.51,1705.55,879.02,1705.49,882.77z'
          />
          <path
            fill='#D50000'
            d='M1148.72,1708.26c0-4.61,3.02-8.1,6.96-8.05c3.79,0.05,7.3,3.92,7.25,8.01c-0.04,4.13-3.47,7.81-7.29,7.81
			C1151.24,1716.04,1148.72,1713.21,1148.72,1708.26z'
          />
          <path
            fill='#D50000'
            d='M2342.76,640.69c-0.03-4.64,2.82-7.94,6.9-7.99c3.93-0.04,7.33,3.56,7.34,7.8c0.02,4.32-3.22,7.95-7.13,8
			C2345.73,648.54,2342.79,645.32,2342.76,640.69z'
          />
          <path
            fill='#D50000'
            d='M1649.61,882.77c-0.05-4.28,3.21-8.16,6.9-8.22c3.7-0.05,6.99,3.67,7.15,8.08c0.17,4.8-2.35,7.55-7.02,7.64
			C1651.96,890.37,1649.67,887.92,1649.61,882.77z'
          />
          <path
            fill='#D50000'
            d='M2279,640.7c0.01-4.26,3.38-8.1,7.04-8.02c3.79,0.09,7.07,3.78,7.1,7.98c0.03,4.46-3.04,7.83-7.12,7.83
			C2281.77,648.5,2278.99,645.41,2279,640.7z'
          />
          <path
            fill='#D50000'
            d='M1784.21,793.35c-4.67-0.01-6.98-2.56-6.9-7.64c0.06-4.24,3.56-8.23,7.1-8.1c3.79,0.14,7.01,3.94,7.02,8.28
			C1791.45,790.75,1788.92,793.36,1784.21,793.35z'
          />
          <path
            fill='#D50000'
            d='M1676.23,890.28c-4.53-0.03-6.74-2.64-6.66-7.87c0.06-3.99,3.29-7.74,6.76-7.85
			c3.89-0.13,7.31,3.84,7.28,8.46C1683.59,887.82,1681.06,890.31,1676.23,890.28z'
          />
          <path
            fill='#D50000'
            d='M786.85,924.68c3.82,0.13,6.91,3.52,6.9,7.56c-0.01,3.76-4.51,8.53-7.78,8.23c-3.44-0.31-7.08-4.81-6.95-8.6
			C779.16,927.83,782.74,924.54,786.85,924.68z'
          />
          <path
            fill='#D50000'
            d='M1111.82,1894.06c3.73,0.04,7.25,3.87,7.24,7.9c-0.01,4.07-3.61,7.93-7.39,7.91
			c-3.84-0.02-6.84-3.61-6.81-8.16C1104.88,1896.69,1107.31,1894.02,1111.82,1894.06z'
          />
          <path
            fill='#D50000'
            d='M1844.05,1368.54c-0.03-4.52,2.99-8.11,6.89-8.19c3.77-0.08,7.25,3.64,7.36,7.84
			c0.11,4.47-2.92,7.89-7.05,7.95C1846.8,1376.21,1844.08,1373.34,1844.05,1368.54z'
          />
          <path
            fill='#D50000'
            d='M1162.95,1854.4c-0.08,4.71-2.87,7.69-7.15,7.63c-3.81-0.05-7-3.77-6.91-8.05c0.09-4.32,3.47-7.9,7.32-7.76
			C1160.33,1846.36,1163.03,1849.64,1162.95,1854.4z'
          />
          <path
            fill='#D50000'
            d='M814.93,932.13c0.02,4.31-3.14,8.3-6.63,8.36c-3.77,0.07-7.53-4.21-7.44-8.5c0.09-4.41,3.16-7.36,7.63-7.31
			C812.93,924.73,814.91,927.01,814.93,932.13z'
          />
          <path
            fill='#D50000'
            d='M757.93,1076.97c0.01-5.12,2.27-7.52,7.01-7.45c4.13,0.06,6.95,2.96,7.05,7.24c0.1,4.44-3.44,8.57-7.29,8.51
			C761.14,1085.21,757.93,1081.27,757.93,1076.97z'
          />
          <path
            fill='#D50000'
            d='M1126.78,1853.99c0.07-4.67,3.03-7.89,7.17-7.78c3.8,0.1,7.22,4.07,7.04,8.19c-0.18,4.18-3.67,7.7-7.55,7.62
			C1129.26,1861.94,1126.71,1858.87,1126.78,1853.99z'
          />
          <path
            fill='#D50000'
            d='M1119.08,1708.39c0,4.96-2.5,7.69-7.02,7.64c-3.96-0.04-7.21-3.66-7.1-7.93c0.11-4.29,3.47-7.91,7.32-7.89
			C1116.26,1700.24,1119.08,1703.63,1119.08,1708.39z'
          />
          <path
            fill='#D50000'
            d='M1141.02,1708.38c0.01,5.02-2.45,7.72-6.97,7.66c-3.65-0.05-7-3.53-7.12-7.41c-0.14-4.51,3.24-8.39,7.32-8.42
			C1138.28,1700.18,1141.01,1703.48,1141.02,1708.38z'
          />
          <path
            fill='#D50000'
            d='M1104.82,1854.07c0.03-4.69,2.94-7.91,7.1-7.86c3.81,0.05,7.27,3.97,7.14,8.09
			c-0.13,4.15-3.62,7.74-7.48,7.72C1107.39,1862,1104.79,1858.93,1104.82,1854.07z'
          />
          <path
            fill='#D50000'
            d='M1061.04,1854.21c0.01-5.02,2.57-8.03,6.79-7.98c3.88,0.04,7.4,3.83,7.37,7.94
			c-0.03,4.13-3.52,7.84-7.37,7.85C1063.38,1862.03,1061.02,1859.33,1061.04,1854.21z'
          />
          <path
            fill='#D50000'
            d='M2154.98,1360.32c3.87,0.05,7.22,3.66,7.31,7.87c0.09,4.41-3.39,8.06-7.59,7.94
			c-4.11-0.11-7.11-3.52-6.99-7.94C2147.84,1364,2151.27,1360.28,2154.98,1360.32z'
          />
          <path
            fill='#D50000'
            d='M1227.99,1708.34c-0.02,4.93-2.56,7.71-7.03,7.7c-3.79-0.01-7.21-3.76-7.19-7.89
			c0.01-4.14,3.52-7.93,7.32-7.94C1225.02,1700.21,1228.01,1703.72,1227.99,1708.34z'
          />
          <path
            fill='#D50000'
            d='M1235.71,1708.35c-0.04-4.32,3.18-8.1,6.94-8.15c3.74-0.04,7.08,3.72,7.12,8.02c0.04,4.66-2.77,7.8-6.99,7.82
			C1238.43,1716.06,1235.75,1713.14,1235.71,1708.35z'
          />
          <path
            fill='#D50000'
            d='M1257.43,1708.62c-0.17-4.54,2.76-8.24,6.66-8.42c3.83-0.17,7.42,3.43,7.62,7.65
			c0.2,4.12-3.13,8.07-6.88,8.19C1260.31,1716.18,1257.61,1713.46,1257.43,1708.62z'
          />
          <path
            fill='#D50000'
            d='M720.72,972.57c4.06,0.1,7.33,3.68,7.15,7.82c-0.16,3.62-4.39,8.05-7.59,7.96c-3.37-0.1-7.16-4.55-7.13-8.38
			C713.18,975.98,716.76,972.48,720.72,972.57z'
          />
          <path
            fill='#D50000'
            d='M1062.03,1708.34c-0.02-4.6,2.96-8.14,6.83-8.11c3.85,0.03,7.22,3.69,7.35,7.97c0.12,3.99-3.42,7.8-7.27,7.84
			C1064.48,1716.08,1062.05,1713.37,1062.03,1708.34z'
          />
          <path
            fill='#D50000'
            d='M641.17,980.06c-0.01,3.91-3.78,8.24-7.19,8.25c-3.59,0.01-7.51-4.51-7.38-8.51c0.13-4.22,3.05-7.2,7.06-7.22
			C637.67,972.56,641.18,976.06,641.17,980.06z'
          />
          <path
            fill='#D50000'
            d='M2198.88,1069.53c3.9-0.03,7.33,3.62,7.4,7.85c0.07,4.16-3.27,7.88-7.14,7.95c-4.4,0.08-7.1-2.8-7.15-7.63
			C2191.94,1073.18,2194.99,1069.56,2198.88,1069.53z'
          />
          <path
            fill='#D50000'
            d='M757.93,980.03c0.01-5.12,2.27-7.53,7.01-7.46c4.13,0.06,6.95,2.97,7.05,7.25c0.11,4.44-3.44,8.59-7.29,8.5
			C761.12,988.24,757.92,984.33,757.93,980.03z'
          />
          <path
            fill='#D50000'
            d='M2206.29,641.11c-0.15,4.61-3.12,7.57-7.41,7.39c-4.21-0.18-6.82-3.37-6.66-8.13
			c0.14-4.02,3.43-7.68,6.93-7.69C2203.05,632.68,2206.43,636.67,2206.29,641.11z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1659.13c0.07,5-2.37,7.82-6.8,7.85c-3.87,0.02-7.36-3.71-7.29-7.81c0.07-4.26,3.43-8,7.2-8.03
			C1203.11,1651.12,1205.83,1654.27,1205.89,1659.13z'
          />
          <path
            fill='#D50000'
            d='M2192.22,1660.15c-0.06-5.07,2.32-7.82,6.78-7.84c3.89-0.02,7.27,3.63,7.28,7.86c0,4.14-3.41,7.92-7.2,7.99
			C2195.01,1668.23,2192.28,1665.04,2192.22,1660.15z'
          />
          <path
            fill='#D50000'
            d='M2170.28,640.66c0.02-4.35,3.33-8.12,7.02-7.98c3.79,0.15,7.05,3.89,7.03,8.07
			c-0.02,4.53-3.01,7.76-7.16,7.75C2172.93,648.49,2170.26,645.45,2170.28,640.66z'
          />
          <path
            fill='#D50000'
            d='M887.71,980.14c-0.1-3.83,3.19-7.42,6.95-7.56c4.42-0.17,7.96,3.23,7.89,7.58c-0.06,3.58-4.14,8.03-7.47,8.15
			C891.7,988.42,887.82,984.12,887.71,980.14z'
          />
          <path
            fill='#D50000'
            d='M2089.89,1425.19c-4.24-0.09-7.18-3.38-7.04-7.88c0.13-4.11,3.86-8.06,7.46-7.88
			c3.86,0.19,7.24,4.08,7.12,8.2C2097.31,1421.83,2093.87,1425.28,2089.89,1425.19z'
          />
          <path
            fill='#D50000'
            d='M857.87,1174.75c-0.05,5.01-2.55,7.67-7.09,7.52c-3.78-0.13-7.25-4.11-7.07-8.11
			c0.19-4.15,3.81-7.81,7.62-7.71C855.35,1166.55,857.92,1169.81,857.87,1174.75z'
          />
          <path
            fill='#D50000'
            d='M2162.28,1222.27c0.11,4.2-3.22,7.87-7.18,7.92c-4.35,0.06-7.29-2.97-7.36-7.58
			c-0.06-3.98,3.58-8.21,7.11-8.26C2158.69,1214.3,2162.17,1218.01,2162.28,1222.27z'
          />
          <path
            fill='#D50000'
            d='M2517.28,1125.81c0.02-4.54,2.99-8.01,6.91-8.07c3.61-0.05,7.14,3.86,7.24,8.01c0.11,4.39-3.5,8.44-7.42,8.34
			C2520.19,1134,2517.25,1130.39,2517.28,1125.81z'
          />
          <path
            fill='#D50000'
            d='M2226.25,1174.32c0.01,4.84-2.66,7.92-6.88,7.94c-3.79,0.02-7.13-3.7-7.19-8c-0.06-4.02,3.5-7.85,7.29-7.84
			C2223.72,1166.44,2226.24,1169.37,2226.25,1174.32z'
          />
          <path
            fill='#D50000'
            d='M1669.55,835.62c0.06-4.08,3.23-7.67,6.85-7.75c3.99-0.1,7.37,3.85,7.22,8.43c-0.16,4.86-2.72,7.39-7.39,7.28
			C1671.81,843.48,1669.47,840.69,1669.55,835.62z'
          />
          <path
            fill='#D50000'
            d='M2105.42,640.9c-0.05-4.43,3.06-8.2,6.75-8.21c3.95-0.01,7.38,3.92,7.31,8.39c-0.06,4.14-3.11,7.37-7.01,7.43
			C2108.03,648.57,2105.47,645.81,2105.42,640.9z'
          />
          <path
            fill='#D50000'
            d='M757.93,1028.82c-0.02-4.23,3.2-8.2,6.77-8.35c3.61-0.15,7.14,3.71,7.29,7.98c0.16,4.48-2.79,7.77-7.02,7.85
			C760.29,1036.39,757.95,1033.91,757.93,1028.82z'
          />
          <path
            fill='#D50000'
            d='M1220.1,1749.27c3.79-0.07,7.32,3.64,7.39,7.76c0.07,4.3-3.06,7.92-6.95,8.04c-4.21,0.13-7.69-3.43-7.69-7.87
			C1212.84,1752.98,1216.16,1749.34,1220.1,1749.27z'
          />
          <path
            fill='#D50000'
            d='M2226.25,1126.59c-0.04,4.92-2.64,7.87-6.88,7.8c-3.78-0.06-7.32-3.97-7.19-7.94
			c0.14-4.33,3.47-7.92,7.3-7.86C2223.71,1118.65,2226.29,1121.7,2226.25,1126.59z'
          />
          <path
            fill='#D50000'
            d='M1183.94,1757.3c0.04,4.95-2.45,7.76-6.87,7.77c-3.8,0.01-7.28-3.77-7.22-7.84c0.06-4.34,3.36-7.97,7.22-7.96
			C1181.25,1749.27,1183.9,1752.38,1183.94,1757.3z'
          />
          <path
            fill='#D50000'
            d='M692.09,1125.54c-0.07-4.77,2.69-8.11,6.7-8.14c3.77-0.03,7.27,3.71,7.34,7.85c0.07,4.17-3.31,7.95-7.11,7.97
			C694.5,1133.23,692.16,1130.65,692.09,1125.54z'
          />
          <path
            fill='#D50000'
            d='M2183.25,1319.21c0.13,4.17-3.21,7.86-7.18,7.92c-4.34,0.07-7.32-2.99-7.39-7.57
			c-0.06-3.9,3.65-8.21,7.14-8.29C2179.51,1311.18,2183.12,1315.04,2183.25,1319.21z'
          />
          <path
            fill='#D50000'
            d='M2198.73,1327.12c-4.17-0.04-7.21-3.31-7.16-7.71c0.04-3.89,3.79-8.1,7.25-8.13c3.88-0.04,7.54,4.09,7.4,8.36
			C2206.07,1323.84,2202.76,1327.15,2198.73,1327.12z'
          />
          <path
            fill='#D50000'
            d='M757.93,1125.49c-0.05-4.75,2.75-8.08,6.8-8.1c3.77-0.02,7.2,3.72,7.25,7.89c0.05,4.22-3.28,7.93-7.11,7.92
			C760.35,1133.2,757.98,1130.57,757.93,1125.49z'
          />
          <path
            fill='#D50000'
            d='M1726.54,785.49c0.08,5.18-2.17,7.81-6.71,7.85c-4.72,0.04-7.24-2.47-7.35-7.35c-0.1-4.24,3.1-8.15,6.85-8.38
			C1722.98,777.4,1726.47,781.21,1726.54,785.49z'
          />
          <path
            fill='#D50000'
            d='M1118.08,1757.4c-0.07,4.81-2.79,7.75-7.1,7.67c-3.78-0.06-7.11-3.87-7.02-8.04
			c0.09-4.24,3.52-7.86,7.35-7.77C1115.47,1749.37,1118.15,1752.6,1118.08,1757.4z'
          />
          <path
            fill='#D50000'
            d='M1090.53,1716c-3.97,0.06-7.45-3.44-7.53-7.58c-0.08-4.14,3.3-8.03,7.12-8.2c3.81-0.17,7.3,3.54,7.39,7.85
			C1097.61,1712.78,1094.83,1715.93,1090.53,1716z'
          />
          <path
            fill='#D50000'
            d='M1184.5,1708.41c-0.11,4.51-3.15,7.66-7.33,7.62c-3.94-0.04-7.24-3.59-7.25-7.8c0-4.42,3.61-8.2,7.67-8.01
			C1181.32,1700.4,1184.6,1704.29,1184.5,1708.41z'
          />
          <path
            fill='#D50000'
            d='M757.93,640.18c0.03-5.01,2.42-7.53,7.08-7.49c4.01,0.04,7.14,3.58,6.98,7.9c-0.16,4.32-3.51,7.92-7.34,7.9
			C761.04,648.47,757.91,644.6,757.93,640.18z'
          />
          <path
            fill='#D50000'
            d='M2192.22,1610.59c-0.01-4.24,3.17-8.38,6.55-8.5c3.41-0.12,7.36,4.06,7.5,7.94c0.15,4.5-3.11,7.91-7.54,7.87
			C2194.12,1617.87,2192.24,1615.75,2192.22,1610.59z'
          />
          <path
            fill='#D50000'
            d='M1205.89,1708.14c0.07,5.07-2.3,7.84-6.72,7.89c-3.83,0.04-7.37-3.65-7.37-7.69c0.01-4.27,3.25-7.98,7.1-8.13
			C1202.9,1700.06,1205.83,1703.38,1205.89,1708.14z'
          />
          <path
            fill='#D50000'
            d='M1198.71,1749.27c3.84,0.04,7.25,3.88,7.14,8.07c-0.1,4.17-3.56,7.78-7.42,7.74
			c-4.22-0.05-6.92-3.15-6.86-7.92C1191.64,1752.51,1194.6,1749.23,1198.71,1749.27z'
          />
          <path
            fill='#D50000'
            d='M1770.44,785.57c0.07,5.15-2.19,7.76-6.74,7.77c-4.72,0.02-7.35-2.62-7.34-7.38c0-4.25,3.28-8.17,6.96-8.33
			C1767.02,777.48,1770.39,781.23,1770.44,785.57z'
          />
          <path
            fill='#D50000'
            d='M1248.8,1757.32c0.02,4.95-2.5,7.81-6.83,7.75c-3.84-0.05-7.36-3.86-7.25-7.86c0.12-4.36,3.42-7.97,7.27-7.95
			C1246.16,1749.29,1248.77,1752.38,1248.8,1757.32z'
          />
          <path
            fill='#D50000'
            d='M851.68,632.69c4.29-0.07,7.17,2.96,7.18,7.56c0.01,4.38-3.17,8.12-6.96,8.2c-3.75,0.08-7.21-3.74-7.22-7.96
			C844.68,635.67,847.28,632.77,851.68,632.69z'
          />
          <path
            fill='#D50000'
            d='M1727.25,1125.68c-0.17,4.66-3.48,7.81-7.92,7.54c-3.62-0.22-6.77-3.84-6.78-7.8
			c-0.01-4.27,3.82-8.23,7.75-8.03C1723.81,1117.59,1727.39,1121.85,1727.25,1125.68z'
          />
          <path
            fill='#D50000'
            d='M1096.46,1901.67c0.09,4.29-3.24,8.19-7.01,8.21c-3.81,0.02-7.34-3.73-7.45-7.91
			c-0.11-3.99,3.43-7.86,7.21-7.9C1093.65,1894.01,1096.36,1896.85,1096.46,1901.67z'
          />
          <path
            fill='#D50000'
            d='M1705.48,1222.92c-0.32,4.47-3.98,7.65-8.32,7.24c-3.85-0.36-6.49-3.87-6.14-8.16
			c0.35-4.26,4.11-8.02,7.69-7.66C1702.46,1214.71,1705.77,1218.91,1705.48,1222.92z'
          />
          <path
            fill='#D50000'
            d='M2226.26,1077.61c-0.05,4.8-2.83,7.81-7.09,7.7c-3.74-0.09-7.03-3.95-6.98-8.19c0.05-4.03,3.7-7.73,7.49-7.6
			C2223.98,1069.68,2226.31,1072.55,2226.26,1077.61z'
          />
          <path
            fill='#D50000'
            d='M814.93,980.35c-0.05,4.31-3.43,8.14-7.03,7.99c-3.68-0.16-7.06-4.16-7.05-8.35
			c0.02-4.33,3.15-7.42,7.51-7.42C813.04,972.58,814.99,974.89,814.93,980.35z'
          />
          <path
            fill='#D50000'
            d='M836.9,931.97c0.03,4.39-2.96,8.31-6.49,8.52c-3.7,0.22-7.66-4.12-7.63-8.36c0.04-4.4,3.13-7.46,7.54-7.45
			C834.94,924.68,836.87,926.83,836.9,931.97z'
          />
          <path
            fill='#D50000'
            d='M2134,1133.25c-4.08,0.04-7.45-3.32-7.55-7.55c-0.09-3.89,3.62-8.18,7.17-8.28c3.57-0.1,7.64,4.43,7.62,8.48
			C2141.22,1129.76,2137.83,1133.21,2134,1133.25z'
          />
          <path
            fill='#D50000'
            d='M2154.97,1327.13c-4.25,0.02-7.3-3.21-7.29-7.7c0.01-3.94,3.81-8.2,7.29-8.16c3.77,0.04,7.26,3.89,7.31,8.07
			C2162.32,1323.54,2158.97,1327.12,2154.97,1327.13z'
          />
          <path
            fill='#D50000'
            d='M2119.39,1417.43c0.03,4.11-3.45,7.8-7.34,7.79c-4.29-0.01-7.22-3.13-7.22-7.72c0-4.05,3.63-8.1,7.24-8.09
			C2115.87,1409.43,2119.36,1413.25,2119.39,1417.43z'
          />
          <path
            fill='#D50000'
            d='M851.42,924.68c4.8-0.09,7.38,2.41,7.45,7.2c0.07,4.58-3.36,8.67-7.17,8.58c-3.65-0.09-6.94-4.01-6.99-8.33
			C844.65,927.55,847.17,924.75,851.42,924.68z'
          />
          <path
            fill='#D50000'
            d='M698.62,924.68c4.48-0.09,7.46,2.82,7.52,7.34c0.06,4.36-3.63,8.58-7.4,8.47c-3.36-0.1-6.57-3.91-6.64-7.88
			C691.99,927.25,694.03,924.77,698.62,924.68z'
          />
          <path
            fill='#D50000'
            d='M749.05,932.23c0,4.26-3.24,8.22-6.74,8.25c-3.54,0.03-7.18-4-7.3-8.1c-0.14-4.56,3.09-7.79,7.71-7.71
			C747.12,924.76,749.05,927.06,749.05,932.23z'
          />
          <path
            fill='#D50000'
            d='M822.76,1174.67c-0.01-4.98,2.59-8.18,6.65-8.22c3.87-0.04,7.38,3.61,7.48,7.77c0.1,4.15-3.26,7.97-7.07,8.05
			C825.26,1182.35,822.77,1179.66,822.76,1174.67z'
          />
          <path
            fill='#D50000'
            d='M836.9,542.57c-0.05,4.42-3.27,8.03-7.01,7.85c-3.56-0.17-7.03-3.95-7.08-7.71c-0.04-3.14,4.94-8.13,8.1-8.11
			C834.78,534.61,836.96,537.51,836.9,542.57z'
          />
          <path
            fill='#D50000'
            d='M2141.1,640.93c-0.08,4.41-3.17,7.56-7.44,7.58c-3.94,0.02-7.24-3.57-7.19-7.83
			c0.05-4.17,3.41-7.92,7.15-7.99C2137.5,632.63,2141.18,636.68,2141.1,640.93z'
          />
          <path
            fill='#D50000'
            d='M684.19,931.91c0.06,4.51-3.45,8.67-7.21,8.55c-3.71-0.12-6.79-3.86-6.84-8.32c-0.05-4.78,2.25-7.38,6.6-7.46
			C681.47,924.59,684.13,927.17,684.19,931.91z'
          />
          <path
            fill='#D50000'
            d='M2097.52,641.03c-0.14,5.02-2.62,7.58-7.22,7.47c-3.73-0.1-7.05-4.06-6.84-8.18
			c0.21-4.24,3.73-7.81,7.51-7.63C2095.02,632.89,2097.66,636.24,2097.52,641.03z'
          />
          <path
            fill='#D50000'
            d='M2221.11,648.5c-4.47,0.05-7.27-2.89-7.25-7.62c0.02-4.55,2.99-8.07,6.9-8.2c3.8-0.12,7.24,3.49,7.4,7.77
			C2228.3,644.69,2225,648.46,2221.11,648.5z'
          />
          <path
            fill='#D50000'
            d='M721.03,680.59c3.64,0.09,6.83,3.71,6.81,7.72c-0.02,3.94-3.81,8.1-7.34,8.07c-3.49-0.03-7.21-3.9-7.22-7.51
			C713.26,685.59,718.03,680.51,721.03,680.59z'
          />
          <path
            fill='#D50000'
            d='M684.19,979.83c0.05,4.63-3.36,8.62-7.23,8.47c-3.78-0.15-6.79-3.83-6.82-8.33
			c-0.03-4.74,2.29-7.32,6.65-7.39C681.5,972.5,684.14,975.08,684.19,979.83z'
          />
          <path
            fill='#D50000'
            d='M844.69,980.09c-0.09-4.58,2.41-7.4,6.66-7.51c4.7-0.12,7.41,2.45,7.52,7.14c0.1,4.62-3.22,8.63-7.1,8.58
			C848.07,988.26,844.78,984.43,844.69,980.09z'
          />
          <path
            fill='#D50000'
            d='M836.9,980.11c-0.02,4.35-3.21,8.18-6.81,8.18c-3.58,0-7.36-4.19-7.3-8.08c0.08-4.57,3.32-7.72,7.84-7.63
			C835.06,972.66,836.92,974.9,836.9,980.11z'
          />
          <path
            fill='#D50000'
            d='M655.43,972.59c4.54-0.01,7.17,2.73,7.15,7.48c-0.01,4.13-3.41,8.17-6.95,8.29c-3.27,0.11-7.3-4.29-7.38-8.06
			C648.16,976.25,651.57,972.6,655.43,972.59z'
          />
          <path
            fill='#D50000'
            d='M749.03,980.27c-0.04,4.33-3.35,8.14-6.96,8.03c-3.69-0.11-7.08-4.07-7.07-8.26
			c0.01-4.37,3.07-7.45,7.41-7.47C747.1,972.55,749.09,974.87,749.03,980.27z'
          />
          <path
            fill='#D50000'
            d='M2362.82,663.83c2.63-3.11,4.07-6.07,6.41-7.27c3.88-1.99,5.17,1.97,6.99,4.4c2.04,2.73,3.51,5.82,0.57,8.31
			c-1.85,1.56-5.48,2.96-7.3,2.15C2367.01,670.32,2365.48,667.05,2362.82,663.83z'
          />
          <path
            fill='#D50000'
            d='M692.09,979.67c0.09-4.84,2.3-7.12,6.84-7.09c4.33,0.03,7.3,3.17,7.21,7.62c-0.09,4.2-3.54,8.1-7.19,8.12
			C695.18,988.34,692.01,984.34,692.09,979.67z'
          />
          <path
            fill='#D50000'
            d='M814.94,565.8c-0.04,4.32-3.26,8.11-6.91,8.14c-3.17,0.02-8.22-4.67-6.84-7.09c1.95-3.41,5.17-7.42,8.58-8.31
			C813.69,557.52,815.11,561.92,814.94,565.8z'
          />
          <path
            fill='#D50000'
            d='M2335.96,735.56c0.13,3.91-3.2,7.58-6.92,7.64c-3.64,0.06-6.72-3.01-6.99-6.95c-0.26-3.83,2.97-7.64,6.6-7.79
			C2332.46,728.3,2335.82,731.56,2335.96,735.56z'
          />
          <path
            fill='#D50000'
            d='M2329.2,719.91c-3.58,0.05-7.12-3.59-7.16-7.34c-0.04-4.05,3.15-7.42,7.01-7.4c3.47,0.02,6.71,3.34,6.88,7.06
			C2336.1,715.95,2332.68,719.86,2329.2,719.91z'
          />
          <path
            fill='#D50000'
            d='M2313.1,712.42c0.03,4.89-2.25,7.54-6.48,7.49c-4.17-0.05-6.77-2.94-6.72-7.47c0.05-4.38,2.54-7.18,6.43-7.26
			C2310.73,705.08,2313.07,707.59,2313.1,712.42z'
          />
          <path
            fill='#D50000'
            d='M2407.63,735.78c1.34-2.07,2.39-4.43,4.11-6.13c2.19-2.16,4.5-1.7,6.05,1.16c0.79,1.46,1.93,2.84,2.28,4.4
			c0.49,2.17,1.35,5.41,0.3,6.51c-1.52,1.58-5.02,2.77-6.92,2.07c-2.09-0.77-3.35-3.85-4.88-5.98c-0.24-0.33-0.03-0.97-0.03-1.47
			C2408.23,736.16,2407.93,735.97,2407.63,735.78z'
          />
          <path
            fill='#D50000'
            d='M2313.1,735.95c-0.07,4.71-2.59,7.4-6.78,7.25c-3.65-0.13-6.43-3.34-6.41-7.39c0.03-4.42,2.74-7.34,6.83-7.36
			C2310.94,728.43,2313.18,731.06,2313.1,735.95z'
          />
          <path
            fill='#D50000'
            d='M2517.26,1076.56c-0.03-3.94,1.69-6.62,5.66-7.51c0.86-0.19,1.9-0.15,2.72,0.15
			c1.84,0.68,4.71,10.08,3.25,12.57c-0.9,1.53-3.6,2.86-5.44,2.79C2519.28,1084.42,2517.21,1081.29,2517.26,1076.56z'
          />
          <path
            fill='#D50000'
            d='M662.72,806.66c-0.46,5.8-1.73,9.35-5.95,9.98c-1.92,0.29-4.57-0.8-6.02-2.18c-0.92-0.88-0.41-3.46-0.21-5.24
			c0.12-1.1,0.81-2.15,1.33-3.18c1.18-2.36,1.66-6.38,5.42-4.73C659.82,802.43,661.52,805.41,662.72,806.66z'
          />
          <path
            fill='#D50000'
            d='M793.67,588.43c0.06,5.6-3.58,9.99-7.09,9.84c-3.37-0.14-7.37-2.09-6.29-5.23c1.37-3.95,5.26-7.02,8.07-10.47
			C790.42,584.84,792.47,587.1,793.67,588.43z'
          />
          <path
            fill='#D50000'
            d='M685.86,761.09c-2.75,3.07-4.49,5.96-7.04,7.44c-1.3,0.75-4.49-0.53-6.01-1.84c-0.9-0.77-0.42-3.43-0.15-5.17
			c0.2-1.26,0.97-2.48,1.71-3.58c1.35-2.01,1.7-6.2,5.23-4.43C681.98,754.71,683.35,757.91,685.86,761.09z'
          />
          <path
            fill='#D50000'
            d='M618.38,932.67c-0.07,3.28-1.88,6.21-5.82,7.48c-3.79,1.23-7.04-1.69-6.31-5.59c0.31-1.63,0.87-3.2,1.32-4.8
			c0.74-2.7,1.02-5.8,5.2-5.05C616.06,925.31,618.29,928.06,618.38,932.67z'
          />
          <path
            fill='#D50000'
            d='M2503.49,947.24c1.05,4.11,2.08,7.75,2.89,11.45c0.85,3.89-1.46,5.87-5.29,4.7c-3.8-1.17-5.58-3.93-5.75-7.73
			C2495.16,951.57,2497.58,949.03,2503.49,947.24z'
          />
          <path
            fill='#D50000'
            d='M767.09,608.21c6.21,4.61,6.59,12.03,0.8,14.6c-1.97,0.88-5.35,0.19-7.36-1.01c-2.91-1.72-0.6-3.96,0.71-5.77
			C763.07,613.51,764.97,611.04,767.09,608.21z'
          />
          <path
            fill='#D50000'
            d='M2480.97,873.81c1.37,3.96,2.75,7.51,3.82,11.16c1.01,3.47-1.39,5.63-5.01,4.72
			c-3.89-0.98-5.74-3.83-6.22-7.49C2473.08,878.61,2476.05,875.3,2480.97,873.81z'
          />
          <path
            fill='#D50000'
            d='M628.84,863.86c1.81-4.87,3.5-9.42,5.22-14.02c5.73,2.66,7.41,7.01,5.06,11.49
			C637.02,865.36,633.6,866.37,628.84,863.86z'
          />
          <path
            fill='#D50000'
            d='M2524.07,1044.9c0.81,5.4,1.55,10.36,2.3,15.43c-6.05-0.06-8.77-2.29-9.1-6.79
			C2516.88,1048.25,2518.72,1045.75,2524.07,1044.9z'
          />
          <path
            fill='#D50000'
            d='M2397.58,695.27c-4.5,2.16-7.32,0.82-9.4-2.09c-2.36-3.31-2.2-6.72,1.39-10.74
			C2392.33,686.86,2394.88,690.93,2397.58,695.27z'
          />
          <path
            fill='#D50000'
            d='M695.49,718.2c2.74-4.85,5.05-8.94,7.58-13.42c3.63,3.53,3.76,6.85,1.74,10.29
			C702.96,718.21,700.2,719.48,695.49,718.2z'
          />
          <path
            fill='#D50000'
            d='M2269.01,524.82c-4.28,2.45-7.29,2.07-9.68-0.78c-2.37-2.83-3.49-6-0.46-9.9
			C2262.19,517.63,2265.4,521.01,2269.01,524.82z'
          />
          <path
            fill='#D50000'
            d='M2289.18,549.88c-2.58-0.59-5.67-0.48-7.61-1.93c-3.16-2.35-3.22-6.07-1.41-11.06
			c3.65,4.03,6.71,7.41,9.77,10.8C2289.68,548.42,2289.43,549.15,2289.18,549.88z'
          />
          <path
            fill='#D50000'
            d='M749.58,636.53c-0.83,3.22-1.08,6.79-2.71,9.54c-0.94,1.59-4.08,1.88-6.23,2.76
			c-0.42-0.59-0.84-1.18-1.26-1.78c2.61-3.79,5.21-7.58,7.82-11.38C747.99,635.96,748.79,636.24,749.58,636.53z'
          />
          <path
            fill='#D50000'
            d='M2309.57,574.43c-2.34-0.91-5.2-1.27-6.89-2.87c-2.6-2.47-3.36-5.88-0.66-9.94c3.08,3.82,5.94,7.37,8.8,10.92
			C2310.4,573.17,2309.98,573.8,2309.57,574.43z'
          />
          <path
            fill='#D50000'
            d='M2322.69,586.79c2.79,3.57,5.43,6.96,8.31,10.64c-4.23,1.9-6.91,0.41-8.63-2.34
			C2320.88,592.72,2319.51,589.82,2322.69,586.79z'
          />
          <path
            fill='#D50000'
            d='M616.1,901.61c3.76,7.21,2.29,11.57-4.4,14.01C613.16,910.98,614.45,906.86,616.1,901.61z'
          />
          <path
            fill='#D50000'
            d='M2520,1021.6c0.9,4.89,1.64,8.91,2.44,13.26C2516.54,1032.59,2515.5,1027.66,2520,1021.6z'
          />
          <path
            fill='#D50000'
            d='M2497.48,925.63c1.3,4.63,2.31,8.2,3.38,12.02C2495.26,936.16,2493.96,932.17,2497.48,925.63z'
          />
          <path
            fill='#D50000'
            d='M2458.61,815.67c-5.92-1.42-6.8-3.37-5.5-12.23C2455.19,808.07,2456.72,811.48,2458.61,815.67z'
          />
          <path
            fill='#D50000'
            d='M2343.6,614.23c2.35,3.16,4.46,5.99,6.7,9C2344.97,623.82,2342.27,620.26,2343.6,614.23z'
          />
          <path
            fill='#D50000'
            d='M2431.01,756.06c1.99,4.06,3.46,7.06,4.93,10.06C2430.77,765.07,2429.83,761.78,2431.01,756.06z'
          />
        </g>
      </g>
    </svg>
  );
};
